import * as React from "react";

import PropTypes from "prop-types";
import { Button, Modal } from "semantic-ui-react";

import styles from "./PublishFeedbackModal.module.css";
import SkipButton from "../../SkipButton";

const { useState } = React;

function PublishFeedbackModal({
  visibility,
  onOpenModal,
  onCloseModal,
  message = "Are you sure you're ready to publish?",
  onConfirm,
}) {
  return (
    <Modal
      size="small"
      centered={true}
      open={visibility}
      onOpen={onOpenModal}
      onClose={onCloseModal}
      closeOnDimmerClick={false}
    >
      <div className={styles.modalContainer}>
        <Button
          className={styles.closeButton}
          onClick={onCloseModal}
          size="big"
          icon="close"
        />

        <text className={styles.headerText}>{message}</text>

        <div className={styles.buttonGroup}>
          <button className={styles.nextTipButton} onClick={onConfirm}>
            Yes
          </button>

          <SkipButton onClick={onCloseModal} buttonText="No" />
        </div>
      </div>
    </Modal>
  );
}

PublishFeedbackModal.propTypes = {
  visibility: PropTypes.bool.isRequired,
  onOpenModal: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  onConfirmRemoveUser: PropTypes.func.isRequired,
};

export default PublishFeedbackModal;
