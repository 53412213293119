import * as React from "react";

import PropTypes from "prop-types";
import { Modal } from "semantic-ui-react";

import styles from "./CredentialError.module.css";
import SkipButton from "../../SkipButton";

function CredentialError({ visibility, onOpenModal, onCloseModal }) {
  return (
    <Modal
      size="small"
      centered
      open={visibility}
      onOpen={onOpenModal}
      onClose={onCloseModal}
      closeOnDimmerClick={false}
    >
      <div className={styles.modalContainer}>
        <text className={styles.headerText}>
          <span className={styles.headerError}>Error:</span> Your username or
          password is incorrect.
          <p>Please try again.</p>
        </text>
        <SkipButton buttonText="Ok" onClick={onCloseModal} />
      </div>
    </Modal>
  );
}

CredentialError.propTypes = {
  visibility: PropTypes.bool.isRequired,
  onOpenModal: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
};

export default CredentialError;
