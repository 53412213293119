import * as React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import Questions from "../../pages/Admin/Questions";

export default function AdminQuestions () {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route
        exact
        path={`${match.path}`}
		render={() => <Questions /> }
      />
    </Switch>
  );
}
