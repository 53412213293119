import * as React from "react";

import PropTypes from "prop-types";
import { Modal, Button, Icon } from "semantic-ui-react";
import InputComponent from "../../InputComponent";
import styles from "./DeletePermanentSubscriptionModal.module.css";
import { FaFileUpload } from "react-icons/fa";
import { FaSpinner } from "react-icons/fa";

const { useState } = React;

function DeletePermanentSubscriptionModal({
  visibility,
  onOpenModal,
  onCloseModal,
  onConfirm,
  loading = false,
}) {
  return (
    <Modal
      size="small"
      centered
      open={visibility}
      onOpen={onOpenModal}
      onClose={onCloseModal}
    >
      <div className={`${styles.modalContainer} flex flex-col`}>
        {loading ? (
          <div className={`flex flex-col justify-center items-center`}>
            <FaSpinner className={`animate-spin`} />{" "}
            <div>Deleting subscription...</div>
          </div>
        ) : (
          <>
            <Button
              className={styles.closeButton}
              onClick={onCloseModal}
              size="big"
              icon="close"
            />
            <text className={`${styles.headerText} block mb-4`}>
              Are you sure you want to permanently delete?
            </text>
            <text className={`${styles.subText} block mb-8`}>
              All subscription information will be permanently deleted from
              Portal with no option to retrieve the information.
            </text>

            <div
              className={`${styles.btnRow} flex items-center justify-center`}
            >
              <button
                onClick={onConfirm}
                className={`${styles.btnGreen} mr-2.5`}
              >
                Permanently delete
              </button>
              <button onClick={onCloseModal} className={`${styles.btnRed}`}>
                Cancel
              </button>
            </div>
          </>
        )}
      </div>
    </Modal>
  );
}

DeletePermanentSubscriptionModal.propTypes = {
  visibility: PropTypes.bool.isRequired,
  onOpenModal: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
};

export default DeletePermanentSubscriptionModal;
