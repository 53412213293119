import * as React from "react";

import PropTypes from "prop-types";
import { Modal, Button, Icon } from "semantic-ui-react";
import InputComponent from "../../InputComponent";
import UnlinkQuestionModal from "../UnlinkQuestionModal";
import SuccessUnlinkQuestionModal from "../SuccessUnlinkQuestionModal";
import styles from "./AddResourceModal.module.css";
import {
  FaFileUpload,
  FaLink,
  FaTimes,
  FaRegFileWord,
  FaSpinner,
} from "react-icons/fa";
import MediaHandler from "../../../components/MediaHandler/MediaHandler";
import { uploadResource } from "../../../api/requests";
import { isEmpty } from "lodash";
import { Empty } from "antd";

const { useState, useEffect, useRef } = React;

function AddResourceModal({
  visibility,
  nqaId,
  childNqaId,
  onOpenModal,
  onCloseModal,
}) {
  const [currentTab, setCurrentTab] = useState(1);
  const [chooseQuestionsUpload, setChooseQuestionsUpload] = useState(false);
  const [chooseQuestionsLink, setChooseQuestionsLink] = useState(false);
  const [unlinkQuestionModal, setUnlinkQuestionModal] = useState(false);
  const [successUnlinkQuestionModal, setSuccessUnlinkQuestionModal] = useState(
    false
  );

  const [resourceFile, setResourceFile] = useState({});
  const [resourceName, setResourceName] = useState("");

  const [urlResource, setUrlResource] = useState("");
  const [urlResourceName, setUrlResourceName] = useState("");

  const [fieldChecked, setFieldChecked] = useState(false);

  const [urlFieldChecked, setUrlFieldChecked] = useState(false);

  const [uploading, setUploading] = useState(false);

  const inputRef = useRef();

  const onConfirmUnlinkQuestion = async () => {
    setSuccessUnlinkQuestionModal(true);
    setUnlinkQuestionModal(false);
  };

  const onSaveLinkResource = async () => {
    setUrlFieldChecked(true);
    if (!urlResource || !urlResourceName) {
      return;
    }

    try {
      setUploading(true);
      const response = await uploadResource({
        base64Image: urlResource,
        name: urlResource,
        label: urlResourceName,
        userId: 2,
        nqaId: Number(nqaId),
        childNqaId: String(childNqaId),
      });
      setUploading(false);
      onCloseModal();
    } catch (error) {
      setUploading(false);
      onCloseModal();
      console.log(error);
    }
  };

  const onSaveResource = async () => {
    setFieldChecked(true);
    if (!resourceName || isEmpty(resourceFile)) {
      return;
    }

    try {
      setUploading(true);
      const response = await uploadResource({
        base64Image: resourceFile.file,
        name: resourceName,
        label: resourceName,
        userId: 1,
        nqaId: Number(nqaId),
        childNqaId: String(childNqaId),
      });
      setUploading(false);
      onCloseModal();
    } catch (error) {
      setUploading(false);
      onCloseModal();
      console.log(error);
    }
  };

  const onSelectResource = (event) => {
    try {
      const files = event.target.files;

      const reader = new FileReader();

      reader.readAsDataURL(files[0]);

      reader.onload = (e) =>
        setResourceFile({
          file: e.target.result,
          mediaType: files[0].type.split("/")[0],
          type: files[0].type.split("/")[1],
          size: files[0].size,
          name: files[0].name,
        });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Modal
      size="large"
      centered
      open={visibility}
      onOpen={onOpenModal}
      onClose={onCloseModal}
    >
      <UnlinkQuestionModal
        visibility={unlinkQuestionModal}
        onOpenModal={() => setUnlinkQuestionModal(true)}
        onCloseModal={() => setUnlinkQuestionModal(false)}
        onConfirmUnlinkQuestion={onConfirmUnlinkQuestion}
      />

      <SuccessUnlinkQuestionModal
        visibility={successUnlinkQuestionModal}
        onOpenModal={() => setSuccessUnlinkQuestionModal(true)}
        onCloseModal={() => setSuccessUnlinkQuestionModal(false)}
      />

      <div className={`${styles.modalContainer} flex flex-col`}>
        <Button
          className={styles.closeButton}
          onClick={onCloseModal}
          size="big"
          icon="close"
        />
        <text className={`${styles.headerText} block mb-2.5`}>
          Add a resource
        </text>
        <text className={`${styles.subText} block mb-11`}>
          Add a new resource document or link for inclusion into the App
        </text>

        <div className={`${styles.tabContainer} flex mb-9`}>
          <div
            className={`${
              currentTab === 1 ? styles.selectedTab : styles.tab
            } flex items-center justify-center`}
            onClick={() => setCurrentTab(1)}
          >
            <FaFileUpload className={`mr-3`} />
            Upload
          </div>

          <div
            className={`${
              currentTab === 2 ? styles.selectedTab : styles.tab
            } flex items-center justify-center`}
            onClick={() => setCurrentTab(2)}
          >
            <FaLink className={`mr-3`} />
            Link
          </div>
        </div>

        <div className={`${styles.tabBodyContainer}`}>
          {currentTab === 1 ? (
            <>
              <div className={`mb-7`}>
                <input
                  ref={inputRef}
                  type="file"
                  accept={`audio/*,video/*,image/*,application/*,text/*`}
                  style={{ display: "none" }}
                  onChange={onSelectResource}
                />

                {isEmpty(resourceFile) && (
                  <span
                    className={`${styles.uploadResourceTitle} block mb-2.5`}
                  >
                    Upload resource (PDF, MP4, Word, Excel, PPT)
                  </span>
                )}

                <div
                  className={`${styles.uploadResourcePlaceholder} text-center py-5`}
                  onClick={() => inputRef.current.click()}
                >
                  {!isEmpty(resourceFile) ? (
                    <MediaHandler resourceFile={resourceFile} />
                  ) : (
                    <div>
                      <Icon
                        className={styles.uploadResourceIcon}
                        name="arrow up"
                      />

                      <text
                        className={`${styles.uploadResourceIconText} block mt-5`}
                      >
                        Upload document
                      </text>
                    </div>
                  )}
                </div>
              </div>

              {/*
              <InputComponent
                fieldName="findService"
                type="text"
                label="Choose NQA areas (separate by comma)"
                placeholder="Start typing (e.g. 1.1.2, 2.2.1)"
              />
              */}

              <InputComponent
                fieldName="findService"
                type="text"
                label="Document name"
                placeholder="Document Name"
                setInputValue={(_, value) => {
                  setResourceName(value);
                }}
              />

              <div className={`text-center mt-7`}>
                <button
                  disabled={uploading}
                  onClick={onSaveResource}
                  className={`${styles.saveButton}`}
                >
                  {uploading ? (
                    <div className={`flex justify-center items-center `}>
                      {" "}
                      <FaSpinner className={`animate-spin`} />{" "}
                      <span className={`ml-2`}>Uploading...</span>{" "}
                    </div>
                  ) : (
                    <> Save resource </>
                  )}
                </button>
              </div>

              {/* <div className={`${styles.chooseQuestions} text-center`}>
                <text className={`block`}>
                  Link this resource to a question
                </text>

                <button
                  className={`${styles.chooseQuestionsButton}`}
                  onClick={() => setChooseQuestionsUpload(true)}
                >
                  Choose questions
                </button>
              </div> */}

              {/* {chooseQuestionsUpload === true ? (
                <div className={`${styles.questionsContainer} mt-16`}>
                  <text className={`mb-6 block`}>
                    3 currently linked questions
                  </text>

                  <div className={`flex`}>
                    <div
                      className={`${styles.questionText} ${styles.selected} flex`}
                    >
                      <div
                        className={`${styles.checkbox} ${styles.selected} mr-6`}
                      >
                        <label>
                          <input type="checkbox" />
                        </label>
                      </div>

                      <text>
                        Is an individual learning plan evident for each child
                        and includes references to all 5 Learning Outcomes? Over
                        time, do individual childrens records show distance
                        travelled in relation to the 5 learning outcomes of
                        EYLF? Is an individual learning plan evident for each
                        child and includes references to all 5 Learning
                        Outcomes? Over time, do individual childrens records
                        show ‘distance travelled in relation to the 5 learning
                        outcomes of EYLF?
                      </text>
                    </div>

                    <div className={`${styles.questionButton}`}>
                      <button
                        className={`flex items-center p-4 rounded text-base`}
                        onClick={() => setUnlinkQuestionModal(true)}
                      >
                        <FaTimes className={`mr-3`} size={15} />
                        Unlink
                      </button>
                    </div>
                  </div>

                  <div className={`flex`}>
                    <div className={`${styles.questionText} flex`}>
                      <div className={`${styles.checkbox} mr-6`}>
                        <label>
                          <input type="checkbox" />
                        </label>
                      </div>

                      <text>
                        Co-programming occurs at the service through morning/
                        afternoon minutes e.g. planning and reflective sessions
                        with the children.
                      </text>
                    </div>

                    <div className={`${styles.questionButton}`}>
                      <button
                        className={`flex items-center p-4 rounded text-base`}
                        onClick={() => setUnlinkQuestionModal(true)}
                      >
                        <FaTimes className={`mr-3`} size={15} />
                        Unlink
                      </button>
                    </div>
                  </div>

                  <div className={`flex`}>
                    <div className={`${styles.questionText} flex`}>
                      <div className={`${styles.checkbox} mr-6`}>
                        <label>
                          <input type="checkbox" />
                        </label>
                      </div>

                      <text>
                        The service documents through a variety of formats, e.g.
                        mind maps, video/audio recordings, learning stories, and
                        a series of jottings and anecdotes.
                      </text>
                    </div>

                    <div className={`${styles.questionButton}`}>
                      <button
                        className={`flex items-center p-4 rounded text-base`}
                        onClick={() => setUnlinkQuestionModal(true)}
                      >
                        <FaTimes className={`mr-3`} size={15} />
                        Unlink
                      </button>
                    </div>
                  </div>

                  <div className={`text-center mt-6`}>
                    <button
                      className={`${styles.saveButton}`}
                      onClick={() => setChooseQuestionsUpload(false)}
                    >
                      Save changes
                    </button>
                  </div>
                </div>
              ) : (
                <></>
              )} */}
            </>
          ) : (
            <>
              <InputComponent
                fieldName="urlResource"
                type="text"
                label="Provide a URL link to the resource (e.g. webinar / YouTube video)"
                setInputValue={(_, value) => setUrlResource(value)}
              />

              {/*
              <InputComponent
                fieldName="findService"
                type="text"
                label="Choose NQA areas (separate by comma)"
				placeholder="Start typing (e.g. 1.1.2, 2.2.1)"
              />
              */}

              <InputComponent
                fieldName="urlResourceName"
                type="text"
                label="Resource name"
                setInputValue={(_, value) => setUrlResourceName(value)}
              />

              <div className={`text-center mt-7`}>
                <button
                  disabled={uploading}
                  onClick={onSaveLinkResource}
                  className={`${styles.saveButton}`}
                >
                  {uploading ? (
                    <div className={`flex justify-center items-center `}>
                      {" "}
                      <FaSpinner className={`animate-spin`} />{" "}
                      <span className={`ml-2`}>Uploading...</span>{" "}
                    </div>
                  ) : (
                    <> Save resource </>
                  )}
                </button>
              </div>

              {/* <div className={`${styles.chooseQuestions} text-center`}>
                <text className={`block`}>
                  Link this resource to a question
                </text>

                <button
                  className={`${styles.chooseQuestionsButton}`}
                  onClick={() => setChooseQuestionsLink(true)}
                >
                  Choose questions
                </button>
              </div> */}

              {/* {chooseQuestionsLink === true ? (
                <div className={`${styles.questionsContainer} mt-16`}>
                  <text className={`mb-6 block`}>
                    3 currently linked questions
                  </text>

                  <div className={`flex`}>
                    <div
                      className={`${styles.questionText} ${styles.selected} flex`}
                    >
                      <div
                        className={`${styles.checkbox} ${styles.selected} mr-6`}
                      >
                        <label>
                          <input type="checkbox" />
                        </label>
                      </div>

                      <text>
                        Is an individual learning plan evident for each child
                        and includes references to all 5 Learning Outcomes? Over
                        time, do individual childrens records show distance
                        travelled in relation to the 5 learning outcomes of
                        EYLF? Is an individual learning plan evident for each
                        child and includes references to all 5 Learning
                        Outcomes? Over time, do individual childrens records
                        show ‘distance travelled in relation to the 5 learning
                        outcomes of EYLF?
                      </text>
                    </div>

                    <div className={`${styles.questionButton}`}>
                      <button
                        className={`flex items-center p-4 rounded text-base`}
                        onClick={() => setUnlinkQuestionModal(true)}
                      >
                        <FaTimes className={`mr-3`} size={15} />
                        Unlink
                      </button>
                    </div>
                  </div>

                  <div className={`flex`}>
                    <div className={`${styles.questionText} flex`}>
                      <div className={`${styles.checkbox} mr-6`}>
                        <label>
                          <input type="checkbox" />
                        </label>
                      </div>

                      <text>
                        Co-programming occurs at the service through morning/
                        afternoon minutes e.g. planning and reflective sessions
                        with the children.
                      </text>
                    </div>

                    <div className={`${styles.questionButton}`}>
                      <button
                        className={`flex items-center p-4 rounded text-base`}
                        onClick={() => setUnlinkQuestionModal(true)}
                      >
                        <FaTimes className={`mr-3`} size={15} />
                        Unlink
                      </button>
                    </div>
                  </div>

                  <div className={`flex`}>
                    <div className={`${styles.questionText} flex`}>
                      <div className={`${styles.checkbox} mr-6`}>
                        <label>
                          <input type="checkbox" />
                        </label>
                      </div>

                      <text>
                        The service documents through a variety of formats, e.g.
                        mind maps, video/audio recordings, learning stories, and
                        a series of jottings and anecdotes.
                      </text>
                    </div>

                    <div className={`${styles.questionButton}`}>
                      <button
                        className={`flex items-center p-4 rounded text-base`}
                        onClick={() => setUnlinkQuestionModal(true)}
                      >
                        <FaTimes className={`mr-3`} size={15} />
                        Unlink
                      </button>
                    </div>
                  </div>

                  <div className={`text-center mt-6`}>
                    <button
                      className={`${styles.saveButton}`}
                      onClick={() => setChooseQuestionsLink(false)}
                    >
                      Save changes
                    </button>
                  </div>
                </div>
              ) : (
                <></>
              )} */}
            </>
          )}
        </div>
      </div>
    </Modal>
  );
}

AddResourceModal.propTypes = {
  visibility: PropTypes.bool.isRequired,
  onOpenModal: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
};

export default AddResourceModal;
