import * as React from "react";

import PropTypes from "prop-types";
import { Modal, Button, Dropdown } from "semantic-ui-react";
import InputComponent from "../../InputComponent";
import BlueButton from "../../BlueButton";
import styles from "./ChooseUserModal.module.css";
import { isEmpty } from "lodash";

const { useState } = React;

function ChooseUserModal({
  visibility,
  onOpenModal,
  onCloseModal,
  currentUsers = [],
  setCurrentUsers,
  users,
}) {
  const [checkedUsers, setCheckedUsers] = useState(currentUsers);

  const [searchKey, setSearchKey] = useState("");

  const onFilterUsers = (user) => {
    if (!checkedUsers.includes(user.value)) {
      setCheckedUsers([...checkedUsers, user.value]);
    } else {
      if (!isEmpty(checkedUsers)) {
        setCheckedUsers([...checkedUsers.filter((c) => c !== user.value)]);
      }
    }
  };

  return (
    <Modal
      size="small"
      centered
      open={visibility}
      onOpen={onOpenModal}
      onClose={onCloseModal}
    >
      <div className={`${styles.modalContainer} flex flex-col`}>
        <Button
          className={styles.closeButton}
          onClick={onCloseModal}
          size="big"
          icon="close"
        />

        <text className={`${styles.headerText} block mb-7`}>
          Choose one or more users to send this message to
        </text>

        <div className={`${styles.modalContent}`}>
          <div className={styles.inputGroup}>
            <input
              className={styles.inputBox}
              placeholder={`Search...`}
              value={searchKey}
              onChange={(e) => setSearchKey(e.target.value)}
            />
          </div>
          <div className={`${styles.servicesList}`}>
            <ul>
              {!isEmpty(users) &&
                users
                  .filter((user) => {
                    if (searchKey.trim()) {
                      return user.text
                        .toLowerCase()
                        .includes(searchKey.toLowerCase());
                    } else {
                      return true;
                    }
                  })
                  .map((user) => {
                    return (
                      <li
                        onClick={() => {
                          onFilterUsers(user);
                        }}
                        className={`${
                          checkedUsers.includes(user.value)
                            ? styles.selected
                            : ""
                        }`}
                      >
                        {user.text}
                        <label className={`${styles.checkbox}`}>
                          <span className={`${styles.checkmark}`}></span>
                        </label>
                      </li>
                    );
                  })}
            </ul>
          </div>

          <div className={`${styles.actionBtn}`}>
            <button
              onClick={() => {
                setCurrentUsers(checkedUsers);
                onCloseModal();
              }}
            >
              Select users
            </button>
          </div>

          <div className={styles.clearSection}>
            <button
              onClick={() => {
                setCheckedUsers([]);
                setCurrentUsers([]);
                // onCloseModal();
              }}
              className={styles.clearSectionButton}
            >
              Clear selection
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

ChooseUserModal.propTypes = {
  visibility: PropTypes.bool.isRequired,
  onOpenModal: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
};

export default ChooseUserModal;
