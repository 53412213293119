import * as React from "react";
import PropTypes from "prop-types";

import { Icon } from "semantic-ui-react";
import { Link, useHistory, useRouteMatch } from "react-router-dom";

import styles from "./PeopleResponsible.module.css";
import BlueButton from "../../../components/BlueButton";
import { ServiceDetailsContext } from "../../../context/ServiceDetailsContext";
import { useStoreState } from "../../../hooks";

import { ServiceSteps } from "../../../components/Steps";

import { FaSpinner } from "react-icons/fa";

const { useContext, useEffect, useState } = React;

const stepsNotDone = [
  {
    id: 11,
    stepsName: "Philosophy",
  },
  {
    id: 12,
    stepsName: "Upload logo",
  },
];

const stepsDone = [
  {
    id: 1,
    stepsName: "Location",
  },
  {
    id: 2,
    stepsName: "Postal Address",
  },
  {
    id: 3,
    stepsName: "Contact Details",
  },
  {
    id: 4,
    stepsName: "Providers",
  },
  {
    id: 5,
    stepsName: "Supervisor",
  },
  {
    id: 6,
    stepsName: "Leader",
  },
  {
    id: 7,
    stepsName: "Operating hours",
  },
  {
    id: 8,
    stepsName: "Additional info",
  },
  {
    id: 9,
    stepsName: "Educators",
  },
];

function PeopleResponsible({ onNextStep, onSaveStep, onSkipServiceDetails }) {
  const history = useHistory();
  const match = useRouteMatch();

  const {
    saveLoading,
    serviceDetailsState,
    changeInputDispatch,
    stepsIndicator,
  } = useContext(ServiceDetailsContext);

  const isLoggedIn = useStoreState((state) => state.authStore.isLoggedIn);

  const [fieldChecked, setFieldChecked] = useState(false);
  const [errors, setErrors] = useState([]);
  const [initialEmployees, setInitialEmployees] = useState(
    serviceDetailsState.input.employees
      ? serviceDetailsState.input.employees
      : []
  );

  const [employees, setEmployees] = useState(
    serviceDetailsState.input.employees
      ? serviceDetailsState.input.employees.length
      : 0
  );

  const setEmployeeDetails = (index, fieldInput, value) => {
    setErrors([]);
    const currentEmployees = serviceDetailsState.input.employees
      ? [...serviceDetailsState.input.employees]
      : [
          {
            id: index,
            name: "",
            position: "",
          },
        ];

    if (currentEmployees.find((employee) => employee.id === index)) {
      currentEmployees[
        currentEmployees.findIndex((employee) => employee.id === index)
      ][fieldInput] = value;
    } else {
      const employeeDetail = {
        id: index,
        name: "",
        position: "",
      };

      employeeDetail[fieldInput] = value;
      currentEmployees.push(employeeDetail);
    }

    changeInputDispatch("employees", currentEmployees);
  };

  const onSave = async () => {
    setFieldChecked(true);

    if (!(employees > 0 && errors.length <= 0)) {
      return;
    }
    await onSaveStep();

    onNextStep();
    history.push(
      `${match.path
        .split("/")
        .slice(0, match.path.split("/").length - 1)
        .join("/")}/11`
    );
    // } else alert("Please fill all required fields.");
  };

  const EmployeesValidation = (index, field = "name", newAddition = false) => {
    let message = "";

    if (employees > 0) {
      if (newAddition) {
        message = "This field is required.";
      } else {
        if (serviceDetailsState.input.employees[index][field].length <= 0) {
          message = "This field is required.";
        }
      }
    }

    if (message.length > 0) {
      if (!errors.includes(index)) {
        setErrors([...errors, index]);
      }
    }
    return message;
  };

  useEffect(() => {
    if (employees < 0) setEmployees(0);
  }, [employees]);

  return (
    <div className={styles.mainContainer}>
      {isLoggedIn && (
        <>
          <div className={styles.headerContainer}>
            <h1>Complete your service details</h1>
            <p>
              Please fill out all missing fields marked with an ‘
              <span className={styles.required}>*</span>’
            </p>
          </div>
        </>
      )}
      <div className={styles.mainCard}>
        <div className={styles.cardContent}>
          <h1 className={styles.headerText}>
            Name and position of person(s) responsible for submitting this
            Quality Improvement Plan (QIP)
          </h1>

          <text className={styles.pleaseComplete}>
            Please complete the below fields.
          </text>

          <text className={styles.descriptionText}>
            How many employees will work on your QIP?
          </text>

          <input
            className={styles.workforceVal}
            type="number"
            value={employees}
            onChange={(e) => {
              setErrors([]);
              if (!isNaN(e.target.value)) {
                changeInputDispatch(
                  "employees",
                  initialEmployees.slice(0, e.target.value)
                );
              }
              setEmployees(e.target.value);
            }}
          />
          {!(employees > 0) && fieldChecked && (
            <div className={`p-2 text-red-400 font-thin`}>
              Please provide number of employees that will work on your QIP.
            </div>
          )}
          <div>
            {employees > 0
              ? Array.from({ length: employees }, (v, index) => (
                  <div className={styles.educatorEntry} key={index}>
                    <div>
                      <text className={styles.inputFieldHeader}>
                        Full Name <span className={styles.required}>*</span>
                      </text>

                      <input
                        className={styles.inputBox}
                        type="text"
                        value={
                          serviceDetailsState.input.employees &&
                          serviceDetailsState.input.employees[index]
                            ? serviceDetailsState.input.employees[index].name
                            : ""
                        }
                        onChange={(e) =>
                          setEmployeeDetails(index + 1, "name", e.target.value)
                        }
                      />
                      <span className={`p-1 text-red-400 font-thin`}>
                        {EmployeesValidation(
                          index,
                          "name",
                          serviceDetailsState.input.employees &&
                            !serviceDetailsState.input.employees[index]
                        )}
                      </span>
                    </div>

                    <div>
                      <text className={styles.inputFieldHeader}>
                        Position<span className={styles.required}>*</span>
                      </text>

                      <input
                        className={styles.inputBox}
                        type="text"
                        value={
                          serviceDetailsState.input.employees &&
                          serviceDetailsState.input.employees[index]
                            ? serviceDetailsState.input.employees[index]
                                .position
                            : ""
                        }
                        onChange={(e) =>
                          setEmployeeDetails(
                            index + 1,
                            "position",
                            e.target.value
                          )
                        }
                      />
                      <span className={`p-1 text-red-400 font-thin`}>
                        {EmployeesValidation(
                          index,
                          "position",
                          serviceDetailsState.input.employees &&
                            !serviceDetailsState.input.employees[index]
                        )}
                      </span>
                    </div>
                  </div>
                ))
              : null}
          </div>

          <div className={styles.buttonHolders}>
            <BlueButton // buttonText="Save step"
              buttonText={
                saveLoading ? (
                  <div className={`flex justify-center items-center`}>
                    <FaSpinner className={`animate-spin`} />
                    <span className={`block pl-2`}>Save step</span>
                  </div>
                ) : (
                  <> Save step</>
                )
              }
              onClick={onSave}
            />

            <button
              className={styles.buttonNextStep}
              onClick={() =>
                history.push(
                  `${match.path
                    .split("/")
                    .slice(0, match.path.split("/").length - 1)
                    .join("/")}/11`
                )
              }
            >
              Next step
            </button>
          </div>

          <div className={styles.buttonStep}>
            <button
              className={styles.buttonStepBack}
              onClick={() =>
                history.push(
                  `${match.path
                    .split("/")
                    .slice(0, match.path.split("/").length - 1)
                    .join("/")}/9`
                )
              }
            >
              <Icon name="angle left" />
              Back a step
            </button>

            <button
              className={styles.buttonFinishLater}
              onClick={onSkipServiceDetails}
            >
              Finish Later
              <Icon name="angle double right" />
            </button>
          </div>
        </div>

        <div className={styles.stepsColumn}>
          {/* {!isLoggedIn && (
            <div
              className={`${styles.stepItemContainer} ${styles.stepItemTickContainer}`}
              onClick={() => history.push(`/qip/auth/legal-notice`)}
            >
              <div className={styles.stepContainerText}>
                <div className={styles.stepItemIndicatorContainer}>
                  <div className={styles.stepItemFillDone} />
                </div>
                Welcome
              </div>
            </div>
          )} */}
          <ServiceSteps route={match.path} />
          {/* {stepsDone.map(steps => (
            <div
              key={steps.id}
              className={stepsIndicator.find(step => step.id === steps.id).completed ? `${styles.stepItemContainer} ${styles.stepItemTickContainer}` : `${styles.stepItemContainer} ${styles.stepItemCrossContainer}`}
              onClick={() => history.push(`${match.path.split('/').slice(0, match.path.split('/').length - 1).join('/')}/${steps.id}`)}
            >
              <div
                className={styles.stepContainerText}
              >
                <div className={styles.stepItemIndicatorContainer}>
                  {stepsIndicator.find(step => step.id === steps.id).completed
                    ? <div className={styles.stepItemFillDone} />
                    : (
                      <Icon
                        className={styles.stepItemUnfinished}
                        name="close"
                      />
                    )}
                </div>
                {steps.stepsName}
              </div>
            </div>
          ))}

          <div className={styles.CurrentItemContainer}>

            <div className={styles.stepItemCurrent}>
              <div className={styles.stepItemFillDone} />
            </div>

            <text className={styles.stepItemCurrentText}>
              QIP submission
            </text>

          </div>

          {stepsNotDone.map(steps => (
            <div
              key={steps.id}
              className={styles.stepItemContainer}
            >
              <Link
                className={styles.stepItemCurrentText}
                to={`${match.path.split('/').slice(0, match.path.split('/').length - 1).join('/')}/${steps.id}`}
              >
                <div className={styles.stepItemIndicatorContainer}>
                  <div className={styles.stepItemFill} />
                </div>
                {steps.stepsName}
              </Link>
            </div>
          ))} */}
        </div>
      </div>
    </div>
  );
}

PeopleResponsible.propTypes = {
  onNextStep: PropTypes.func,
  onSkipStep: PropTypes.func,
  onSaveStep: PropTypes.func,
  onSkipServiceDetails: PropTypes.func,
};

export default PeopleResponsible;
