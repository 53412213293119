import * as React from "react";
import { useHistory } from "react-router-dom";
import { Button, Input } from "semantic-ui-react";
import { FaCommentDots, FaCaretDown } from "react-icons/fa";
import FilterUpcomingTasksModal from "../../../components/Modals/FilterUpcomingTasksModal";
import TasksChooseDateRangeModal  from "../../../components/Modals/TasksChooseDateRangeModal";
import ChangeStatusModal from "../../../components/Modals/ChangeStatusModal";

import styles from "./Tasks.module.css";

const { useState } = React;

const tasksPerPage = 10; 

function Tasks () {
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedTaskFilter, setSelectedTaskFilter] = useState(1);
  const [filterUpcomingTasksModal, setFilterUpcomingTasksModal] = useState(false);  
  const [tasksChooseDateRangeModal , setTasksChooseDateRangeModal ] = useState(false);  
  const [changeStatusModal, setChangeStatusModal] = useState(false);  

  return (
    <div className={`${styles.mainContainer} mt-5 md:mb-28 lg:mb-10`}>

      {filterUpcomingTasksModal && (
        <FilterUpcomingTasksModal
          visibility={filterUpcomingTasksModal}
          onOpenModal={() => setFilterUpcomingTasksModal(true)}
          onCloseModal={() => setFilterUpcomingTasksModal(false)}
        />
      )}

      {tasksChooseDateRangeModal && (
        <TasksChooseDateRangeModal 
          visibility={tasksChooseDateRangeModal}
          onOpenModal={() => setTasksChooseDateRangeModal(true)}
          onCloseModal={() => setTasksChooseDateRangeModal(false)}
        />
      )}

      {changeStatusModal && (
        <ChangeStatusModal
          visibility={changeStatusModal}
          onOpenModal={() => setChangeStatusModal(true)}
          onCloseModal={() => setChangeStatusModal(false)}
        />
      )}

      <div className={`${styles.headerTextContainer} flex`}>

        <div className={styles.headerTextIcon}>
          <FaCommentDots size={55} />
        </div>

        <div className={`${styles.headerText} ml-4`}>
          <h1>Admin Tasks</h1>
          <text>View, update and action tasks</text>
        </div>

      </div>

      <div className={`${styles.main} md:px-7 lg:px-14 pt-9 pb-16 mt-9`}>

        <div className={`${styles.inputContainer} flex flex-wrap`}>

          <text className={`block mb-1.5`}>Assignee name</text>

          <Input
            className={styles.searchBox}
            icon="search"
            placeholder="Type name"
          />

        </div>

        <div className={`${styles.searchFilterButtonGroup} flex flex-wrap justify-between mt-9`}>

          <button
            className={
              selectedTaskFilter === 1 ? styles.selectedTaskFilterButton : styles.taskFilterButton
            }
            onClick={() => setSelectedTaskFilter(1)}
          >
            All tasks
          </button>

          <button
            className={
              selectedTaskFilter === 2 ? styles.selectedTaskFilterButton : styles.taskFilterButton
            }
            onClick={() => { setSelectedTaskFilter(2); setTasksChooseDateRangeModal(true); } }
          >
            Overdue tasks
          </button>

          <button
            className={selectedTaskFilter === 3 ? styles.selectedTaskFilterButton : styles.taskFilterButton}
            onClick={() => { setSelectedTaskFilter(3); setFilterUpcomingTasksModal(true); } }
          >
            Upcoming tasks
          </button>

          <button
            className={selectedTaskFilter === 4 ? styles.selectedTaskFilterButton : styles.taskFilterButton}
            onClick={() => { setSelectedTaskFilter(4); setChangeStatusModal(true); } }
          >
            Tasks by status
          </button>

          </div>

        <div className={`${styles.tableContainer} my-9`}>
          <div className={`${styles.tableHeader} flex flex-wrap`}>
            <div className={`${styles.tableHeaderName} flex p-4`}>
              <text>Task name</text>
              <FaCaretDown className={`ml-1 mt-1`} size={12} />
            </div>
            <div className={`${styles.tableHeaderName} flex p-4`}>
              <text>Assignee</text>
              <FaCaretDown className={`ml-1 mt-1`} size={12} />
            </div>
            <div className={`${styles.tableHeaderName} flex p-4`}>
              <text>Task Due</text>
              <FaCaretDown className={`ml-1 mt-1`} size={12} />
            </div>
            <div className={`${styles.tableHeaderName} flex p-4`}>
              <text>Status</text>
              <FaCaretDown className={`ml-1 mt-1`} size={12} />
            </div>
            <div className={`${styles.tableHeaderName} flex p-4`}>

            </div>
          </div>
          <div className={`${styles.tableBody}`}>

            <div className={`${styles.tableRow} flex flex-wrap`}>
              <div className={`${styles.tableData} flex items-center p-4`}>
                <text>Upload missing documentation for NQA1</text>
              </div>
              <div className={`${styles.tableData} flex items-center p-4`}>
                <text>Peter Jenkins</text>
              </div>
              <div className={`${styles.tableData} flex items-center p-4`}>
                <text>21/10/2020</text>
              </div>
              <div className={`${styles.tableData} flex items-center p-4`}>
                <text>In progress</text>
              </div>
              <div className={`${styles.tableData} flex items-center justify-center	p-1.5`}>
                <button
                  className={`${styles.openButton} flex items-center justify-center`}
                >
                  Open
                </button>
              </div>
            </div>

          </div>
        </div>

        <div className={`${styles.paginationContainer} flex justify-start pt-7`}>
          <Button
            className={`${styles.pageButtonStartEnd} rounded`}
            icon="angle double left"
            onClick={() => setCurrentPage(1)}
          />

          {
          [...Array(Math.ceil( 50 / tasksPerPage)).keys()].map(
            (page) => (
              <button
                key={page}
                className={
                  currentPage === page + 1
                    ? styles.pageButtonSelected
                    : styles.pageButton
                }
                onClick={() => setCurrentPage(page + 1)}
              >
                {page + 1}
              </button>
            )
          )
          }

          <Button
            className={`${styles.pageButtonStartEnd} rounded`}
            icon="angle double right"
            onClick={() =>
              setCurrentPage(Math.ceil( 50 / tasksPerPage))
            }
          />
        </div>

      </div>

    </div>
  );

}
 
export default Tasks;