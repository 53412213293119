import * as React from 'react';

import PropTypes from 'prop-types';
import { Modal, Button } from 'semantic-ui-react';

import BlueButton from '../../BlueButton'; 
import styles from './SuccessChangedPasswordModal.module.css';

function SuccessChangedPasswordModal({ visibility, onOpenModal, onCloseModal }) {

  return (
    <Modal
      size="small"
      centered
      open={visibility}
      onOpen={onOpenModal}
      onClose={onCloseModal}
    >
      <div className={styles.modalContainer}>
        <Button
          className={styles.closeButton}
          onClick={onCloseModal}
          size="big"
          icon="close"
        />
        <text className={styles.headerText}>
          User recieved username & temporary password on their email
        </text>

        <BlueButton
          className={styles.saveButton}
          buttonText="Ok"
          onClick={onCloseModal}
        />

      </div>

    </Modal>
  );
}

SuccessChangedPasswordModal.propTypes = {
  visibility: PropTypes.bool.isRequired,
  onOpenModal: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
};

export default SuccessChangedPasswordModal;
