import * as React from "react";

import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";

import AdminQuestions from "../AdminQuestions";
import AdminRequestFeedback from "../AdminRequestFeedback";
import AdminUserManagement from "../AdminUserManagement";
import AdminMessages from "../AdminMessages";
import AdminTasks from "../AdminTasks";
import AdminResources from "../AdminResources";
import AdminSubscribers from "../AdminSubscribers";
import AdminDashboard from "../AdminDashboard";
import { useStoreActions, useStoreState } from "../../hooks";

const { useState, useEffect, useCallback } = React;

export default function LoggedInPages() {
  const match = useRouteMatch();

  const clearSession = useStoreActions((actions) => actions.authStore.logout);

  const clearNQASession = useStoreActions(
    (actions) => actions.nqaStore.clearSession
  );
  const clearUserSession = useStoreActions(
    (actions) => actions.userStore.clearSession
  );

  const handleTokenExpiry = (token) => {
    const t = JSON.parse(atob(token.split(".")[1]));
    const dateNow = new Date();

    if (t.exp - dateNow.getTime() / 1000 < 0) {
      clearNQASession();
      clearUserSession();
      clearSession();
    }
  };

  const checkTokenExpiry = useCallback(async () => {
    try {
      setTimeout(function run() {
        const cachedObject = localStorage.getItem("qip_client_token");
        const authObject = JSON.parse(cachedObject);
        if (authObject) {
          handleTokenExpiry(authObject.accessToken);
        }
        setTimeout(run, 5000);
      }, 5000);
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    checkTokenExpiry();
  }, []);

  return (
    <Switch>
      <Route path={`${match.path}/questions`} component={AdminQuestions} />
      {/* <Route path={`${match.path}/subscribers`} component={AdminSubscribers} /> */}
      <Route path={`${match.path}/tasks`} component={AdminTasks} />
      <Route path={`${match.path}/resources`} component={AdminResources} />
      <Route path={`${match.path}/messages`} component={AdminMessages} />
      <Route
        path={`${match.path}/request-feedback`}
        component={AdminRequestFeedback}
      />
      <Route
        path={`${match.path}/user-management`}
        component={AdminUserManagement}
      />

      {/* <Route path={`${match.path}`} component={AdminDashboard} /> */}

      {/* <Route
        path={`${match.path}`}
        render={() => {
          return <Redirect to={`${match.path}/subscribers/summary`} />;
        }}
      /> */}

      <Route
        path={`${match.path}`}
        render={() => {
          return <Redirect to={`${match.path}/resources`} />;
        }}
      />
    </Switch>
  );
}
