import React from "react";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import { useStoreActions, useStoreState } from "./hooks/";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Banner from "./components/Banner";
import LoggedInPages from "./routes/LoggedInPages";
import LoggedOutPages from "./routes/LoggedOutPages";
import HubAdminRedirect from "./routes/HubAdminRedirect";

// import "./App.css";
// import "semantic-ui-css/semantic.min.css";

import { FaSpinner } from "react-icons/fa";
import MinimumWidthWarning from "./pages/MinimumWidthWarning";

import { app_cache } from "./utils";

const { useEffect, useState } = React;

function App() {
  const isLoggedIn = useStoreState((state) => state.authStore.isLoggedIn);

  const fetchServices = useStoreActions(
    (state) => state.authStore.fetchServices
  );
  const loadAuthToken = useStoreActions(
    (actions) => actions.authStore.loadAuthObject
  );

  const getRemainingTrial = useStoreActions(
    (actions) => actions.userStore.setRemainingTrial
  );

  const setIsAdmin = useStoreActions((actions) => actions.authStore.setIsAdmin);

  const [tokenLoading, setLoading] = useState(true);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const [redirectloading, setRedirectLoading] = useState(false);

  useEffect(() => {
    loadAuthToken().then(() => setLoading(false));
  }, [fetchServices, loadAuthToken, setLoading]);

  useEffect(() => {
    const resize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", resize);
    resize();

    return () => window.removeEventListener("resize", resize);
  }, []);

  const user = useStoreState((state) => state.userStore.loggedInUser);

  // const authObj = useStoreState((state) => state.authStore.authObject);

  // const getAdminAttr = useStoreActions((state) =>
  //   state.authStore.fetchAdminAttr({ memberId: user.id })
  // );

  // const userId = useStoreState((state) => state.authStore.authObject.userId);

  const fetchPaymentMethods = useStoreActions(
    (actions) => actions.userStore.fetchPaymentMethods
  );
  const adminAttr = useStoreState((state) => state.authStore.adminAttr);

  const authObj = useStoreState((state) => state.authStore.authObject);

  const getRemainingDays = () => {
    const date1 = new Date(adminAttr.datetime_end);
    const date2 = new Date();
    const diffTime = date1 - date2;
    getRemainingTrial(Math.ceil(diffTime / (1000 * 60 * 60 * 24)));
    return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  };

  const isTrialEndSoon = () => {
    return getRemainingDays() <= 5;
  };

  let isExpiring = false;
  let remainingDays = 0;
  let subExpired = false;
  if (adminAttr) {
    remainingDays = getRemainingDays();
    console.log("remaining ", remainingDays);
    isExpiring = adminAttr && adminAttr.datetime_end ? isTrialEndSoon() : false;
    if (remainingDays <= 0) {
      subExpired = true;
    }

    // console.log(
    //   "datetime end ",
    //   adminAttr.datetime_end,
    //   " remaining  ",
    //   remainingDays
    // );
  }

  if (isLoggedIn && authObj && adminAttr) {
    if (Number(authObj.userId) === Number(adminAttr.admin_id)) {
      setIsAdmin(true);
      // fetchPaymentMethods({ userId: authObj.userId });
    }
  }

  useStoreActions((state) => state.appStore.setSubExpired(subExpired));

  // if (authObj) {
  //   remainingDays = getRemainingDays();
  //   hasSubs = authObj && authObj.stripeId ? true : false;
  //   isExpiring = authObj && authObj.createdAt ? isTrialEndSoon() : false;
  //   console.log("remaining ", remainingDays);
  //   console.log(isTrialEndSoon());
  // }

  console.log("sub expired ", remainingDays <= 0);
  const banner = useStoreState((state) => state.appStore.banner);

  const nonBlockingUrls = [
    "pricing-plans",
    "free-trial",
    "signup",
    "payment",
    "login",
  ];
  const curLoc = window.location.href;
  let block700 = true;
  nonBlockingUrls.forEach((url) => {
    if (curLoc.indexOf(url) > -1) {
      block700 = false;
    }
  });

  return (
    <div
      className={`w-full h-full min-h-screen flex flex-col justify-between relative`}
    >
      <div className={`flex-1`} style={{ backgroundColor: "#eaeee8" }}>
        {tokenLoading || redirectloading ? (
          <div
            className={`w-full h-screen flex flex-col justify-center items-center`}
          >
            <FaSpinner
              className={`animate-spin text-qipGreen font-bold text-6xl`}
            />

            <div>Logging in...</div>
          </div>
        ) : (
          <>
            <Router basename={process.env.BASENAME}>
              {/* {isLoggedIn &&
                isExpiring &&
                banner &&
                user &&
                user.role === "admin" && <Banner remaining={remainingDays} />} */}
              <Header />

              {windowWidth < 768 && block700 ? (
                <MinimumWidthWarning />
              ) : (
                <Switch>
                  <Route
                    path={`/view-hub-admin-service`}
                    render={() => {
                      return (
                        <HubAdminRedirect
                          iAmLoading={(val) => setRedirectLoading(val)}
                        />
                      );
                    }}
                  />
                  <Route
                    path="/qip/auth"
                    render={() => {
                      return !isLoggedIn ? (
                        <LoggedOutPages />
                      ) : (
                        <Redirect to="/qip" />
                      );
                    }}
                  />
                  <Route
                    path="/qip"
                    render={() => {
                      return isLoggedIn ? (
                        <LoggedInPages />
                      ) : (
                        <Redirect to="/qip/auth" />
                      );
                    }}
                  />
                  <Route
                    path="/"
                    render={() => {
                      return isLoggedIn ? (
                        <Redirect to="/qip" />
                      ) : (
                        <Redirect to="/qip/auth" />
                      );
                    }}
                  />
                </Switch>
              )}
            </Router>
          </>
        )}
      </div>
      <Footer />
    </div>
  );
}

export default App;
