import * as React from "react";

import PropTypes from "prop-types";
import { Modal, Button, Dropdown } from "semantic-ui-react";
import InputComponent from "../../InputComponent";
import BlueButton from "../../BlueButton";
import styles from "./ShowListModal.module.css";
import { isEmpty } from "lodash";

const { useState } = React;

function ShowListModal({
  visibility,
  onOpenModal,
  onCloseModal,
  option = 0,
  items = [],
}) {
  return (
    <Modal
      size="small"
      centered
      open={visibility}
      onOpen={onOpenModal}
      onClose={onCloseModal}
    >
      <div className={`${styles.modalContainer} flex flex-col`}>
        <Button
          className={styles.closeButton}
          onClick={onCloseModal}
          size="big"
          icon="close"
        />

        {option === 4 && (
          <text className={`${styles.headerText} block mb-7`}>
            Users who received this message
          </text>
        )}

        {option === 3 && (
          <text className={`${styles.headerText} block mb-7`}>
            Services that received this message
          </text>
        )}

        <div className={`${styles.modalContent}`}>
          <div className={`${styles.servicesList}`}>
            <ul>
              {!isEmpty(items) &&
                items.map((item) => {
                  return <li>{item}</li>;
                })}
            </ul>
          </div>

          <div className={`${styles.actionBtn}`}>
            <button
              onClick={() => {
                onCloseModal();
              }}
            >
              Ok
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

ShowListModal.propTypes = {
  visibility: PropTypes.bool.isRequired,
  onOpenModal: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
};

export default ShowListModal;
