import * as React from "react";

import PropTypes from "prop-types";
import { Button, Modal } from "semantic-ui-react";

import styles from "./DeleteServiceLogoModal.module.css";
import spinner from "../../../static/images/preloader.png";
import SkipButton from "../../SkipButton";

const { useState } = React;

function DeleteServiceLogoModal({
  visibility,
  onOpenModal,
  onCloseModal,
  onRemoveLogo,
}) {
  const [deleteLoading, setLoading] = useState(false);

  const onDeleteLogo = async () => {
    setLoading(true);
    await onRemoveLogo();
    setLoading(false);
  };

  return (
    <Modal
      size="small"
      centered={true}
      open={visibility}
      // style={{ marginTop: "20vh" }}
      onOpen={onOpenModal}
      onClose={onCloseModal}
      closeOnDimmerClick={false}
    >
      {deleteLoading ? (
        <div
          style={{ height: "20vh" }}
          className={`w-full flex flex-col justify-center items-center`}
        >
          <img src={spinner} className={`block`} />

          <span className={`block`}>Deleting service logo...</span>
        </div>
      ) : (
        <div className={styles.modalContainer}>
          <Button
            className={styles.closeButton}
            onClick={onCloseModal}
            size="big"
            icon="close"
          />

          <text className={styles.headerText}>
            Are you sure you want to remove service logo?
          </text>

          <div className={styles.buttonGroup}>
            <button className={styles.nextTipButton} onClick={onDeleteLogo}>
              Yes
            </button>

            <SkipButton onClick={onCloseModal} buttonText="No" />
          </div>
        </div>
      )}
    </Modal>
  );
}

DeleteServiceLogoModal.propTypes = {
  visibility: PropTypes.bool.isRequired,
  onOpenModal: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  onRemoveLogo: PropTypes.func.isRequired,
};

export default DeleteServiceLogoModal;
