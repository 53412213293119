import React from "react";
import styles from "./NQASelector.module.css";
import { isEmpty } from "lodash";

const { useState } = React;

const NQASelector = ({
  currentNqaLevel,
  nqaData,
  setNqalevel,
  setSubNqalevel,
  onClose,
  setOnFocus,
}) => {
  const [currentFilter, setCurrentFilter] = useState(
    parseInt(currentNqaLevel.split(".")[0])
  );
  const [subCurrentFilter, setSubCurrentFilter] = useState(currentNqaLevel);

  const getSublevels = (filter) => {
    let sublevels = [];
    let id = 0;
    if (nqaData) {
      nqaData[filter - 1].levels.forEach((level) => {
        id = id + 1;
        sublevels.push({ label: level.name, id: id });
        // level.subLevels.forEach((sub) => {
        //   id = id + 1;
        //   sublevels.push({ label: sub.label, id: id });
        // });
      });
    }
    return sublevels;
  };

  return (
    <div
      tabIndex="-1"
      onMouseEnter={() => {
        setOnFocus(true);
      }}
      onMouseLeave={() => {
        setOnFocus(false);
      }}
      className={`relative w-full bg-white -mb-12 `}
    >
      <div
        className={`w-full absolute bottom-0 left-0 bg-white border border-gray-700 rounded-md p-4`}
      >
        <div
          className={` w-full ${styles.resourceFilterRow} flex flex-wrap pb-2.5`}
        >
          <div
            className={`${
              currentFilter === 1
                ? styles.resourceFilterSelected
                : styles.resourceFilter
            }  flex items-center justify-center`}
            onClick={() => {
              setSubCurrentFilter("");
              //   setSubNqalevel("1.1");
              setNqalevel(1);
              setCurrentFilter(1);
            }}
          >
            NQA 1
          </div>
          <div
            className={`${
              currentFilter === 2
                ? styles.resourceFilterSelected
                : styles.resourceFilter
            }  flex items-center justify-center`}
            onClick={() => {
              setSubCurrentFilter("");
              //   setSubNqalevel("2.1");
              setNqalevel(2);
              setCurrentFilter(2);
            }}
          >
            NQA 2
          </div>
          <div
            className={`${
              currentFilter === 3
                ? styles.resourceFilterSelected
                : styles.resourceFilter
            }  flex items-center justify-center`}
            onClick={() => {
              setSubCurrentFilter("");
              //   setSubNqalevel("3.1");
              setNqalevel(3);
              setCurrentFilter(3);
            }}
          >
            NQA 3
          </div>
          <div
            className={`${
              currentFilter === 4
                ? styles.resourceFilterSelected
                : styles.resourceFilter
            }  flex items-center justify-center`}
            onClick={() => {
              setSubCurrentFilter("");
              //   setSubNqalevel("4.1");
              setNqalevel(4);
              setCurrentFilter(4);
            }}
          >
            NQA 4
          </div>
          <div
            className={`${
              currentFilter === 5
                ? styles.resourceFilterSelected
                : styles.resourceFilter
            }  flex items-center justify-center`}
            onClick={() => {
              setSubCurrentFilter("");
              //   setSubNqalevel("5.1");
              setNqalevel(5);
              setCurrentFilter(5);
            }}
          >
            NQA 5
          </div>
          <div
            className={`${
              currentFilter === 6
                ? styles.resourceFilterSelected
                : styles.resourceFilter
            }  flex items-center justify-center`}
            onClick={() => {
              setSubCurrentFilter("");
              //   setSubNqalevel("6.1");
              setNqalevel(6);
              setCurrentFilter(6);
            }}
          >
            NQA 6
          </div>
          <div
            className={`${
              currentFilter === 7
                ? styles.resourceFilterSelected
                : styles.resourceFilter
            }  flex items-center justify-center`}
            onClick={() => {
              setSubCurrentFilter("");
              //   setSubNqalevel("7.1");
              setNqalevel(7);
              setCurrentFilter(7);
            }}
          >
            NQA 7
          </div>
        </div>

        <div
          className={` w-full relative ${styles.subResourcesFilterRow} ${
            styles[`nqa_${currentFilter}`]
          } flex justify-start flex-wrap`}
        >
          {!isEmpty(getSublevels(currentFilter)) &&
            getSublevels(currentFilter).map((sub, key) => {
              return (
                <div
                  key={sub.label}
                  className={`${
                    String(subCurrentFilter).trim() === String(sub.label).trim()
                      ? styles.subResourcesFilterSelected
                      : styles.subResourcesFilter
                  } flex items-center justify-center`}
                  onClick={() => {
                    setSubNqalevel(sub.label);

                    setSubCurrentFilter(sub.label);
                    setTimeout(() => onClose(), 200);
                  }}
                >
                  NQA{sub.label}
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default NQASelector;
