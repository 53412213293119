import * as React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import clockImg from '../../static/images/clock.png';

import styles from './Banner.module.css';
const { useState } = React;

export default function Banner({ remaining }) {

  

  return (
    <div className={styles.alertContainer}>
      <Link to="/qip/pricing-plans">
        <div className={styles.alert}>
          <img
            src={clockImg}
            width="26"
            height="26"
          />
          <text>You have only <span> {remaining} days </span> of your trial remaining. <span> Click here </span> to signup to a full plan and continue enjoying all your benefits.</text>
        </div>
      </Link>
    </div>
  );
}

Banner.propTypes = {
  remaining: PropTypes.number,
};