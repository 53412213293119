import * as React from "react";

import { useHistory, useParams } from "react-router-dom";
import {
  FaUsers,
  FaCaretDown,
  FaCaretUp,
  FaSearch,
  FaCheckCircle,
  FaTrashAlt,
  FaCog,
  FaTrashRestore,
  FaTimesCircle,
  FaEye,
  FaSpinner,
} from "react-icons/fa";
import { Button, Dropdown } from "semantic-ui-react";
import styles from "./SubscribersSummary.module.css";
import {
  fetchAllCenterAdmins,
  fetchAllCounts,
  fetchRevenue,
  softDeleteTrialCenter,
  softDeleteSubscription,
  cancelSubscription,
  restoreSoftDeleteSubscription,
  restoreSoftDeleteTrialCenter,
  deleteSdSubscription,
  hardDeleteTrialCenter,
} from "../../../../api/requests";
import Pagination from "../../../../components/Pagination/Pagination";
import { isEmpty, debounce } from "lodash";
import useSWR, { mutate } from "swr";
import { Skeleton } from "antd";
import moment from "moment";
import DeleteSubscriptionModal from "../../../../components/Modals/DeleteSubscriptionModal";
import CancelSubscriptionSubscribersModal from "../../../../components/Modals/CancelSubscriptionSubscribersModal";
import DeletePermanentSubscriptionModal from "../../../../components/Modals/DeletePermanentSubscriptionModal";
import DeletedPermanentlySubscriptionModal from "../../../../components/Modals/DeletedPermanentlySubscriptionModal";
import RestoredSubscriptionModal from "../../../../components/Modals/RestoredSubscriptionModal";
import Swal from "sweetalert2";

const { useState, useCallback } = React;

const servicesPerPage = 30;

const getPrice = (planChoice, planSubscription, sub) => {
  if (sub.status === "trialing" || sub.status === "trial") {
    return "n/a";
  }

  if (planChoice === "QIP Portal") {
    if (planSubscription === "month") {
      return `$97 Monthly`;
    } else {
      return `$997 Yearly`;
    }
  } else {
    if (planSubscription === "month") {
      return `$197 Monthly`;
    } else {
      return `$1,997 Yearly`;
    }
  }
};

function SubscribersSummary() {
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState(1);
  const [fetchLimitError, setFetchLimitError] = useState(false);

  const [serviceFilter, setServiceFilter] = useState("");
  const [filterSelection, setFilterSelection] = useState("Service");

  const [columnFilter, setColumnFilter] = useState("none");
  const [filterDir, setFilterDir] = useState(false);

  const [activeView, setActiveView] = useState(true);

  const [currentServiceId, setCurrentServiceId] = useState(0);

  const [sdType, setSdType] = useState(0);

  const [restoreType, setRestoreType] = useState(0);

  const [hdType, setHdType] = useState(0);

  const [curUserId, setCurUserId] = useState(0);

  const [loadingsd, setLoadingsd] = useState(false);

  const [loadingcs, setLoadingcs] = useState(false);

  const [loadingpd, setLoadingpd] = useState(false);

  const [loadingrs, setLoadingrs] = useState(false);

  const [deleteSubscriptionModal, setDeleteSubscriptionModal] = useState(false);
  const [
    cancelSubscriptionSubscribersModal,
    setCancelSubscriptionSubscribersModal,
  ] = useState(false);

  const [restoreDeletedSubModal, setRestoreDeletedSubModal] = useState(false);

  const [permanentlyDeleteSubModal, setPermanentlyDeleteSubModal] =
    useState(false);

  const [permanentlyDeletedSubModal, setPermanentlyDeletedSubModal] =
    useState(false);

  const { data: revenue, isValidatingRevenue } = useSWR(
    ["/revenue"],
    () => fetchRevenue(),
    {
      onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
        // Only retry up to 3 times.
        if (retryCount && retryCount >= 3) {
          if (!revenue) setFetchLimitError(true);

          return;
        }
        // Retry after 3 seconds.
        setTimeout(() => revalidate({ retryCount: retryCount + 1 }), 3000);
      },
    }
  );

  const { data: services, isValidating } = useSWR(
    ["/centers/admin/users"],
    () => fetchAllCenterAdmins(),
    {
      onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
        // Only retry up to 3 times.
        if (retryCount && retryCount >= 3) {
          if (!services) setFetchLimitError(true);

          return;
        }
        // Retry after 3 seconds.
        setTimeout(() => revalidate({ retryCount: retryCount + 1 }), 3000);
      },
    }
  );

  const { data: counts, isValidatingCounts } = useSWR(
    ["/counts"],
    () => fetchAllCounts(),
    {
      onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
        // Only retry up to 3 times.
        if (retryCount && retryCount >= 3) {
          if (!counts) setFetchLimitError(true);

          return;
        }
        // Retry after 3 seconds.
        setTimeout(() => revalidate({ retryCount: retryCount + 1 }), 3000);
      },
    }
  );

  const filteredServices = (
    array,
    searchFilter,
    selectedFilter,
    filterColumn,
    filterDir
  ) => {
    return array
      .filter((service) => {
        if (searchFilter.trim() === "") {
          return true;
        } else {
          switch (selectedFilter) {
            case "Service":
              return service.centers.some((center) =>
                center.center_name
                  .toLowerCase()
                  .includes(searchFilter.toLowerCase())
              );
            case "Email":
              return service.email
                .toLowerCase()
                .indexOf(searchFilter.toLowerCase()) !== -1
                ? true
                : false;
          }
        }
      })
      .map((service) => {
        if (searchFilter.trim() === "") {
          return service;
        } else {
          let centers = [];
          const serviceInfo = {
            full_name: service.full_name,
            user_id: service.user_id,
            email: service.email,
            type: service.type,
          };

          switch (selectedFilter) {
            case "Service":
              service.centers.map((center) => {
                if (
                  center.center_name
                    .toLowerCase()
                    .includes(searchFilter.toLowerCase())
                ) {
                  centers.push(center);
                }
              });
              return { ...serviceInfo, centers: centers };
            case "Email":
              if (
                service.email
                  .toLowerCase()
                  .indexOf(searchFilter.toLowerCase()) !== -1
              ) {
                return service;
              }
          }

          service.centers.map((center) => {
            if (
              center.center_name
                .toLowerCase()
                .includes(searchFilter.toLowerCase())
            ) {
              centers.push(center);
            }
          });
          return { ...serviceInfo, centers: centers };
        }
      })
      .sort((a, b) => {
        switch (filterColumn) {
          case "name":
            if (filterDir) {
              if (a.full_name.toLowerCase() < b.full_name.toLowerCase()) {
                return -1;
              }
              if (a.full_name.toLowerCase() > b.full_name.toLowerCase()) {
                return 1;
              }
              return 0;
            } else {
              if (a.full_name.toLowerCase() < b.full_name.toLowerCase()) {
                return 1;
              }
              if (a.full_name.toLowerCase() > b.full_name.toLowerCase()) {
                return -1;
              }
              return 0;
            }

          case "email":
            if (filterDir) {
              if (a.email.toLowerCase() < b.email.toLowerCase()) {
                return -1;
              }
              if (a.email.toLowerCase() > b.email.toLowerCase()) {
                return 1;
              }
              return 0;
            } else {
              if (a.email.toLowerCase() < b.email.toLowerCase()) {
                return 1;
              }
              if (a.email.toLowerCase() > b.email.toLowerCase()) {
                return -1;
              }
              return 0;
            }

          case "service":
            if (filterDir) {
              if (
                a.centers[0].center_name.toLowerCase() <
                b.centers[0].center_name.toLowerCase()
              ) {
                return -1;
              }
              if (
                a.centers[0].center_name.toLowerCase() >
                b.centers[0].center_name.toLowerCase()
              ) {
                return 1;
              }
              return 0;
            } else {
              if (
                a.centers[0].center_name.toLowerCase() <
                b.centers[0].center_name.toLowerCase()
              ) {
                return 1;
              }
              if (
                a.centers[0].center_name.toLowerCase() >
                b.centers[0].center_name.toLowerCase()
              ) {
                return -1;
              }
              return 0;
            }
          case "subscription":
            if (filterDir) {
              if (
                !isEmpty(a.centers[0].subscription) &&
                !isEmpty(b.centers[0].subscription)
              ) {
                if (
                  a.centers[0].subscription.product_name <
                  b.centers[0].subscription.product_name
                ) {
                  return -1;
                }
                if (
                  a.centers[0].subscription.product_name >
                  b.centers[0].subscription.product_name
                ) {
                  return 1;
                }
                return 0;
              }
              if (
                !isEmpty(a.centers[0].subscription) &&
                isEmpty(b.centers[0].subscription)
              ) {
                return -1;
              }
              if (
                isEmpty(a.centers[0].subscription) &&
                !isEmpty(b.centers[0].subscription)
              ) {
                return 1;
              }
            } else {
              if (
                !isEmpty(a.centers[0].subscription) &&
                !isEmpty(b.centers[0].subscription)
              ) {
                if (
                  a.centers[0].subscription.product_name <
                  b.centers[0].subscription.product_name
                ) {
                  return 1;
                }
                if (
                  a.centers[0].subscription.product_name >
                  b.centers[0].subscription.product_name
                ) {
                  return -1;
                }
                return 0;
              }
              if (
                !isEmpty(a.centers[0].subscription) &&
                isEmpty(b.centers[0].subscription)
              ) {
                return 1;
              }
              if (
                isEmpty(a.centers[0].subscription) &&
                !isEmpty(b.centers[0].subscription)
              ) {
                return -1;
              }
            }
            break;
          case "status":
            if (filterDir) {
              if (
                !isEmpty(a.centers[0].subscription) &&
                !isEmpty(b.centers[0].subscription)
              ) {
                if (
                  a.centers[0].subscription.status <
                  b.centers[0].subscription.status
                ) {
                  return -1;
                }
                if (
                  a.centers[0].subscription.status >
                  b.centers[0].subscription.status
                ) {
                  return 1;
                }
                return 0;
              }
              if (
                !isEmpty(a.centers[0].subscription) &&
                isEmpty(b.centers[0].subscription)
              ) {
                return -1;
              }
              if (
                isEmpty(a.centers[0].subscription) &&
                !isEmpty(b.centers[0].subscription)
              ) {
                return 1;
              }
            } else {
              if (
                !isEmpty(a.centers[0].subscription) &&
                !isEmpty(b.centers[0].subscription)
              ) {
                if (
                  a.centers[0].subscription.status <
                  b.centers[0].subscription.status
                ) {
                  return 1;
                }
                if (
                  a.centers[0].subscription.status >
                  b.centers[0].subscription.status
                ) {
                  return -1;
                }
                return 0;
              }
              if (
                !isEmpty(a.centers[0].subscription) &&
                isEmpty(b.centers[0].subscription)
              ) {
                return 1;
              }
              if (
                isEmpty(a.centers[0].subscription) &&
                !isEmpty(b.centers[0].subscription)
              ) {
                return -1;
              }
            }
            break;

          case "end_date":
            if (filterDir) {
              return moment(
                a.centers[0].subscription.current_period_end,
                "DD-MM-YYYY"
              ).diff(
                moment(
                  b.centers[0].subscription.current_period_end,
                  "DD-MM-YYYY"
                )
              );
            } else {
              return moment(
                b.centers[0].subscription.current_period_end,
                "DD-MM-YYYY"
              ).diff(
                moment(
                  a.centers[0].subscription.current_period_end,
                  "DD-MM-YYYY"
                )
              );
            }

          case "payment":
            if (filterDir) {
              if (
                a.centers[0].subscription.product_name <
                b.centers[0].subscription.product_name
              ) {
                return 1;
              }
              if (
                a.centers[0].subscription.product_name >
                b.centers[0].subscription.product_name
              ) {
                return -1;
              }
              return 0;
            } else {
              if (
                a.centers[0].subscription.product_name <
                b.centers[0].subscription.product_name
              ) {
                return -1;
              }
              if (
                a.centers[0].subscription.product_name >
                b.centers[0].subscription.product_name
              ) {
                return 1;
              }
              return 0;
            }
        }
      });
  };

  const checkCombineCenters = (active = true, userId, centers) => {
    let newCenters = [...centers];

    if (services) {
      if (active) {
        if (services.adminsSoft.some((sd) => sd.user_id === userId)) {
          newCenters = [
            ...newCenters,
            ...services.adminsSoft.filter((sd) => sd.user_id === userId)[0]
              .centers,
          ];
        }
      } else {
        if (services.admins.some((sd) => sd.user_id === userId)) {
          newCenters = [
            ...newCenters,
            ...services.admins.filter((sd) => sd.user_id === userId)[0].centers,
          ];
        }
      }
    }

    return newCenters;
  };

  const ServiceOptions = ({
    userId,
    centerId,
    userName,
    centers,
    currentService,
  }) => {
    if (currentService.subscription.product_name === "trial") {
      return (
        <ul className={`${styles.tableMenuAction}`}>
          <li
            className={`flex items-center justify-start`}
            onClick={() =>
              history.push("/qip/subscribers/details", {
                userId,
                centerId,
                userName,
                centers: checkCombineCenters(true, userId, centers),
              })
            }
          >
            <FaEye className={`mr-1.5`} size={18} />
            View details
          </li>

          <li
            className={`flex items-center justify-start`}
            onClick={() => {
              setSdType(0);
              setDeleteSubscriptionModal(true);
            }}
          >
            <FaTrashAlt className={`mr-1.5`} size={18} />
            Delete subscription
          </li>
        </ul>
      );
    } else {
      if (currentService.subscription.cancel_at_period_end) {
        return (
          <ul className={`${styles.tableMenuAction}`}>
            <li
              className={`flex items-center justify-start`}
              onClick={() =>
                history.push("/qip/subscribers/details", {
                  userId,
                  centerId,
                  userName,
                  centers: checkCombineCenters(true, userId, centers),
                })
              }
            >
              <FaEye className={`mr-1.5`} size={18} />
              View details
            </li>

            <li
              className={`flex items-center justify-start`}
              onClick={() => {
                setCurUserId(userId);
                setSdType(1);
                setDeleteSubscriptionModal(true);
              }}
            >
              <FaTrashAlt className={`mr-1.5`} size={18} />
              Delete subscription
            </li>
          </ul>
        );
      } else {
        return (
          <ul className={`${styles.tableMenuAction}`}>
            <li
              className={`flex items-center justify-start`}
              onClick={() =>
                history.push("/qip/subscribers/details", {
                  userId,
                  centerId,
                  userName,
                  centers: checkCombineCenters(true, userId, centers),
                })
              }
            >
              <FaEye className={`mr-1.5`} size={18} />
              View details
            </li>
            <li
              className={`flex items-center justify-start`}
              onClick={() => {
                setCurUserId(userId);
                setCancelSubscriptionSubscribersModal(true);
              }}
            >
              <FaTimesCircle className={`mr-1.5`} size={18} />
              Cancel subscription
            </li>
            <li
              className={`opacity-50 flex items-center justify-start cursor-default `}
            >
              <FaTrashAlt className={`mr-1.5`} size={18} />
              Delete subscription
            </li>
          </ul>
        );
      }
    }
  };

  const SDServiceOptions = ({
    userId,
    centerId,
    userName,
    centers,
    currentService,
  }) => {
    return (
      <ul className={`${styles.tableMenuAction}`}>
        <li
          className={`flex items-center justify-start`}
          onClick={() =>
            history.push("/qip/subscribers/details", {
              userId,
              centerId,
              userName,
              centers: checkCombineCenters(false, userId, centers),
            })
          }
          // onClick={() => {
          //   console.log("centers ", centers);
          // }}
        >
          <FaEye className={`mr-1.5`} size={18} />
          View details
        </li>

        <li
          className={`flex items-center justify-start`}
          onClick={() => {
            setCurUserId(userId);
            if (currentService.subscription.product_name === "trial") {
              setRestoreType(0);
            } else {
              setRestoreType(1);
            }
            setRestoreDeletedSubModal(true);
          }}
        >
          <FaTrashRestore className={`mr-1.5`} size={18} />
          Restore subscription
        </li>
        <li
          className={`flex items-center justify-start`}
          onClick={() => {
            setCurUserId(userId);
            if (currentService.subscription.product_name === "trial") {
              setHdType(0);
            } else {
              setHdType(1);
            }
            setPermanentlyDeleteSubModal(true);
          }}
        >
          <FaTimesCircle className={`mr-1.5`} size={18} />
          Permanently delete
        </li>
      </ul>
    );
  };

  const alertMessage = (type, message) => {
    Swal.fire({
      icon: `${type}`,
      title: `${message}`,
      showConfirmButton: false,
      timer: 1500,
    });
  };

  const onConfirmDeleteSub = async () => {
    try {
      setLoadingsd(true);
      if (sdType === 1) {
        const response = await softDeleteSubscription(
          Number(curUserId),
          Number(currentServiceId)
        );
      }
      if (sdType === 0) {
        const response = await softDeleteTrialCenter(Number(currentServiceId));
      }

      await mutate(["/centers/admin/users"]);
      setDeleteSubscriptionModal(false);
      setLoadingsd(false);
      alertMessage(`info`, `Subscription deleted.`);
    } catch (error) {
      setDeleteSubscriptionModal(false);
      setLoadingsd(false);
      console.log(error);
    }
  };

  const onConFirmCancelSub = async () => {
    try {
      setLoadingcs(true);
      const response = await cancelSubscription(
        Number(curUserId),
        Number(currentServiceId)
      );
      await mutate(["/centers/admin/users"]);
      await mutate(["/revenue"]);
      setCancelSubscriptionSubscribersModal(false);
      setLoadingcs(false);
      alertMessage(`info`, `Subscription cancelled.`);
    } catch (error) {
      setCancelSubscriptionSubscribersModal(false);
      setLoadingcs(false);
      console.log(error);
    }
  };

  const onConfirmRestoreSub = async () => {
    if (currentServiceId !== 0) {
      try {
        setLoadingrs(true);
        if (restoreType === 1) {
          const response = await restoreSoftDeleteSubscription(
            Number(curUserId),
            Number(currentServiceId)
          );
        }

        if (restoreType === 0) {
          const response = await restoreSoftDeleteTrialCenter(
            Number(currentServiceId)
          );
        }

        await mutate(["/centers/admin/users"]);
        setRestoreDeletedSubModal(false);
        setLoadingrs(false);
        alertMessage(`info`, `Subscription restored.`);
      } catch (error) {
        setRestoreDeletedSubModal(false);
        setLoadingrs(false);
        console.log(error);
      }
    }
  };

  const onConfirmPermanentDeleteSub = async () => {
    if (currentServiceId !== 0) {
      try {
        setLoadingpd(true);
        if (hdType === 1) {
          const response = await deleteSdSubscription(
            Number(curUserId),
            Number(currentServiceId)
          );
        }

        if (hdType === 0) {
          const response = await hardDeleteTrialCenter(
            Number(currentServiceId)
          );
        }

        await mutate(["/centers/admin/users"]);
        await mutate(["/counts"]);

        setPermanentlyDeleteSubModal(false);
        setPermanentlyDeletedSubModal(true);
        setLoadingpd(false);
      } catch (error) {
        setPermanentlyDeleteSubModal(false);
        setPermanentlyDeletedSubModal(true);
        setLoadingpd(false);
        console.log(error);
      }
    }
  };

  const resetFilters = () => {
    setCurrentPage(1);
    setServiceFilter("");
    setFilterSelection("Service");
    setColumnFilter("none");
    setFilterDir(false);
    setCurrentServiceId(0);
  };

  return (
    <div className={`${styles.mainContainer} mt-5 md:mb-28 lg:mb-10`}>
      {cancelSubscriptionSubscribersModal && (
        <CancelSubscriptionSubscribersModal
          visibility={cancelSubscriptionSubscribersModal}
          onOpenModal={() => setCancelSubscriptionSubscribersModal(true)}
          onCloseModal={() => setCancelSubscriptionSubscribersModal(false)}
          onConfirm={onConFirmCancelSub}
          loading={loadingcs}
        />
      )}

      {deleteSubscriptionModal && (
        <DeleteSubscriptionModal
          visibility={deleteSubscriptionModal}
          onOpenModal={() => setDeleteSubscriptionModal(true)}
          onCloseModal={() => setDeleteSubscriptionModal(false)}
          onConfirm={onConfirmDeleteSub}
          loading={loadingsd}
        />
      )}

      {restoreDeletedSubModal && (
        <RestoredSubscriptionModal
          visibility={restoreDeletedSubModal}
          onOpenModal={() => setRestoreDeletedSubModal(true)}
          onCloseModal={() => setRestoreDeletedSubModal(false)}
          onConfirm={onConfirmRestoreSub}
          loading={loadingrs}
        />
      )}

      {permanentlyDeleteSubModal && (
        <DeletePermanentSubscriptionModal
          visibility={permanentlyDeleteSubModal}
          onOpenModal={() => setPermanentlyDeleteSubModal(true)}
          onCloseModal={() => setPermanentlyDeleteSubModal(false)}
          onConfirm={onConfirmPermanentDeleteSub}
          loading={loadingpd}
        />
      )}

      {permanentlyDeletedSubModal && (
        <DeletedPermanentlySubscriptionModal
          visibility={permanentlyDeletedSubModal}
          onOpenModal={() => setPermanentlyDeletedSubModal(true)}
          onCloseModal={() => setPermanentlyDeletedSubModal(false)}
          onConfirm={() => {
            setPermanentlyDeletedSubModal(false);
          }}
        />
      )}

      <div className={`${styles.headerTextContainer} flex`}>
        <div className={styles.headerTextIcon}>
          <FaUsers size={55} />
        </div>

        <div className={`${styles.headerText} ml-4`}>
          <h1>Subscriptions</h1>
          <text>Summary of Holistic QIP subscribers</text>
        </div>
      </div>

      <div
        className={`${styles.servicesListContainer} md:px-7 lg:px-14 py-16 mt-9`}
      >
        <div
          className={`${styles.summaryContainer} flex flex-wrap justify-between pb-7 mb-10`}
        >
          <div className={`p-6`}>
            {services ? (
              <>
                {" "}
                <div className={`flex items-baseline mb-4`}>
                  <text className={`${styles.data} text-right mr-4 w-1/5 `}>
                    {" "}
                    {activeView && services && services.total}
                    {!activeView && services && services.totalSoft}
                  </text>
                  <text className={`${styles.label} w-4/5`}>
                    Total subscribers
                  </text>
                </div>
                <div className={`flex items-baseline mb-4`}>
                  <text className={`${styles.data} text-right mr-4 w-1/5`}>
                    {activeView && services && services.paying}
                    {!activeView && services && services.payingSoft}
                  </text>
                  <text className={`${styles.label} w-4/5`}>
                    Paying subscribers
                  </text>
                </div>
                <div className={`flex items-baseline mb-4`}>
                  <text className={`${styles.data} text-right mr-4 w-1/5`}>
                    {activeView && services && services.trial}
                    {!activeView && services && services.trialSoft}
                  </text>
                  <text className={`${styles.label} w-4/5`}>
                    Trial subscribers
                  </text>
                </div>{" "}
              </>
            ) : (
              <div className={`flex flex-col justify-center items-start`}>
                <div className={`flex items-baseline mb-4 `}>
                  <Skeleton.Input
                    className={`block`}
                    style={{ width: "55px", marginRight: "2rem" }}
                    active={true}
                    size={`large`}
                  />

                  <Skeleton.Input
                    className={`block `}
                    style={{ width: "200px" }}
                    active={true}
                    size={`large`}
                  />
                </div>
                <div className={`flex items-baseline mb-4 `}>
                  <Skeleton.Input
                    className={`block`}
                    style={{ width: "55px", marginRight: "2rem" }}
                    active={true}
                    size={`large`}
                  />

                  <Skeleton.Input
                    className={`block `}
                    style={{ width: "200px" }}
                    active={true}
                    size={`large`}
                  />
                </div>
                <div className={`flex items-baseline mb-4 `}>
                  <Skeleton.Input
                    className={`block`}
                    style={{ width: "55px", marginRight: "2rem" }}
                    active={true}
                    size={`large`}
                  />

                  <Skeleton.Input
                    className={`block `}
                    style={{ width: "200px" }}
                    active={true}
                    size={`large`}
                  />
                </div>
              </div>
            )}
          </div>

          <div className={`p-6`}>
            {counts ? (
              <>
                {" "}
                <div className={`flex flex-wrap items-baseline mb-4`}>
                  <text className={`${styles.data} text-right mr-4`}>
                    {counts ? counts.total : "0"}
                  </text>
                  <text className={`${styles.label}`}>Total users</text>
                </div>
                <div className={`flex flex-wrap items-baseline mb-4`}>
                  <text className={`${styles.data} text-right mr-4`}>
                    {counts ? counts.admin : "0"}
                  </text>
                  <text className={`${styles.label}`}>Admin users</text>
                </div>
                <div className={`flex flex-wrap items-baseline mb-4`}>
                  <text className={`${styles.data} text-right mr-4`}>
                    {counts ? counts.member : "0"}
                  </text>
                  <text className={`${styles.label}`}>Member users</text>
                </div>{" "}
              </>
            ) : (
              <div className={`flex flex-col justify-center items-start`}>
                <div className={`flex items-baseline mb-4 `}>
                  <Skeleton.Input
                    className={`block`}
                    style={{ width: "55px", marginRight: "2rem" }}
                    active={true}
                    size={`large`}
                  />

                  <Skeleton.Input
                    className={`block `}
                    style={{ width: "200px" }}
                    active={true}
                    size={`large`}
                  />
                </div>
                <div className={`flex items-baseline mb-4 `}>
                  <Skeleton.Input
                    className={`block`}
                    style={{ width: "55px", marginRight: "2rem" }}
                    active={true}
                    size={`large`}
                  />

                  <Skeleton.Input
                    className={`block `}
                    style={{ width: "200px" }}
                    active={true}
                    size={`large`}
                  />
                </div>
                <div className={`flex items-baseline mb-4 `}>
                  <Skeleton.Input
                    className={`block`}
                    style={{ width: "55px", marginRight: "2rem" }}
                    active={true}
                    size={`large`}
                  />

                  <Skeleton.Input
                    className={`block `}
                    style={{ width: "200px" }}
                    active={true}
                    size={`large`}
                  />
                </div>
              </div>
            )}
          </div>

          {revenue ? (
            <div className={`p-6`}>
              <div className={`flex flex-wrap items-baseline mb-4`}>
                <text className={`${styles.data} mr-4`}>
                  ${revenue ? revenue.sum_of_monthly : 0}
                </text>
                <text className={`${styles.label}`}>Monthly revenue</text>
              </div>
              <div className={`flex flex-wrap items-baseline mb-4`}>
                <text className={`${styles.data} mr-4`}>
                  ${revenue ? revenue.sum_of_yearly : 0}
                </text>
                <text className={`${styles.label}`}>Annual revenue</text>
              </div>
            </div>
          ) : (
            <div className={`p-6`}>
              <div className={`flex flex-wrap items-baseline mb-4`}>
                <Skeleton.Input
                  className={`block `}
                  style={{ width: "250px" }}
                  active={true}
                  size={`large`}
                />
              </div>
              <div className={`flex flex-wrap items-baseline mb-4`}>
                <Skeleton.Input
                  className={`block `}
                  style={{ width: "250px" }}
                  active={true}
                  size={`large`}
                />
              </div>
            </div>
          )}
        </div>
        <h2 className={styles.serviceHeader}>Services</h2>
        <div className={`w-full flex justify-between mt-4`}>
          <div className={`flex items-center`}>
            <label
              onClick={() => {
                resetFilters();
                setActiveView(true);
              }}
              className={`${
                activeView ? styles.activeView : styles.nonView
              } inline-flex items-center cursor-pointer `}
            >
              <FaCheckCircle className={`mr-1.5`} size={12} /> Active
            </label>
            <label
              onClick={() => {
                resetFilters();
                setActiveView(false);
              }}
              className={`${
                activeView ? styles.nonView : styles.deletedView
              } inline-flex items-center cursor-pointer ml-1.5`}
            >
              <FaTrashAlt className={`mr-1.5`} size={12} /> Deleted (
              {services ? services.softDeletedCount : 0})
            </label>
          </div>
          <div className={`${styles.searchHolder} flex items-center py-4 px-2`}>
            <input
              className={`${styles.searchText} flex-1`}
              type={`text`}
              placeholder={`Search...`}
              onChange={(e) => {
                if (currentPage !== 1) {
                  setCurrentPage(1);
                }
                setServiceFilter(e.target.value);
              }}
            />
            <div className={`p-4 border-l border-gray-100`}>
              <Dropdown
                className={`text-gray-700 `}
                value={filterSelection}
                options={[
                  { text: "Service", value: "Service" },
                  { text: "Email", value: "Email" },
                ]}
                onChange={(e, data) => {
                  setFilterSelection(data.value);
                }}
              />
            </div>
            <div
              className={`${styles.searchIconHolder} flex justify-center items-center`}
            >
              <FaSearch className={styles.searchIcon} />
            </div>
          </div>
        </div>

        {activeView ? (
          <div className={`${styles.tableContainer}`}>
            <div className={`${styles.tableHeader} flex flex-wrap`}>
              {/* <div className={`${styles.tableHeaderName} flex p-4`}>
              <text>First Name</text>
              <FaCaretDown className={`ml-1 mt-1`} size={12} />
            </div> */}
              <div
                onClick={() => {
                  setColumnFilter("name");
                  setFilterDir(!filterDir);
                }}
                className={`${styles.tableHeaderName} flex p-4`}
              >
                <text>Name</text>
                {columnFilter === "name"
                  ? (filterDir && (
                      <FaCaretUp className={`ml-1 mt-1`} size={12} />
                    )) ||
                    (!filterDir && (
                      <FaCaretDown className={`ml-1 mt-1`} size={12} />
                    ))
                  : (filterDir && (
                      <FaCaretUp className={`ml-1 mt-1`} size={12} />
                    )) ||
                    (!filterDir && (
                      <FaCaretDown className={`ml-1 mt-1`} size={12} />
                    ))}
              </div>

              <div
                onClick={() => {
                  setColumnFilter("email");
                  setFilterDir(!filterDir);
                }}
                className={`${styles.tableHeaderName} flex p-4`}
              >
                <text>Email</text>
                {columnFilter === "email"
                  ? (filterDir && (
                      <FaCaretUp className={`ml-1 mt-1`} size={12} />
                    )) ||
                    (!filterDir && (
                      <FaCaretDown className={`ml-1 mt-1`} size={12} />
                    ))
                  : (filterDir && (
                      <FaCaretUp className={`ml-1 mt-1`} size={12} />
                    )) ||
                    (!filterDir && (
                      <FaCaretDown className={`ml-1 mt-1`} size={12} />
                    ))}
              </div>

              <div
                onClick={() => {
                  setColumnFilter("service");
                  setFilterDir(!filterDir);
                }}
                className={`${styles.tableHeaderName} w-1/3 flex p-4`}
              >
                <text>Service</text>
                {columnFilter === "service"
                  ? (filterDir && (
                      <FaCaretUp className={`ml-1 mt-1`} size={12} />
                    )) ||
                    (!filterDir && (
                      <FaCaretDown className={`ml-1 mt-1`} size={12} />
                    ))
                  : (filterDir && (
                      <FaCaretUp className={`ml-1 mt-1`} size={12} />
                    )) ||
                    (!filterDir && (
                      <FaCaretDown className={`ml-1 mt-1`} size={12} />
                    ))}
              </div>
              <div
                onClick={() => {
                  setColumnFilter("subscription");
                  setFilterDir(!filterDir);
                }}
                className={`${styles.tableHeaderName} flex p-4`}
              >
                <text>Subscription</text>
                {columnFilter === "subscription"
                  ? (filterDir && (
                      <FaCaretUp className={`ml-1 mt-1`} size={12} />
                    )) ||
                    (!filterDir && (
                      <FaCaretDown className={`ml-1 mt-1`} size={12} />
                    ))
                  : (filterDir && (
                      <FaCaretUp className={`ml-1 mt-1`} size={12} />
                    )) ||
                    (!filterDir && (
                      <FaCaretDown className={`ml-1 mt-1`} size={12} />
                    ))}
              </div>

              <div
                onClick={() => {
                  setColumnFilter("end_date");
                  setFilterDir(!filterDir);
                }}
                className={`${styles.tableHeaderName} flex p-4`}
              >
                <text>End Date</text>
                {columnFilter === "end_date"
                  ? (filterDir && (
                      <FaCaretUp className={`ml-1 mt-1`} size={12} />
                    )) ||
                    (!filterDir && (
                      <FaCaretDown className={`ml-1 mt-1`} size={12} />
                    ))
                  : (filterDir && (
                      <FaCaretUp className={`ml-1 mt-1`} size={12} />
                    )) ||
                    (!filterDir && (
                      <FaCaretDown className={`ml-1 mt-1`} size={12} />
                    ))}
              </div>

              <div
                onClick={() => {
                  setColumnFilter("status");
                  setFilterDir(!filterDir);
                }}
                className={`${styles.tableHeaderName} flex p-4`}
              >
                <text>Status</text>
                {columnFilter === "status"
                  ? (filterDir && (
                      <FaCaretUp className={`ml-1 mt-1`} size={12} />
                    )) ||
                    (!filterDir && (
                      <FaCaretDown className={`ml-1 mt-1`} size={12} />
                    ))
                  : (filterDir && (
                      <FaCaretUp className={`ml-1 mt-1`} size={12} />
                    )) ||
                    (!filterDir && (
                      <FaCaretDown className={`ml-1 mt-1`} size={12} />
                    ))}
              </div>
              <div
                onClick={() => {
                  setColumnFilter("payment");
                  setFilterDir(!filterDir);
                }}
                className={`${styles.tableHeaderName} flex p-4`}
              >
                <text>Payment</text>
                {columnFilter === "payment"
                  ? (filterDir && (
                      <FaCaretUp className={`ml-1 mt-1`} size={12} />
                    )) ||
                    (!filterDir && (
                      <FaCaretDown className={`ml-1 mt-1`} size={12} />
                    ))
                  : (filterDir && (
                      <FaCaretUp className={`ml-1 mt-1`} size={12} />
                    )) ||
                    (!filterDir && (
                      <FaCaretDown className={`ml-1 mt-1`} size={12} />
                    ))}
              </div>
              <div className={`${styles.tableHeaderName} flex p-4`}></div>
            </div>

            {!services ? (
              <div
                className={`w-full h-full flex flex-col justify-center items-center py-20`}
              >
                <FaSpinner
                  className={`animate-spin text-qipGreen font-bold text-6xl`}
                />
                <span className={`block`}> Loading data...</span>
              </div>
            ) : (
              <div className={`${styles.tableBody}`}>
                {services.admins &&
                  filteredServices(
                    services.admins,
                    serviceFilter,
                    filterSelection,
                    columnFilter,
                    filterDir
                  )
                    .slice(
                      currentPage * servicesPerPage - servicesPerPage,
                      currentPage * servicesPerPage
                    )
                    .map((service, key) => {
                      return (
                        <div
                          key={key}
                          className={`${styles.tableRow} w-full flex flex-wrap`}
                        >
                          <div
                            className={`${styles.tableData} break-words flex p-4`}
                          >
                            <text>{service.full_name}</text>
                          </div>
                          <div
                            className={`${styles.tableData} break-words flex p-4`}
                          >
                            <text>{service.email}</text>
                          </div>

                          <div
                            className={`${styles.tableData} w-1/3 break-words flex flex-col p-4`}
                          >
                            {service.centers.map((center, key) => {
                              return (
                                <text key={key}>{center.center_name}</text>
                              );
                            })}
                          </div>

                          <div
                            className={`${styles.tableData} flex flex-col p-4`}
                          >
                            {service.centers.map((center, key) => {
                              return (
                                <text key={key} className={`capitalize`}>
                                  {center.subscription.product_name}
                                </text>
                              );
                            })}
                          </div>

                          <div
                            className={`${styles.tableData} flex flex-col p-4`}
                          >
                            {service.centers.map((center, key) => {
                              return (
                                <text key={key} className={`capitalize`}>
                                  {center.subscription.product_name === "trial"
                                    ? moment(
                                        center.subscription.created_at,
                                        "DD-MM-YYYY"
                                      )
                                        .add(30, "days")
                                        .format("DD/MM/YYYY")
                                    : moment(
                                        center.subscription.current_period_end,
                                        "DD-MM-YYYY"
                                      ).format("DD/MM/YYYY")}
                                </text>
                              );
                            })}
                          </div>

                          <div
                            className={`${styles.tableData} flex flex-col p-4`}
                          >
                            {service.centers.map((center, key) => {
                              return (
                                <text key={key} className={`capitalize`}>
                                  {center.subscription.product_name === "trial"
                                    ? center.subscription.trial_remaining_days.includes(
                                        "-"
                                      )
                                      ? "Trial (Ended) "
                                      : "Trial"
                                    : center.subscription.cancel_at_period_end
                                    ? "Canceled"
                                    : center.subscription.status === "trialing"
                                    ? "Trial"
                                    : center.subscription.status ===
                                        "incomplete_expired" ||
                                      center.subscription.status ===
                                        "incomplete" ||
                                      center.subscription.status === "past_due"
                                    ? "Payment Failed"
                                    : center.subscription.status}
                                </text>
                              );
                            })}
                          </div>
                          <div
                            className={`${styles.tableData} flex flex-col p-4`}
                          >
                            {service.centers.map((center, key) => {
                              return (
                                <text key={key}>
                                  {center.subscription.product_name !== "trial"
                                    ? getPrice(
                                        center.subscription.product_name,
                                        center.subscription.interval,
                                        center.subscription
                                      )
                                    : `n/a`}
                                </text>
                              );
                            })}
                          </div>

                          <div
                            className={`${styles.tableData} flex flex-col items-center p-1.5 `}
                          >
                            {service.centers.map((center, key) => {
                              return (
                                <div key={key} className={`relative mt-1.5`}>
                                  <Button
                                    onClick={() => {
                                      if (currentServiceId !== 0) {
                                        if (
                                          currentServiceId !== center.center_id
                                        ) {
                                          setCurrentServiceId(center.center_id);
                                        } else {
                                          setCurrentServiceId(0);
                                        }
                                      } else {
                                        setCurrentServiceId(center.center_id);
                                      }
                                    }}
                                    className={`block ${styles.cog} ${
                                      currentServiceId === center.center_id
                                        ? styles.active
                                        : ""
                                    }`}
                                    icon="cog"
                                  />
                                  {currentServiceId === center.center_id && (
                                    <ServiceOptions
                                      userId={service.user_id}
                                      // centerId={service.centers[0].center_id}
                                      centerId={currentServiceId}
                                      userName={service.full_name}
                                      centers={service.centers}
                                      currentService={center}
                                    />
                                  )}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      );
                    })}
              </div>
            )}
          </div>
        ) : (
          <div className={`${styles.tableContainer}`}>
            <div className={`${styles.tableHeader} flex flex-wrap`}>
              {/* <div className={`${styles.tableHeaderName} flex p-4`}>
            <text>First Name</text>
            <FaCaretDown className={`ml-1 mt-1`} size={12} />
          </div> */}
              <div
                onClick={() => {
                  setColumnFilter("name");
                  setFilterDir(!filterDir);
                }}
                className={`${styles.tableHeaderName} flex p-4`}
              >
                <text>Name</text>
                {columnFilter === "name"
                  ? (filterDir && (
                      <FaCaretUp className={`ml-1 mt-1`} size={12} />
                    )) ||
                    (!filterDir && (
                      <FaCaretDown className={`ml-1 mt-1`} size={12} />
                    ))
                  : (filterDir && (
                      <FaCaretUp className={`ml-1 mt-1`} size={12} />
                    )) ||
                    (!filterDir && (
                      <FaCaretDown className={`ml-1 mt-1`} size={12} />
                    ))}
              </div>

              <div
                onClick={() => {
                  setColumnFilter("email");
                  setFilterDir(!filterDir);
                }}
                className={`${styles.tableHeaderName} flex p-4`}
              >
                <text>Email</text>
                {columnFilter === "email"
                  ? (filterDir && (
                      <FaCaretUp className={`ml-1 mt-1`} size={12} />
                    )) ||
                    (!filterDir && (
                      <FaCaretDown className={`ml-1 mt-1`} size={12} />
                    ))
                  : (filterDir && (
                      <FaCaretUp className={`ml-1 mt-1`} size={12} />
                    )) ||
                    (!filterDir && (
                      <FaCaretDown className={`ml-1 mt-1`} size={12} />
                    ))}
              </div>

              <div
                onClick={() => {
                  setColumnFilter("service");
                  setFilterDir(!filterDir);
                }}
                className={`${styles.tableHeaderName} w-1/3 flex p-4`}
              >
                <text>Service</text>
                {columnFilter === "service"
                  ? (filterDir && (
                      <FaCaretUp className={`ml-1 mt-1`} size={12} />
                    )) ||
                    (!filterDir && (
                      <FaCaretDown className={`ml-1 mt-1`} size={12} />
                    ))
                  : (filterDir && (
                      <FaCaretUp className={`ml-1 mt-1`} size={12} />
                    )) ||
                    (!filterDir && (
                      <FaCaretDown className={`ml-1 mt-1`} size={12} />
                    ))}
              </div>
              <div
                onClick={() => {
                  setColumnFilter("subscription");
                  setFilterDir(!filterDir);
                }}
                className={`${styles.tableHeaderName} flex p-4`}
              >
                <text>Subscription</text>
                {columnFilter === "subscription"
                  ? (filterDir && (
                      <FaCaretUp className={`ml-1 mt-1`} size={12} />
                    )) ||
                    (!filterDir && (
                      <FaCaretDown className={`ml-1 mt-1`} size={12} />
                    ))
                  : (filterDir && (
                      <FaCaretUp className={`ml-1 mt-1`} size={12} />
                    )) ||
                    (!filterDir && (
                      <FaCaretDown className={`ml-1 mt-1`} size={12} />
                    ))}
              </div>

              <div
                onClick={() => {
                  setColumnFilter("end_date");
                  setFilterDir(!filterDir);
                }}
                className={`${styles.tableHeaderName} flex p-4`}
              >
                <text>End Date</text>
                {columnFilter === "end_date"
                  ? (filterDir && (
                      <FaCaretUp className={`ml-1 mt-1`} size={12} />
                    )) ||
                    (!filterDir && (
                      <FaCaretDown className={`ml-1 mt-1`} size={12} />
                    ))
                  : (filterDir && (
                      <FaCaretUp className={`ml-1 mt-1`} size={12} />
                    )) ||
                    (!filterDir && (
                      <FaCaretDown className={`ml-1 mt-1`} size={12} />
                    ))}
              </div>

              <div
                onClick={() => {
                  setColumnFilter("status");
                  setFilterDir(!filterDir);
                }}
                className={`${styles.tableHeaderName} flex p-4`}
              >
                <text>Status</text>
                {columnFilter === "status"
                  ? (filterDir && (
                      <FaCaretUp className={`ml-1 mt-1`} size={12} />
                    )) ||
                    (!filterDir && (
                      <FaCaretDown className={`ml-1 mt-1`} size={12} />
                    ))
                  : (filterDir && (
                      <FaCaretUp className={`ml-1 mt-1`} size={12} />
                    )) ||
                    (!filterDir && (
                      <FaCaretDown className={`ml-1 mt-1`} size={12} />
                    ))}
              </div>
              <div className={`${styles.tableHeaderName} flex p-4`}>
                <text>Payment</text>
                <FaCaretDown className={`ml-1 mt-1`} size={12} />
              </div>
              <div className={`${styles.tableHeaderName} flex p-4`}></div>
            </div>

            {!services ? (
              <div
                className={`w-full h-full flex flex-col justify-center items-center py-20`}
              >
                <FaSpinner
                  className={`animate-spin text-qipGreen font-bold text-6xl`}
                />
                <span className={`block`}> Loading data...</span>
              </div>
            ) : (
              <div className={`${styles.tableBody}`}>
                {services.adminsSoft &&
                  filteredServices(
                    services.adminsSoft,
                    serviceFilter,
                    filterSelection,
                    columnFilter,
                    filterDir
                  )
                    .slice(
                      currentPage * servicesPerPage - servicesPerPage,
                      currentPage * servicesPerPage
                    )
                    .map((service, key) => {
                      return (
                        <div
                          key={key}
                          className={`${styles.tableRow} w-full flex flex-wrap`}
                        >
                          <div
                            className={`${styles.tableData} break-words flex p-4`}
                          >
                            <text>{service.full_name}</text>
                          </div>
                          <div
                            className={`${styles.tableData} break-words flex p-4`}
                          >
                            <text>{service.email}</text>
                          </div>

                          <div
                            className={`${styles.tableData} w-1/3 break-words flex flex-col p-4`}
                          >
                            {service.centers.map((center, key) => {
                              return (
                                <text key={key}>{center.center_name}</text>
                              );
                            })}
                          </div>

                          <div
                            className={`${styles.tableData} flex flex-col p-4`}
                          >
                            {service.centers.map((center, key) => {
                              return (
                                <text key={key} className={`capitalize`}>
                                  {center.subscription.product_name}
                                </text>
                              );
                            })}
                          </div>

                          <div
                            className={`${styles.tableData} flex flex-col p-4`}
                          >
                            {service.centers.map((center, key) => {
                              return (
                                <text key={key} className={`capitalize`}>
                                  {center.subscription.product_name === "trial"
                                    ? moment(
                                        center.subscription.created_at,
                                        "DD-MM-YYYY"
                                      )
                                        .add(30, "days")
                                        .format("DD/MM/YYYY")
                                    : moment(
                                        center.subscription.current_period_end,
                                        "DD-MM-YYYY"
                                      ).format("DD/MM/YYYY")}
                                </text>
                              );
                            })}
                          </div>

                          <div
                            className={`${styles.tableData} flex flex-col p-4`}
                          >
                            {service.centers.map((center, key) => {
                              return (
                                <text key={key} className={`capitalize`}>
                                  {center.subscription.product_name === "trial"
                                    ? center.subscription.trial_remaining_days.includes(
                                        "-"
                                      )
                                      ? "Trial (Ended) "
                                      : "Trial"
                                    : center.subscription.cancel_at_period_end
                                    ? "Canceled"
                                    : center.subscription.status === "trialing"
                                    ? "Trial"
                                    : center.subscription.status ===
                                        "incomplete_expired" ||
                                      center.subscription.status ===
                                        "incomplete" ||
                                      center.subscription.status === "past_due"
                                    ? "Payment Failed"
                                    : center.subscription.status}
                                </text>
                              );
                            })}
                          </div>
                          <div
                            className={`${styles.tableData} flex flex-col p-4`}
                          >
                            {service.centers.map((center, key) => {
                              return (
                                <text key={key}>
                                  {center.subscription.product_name !== "trial"
                                    ? getPrice(
                                        center.subscription.product_name,
                                        center.subscription.interval,
                                        center.subscription
                                      )
                                    : `n/a`}
                                </text>
                              );
                            })}
                          </div>

                          <div
                            className={`${styles.tableData} flex flex-col items-center p-1.5`}
                          >
                            {service.centers.map((center, key) => {
                              return (
                                <div key={key} className={`relative mt-1.5`}>
                                  <Button
                                    onClick={() => {
                                      if (currentServiceId !== 0) {
                                        if (
                                          currentServiceId !== center.center_id
                                        ) {
                                          setCurrentServiceId(center.center_id);
                                        } else {
                                          setCurrentServiceId(0);
                                        }
                                      } else {
                                        setCurrentServiceId(center.center_id);
                                      }
                                    }}
                                    className={`block ${styles.cog} ${
                                      currentServiceId === center.center_id
                                        ? styles.active
                                        : ""
                                    }`}
                                    icon="cog"
                                  />
                                  {currentServiceId === center.center_id && (
                                    <SDServiceOptions
                                      userId={service.user_id}
                                      // centerId={service.centers[0].center_id}
                                      centerId={currentServiceId}
                                      userName={service.full_name}
                                      centers={service.centers}
                                      currentService={center}
                                    />
                                  )}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      );
                    })}
              </div>
            )}
          </div>
        )}

        {activeView ? (
          <div className={`flex justify-start`}>
            {services &&
              servicesPerPage <
                filteredServices(services.admins, serviceFilter).length && (
                <Pagination
                  currentPage={currentPage}
                  lastPage={Math.ceil(
                    filteredServices(services.admins, serviceFilter).length /
                      servicesPerPage
                  )}
                  onPageChange={setCurrentPage}
                />
              )}
          </div>
        ) : (
          <div className={`flex justify-start`}>
            {services &&
              servicesPerPage <
                filteredServices(services.adminsSoft, serviceFilter).length && (
                <Pagination
                  currentPage={currentPage}
                  lastPage={Math.ceil(
                    filteredServices(services.adminsSoft, serviceFilter)
                      .length / servicesPerPage
                  )}
                  onPageChange={setCurrentPage}
                />
              )}
          </div>
        )}
      </div>
    </div>
  );
}

export default SubscribersSummary;
