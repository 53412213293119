import React from "react";
import styles from "./Details.module.css";
import { Button, Dropdown } from "semantic-ui-react";
import { useHistory } from "react-router-dom";
import BlueButton from "../../../components/BlueButton";
import {
  fetchUserCenters,
  fetchNQAProgress,
  getUserProducts,
  fetchCenterUsers,
  listRequestFeedback,
} from "../../../api/requests";
import { generateRandom } from "../../../utils";

import { FaSpinner } from "react-icons/fa";
import { isEmpty } from "lodash";
import { Skeleton } from "antd";

const { useState, useEffect, useCallback } = React;

const getPrice = (planChoice, planSubscription, sub) => {
  if (sub.status === "trialing" || sub.status === "trial") {
    return "n/a";
  }

  if (planChoice === "QIP Portal") {
    if (planSubscription === "month") {
      return `$97 Monthly`;
    } else {
      return `$997 Yearly`;
    }
  } else {
    if (planSubscription === "month") {
      return `$197 Monthly`;
    } else {
      return `$1,997 Yearly`;
    }
  }
};

const Details = ({
  centerId,
  center,
  userId,
  userName,
  sumLocState,
  nqaFeedbacks,
}) => {
  const [nqaProgress, setNqaProgress] = useState({
    totalProgress: 0,
    nqaItems: [],
  });

  const history = useHistory();

  const [products, setProducts] = useState([]);

  const [loadingProgress, setLoadingProgress] = useState(false);

  const [loadingUsers, setLoadingUsers] = useState(false);

  const [members, setMembers] = useState(-1);

  const [admins, setAdmins] = useState(-1);

  const fetchUsers = useCallback(async () => {
    try {
      setLoadingUsers(true);
      const response = await fetchCenterUsers(Number(centerId));
      let members = 0;
      let admins = 0;
      response.forEach((r) => {
        if (r.userType === "ADMIN") {
          admins = admins + 1;
        }
        if (r.userType === "MEMBER") {
          members = members + 1;
        }
      });

      setMembers(members);
      setAdmins(admins);
      setLoadingUsers(false);
    } catch (error) {
      setLoadingUsers(false);
      console.log(error);
    }
  }, []);

  const fetchUserProducts = useCallback(async () => {
    try {
      const response = await getUserProducts(userId);

      if (response.products) {
        setProducts(response.products);
      } else {
        setProducts([]);
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  const getNQAProgress = useCallback(async () => {
    try {
      setLoadingProgress(true);
      const progress = await fetchNQAProgress(centerId);
      setNqaProgress(progress);
      setLoadingProgress(false);
    } catch (error) {
      setLoadingProgress(false);
      console.log(error);
    }
  }, [centerId]);

  useEffect(() => {
    fetchUsers();
  }, [centerId]);

  useEffect(() => {
    fetchUserProducts();
  }, [userId]);

  useEffect(() => {
    getNQAProgress();
  }, [userId, centerId]);

  return (
    <div className={`${styles.tabDetailsContainer} md:px-7 lg:px-14`}>
      <div
        className={`${styles.tabTitle} flex flex-wrap justify-between items-baseline`}
      >
        <h2>Subscriber info</h2>
        <div className={`flex `}>
          <div className={`flex `}>
            <text className={`${styles.label} mr-1.5`}>Subscription:</text>
            <text className={`${styles.data} ${styles.small} capitalize`}>
              {!isEmpty(center.subscription)
                ? center.subscription.product_name
                : "Trial"}
            </text>
          </div>

          <div className={`flex mx-10 `}>
            <text className={`${styles.label} mr-1.5`}>Status:</text>
            <text className={`${styles.data} ${styles.small} capitalize`}>
              {" "}
              {center.subscription.product_name === "trial"
                ? center.subscription.trial_remaining_days.includes("-")
                  ? "Trial (Ended) "
                  : "Trial"
                : center.subscription.cancel_at_period_end
                ? "Canceled"
                : center.subscription.status === "trialing"
                ? "Trial"
                : center.subscription.status === "incomplete_expired" ||
                  center.subscription.status === "incomplete" ||
                  center.subscription.status === "past_due"
                ? "Payment Failed"
                : center.subscription.status}
            </text>
          </div>

          <div className={`flex `}>
            <text className={`${styles.label} mr-1.5`}>Payment:</text>
            <text className={`${styles.data} ${styles.small} capitalize`}>
              {!isEmpty(center.subscription)
                ? getPrice(
                    center.subscription.product_name,
                    center.subscription.interval,
                    center.subscription
                  )
                : `n/a`}
            </text>
          </div>
        </div>
      </div>

      {admins + members >= 0 ? (
        <div className={`${styles.subsInfo} flex flex-wrap mt-14`}>
          <div className={`flex items-baseline px-10 py-8`}>
            <text className={`${styles.data} ${styles.large} mr-3`}>
              {admins + members}
            </text>
            <text className={`${styles.label}`}>Total Users</text>
          </div>

          <div className={`flex items-baseline px-10 py-8`}>
            <text className={`${styles.data} ${styles.large} mr-3`}>
              {admins}
            </text>
            <text className={`${styles.label}`}>Admin User</text>
          </div>

          <div className={`flex items-baseline px-10 py-8`}>
            <text className={`${styles.data} ${styles.large} mr-3`}>
              {members}
            </text>
            <text className={`${styles.label}`}>Team Members</text>
          </div>
        </div>
      ) : (
        <div className={`${styles.subsInfo} flex flex-wrap mt-14`}>
          <div className={`flex items-baseline px-10 py-8`}>
            <Skeleton.Input
              className={`block`}
              style={{ width: "15vw", marginRight: "2rem" }}
              active={true}
              size={`large`}
            />
          </div>
          <div className={`flex items-baseline px-10 py-8`}>
            <Skeleton.Input
              className={`block`}
              style={{ width: "15vw", marginRight: "2rem" }}
              active={true}
              size={`large`}
            />
          </div>
          <div className={`flex items-baseline px-10 py-8`}>
            <Skeleton.Input
              className={`block`}
              style={{ width: "15vw", marginRight: "2rem" }}
              active={true}
              size={`large`}
            />
          </div>
        </div>
      )}

      <div className={`${styles.nqaProgress} mt-14`}>
        <h2>NQA progress</h2>

        {nqaProgress.nqaItems.length === 0 || loadingProgress ? (
          <div
            className={`w-full h-full flex flex-col justify-center items-center py-20`}
          >
            <FaSpinner
              className={`animate-spin text-qipGreen font-bold text-6xl`}
            />
            <span className={`block`}> Loading data...</span>
          </div>
        ) : (
          <div
            className={`${styles.nqaProgressInfoContainer} flex flex-wrap mt-8`}
          >
            <div
              className={`${styles.nqaProgressInfo} ${styles.nqaTotalProgress} flex px-12 py-9`}
            >
              <div className={`w-full`}>
                <text className={`${styles.data} ${styles.large}`}>
                  {Number(nqaProgress.totalProgress) !== 99.94462901439645
                    ? Math.floor(nqaProgress.totalProgress)
                      ? Math.floor(nqaProgress.totalProgress)
                      : "0"
                    : "100"}
                  %
                </text>
                <div className={`${styles.progressBarContainer} mt-3 mb-3.5`}>
                  <div
                    className={`${styles.progressBar}`}
                    style={{
                      width: `${
                        Number(nqaProgress.totalProgress) !== 99.94462901439645
                          ? Math.floor(nqaProgress.totalProgress)
                            ? Math.floor(nqaProgress.totalProgress)
                            : "0"
                          : "100"
                      }%`,
                    }}
                  ></div>
                </div>
                <text className={`${styles.label}`}>Total progress</text>
              </div>
            </div>

            <div
              className={`${styles.nqaProgressInfo} ${styles.nqa1Progress} flex px-12 py-9`}
            >
              <div className={`w-full	`}>
                <text className={`${styles.data} ${styles.large}`}>
                  {Math.floor(nqaProgress.nqaItems[0].completed)}%
                </text>
                <div className={`${styles.progressBarContainer} mt-3 mb-3.5`}>
                  <div
                    className={`${styles.progressBar}`}
                    style={{
                      width: `${Math.floor(
                        nqaProgress.nqaItems[0].completed
                      )}%`,
                    }}
                  ></div>
                </div>
                <text className={`${styles.label}`}>NQA 1</text>
              </div>
            </div>

            <div
              className={`${styles.nqaProgressInfo} ${styles.nqa2Progress} flex px-12 py-9`}
            >
              <div className={`w-full	`}>
                <text className={`${styles.data} ${styles.large}`}>
                  {Number(nqaProgress.nqaItems[1].completed) !==
                  99.6124031007752
                    ? Math.floor(nqaProgress.nqaItems[1].completed)
                    : "100"}
                  %
                </text>
                <div className={`${styles.progressBarContainer} mt-3 mb-3.5`}>
                  <div
                    className={`${styles.progressBar}`}
                    style={{
                      width: `${
                        Number(nqaProgress.nqaItems[1].completed) !==
                        99.6124031007752
                          ? Math.floor(nqaProgress.nqaItems[1].completed)
                          : "100"
                      }%`,
                    }}
                  ></div>
                </div>
                <text className={`${styles.label}`}>NQA 2</text>
              </div>
            </div>

            <div
              className={`${styles.nqaProgressInfo} ${styles.nqa3Progress} flex px-12 py-9`}
            >
              <div className={`w-full	`}>
                <text className={`${styles.data} ${styles.large}`}>
                  {Math.floor(nqaProgress.nqaItems[2].completed)}%
                </text>
                <div className={`${styles.progressBarContainer} mt-3 mb-3.5`}>
                  <div
                    className={`${styles.progressBar}`}
                    style={{
                      width: `${Math.floor(
                        nqaProgress.nqaItems[2].completed
                      )}%`,
                    }}
                  ></div>
                </div>
                <text className={`${styles.label}`}>NQA 3</text>
              </div>
            </div>

            <div
              className={`${styles.nqaProgressInfo} ${styles.nqa4Progress} flex px-12 py-9`}
            >
              <div className={`w-full	`}>
                <text className={`${styles.data} ${styles.large}`}>
                  {" "}
                  {Math.floor(nqaProgress.nqaItems[3].completed)}%
                </text>
                <div className={`${styles.progressBarContainer} mt-3 mb-3.5`}>
                  <div
                    className={`${styles.progressBar}`}
                    style={{
                      width: `${Math.floor(
                        nqaProgress.nqaItems[3].completed
                      )}%`,
                    }}
                  ></div>
                </div>
                <text className={`${styles.label}`}>NQA 4</text>
              </div>
            </div>

            <div
              className={`${styles.nqaProgressInfo} ${styles.nqa5Progress} flex px-12 py-9`}
            >
              <div className={`w-full	`}>
                <text className={`${styles.data} ${styles.large}`}>
                  {Math.floor(nqaProgress.nqaItems[4].completed)}%
                </text>
                <div className={`${styles.progressBarContainer} mt-3 mb-3.5`}>
                  <div
                    className={`${styles.progressBar}`}
                    style={{
                      width: `${Math.floor(
                        nqaProgress.nqaItems[4].completed
                      )}%`,
                    }}
                  ></div>
                </div>
                <text className={`${styles.label}`}>NQA 5</text>
              </div>
            </div>

            <div
              className={`${styles.nqaProgressInfo} ${styles.nqa6Progress} flex px-12 py-9`}
            >
              <div className={`w-full	`}>
                <text className={`${styles.data} ${styles.large}`}>
                  {Math.floor(nqaProgress.nqaItems[5].completed)}%
                </text>
                <div className={`${styles.progressBarContainer} mt-3 mb-3.5`}>
                  <div
                    className={`${styles.progressBar}`}
                    style={{
                      width: `${Math.floor(
                        nqaProgress.nqaItems[5].completed
                      )}%`,
                    }}
                  ></div>
                </div>
                <text className={`${styles.label}`}>NQA 6</text>
              </div>
            </div>

            <div
              className={`${styles.nqaProgressInfo} ${styles.nqa7Progress} flex px-12 py-9`}
            >
              <div className={`w-full	`}>
                <text className={`${styles.data} ${styles.large}`}>
                  {" "}
                  {Math.floor(nqaProgress.nqaItems[6].completed)}%
                </text>
                <div className={`${styles.progressBarContainer} mt-3 mb-3.5`}>
                  <div
                    className={`${styles.progressBar}`}
                    style={{
                      width: `${Math.floor(
                        nqaProgress.nqaItems[6].completed
                      )}%`,
                    }}
                  ></div>
                </div>
                <text className={`${styles.label}`}>NQA 7</text>
              </div>
            </div>
          </div>
        )}
      </div>

      <div
        className={`${styles.requestFeedbackContainer} mt-14 cursor-default`}
        onClick={() => {
          history.push(`/qip/request-feedback/list`);
        }}
      >
        <h2>Request feedback</h2>

        <div className={`${styles.requestFeedback} flex flex-wrap mt-8`}>
          <div className={`flex items-baseline px-10 py-8`}>
            <text className={`${styles.data} ${styles.large} mr-3`}>
              {/* {feedbacks.length} */}
              {
                nqaFeedbacks.filter(
                  (fb) => Number(fb.center_id) === Number(centerId)
                ).length
              }
            </text>
            <text className={`${styles.label}`}>Feedback requests</text>
          </div>

          <div className={`flex flex-wrap items-baseline px-10 py-8`}>
            <text className={`${styles.data} ${styles.large} mr-3`}>
              {/* {publishedFeedbacks.length} */}
              {
                nqaFeedbacks.filter((fb) => {
                  if (Number(fb.center_id) === Number(centerId)) {
                    if (!isEmpty(fb.rf)) {
                      if (fb.rf.save_or_publish === 2) {
                        return true;
                      } else {
                        return false;
                      }
                    } else {
                      return false;
                    }
                  } else {
                    return false;
                  }
                }).length
              }
            </text>
            <text className={`${styles.label}`}>Published feedback</text>

            {/* <div className={`${styles.dropdown} w-full mt-5`}>
              <Dropdown
                disabled={!feedbacks.length}
                selection
                placeholder="View feedback"
                className={`w-full`}
              />
            </div> */}
          </div>

          <div className={`flex flex-wrap items-baseline px-10 py-8`}>
            <text className={`${styles.data} ${styles.large} mr-3`}>
              {/* {unPublishedFeedbacks.length} */}
              {
                nqaFeedbacks.filter((fb) => {
                  if (Number(fb.center_id) === Number(centerId)) {
                    if (!isEmpty(fb.rf)) {
                      if (fb.rf.save_or_publish === 1) {
                        return true;
                      } else {
                        return false;
                      }
                    } else {
                      return true;
                    }
                  } else {
                    return false;
                  }
                }).length
              }
            </text>
            <text className={`${styles.label}`}>Unpublished feedback</text>

            {/* <div className={`${styles.dropdown} w-full mt-5`}>
              <Dropdown
                disabled={!feedbacks.length}
                selection
                placeholder="Complete feedback"
                className={`w-full`}
              />
            </div> */}
          </div>
        </div>
      </div>

      <div className={`${styles.subsAccount} mt-14`}>
        <h2>View subscriber’s account</h2>
        <div
          className={`${styles.actionButtonGroup} flex flex-wrap justify-center items-center mt-8`}
        >
          <BlueButton
            onClick={() => {
              const hostname = new URL(window.location.href).hostname.split(
                "."
              );

              let token = JSON.parse(localStorage.getItem("qip_client_token"));

              const sub = new URL(process.env.REACT_APP_QIP_URL).hostname.split(
                "."
              )[0];

              if (hostname[0] === "localhost") {
                window.open(
                  "http://localhost:3001/view-service-dashboard?userId=" +
                    userId +
                    "&centerId=" +
                    centerId +
                    "&stripeId=" +
                    (!isEmpty(center.subscription)
                      ? center.subscription.customer_id
                      : "empty") +
                    "&accessToken=" +
                    token.accessToken +
                    "&counter=" +
                    generateRandom(12)
                );
              } else {
                const host = hostname.slice(1, hostname.length).join(".");
                window.open(
                  "https://" +
                    sub +
                    "." +
                    host +
                    "/view-service-dashboard?userId=" +
                    userId +
                    "&centerId=" +
                    centerId +
                    "&stripeId=" +
                    (!isEmpty(center.subscription)
                      ? center.subscription.customer_id
                      : "empty") +
                    "&accessToken=" +
                    token.accessToken +
                    "&counter=" +
                    generateRandom(12)
                );
              }
            }}
            className={`${styles.actionButton}`}
            buttonText="Visit service’s dashboard"
          />

          <BlueButton
            onClick={() => {
              history.push(`/qip/subscribers/subscription-billing`, {
                sumLocState,
                userId,
                userName,
              });
            }}
            className={`${styles.actionButton}`}
            // className={`${styles.nonActionButton} cursor-pointer opacity-50`}
            buttonText="Subscription & Billing"
          />

          <BlueButton
            onClick={() => {
              history.push(`/qip/subscribers/app-usage-logs`, {
                sumLocState,
                userId,
                userName,
              });
            }}
            className={`${styles.actionButton}`}
            buttonText="App usage logs"
          />
        </div>
      </div>
    </div>
  );
};

export default Details;
