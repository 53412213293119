import * as React from "react";

import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useRouteMatch,
} from "react-router-dom";

// TODO:: Lazy Load these components
import ServiceName from "../../pages/ServiceDetails/ServiceName";
import Philosophy from "../../pages/ServiceDetails/Philosophy";
import PostalAddress from "../../pages/ServiceDetails/PostalAddress";
import CenterBranding from "../../pages/ServiceDetails/CenterBranding";
import PhysicalLocation from "../../pages/ServiceDetails/PhysicalLocation";
import ApprovedProvider from "../../pages/ServiceDetails/ApprovedProvider";
import OperationalHours from "../../pages/ServiceDetails/OperationalHours";
import EducationalLeader from "../../pages/ServiceDetails/EducationalLeader";
import NumberOfEducators from "../../pages/ServiceDetails/NumberOfEducators";
import PeopleResponsible from "../../pages/ServiceDetails/PeopleResponsible";
import NominatedSupervisor from "../../pages/ServiceDetails/NominatedSupervisor";
import AdditionalInformation from "../../pages/ServiceDetails/AdditionalInformation";
import ServiceContactDetails from "../../pages/ServiceDetails/ServiceContactDetails";
import spinner from "../../static/images/preloader.png";
import { useStoreActions, useStoreState } from "../../hooks";
import { ServiceDetailsContext } from "../../context/ServiceDetailsContext";
import { isEmpty } from "lodash";

const { useCallback, useReducer, useState, useEffect } = React;

const serviceDetailsReducer = (state, action) => {
  switch (action.type) {
    case "UPDATE": {
      const updatedInput = {
        ...state.input,
        [action.field]: action.value,
      };

      const updatedValidation = {
        ...state.validation,
        [action.field]: action.valid,
      };

      // const formValidity = Object.values(updatedValidation).every(Boolean);

      return {
        input: updatedInput,
        validation: updatedValidation,
        // formValidation: formValidity
      };
    }
    default:
      return state;
  }
};

const initialInput = {
  name: "",
  approvalNumber: "",
  serviceCatId: 1,
  locationStreet: "",
  locationSuburb: "",
  locationState: "",
  locationPostalCode: "",
  postalStreet: "",
  postalSuburb: "",
  postalState: "",
  postalCode: "",
  contactPhoneNum: "",
  contactMobileNum: "",
  contactFaxNum: "",
  contactEmail: "",
  contactAdditionalEmail: "",
  providerFirstName: "",
  providerLastName: "",
  providerPhoneNum: "",
  providerMobileNum: "",
  providerFaxNum: "",
  providerEmail: "",
  supervisorFirstName: "",
  supervisorLastName: "",
  supervisorPhoneNum: "",
  supervisorMobileNum: "",
  supervisorFaxNum: "",
  supervisorEmail: "",
  leaderFirstName: "",
  leaderLastName: "",
  leaderPhoneNum: "",
  leaderMobileNum: "",
  leaderFaxNum: "",
  leaderEmail: "",
  operatingHours: [
    {
      day: "monday",
      openTime: "06:00AM",
      closeTime: "07:00PM",
      working: true,
    },
    {
      day: "tuesday",
      openTime: "06:00AM",
      closeTime: "07:00PM",
      working: true,
    },
    {
      day: "wednesday",
      openTime: "06:00AM",
      closeTime: "07:00PM",
      working: true,
    },
    {
      day: "thursday",
      openTime: "06:00AM",
      closeTime: "07:00PM",
      working: true,
    },
    {
      day: "friday",
      openTime: "06:00AM",
      closeTime: "07:00PM",
      working: true,
    },
    {
      day: "saturday",
      openTime: "06:00AM",
      closeTime: "07:00PM",
      working: true,
    },
    {
      day: "sunday",
      openTime: "06:00AM",
      closeTime: "07:00PM",
      working: true,
    },
  ],
  additionalInfo: "",
  rooms: [],
  educators: [],
  employees: [],
  philosophy: "",
  logo: {},
};

export default function ServiceDetails({ onboarding = false }) {
  const history = useHistory();
  const match = useRouteMatch();

  const login = useStoreActions((actions) => actions.authStore.login);
  const authObject = useStoreState((state) => state.authStore.authObject);
  const isLoggedIn = useStoreState((state) => state.authStore.isLoggedIn);
  const signUpCreds = useStoreState((state) => state.authStore.signUpCreds);

  useStoreActions((state) => state.appStore.setHeaderMenu(!onboarding));

  const serviceDetails = useStoreState(
    (state) => state.nqaStore.serviceDetails
  );
  const updateServiceDetails = useStoreActions(
    (actions) => actions.nqaStore.updateServiceDetails
  );

  const [saveLoading, setLoading] = useState(false);

  const steps = [
    {
      id: 0,
      completed: isLoggedIn
        ? serviceDetails.name &&
          serviceDetails.approvalNumber &&
          serviceDetails.serviceCatId
          ? true
          : false
        : false,
    },
    {
      id: 1,
      completed: isLoggedIn
        ? serviceDetails.locationStreet && serviceDetails.locationSuburb
          ? true
          : false
        : false,
    },
    {
      id: 2,
      completed: isLoggedIn
        ? serviceDetails.postalStreet && serviceDetails.postalSuburb
          ? true
          : false
        : false,
    },
    {
      id: 3,
      completed: isLoggedIn
        ? serviceDetails.contactPhoneNum && serviceDetails.contactEmail
          ? true
          : false
        : false,
    },
    {
      id: 4,
      completed: isLoggedIn
        ? serviceDetails.providerFirstName &&
          serviceDetails.providerLastName &&
          serviceDetails.providerEmail &&
          serviceDetails.providerPhoneNum
          ? true
          : false
        : false,
    },
    {
      id: 5,
      completed: isLoggedIn
        ? serviceDetails.supervisorFirstName &&
          serviceDetails.supervisorLastName &&
          serviceDetails.supervisorEmail &&
          serviceDetails.supervisorPhoneNum
          ? true
          : false
        : false,
    },
    {
      id: 6,
      completed: isLoggedIn
        ? serviceDetails.leaderFirstName &&
          serviceDetails.leaderLastName &&
          serviceDetails.leaderEmail &&
          serviceDetails.leaderPhoneNum
          ? true
          : false
        : false,
    },
    {
      id: 7,
      completed: isLoggedIn
        ? serviceDetails.operatingHours &&
          serviceDetails.operatingHours.length > 0
          ? true
          : false
        : true,
    },
    {
      id: 8,
      completed: isLoggedIn
        ? serviceDetails.rooms && serviceDetails.rooms.length > 0
          ? true
          : false
        : false,
    },
    {
      id: 9,
      completed: isLoggedIn
        ? serviceDetails.educators && serviceDetails.educators.length > 0
          ? true
          : false
        : false,
    },
    {
      id: 10,
      completed: isLoggedIn
        ? serviceDetails.employees && serviceDetails.employees.length > 0
          ? true
          : false
        : false,
    },
    {
      id: 11,
      completed: isLoggedIn
        ? serviceDetails.philosophy
          ? true
          : false
        : false,
    },
    {
      id: 12,
      completed: isLoggedIn
        ? serviceDetails.logo
          ? !isEmpty(serviceDetails.logo)
            ? true
            : false
          : false
        : false,
    },
  ];

  const [currentStep, setCurrentStep] = useState(onboarding ? 1 : 0);
  const [stepsIndicator, setCompletedSteps] = useState(steps);

  const initialDetailsState = {
    input: serviceDetails ? serviceDetails : initialInput,
    validation: {
      //TODO: configure for input components
      name: isLoggedIn
        ? serviceDetails.name
          ? serviceDetails.name
          : false
        : false,
      approvalNumber: isLoggedIn
        ? serviceDetails.approvalNumber
          ? serviceDetails.approvalNumber
          : false
        : false,
      serviceCatId: isLoggedIn
        ? serviceDetails.serviceCatId
          ? serviceDetails.serviceCatId
          : false
        : false,
      locationStreet: isLoggedIn
        ? serviceDetails.locationStreet
          ? serviceDetails.locationStreet
          : false
        : false,
      locationSuburb: isLoggedIn
        ? serviceDetails.locationSuburb
          ? serviceDetails.locationSuburb
          : false
        : false,
      locationState: true,
      locationPostalCode: true,
      postalStreet: isLoggedIn
        ? serviceDetails.postalStreet
          ? serviceDetails.postalStreet
          : false
        : false,
      postalSuburb: isLoggedIn
        ? serviceDetails.postalSuburb
          ? serviceDetails.postalSuburb
          : false
        : false,
      postalState: true,
      postalCode: true,
      contactPhoneNum: isLoggedIn
        ? serviceDetails.contactPhoneNum
          ? serviceDetails.contactPhoneNum
          : false
        : false,
      contactAdditionalEmail: true,
      contactMobileNum: true,
      contactFaxNum: true,
      contactEmail: isLoggedIn
        ? serviceDetails.contactEmail
          ? serviceDetails.contactEmail
          : false
        : false,
      providerFirstName: isLoggedIn
        ? serviceDetails.providerFirstName
          ? serviceDetails.providerFirstName
          : false
        : false,
      providerLastName: isLoggedIn
        ? serviceDetails.providerLastName
          ? serviceDetails.providerLastName
          : false
        : false,
      providerPhoneNum: isLoggedIn
        ? serviceDetails.providerPhoneNum
          ? serviceDetails.providerPhoneNum
          : false
        : false,
      providerMobileNum: true,
      providerFaxNum: true,
      providerEmail: isLoggedIn
        ? serviceDetails.providerEmail
          ? serviceDetails.providerEmail
          : false
        : false,
      supervisorFirstName: isLoggedIn
        ? serviceDetails.supervisorFirstName
          ? serviceDetails.supervisorFirstName
          : false
        : false,
      supervisorLastName: isLoggedIn
        ? serviceDetails.supervisorLastName
          ? serviceDetails.supervisorLastName
          : false
        : false,
      supervisorPhoneNum: isLoggedIn
        ? serviceDetails.supervisorPhoneNum
          ? serviceDetails.supervisorPhoneNum
          : false
        : false,
      supervisorMobileNum: true,
      supervisorFaxNum: true,
      supervisorEmail: isLoggedIn
        ? serviceDetails.supervisorEmail
          ? serviceDetails.supervisorEmail
          : false
        : false,
      leaderFirstName: isLoggedIn
        ? serviceDetails.leaderFirstName
          ? serviceDetails.leaderFirstName
          : false
        : false,
      leaderLastName: isLoggedIn
        ? serviceDetails.leaderLastName
          ? serviceDetails.leaderLastName
          : false
        : false,
      leaderPhoneNum: isLoggedIn
        ? serviceDetails.leaderPhoneNum
          ? serviceDetails.leaderPhoneNum
          : false
        : false,
      leaderMobileNum: true,
      leaderFaxNum: true,
      leaderEmail: isLoggedIn
        ? serviceDetails.leaderEmail
          ? serviceDetails.leaderEmail
          : false
        : false,
      operatingHours: true,
      additionalInfo: true,
      rooms: true,
      educators: true,
      employees: true,
      philosophy: isLoggedIn
        ? serviceDetails.philosophy
          ? serviceDetails.philosophy
          : false
        : false,
      logo: isLoggedIn
        ? serviceDetails.logo
          ? !isEmpty(serviceDetails.logo)
            ? true
            : false
          : false
        : false,
    },
    // formValidation: true
  };

  const [serviceDetailsState, dispatch] = useReducer(
    serviceDetailsReducer,
    initialDetailsState
  );

  const changeInputDispatch = useCallback((field, value, validity = true) => {
    const action = {
      type: "UPDATE",
      field,
      value,
      valid: validity,
    };
    dispatch(action);
  }, []);

  const onNextStep = (id) => {
    const updatedSteps = [...stepsIndicator];
    updatedSteps.find((step) => step.id === id).completed = true;
    setCompletedSteps(updatedSteps);

    setCurrentStep((prev) => prev + 1);
  };

  const onSkipStep = (id) => {
    const updatedSteps = [...stepsIndicator];
    updatedSteps.find((step) => step.id === id).completed = false;
    setCompletedSteps(updatedSteps);

    setCurrentStep((prev) => prev + 1);
  };

  const onSaveServiceDetailStep = async (id = -1) => {
    setLoading(true);

    try {
      const payload = {
        serviceDetails: {
          ...serviceDetailsState.input,
          userId: isLoggedIn ? authObject.userId : signUpCreds.userId,
        },
        centerId: isLoggedIn ? serviceDetails.id : signUpCreds.centerId,
      };

      await updateServiceDetails(payload);

      if (id !== -1) {
        const updatedSteps = [...stepsIndicator];
        updatedSteps.find((step) => step.id === id).completed = isEmpty(
          serviceDetailsState.input.logo
        );
        setCompletedSteps(updatedSteps);
      }
    } catch (error) {
      // alert(error);
      console.log(error);
    }

    setLoading(false);
  };

  const onServiceDetailsFinish = async () => {
    console.log(" on service details finish..");
    // setLoading(true);

    //  let lsID = JSON.parse(localStorage.getItem("qip_client_token"));
    // console.log(lsID);
    // location.reload();
    // const payload = {
    //   serviceDetails: {
    //     ...serviceDetailsState.input,
    //     userId: isLoggedIn ? authObject.userId : signUpCreds.userId,
    //     // userId: isLoggedIn ? authObject.userId : lsID.userId
    //   },
    //   centerId: isLoggedIn ? serviceDetails.id : signUpCreds.centerId,
    // };

    // const detailsResponse = await updateServiceDetails(payload);

    // if (detailsResponse && !isLoggedIn) {
    //   const loginPayload = {
    //     email: signUpCreds.email,
    //     password: signUpCreds.password,
    //   };

    //   const loginResponse = await login(loginPayload);

    //   if (!loginResponse) setLoading(false);
    // } else {
    //   setLoading(false);
    //   history.push("/nqa");
    // }
    location.reload();
    if (!isLoggedIn) {
      const loginPayload = {
        email: signUpCreds.email,
        password: signUpCreds.password,
      };
      await login(loginPayload);
      window.location = "/nqa";
    } else {
      // history.push("/nqa");
      window.location = "/nqa";
    }
  };

  return (
    <ServiceDetailsContext.Provider
      value={{
        saveLoading,
        onboarding,
        stepsIndicator,
        serviceDetailsState,
        changeInputDispatch,
      }}
    >
      {/* {saveLoading ? (
        <div
          className={`w-full h-screen flex flex-col justify-center items-center`}
        >
          <img src={spinner} className={`block`} />
          <span className={`block`}> Saving service details...</span>
        </div>
      ) : ( */}
      <Switch>
        {!onboarding && (
          <Route exact path={`${match.path}/0`}>
            <ServiceName
              onNextStep={() => onNextStep(0)}
              onSkipStep={() => onSkipStep(0)}
              onSaveStep={onSaveServiceDetailStep}
              onSkipServiceDetails={onServiceDetailsFinish}
            />
          </Route>
        )}
        <Route exact path={`${match.path}/1`}>
          <PhysicalLocation
            onNextStep={() => onNextStep(1)}
            onSkipStep={() => onSkipStep(1)}
            onSaveStep={onSaveServiceDetailStep}
            onSkipServiceDetails={onServiceDetailsFinish}
          />
        </Route>
        <Route exact path={`${match.path}/2`}>
          <PostalAddress
            onNextStep={() => onNextStep(2)}
            onSkipStep={() => onSkipStep(2)}
            onSaveStep={onSaveServiceDetailStep}
            onSkipServiceDetails={onServiceDetailsFinish}
          />
        </Route>
        <Route exact path={`${match.path}/3`}>
          <ServiceContactDetails
            onNextStep={() => onNextStep(3)}
            onSkipStep={() => onSkipStep(3)}
            onSaveStep={onSaveServiceDetailStep}
            onSkipServiceDetails={onServiceDetailsFinish}
          />
        </Route>
        <Route exact path={`${match.path}/4`}>
          <ApprovedProvider
            onNextStep={() => onNextStep(4)}
            onSkipStep={() => onSkipStep(4)}
            onSaveStep={onSaveServiceDetailStep}
            onSkipServiceDetails={onServiceDetailsFinish}
          />
        </Route>
        <Route exact path={`${match.path}/5`}>
          <NominatedSupervisor
            onNextStep={() => onNextStep(5)}
            onSkipStep={() => onSkipStep(5)}
            onSaveStep={onSaveServiceDetailStep}
            onSkipServiceDetails={onServiceDetailsFinish}
          />
        </Route>
        <Route exact path={`${match.path}/6`}>
          <EducationalLeader
            onNextStep={() => onNextStep(6)}
            onSkipStep={() => onSkipStep(6)}
            onSaveStep={onSaveServiceDetailStep}
            onSkipServiceDetails={onServiceDetailsFinish}
          />
        </Route>
        <Route exact path={`${match.path}/7`}>
          <OperationalHours
            onNextStep={() => onNextStep(7)}
            onSkipStep={() => onSkipStep(7)}
            onSaveStep={onSaveServiceDetailStep}
            onSkipServiceDetails={onServiceDetailsFinish}
          />
        </Route>
        <Route exact path={`${match.path}/8`}>
          <AdditionalInformation
            onNextStep={() => onNextStep(8)}
            onSkipStep={() => onSkipStep(8)}
            onSaveStep={onSaveServiceDetailStep}
            onSkipServiceDetails={onServiceDetailsFinish}
          />
        </Route>
        <Route exact path={`${match.path}/9`}>
          <NumberOfEducators
            onNextStep={() => onNextStep(9)}
            onSkipStep={() => onSkipStep(9)}
            onSaveStep={onSaveServiceDetailStep}
            onSkipServiceDetails={onServiceDetailsFinish}
          />
        </Route>
        <Route exact path={`${match.path}/10`}>
          <PeopleResponsible
            onNextStep={() => onNextStep(10)}
            onSkipStep={() => onSkipStep(10)}
            onSaveStep={onSaveServiceDetailStep}
            onSkipServiceDetails={onServiceDetailsFinish}
          />
        </Route>
        <Route exact path={`${match.path}/11`}>
          <Philosophy
            onNextStep={() => onNextStep(11)}
            onSkipStep={() => onSkipStep(11)}
            onSaveStep={onSaveServiceDetailStep}
            onSkipServiceDetails={onServiceDetailsFinish}
          />
        </Route>
        <Route exact path={`${match.path}/12`}>
          <CenterBranding
            onUpdateStep={() => onSaveServiceDetailStep(12)}
            onFinish={onServiceDetailsFinish}
          />
        </Route>
        {/* <Route exact path={`${match.path}/0`}>
            <Redirect to={`${match.path}/${currentStep}`} />
          </Route> */}
        <Route exact path={match.url}>
          <Redirect to={`${match.path}/${currentStep}`} />
        </Route>
      </Switch>
      {/* )} */}
    </ServiceDetailsContext.Provider>
  );
}
