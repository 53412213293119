/* eslint-disable object-property-newline */
import * as React from "react";
import { useHistory } from "react-router-dom";
import { Dropdown } from "semantic-ui-react";
import InputComponent from "../../../components/InputComponent";
import BlueButton from "../../../components/BlueButton";
import SkipButton from "../../../components/SkipButton";
import AddNewQuestionModal from "../../../components/Modals/AddNewQuestionModal";
import MultiSelect from "../../../components/MultiSelect";
import {
  FaCommentDots,
  FaPlusCircle,
  FaBookOpen,
  FaSpinner,
  FaReply,
  FaArchive,
  FaArrowDown,
  FaArrowUp,
} from "react-icons/fa";
import Pagination from "../../../components/Pagination/Pagination";
import {
  fetchNQAData,
  fetchNQAQuestionsAggregate,
  editQuestion,
  archiveQuestion,
} from "../../../api/requests";
import { isEmpty } from "lodash";

import Swal from "sweetalert2";

import styles from "./Questions.module.css";

const { useState, useEffect, useCallback } = React;

const questionsPerPage = 12;
export const SERVICE_TYPES = [
  { label: "LDC", value: 1 },
  { label: "FDC", value: 2 },
  { label: "Pre-school", value: 3 },
  { label: "OOSH", value: 4 },
];

function Questions() {
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState(1);
  const [currentFilter, setCurrentFilter] = useState(1);
  const [subCurrentFilter, setSubCurrentFilter] = useState(`1.1.1`);
  // const [currentFilterClass, setCurrentFilterClass] = useState("nqa_1");
  const [addNewQuestionModal, setAddNewQuestionModal] = useState(false);

  const [sourceId, setSourceId] = useState(0);

  const [nqaData, setNqaData] = useState();

  const [questionItems, setQuestionItems] = useState([]);

  const [loadingQuestions, setLoadingQuestions] = useState(false);

  const [updatingQuestion, setUpdatingQuestion] = useState(false);

  const [archivingQuestion, setArchvingQuestion] = useState(false);

  const [selectedQuestion, setSelectedQuestion] = useState({});

  const nqaQuestionTypes = [
    {
      key: "embedded",
      text: "Embedded",
      value: "embedded",
    },
    {
      key: "critical_reflections",
      text: "Critical Reflections",
      value: "critical_reflections",
    },
    {
      key: "partnerships_with_family",
      text: "Partnerships with Families",
      value: "partnerships_with_family",
    },
  ];

  const Options = [
    {
      key: "Option 1",
      text: "Option 1",
      value: "Option 1",
    },
    {
      key: "Option 2",
      text: "Option 2",
      value: "Option 2",
    },
  ];

  const getSublevels = (filter) => {
    let sublevels = [];
    let id = 0;
    let initSourceId = 0;
    if (nqaData) {
      nqaData[filter - 1].levels.forEach((level) => {
        // id = id + 1;
        // sublevels.push({ label: level.name, id: id });

        level.subLevels.forEach((sub) => {
          const sublabellarray = sub.label.split(".");

          if (
            sourceId === 0 &&
            sublabellarray[1] === `1` &&
            sublabellarray[2] === `1`
          ) {
            setSourceId(sub.sourceId);
            initSourceId = sub.sourceId;
          }
          id = id + 1;
          sublevels.push({ label: sub.label, id: id, sourceId: sub.sourceId });
        });
      });
    }
    if (initSourceId !== 0) {
      getNqaQuestions(initSourceId);
    }

    return sublevels;
    // setSubLevels(sublevels);
  };

  const getNqaQuestions = useCallback(async (sourceId) => {
    try {
      setLoadingQuestions(true);
      const response = await fetchNQAQuestionsAggregate(
        Number(sourceId),
        subCurrentFilter
      );
      setLoadingQuestions(false);
      setQuestionItems(response);
    } catch (error) {
      setLoadingQuestions(false);
      console.log(error);
    }
  }, []);

  const getNqaData = useCallback(async () => {
    try {
      const response = await fetchNQAData();
      setNqaData(response);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const onUpdateQuestion = async (question) => {
    try {
      setUpdatingQuestion(true);
      const serviceType = question.serviceType.map(({ value }) => value);
      const response = await editQuestion(question.id, {
        ...question,
        serviceType,
      });
      await getNqaQuestions(sourceId);
      Swal.fire({
        icon: `success`,
        title: `Question updated`,
        showConfirmButton: false,
        timer: 1700,
      }).then(() => {
        setUpdatingQuestion(false);
      });
    } catch (error) {
      setUpdatingQuestion(false);
      console.log(error);
    }
  };

  const onConfirmAddQuestion = () => {
    setAddNewQuestionModal(false);

    Swal.fire({
      icon: `success`,
      title: `Question created`,
      showConfirmButton: false,
      timer: 1700,
    });
  };

  const onArchiveQuestion = async (questionId, archive) => {
    try {
      setArchvingQuestion(true);
      const response = await archiveQuestion(questionId, archive);

      await getNqaQuestions(sourceId);
      setArchvingQuestion(false);
      Swal.fire({
        icon: `success`,
        title: `Question ${archive === 1 ? "archived" : "restored"}`,
        showConfirmButton: false,
        timer: 1700,
      });
    } catch (error) {
      setArchvingQuestion(false);
      console.log(error);
    }
  };

  useEffect(() => {
    getNqaData();
  }, []);

  return (
    <div className={`${styles.mainContainer} mt-5 mb-10	`}>
      {addNewQuestionModal && sourceId !== 0 && (
        <AddNewQuestionModal
          visibility={addNewQuestionModal}
          onOpenModal={() => setAddNewQuestionModal(true)}
          onCloseModal={() => setAddNewQuestionModal(false)}
          nqaSubLevel={subCurrentFilter}
          sourceId={sourceId}
          refreshQuestions={async () => {
            await getNqaQuestions(sourceId);
          }}
          onConfirm={onConfirmAddQuestion}
        />
      )}

      <div className={`${styles.headerTextContainer} flex`}>
        <div className={styles.headerTextIcon}>
          <FaCommentDots size={55} />
        </div>

        <div className={`${styles.headerText} ml-4`}>
          <h1>NQA Questions</h1>
          <text>Add, update and manage your NQA questions</text>
        </div>
      </div>

      <div
        className={`${styles.questionsContainer} md:px-7 lg:px-14 pt-14 pb-16 mt-9`}
      >
        <div className={`${styles.questionsFilterRow} flex flex-wrap pb-2.5`}>
          <div
            className={`${
              currentFilter === 1
                ? styles.questionsFilterSelected
                : styles.questionsFilter
            }  flex items-center justify-center`}
            onClick={async () => {
              if (!loadingQuestions) {
                setCurrentPage(1);
                setQuestionItems([]);
                setSourceId(0);
                setSubCurrentFilter(`1.1.1`);
                setCurrentFilter(1); /*setCurrentFilterClass("nqa_1")*/
                getSublevels(1);
              }
            }}
          >
            NQA 1
          </div>
          <div
            className={`${
              currentFilter === 2
                ? styles.questionsFilterSelected
                : styles.questionsFilter
            }  flex items-center justify-center`}
            onClick={async () => {
              if (!loadingQuestions) {
                setCurrentPage(1);
                setQuestionItems([]);
                setSourceId(0);
                setSubCurrentFilter(`2.1.1`);
                setCurrentFilter(2); /*setCurrentFilterClass("nqa_2")*/
                getSublevels(2);
              }
            }}
          >
            NQA 2
          </div>
          <div
            className={`${
              currentFilter === 3
                ? styles.questionsFilterSelected
                : styles.questionsFilter
            }  flex items-center justify-center`}
            onClick={async () => {
              if (!loadingQuestions) {
                setCurrentPage(1);
                setQuestionItems([]);
                setSourceId(0);
                setSubCurrentFilter(`3.1.1`);
                setCurrentFilter(3); /*setCurrentFilterClass("nqa_3")*/
                getSublevels(3);
              }
            }}
          >
            NQA 3
          </div>
          <div
            className={`${
              currentFilter === 4
                ? styles.questionsFilterSelected
                : styles.questionsFilter
            }  flex items-center justify-center`}
            onClick={async () => {
              if (!loadingQuestions) {
                setCurrentPage(1);
                setQuestionItems([]);
                setSourceId(0);
                setSubCurrentFilter(`4.1.1`);
                setCurrentFilter(4); /*setCurrentFilterClass("nqa_4")*/
                getSublevels(4);
              }
            }}
          >
            NQA 4
          </div>
          <div
            className={`${
              currentFilter === 5
                ? styles.questionsFilterSelected
                : styles.questionsFilter
            }  flex items-center justify-center`}
            onClick={async () => {
              if (!loadingQuestions) {
                setCurrentPage(1);
                setQuestionItems([]);
                setSourceId(0);
                setSubCurrentFilter(`5.1.1`);
                setCurrentFilter(5); /*setCurrentFilterClass("nqa_5")*/
                getSublevels(5);
              }
            }}
          >
            NQA 5
          </div>
          <div
            className={`${
              currentFilter === 6
                ? styles.questionsFilterSelected
                : styles.questionsFilter
            }  flex items-center justify-center`}
            onClick={async () => {
              if (!loadingQuestions) {
                setCurrentPage(1);
                setQuestionItems([]);
                setSourceId(0);
                setSubCurrentFilter(`6.1.1`);
                setCurrentFilter(6); /*setCurrentFilterClass("nqa_6")*/
                getSublevels(6);
              }
            }}
          >
            NQA 6
          </div>
          <div
            className={`${
              currentFilter === 7
                ? styles.questionsFilterSelected
                : styles.questionsFilter
            }  flex items-center justify-center`}
            onClick={async () => {
              if (!loadingQuestions) {
                setCurrentPage(1);
                setQuestionItems([]);
                setSourceId(0);
                setSubCurrentFilter(`7.1.1`);
                setCurrentFilter(7); /*setCurrentFilterClass("nqa_7")*/
                getSublevels(7);
              }
            }}
          >
            NQA 7
          </div>
        </div>

        <div
          className={`${styles.subQuestionsFilterRow} ${
            styles[`nqa_${currentFilter}`]
          } flex flex-wrap pb-6`}
        >
          {!isEmpty(getSublevels(currentFilter)) &&
            getSublevels(currentFilter).map((sub, key) => {
              return (
                <div
                  key={key}
                  className={`mb-2 ${
                    subCurrentFilter === sub.label
                      ? styles.subQuestionsFilterSelected
                      : styles.subQuestionsFilter
                  } flex items-center justify-center`}
                  onClick={async () => {
                    if (!loadingQuestions) {
                      setCurrentPage(1);
                      setQuestionItems([]);
                      setSubCurrentFilter(sub.label);
                      setSourceId(sub.sourceId);
                      await getNqaQuestions(sub.sourceId);
                    }
                  }}
                >
                  NQA{sub.label}
                </div>
              );
            })}
        </div>

        <div
          className={`${styles.fullButtonContainer} my-7 ${
            sourceId === 0 ? "hidden" : ""
          }`}
        >
          <button
            className={`flex justify-center items-center`}
            onClick={() => setAddNewQuestionModal(true)}
          >
            <FaPlusCircle className={`mr-3`} size={15} />
            {`Create a new question under NQA ${subCurrentFilter}`}
          </button>
        </div>

        {!isEmpty(questionItems) ? (
          questionItems
            .slice(
              currentPage * questionsPerPage - questionsPerPage,
              currentPage * questionsPerPage
            )
            .map((question, key) => {
              return (
                <div className={`${styles.formContainer} pt-24 mt-6`}>
                  {question.archive === 1 && (
                    <div
                      className={`${styles.archiveContainer} flex justify-center items-center -mt-8 mb-5`}
                    >
                      {" "}
                      <FaArchive className={`${styles.archiveIcon}`} />{" "}
                      <span className={`${styles.archiveText} ml-2`}>
                        Archived question
                      </span>{" "}
                    </div>
                  )}
                  <div
                    className={`${styles.formRow} flex flex-wrap justify-between`}
                  >
                    <div
                      className={`${styles.dropdownGroups} flex flex-wrap justify-between`}
                    >
                      <text className={`block w-full`}>
                        Where this question will be visible
                      </text>

                      <div className={`${styles.inputDropdown}`}>
                        {/* <Dropdown
                          disabled
                          selection
                          placeholder={subCurrentFilter}
                          // options={Options}
                        /> */}
                        <input
                          className={styles.inputBox}
                          disabled
                          value={subCurrentFilter}
                        />
                      </div>

                      <div className={`${styles.inputDropdown}`}>
                        <Dropdown
                          selection
                          placeholder="Question Type"
                          value={question.type}
                          options={nqaQuestionTypes}
                          onChange={(e, { value }) => {
                            setQuestionItems(
                              questionItems.map((q) => {
                                if (q.id === question.id) {
                                  return {
                                    ...question,
                                    type: value,
                                  };
                                } else {
                                  return q;
                                }
                              })
                            );
                          }}
                        />
                      </div>
                    </div>

                    <div className={styles.multiSelect}>
                      <text className={`block`}>
                        Which service types will see this question
                      </text>
                      <MultiSelect
                        options={SERVICE_TYPES}
                        value={question.serviceType}
                        onChange={(data) => {
                          setQuestionItems(
                            questionItems.map((q) =>
                              q.id === question.id
                                ? {
                                    ...question,
                                    serviceType: data,
                                  }
                                : q
                            )
                          );
                        }}
                        hasSelectAll={false}
                        placeholder="Select service types"
                      />
                    </div>
                  </div>

                  <div
                    className={`${styles.formRow} flex flex-wrap justify-between`}
                  >
                    <div className={styles.inputGroup}>
                      <text className={styles.inputName}>{`Question`}</text>
                      <textarea
                        className={styles.inputTextArea}
                        value={question.description}
                        onChange={(e) => {
                          setQuestionItems(
                            questionItems.map((q) => {
                              if (q.id === question.id) {
                                return {
                                  ...question,
                                  description: e.target.value,
                                };
                              } else {
                                return q;
                              }
                            })
                          );
                        }}
                      />
                    </div>

                    <div className={styles.inputGroup}>
                      <text className={styles.inputName}>
                        Statement (
                        <span className={`italicText`}>
                          visible on reports only)
                        </span>
                      </text>
                      <textarea
                        className={styles.inputTextArea}
                        value={question.statement}
                        onChange={(e) => {
                          setQuestionItems(
                            questionItems.map((q) => {
                              if (q.id === question.id) {
                                return {
                                  ...question,
                                  statement: e.target.value,
                                };
                              } else {
                                return q;
                              }
                            })
                          );
                        }}
                      />
                    </div>
                  </div>

                  <div
                    className={`${styles.formRow} flex flex-wrap justify-between`}
                  >
                    <div className={styles.inputGroup}>
                      <text
                        className={styles.inputName}
                      >{`Yes answer (evidence required)`}</text>
                      <textarea
                        className={styles.inputTextArea}
                        value={question.yesDescription}
                        onChange={(e) => {
                          setQuestionItems(
                            questionItems.map((q) => {
                              if (q.id === question.id) {
                                return {
                                  ...question,
                                  yesDescription: e.target.value,
                                };
                              } else {
                                return q;
                              }
                            })
                          );
                        }}
                      />
                    </div>

                    <div className={styles.inputGroup}>
                      <text
                        className={styles.inputName}
                      >{`No answer (QIP Goal)`}</text>
                      <textarea
                        className={styles.inputTextArea}
                        value={question.noDescription}
                        onChange={(e) => {
                          setQuestionItems(
                            questionItems.map((q) => {
                              if (q.id === question.id) {
                                return {
                                  ...question,
                                  noDescription: e.target.value,
                                };
                              } else {
                                return q;
                              }
                            })
                          );
                        }}
                      />
                    </div>
                  </div>

                  <div className={`hidden ${styles.fullButtonContainer} my-7`}>
                    <text className={`${styles.fullButtonText} block mb-3.5`}>
                      2 resources linked
                    </text>

                    <button className={`flex justify-center items-center`}>
                      <FaBookOpen className={`mr-3`} size={20} />
                      Add a resource to this question
                    </button>
                  </div>

                  <div
                    className={`${styles.formActionGroup} flex flex-wrap justify-center mt-6 pb-4`}
                  >
                    <BlueButton
                      disabled={updatingQuestion || archivingQuestion}
                      onClick={() => {
                        setSelectedQuestion(question);
                        onUpdateQuestion(question);
                      }}
                      buttonText={
                        updatingQuestion &&
                        selectedQuestion.id === question.id ? (
                          <div className={`flex justify-center items-center`}>
                            <FaSpinner className={`animate-spin `} />
                            <span className={`ml-2`}> Updating...</span>{" "}
                          </div>
                        ) : (
                          <> Update </>
                        )
                      }
                    />

                    {question.archive === 0 && (
                      <SkipButton
                        disabled={archivingQuestion || updatingQuestion}
                        onClick={() => {
                          setSelectedQuestion(question);
                          onArchiveQuestion(question.id, 1);
                        }}
                        buttonText={
                          archivingQuestion &&
                          selectedQuestion.id === question.id ? (
                            <div className={`flex justify-center items-center`}>
                              <FaSpinner className={`animate-spin`} />{" "}
                              <span className={`ml-2`}> Archiving...</span>{" "}
                            </div>
                          ) : (
                            <> Archive </>
                          )
                        }
                      />
                    )}

                    {question.archive === 1 && (
                      <SkipButton
                        disabled={archivingQuestion || updatingQuestion}
                        onClick={() => {
                          setSelectedQuestion(question);
                          onArchiveQuestion(question.id, 0);
                        }}
                        buttonText={
                          archivingQuestion &&
                          selectedQuestion.id === question.id ? (
                            <div className={`flex justify-center items-center`}>
                              <FaSpinner className={`animate-spin`} />{" "}
                              <span className={`ml-2`}> Restoring...</span>{" "}
                            </div>
                          ) : (
                            <div className={`flex justify-center items-center`}>
                              <FaReply className={` `} />{" "}
                              <span className={`ml-2`}> Restore</span>{" "}
                            </div>
                          )
                        }
                      />
                    )}
                  </div>
                  {question.archive === 1 && (
                    <div className={`${styles.restoreContainer} pt-2 pb-5`}>
                      This question{" "}
                      <span className={`${styles.beenArchvedText}`}>
                        has been archived
                      </span>
                      . You can restore it by clicking the restore button.{" "}
                    </div>
                  )}
                </div>
              );
            })
        ) : (
          <>
            {loadingQuestions ? (
              <div
                className={`flex flex-col justify-center items-center py-14`}
              >
                <FaSpinner
                  className={`animate-spin text-qipGreen font-bold text-6xl`}
                />
                <span>Loading questions...</span>
              </div>
            ) : (
              <> </>
            )}
          </>
        )}

        {/* <div className={`${styles.formContainer} pt-24 mt-6`}>
          <div
            className={`${styles.formRow} ${styles.multipleDropdown} flex flex-wrap justify-between md:w-full xl:w-1/2 xl:pr-3`}
          >
            <div className={`${styles.dropdownGroup}`}>
              <text className={`block`}>Choose NQA</text>

              <Dropdown selection placeholder="NQA 3.1.1" />
            </div>

            <div className={`${styles.dropdownGroup}`}>
              <text className={`block`}>Choose NQA</text>

              <Dropdown selection placeholder="Themes Embedded" />
            </div>
          </div>

          <div className={`${styles.formRow} flex flex-wrap justify-between`}>
            <InputComponent textArea fieldName="question" label="Question" />

            <InputComponent
              textArea
              fieldName="statement"
              label="Statement (visible on reports only)"
            />
          </div>

          <div className={`${styles.formRow} flex flex-wrap justify-between`}>
            <InputComponent
              textArea
              fieldName="yesAnswer"
              label="Yes answer (evidence required)"
            />

            <InputComponent
              textArea
              fieldName="noAnswer"
              label="No answer (QIP Goal)"
            />
          </div>

          <div className={`hidden ${styles.fullButtonContainer} my-7`}>
            <text className={`${styles.fullButtonText} block mb-3.5`}>
              4 resources linked
            </text>

            <button className={`flex justify-center items-center`}>
              <FaBookOpen className={`mr-3`} size={20} />
              Add a resource to this question
            </button>
          </div>

          <div
            className={`${styles.formActionGroup} flex flex-wrap justify-center mt-6`}
          >
            <BlueButton buttonText="Update" />

            <SkipButton buttonText="Archive" />
          </div>
        </div> */}

        {/* <div className={`${styles.formContainer} pt-24 mt-6`}>
          <div
            className={`${styles.formRow} ${styles.multipleDropdown} flex flex-wrap justify-between md:w-full xl:w-1/2 xl:pr-3`}
          >
            <div className={`${styles.dropdownGroup}`}>
              <text className={`block`}>Choose NQA</text>

              <Dropdown selection placeholder="NQA 3.1.1" />
            </div>

            <div className={`${styles.dropdownGroup}`}>
              <text className={`block`}>Choose NQA</text>

              <Dropdown selection placeholder="Themes Embedded" />
            </div>
          </div>

          <div className={`${styles.formRow} flex flex-wrap justify-between`}>
            <InputComponent textArea fieldName="question" label="Question" />

            <InputComponent
              textArea
              fieldName="statement"
              label="Statement (visible on reports only)"
            />
          </div>

          <div className={`${styles.formRow} flex flex-wrap justify-between`}>
            <InputComponent
              textArea
              fieldName="yesAnswer"
              label="Yes answer (evidence required)"
            />

            <InputComponent
              textArea
              fieldName="noAnswer"
              label="No answer (QIP Goal)"
            />
          </div>

          <div className={`hidden ${styles.fullButtonContainer} my-7`}>
            <text className={`${styles.fullButtonText} block mb-3.5`}>
              0 resources linked
            </text>

            <button className={`flex justify-center items-center`}>
              <FaBookOpen className={`mr-3`} size={20} />
              Add a resource to this question
            </button>
          </div>

          <div
            className={`${styles.formActionGroup} flex flex-wrap justify-center mt-6`}
          >
            <BlueButton buttonText="Update" />

            <SkipButton buttonText="Archive" />
          </div>
        </div> */}

        {/*<div className={`${styles.fullButtonContainer} my-10`}>

          <button 
            className={`flex justify-center items-center`} 
            onClick={ () => setAddNewQuestionModal(true) }
          >
            <FaPlusCircle className={`mr-3`} size={15} />
            Create a new question under NQA 3.1.1
          </button>

        </div>*/}

        <div
          className={`${styles.paginationContainer} flex justify-start py-7 mt-6`}
        >
          {!isEmpty(questionItems) &&
            questionsPerPage < questionItems.length && (
              <Pagination
                currentPage={currentPage}
                lastPage={Math.ceil(questionItems.length / questionsPerPage)}
                onPageChange={setCurrentPage}
              />
            )}
        </div>
      </div>
    </div>
  );
}

export default Questions;
