import * as React from "react";

import PropTypes from "prop-types";
import { Button, Modal } from "semantic-ui-react";

import styles from "./UnlinkQuestionModal.module.css";
import SkipButton from "../../SkipButton";

const { useState } = React;
 
function UnlinkQuestionModal({
  visibility,
  onOpenModal,
  onCloseModal,
  onConfirmUnlinkQuestion,
}) {

  return (
    <Modal
      size="small"
      centered={true}
      open={visibility}
      onOpen={onOpenModal}
      onClose={onCloseModal}
      closeOnDimmerClick={false}
    >
      <div className={styles.modalContainer}>
        <Button
          className={styles.closeButton}
          onClick={onCloseModal}
          size="big"
          icon="close"
        />

        <text className={styles.headerText}>
          Are you sure you want to unlink this?
        </text>

        <div className={styles.buttonGroup}>
          <button className={styles.nextTipButton} onClick={onConfirmUnlinkQuestion}>
            Yes
          </button>

          <SkipButton onClick={onCloseModal} buttonText="No" />
        </div>
      </div>
		
    </Modal>
  );
}

UnlinkQuestionModal.propTypes = {
  visibility: PropTypes.bool.isRequired,
  onOpenModal: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  onConfirmUnlinkQuestion: PropTypes.func.isRequired,
};

export default UnlinkQuestionModal;
