import * as React from "react";

import PropTypes from "prop-types";
import { Button, Modal, Dropdown } from "semantic-ui-react";
import InputComponent from "../../../components/InputComponent";
import styles from "./AddNewQuestionModal.module.css";
import { FaSpinner } from "react-icons/fa";
import { createQuestion } from "../../../api/requests";

import MultiSelect from "../../../components/MultiSelect";
import { SERVICE_TYPES } from "../../../pages/Admin/Questions/Questions";

const { useState } = React;

const nqaQuestionTypes = [
  {
    key: "embedded",
    text: "Embedded",
    value: "embedded",
  },
  {
    key: "critical_reflections",
    text: "Critical Reflections",
    value: "critical_reflections",
  },
  {
    key: "partnerships_with_family",
    text: "Partnerships with Families",
    value: "partnerships_with_family",
  },
];

function AddNewQuestionModal({
  visibility,
  onOpenModal,
  onCloseModal,
  nqaSubLevel,
  sourceId,
  refreshQuestions,
  onConfirm,
}) {
  const [addingQuestion, setAddingQuestion] = useState(false);
  const [questionType, setQuestionType] = useState(nqaQuestionTypes[0].value);

  const [statement, setStatement] = useState("");
  const [question, setQuestion] = useState("");
  const [yesDescription, setYesDescription] = useState("");
  const [noDescription, setNoDescription] = useState("");

  const [fieldsChecked, setFieldsChecked] = useState(false);

  const [selectedServiceTypes, setSelectedServiceTypes] = useState([]);

  const onCreateQuestion = async () => {
    setFieldsChecked(true);

    if (
      !(
        statement &&
        question &&
        yesDescription &&
        noDescription &&
        selectedServiceTypes.length
      )
    ) {
      return;
    }

    try {
      setAddingQuestion(true);

      const service_type = selectedServiceTypes.map(({ value }) => value);
      const response = await createQuestion({
        title: null,
        type: questionType,
        description: question,
        center_id: null,
        user_id: null,
        statement,
        yes_description: yesDescription,
        no_description: noDescription,
        archive: 0,
        categoryId: sourceId,
        service_type,
      });
      await refreshQuestions();
      onConfirm();
      setAddingQuestion(false);
    } catch (error) {
      setAddingQuestion(false);
      console.log(error);
    }
  };

  return (
    <Modal
      size="small"
      centered={true}
      open={visibility}
      onOpen={onOpenModal}
      onClose={onCloseModal}
    >
      <div
        className={`${styles.modalContainer} flex flex-col px-8 pt-14 pb-20`}
      >
        <Button
          className={styles.closeButton}
          onClick={onCloseModal}
          size="big"
          icon="close"
        />

        <text className={`${styles.headerText} mb-2.5`}>
          Add a new question
        </text>
        <text className={styles.subHeaderText}>
          Complete the below fields to create a new NQA question
        </text>

        <div className={`${styles.formContainer} mt-14`}>
          <div className={`${styles.dropdownGroup}`}>
            <text className={`block mb-2`}>
              Choose NQA sections<span className={`${styles.required}`}>*</span>
            </text>

            <Dropdown selection placeholder={nqaSubLevel} />
          </div>

          <div className={`${styles.dropdownGroup}`}>
            <Dropdown
              selection
              value={questionType}
              options={nqaQuestionTypes}
              onChange={(e, { value }) => {
                setQuestionType(value);
              }}
            />
          </div>

          <div className={styles.multiselect}>
            <text className="block">
              Which service types will see this question{" "}
              <span className="text-red-500">*</span>
            </text>
            <MultiSelect
              options={SERVICE_TYPES}
              value={selectedServiceTypes}
              onChange={setSelectedServiceTypes}
              hasSelectAll={false}
              placeholder="Select service types"
            />
            {fieldsChecked && !selectedServiceTypes.length && (
              <div className={`text-red-400 p-2 font-thin italic`}>
                This field is required.
              </div>
            )}
          </div>

          <InputComponent
            textArea
            fieldName="question"
            label="Question"
            required={true}
            showNotFilled={fieldsChecked && !question}
            inputValue={question}
            setInputValue={(_, value) => {
              setQuestion(value);
            }}
          />

          <InputComponent
            textArea
            fieldName="statement"
            label="Statement (visible on reports only)"
            required={true}
            showNotFilled={fieldsChecked && !statement}
            inputValue={statement}
            setInputValue={(_, value) => {
              setStatement(value);
            }}
          />

          <InputComponent
            textArea
            fieldName="noAnswer"
            label="No answer* (QIP Goal)"
            required={true}
            showNotFilled={fieldsChecked && !noDescription}
            inputValue={noDescription}
            setInputValue={(_, value) => {
              setNoDescription(value);
            }}
          />

          <InputComponent
            textArea
            fieldName="yesAnswer"
            label="Yes answer* (evidence required)"
            required={true}
            showNotFilled={fieldsChecked && !yesDescription}
            inputValue={yesDescription}
            setInputValue={(_, value) => {
              setYesDescription(value);
            }}
          />

          <div className={`text-center`}>
            <button
              onClick={() => {
                onCreateQuestion();
              }}
              disabled={addingQuestion}
              className={`${styles.buttonSave}`}
            >
              {addingQuestion ? (
                <div className={`flex justify-center items-center`}>
                  <FaSpinner className={`animate-spin`} />{" "}
                  <span className={`ml-2`}>Saving...</span>{" "}
                </div>
              ) : (
                <> Save new question </>
              )}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

AddNewQuestionModal.propTypes = {
  visibility: PropTypes.bool.isRequired,
  onOpenModal: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
};

export default AddNewQuestionModal;
