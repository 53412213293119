import * as React from "react";
import { useHistory } from "react-router-dom";
import { Button, Dropdown } from "semantic-ui-react";
import InputComponent from "../../../../components/InputComponent";
import ChooseServiceModal from "../../../../components/Modals/ChooseServiceModal";
import ChooseUserModal from "../../../../components/Modals/ChooseUserModal";
import BlueButton from "../../../../components/BlueButton";
import {
  FaEnvelope,
  FaUser,
  FaUsers,
  FaUserShield,
  FaUserFriends,
  FaTimesCircle,
  FaSpinner,
  FaArrowLeft,
} from "react-icons/fa";
import useSWR from "swr";
import { isEmpty } from "lodash";

import Swal from "sweetalert2";

import styles from "./MessageView.module.css";
import ReactQuill from "react-quill";

const { useState } = React;

const tasksPerPage = 10;

function MessageView({ location }) {
  const { message } = location.state;

  const history = useHistory();

  return (
    <div className={`${styles.mainContainer} mt-5 md:mb-28 lg:mb-10`}>
      <div className={`${styles.headerTextContainer} flex`}>
        <div className={styles.headerTextIcon}>
          <FaEnvelope size={55} />
        </div>
        <div className={`w-full flex justify-between justify-baseline`}>
          <div className={`${styles.headerText} ml-4`}>
            <h1>Messages</h1>
            <text>Create and send important updates to Holistic QIP users</text>
          </div>
          <div
            onClick={() => history.push(`/qip/messages`)}
            className={`${styles.backMessageButton} flex justify-center items-center`}
          >
            <FaArrowLeft className={styles.backMessageIcon} />{" "}
            <span className={`${styles.backMessageText} ml-2`}>
              Back to messages
            </span>
          </div>
        </div>
      </div>

      <div className={`${styles.Content}  pt-16 pb-28 mt-9 `}>
        <div className={`${styles.subjectHeader} md:px-7 lg:px-14 mb-5`}>
          {message.subject}
        </div>
        <div className={`${styles.sentLine}`}>
          <span className={`md:px-7 lg:px-14`}>
            {`Sent on ${message.date.split("-").join("/")}    |    Sent by ${
              message.sender
            }`}
          </span>
        </div>

        {!isEmpty(message.user_to) && (
          <>
            <div
              className={`${styles.whoReceiveHeader} md:px-7 lg:px-14 mt-12 mb-4`}
            >
              Users who received this message
            </div>
            <div className={`${styles.whoReceiveText} md:px-7 lg:px-14`}>
              {message.user_to}
            </div>
          </>
        )}
        {!isEmpty(message.sent_to) && (
          <>
            <div
              className={`${styles.whoReceiveHeader} md:px-7 lg:px-14 mt-12 mb-4`}
            >
              Users who received this message
            </div>
            <div className={`${styles.whoReceiveText} md:px-7 lg:px-14`}>
              {message.sent_to.join(", ")}
            </div>
          </>
        )}
        {!isEmpty(message.send_to) && (
          <>
            <div
              className={`${styles.whoReceiveHeader} md:px-7 lg:px-14 mt-12 mb-4`}
            >
              Services that received this message
            </div>
            <div className={`${styles.whoReceiveText} md:px-7 lg:px-14`}>
              {message.send_to.join(", ")}
            </div>
          </>
        )}
        <div
          className={`disabledPreflight md:px-7 lg:px-14 mt-8`}
          dangerouslySetInnerHTML={{ __html: message.content }}
        ></div>
        <div className={`md:px-7 lg:px-14 mt-16`}>
          <div
            onClick={() => history.push(`/qip/messages`)}
            className={`${styles.backMessageButtonGreen} flex justify-center items-center`}
          >
            <FaArrowLeft className={styles.backMessageIcon} />{" "}
            <span className={`${styles.backMessageText} ml-2`}>
              Back&nbsp;to&nbsp;messages
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MessageView;
