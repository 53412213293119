import * as React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import Tasks from "../../pages/Admin/Tasks";

export default function AdminTasks () {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route
        exact
        path={`${match.path}`}
		render={() => <Tasks /> }
      />
    </Switch>
  );
}
