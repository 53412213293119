import * as React from "react";

import { Route, Switch, useRouteMatch } from "react-router-dom";

import FeedbackRequestList from "../../pages/Admin/RequestFeedback/FeedbackRequestList";
import SingleFeedbackRequest from "../../pages/Admin/RequestFeedback/SingleFeedbackRequest";
import ServicesDashboard from "../../pages/Admin/RequestFeedback/ServicesDashboard";

export default function AdminRequestFeedback() {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route
        exact
        path={`${match.path}/list`}
        render={() => <FeedbackRequestList />}
      />
      <Route
        exact
        path={`${match.path}/single`}
        render={(props) => <SingleFeedbackRequest {...props} />}
      />
      <Route
        exact
        path={`${match.path}/services-dashboard`}
        render={() => <ServicesDashboard />}
      />
    </Switch>
  );
}
