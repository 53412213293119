import * as React from "react";

import PropTypes from "prop-types";
import Switch from "react-ios-switch";
import { Dropdown, Icon } from "semantic-ui-react";
import { Link, useHistory, useRouteMatch } from "react-router-dom";

import styles from "./OperationalHours.module.css";
import BlueButton from "../../../components/BlueButton";
import { ServiceDetailsContext } from "../../../context/ServiceDetailsContext";
import { TimePicker } from "antd";
import moment from "moment";

import { useStoreState } from "../../../hooks";

import { ServiceSteps } from "../../../components/Steps";

import { FaSpinner } from "react-icons/fa";

const { useContext, useEffect, useState } = React;

const stepsNotDone = [
  {
    id: 8,
    stepsName: "Additional info",
  },
  {
    id: 9,
    stepsName: "Educators",
  },
  {
    id: 10,
    stepsName: "QIP submission",
  },
  {
    id: 11,
    stepsName: "Philosophy",
  },
  {
    id: 12,
    stepsName: "Upload logo",
  },
];

const stepsDone = [
  {
    id: 1,
    stepsName: "Location",
  },
  {
    id: 2,
    stepsName: "Postal Address",
  },
  {
    id: 3,
    stepsName: "Contact Details",
  },
  {
    id: 4,
    stepsName: "Providers",
  },
  {
    id: 5,
    stepsName: "Supervisor",
  },
  {
    id: 6,
    stepsName: "Leader",
  },
];

const days = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
];

const timeOptions = [
  {
    key: 1,
    value: "06:00AM",
    text: "06:00AM",
  },
  {
    key: 2,
    value: "07:00AM",
    text: "07:00AM",
  },
  {
    key: 3,
    value: "08:00AM",
    text: "08:00AM",
  },
  {
    key: 4,
    value: "09:00AM",
    text: "09:00AM",
  },
  {
    key: 5,
    value: "10:00AM",
    text: "10:00AM",
  },
  {
    key: 6,
    value: "11:00AM",
    text: "11:00AM",
  },
  {
    key: 7,
    value: "12:00NN",
    text: "12:00NN",
  },
  {
    key: 8,
    value: "01:00PM",
    text: "01:00PM",
  },
  {
    key: 9,
    value: "02:00PM",
    text: "02:00PM",
  },
  {
    key: 10,
    value: "03:00PM",
    text: "03:00PM",
  },
  {
    key: 11,
    value: "04:00PM",
    text: "04:00PM",
  },
  {
    key: 12,
    value: "05:00PM",
    text: "05:00PM",
  },
  {
    key: 13,
    value: "06:00PM",
    text: "06:00PM",
  },
  {
    key: 14,
    value: "07:00PM",
    text: "07:00PM",
  },
];

const initialHours = [
  {
    day: "monday",
    openTime: "06:00AM",
    closeTime: "07:00PM",
    working: true,
  },
  {
    day: "tuesday",
    openTime: "06:00AM",
    closeTime: "07:00PM",
    working: true,
  },
  {
    day: "wednesday",
    openTime: "06:00AM",
    closeTime: "07:00PM",
    working: true,
  },
  {
    day: "thursday",
    openTime: "06:00AM",
    closeTime: "07:00PM",
    working: true,
  },
  {
    day: "friday",
    openTime: "06:00AM",
    closeTime: "07:00PM",
    working: true,
  },
  {
    day: "saturday",
    openTime: "06:00AM",
    closeTime: "07:00PM",
    working: false,
  },
  {
    day: "sunday",
    openTime: "06:00AM",
    closeTime: "07:00PM",
    working: false,
  },
];

function OperationalHours({ onNextStep, onSaveStep, onSkipServiceDetails }) {
  const history = useHistory();
  const match = useRouteMatch();

  const {
    saveLoading,
    serviceDetailsState,
    changeInputDispatch,
    stepsIndicator,
  } = useContext(ServiceDetailsContext);

  const isLoggedIn = useStoreState((state) => state.authStore.isLoggedIn);
  const [fieldChecked, setFieldChecked] = useState(false);

  const [mondayToggle, setMondayToggle] = useState(false);
  const [tuesdayToggle, setTuesdayToggle] = useState(false);
  const [wednesdayToggle, setWednesdayToggle] = useState(false);
  const [thursdayToggle, setThursdayToggle] = useState(false);
  const [fridayToggle, setFridayToggle] = useState(false);
  const [saturdayToggle, setSaturdayToggle] = useState(false);
  const [sundayToggle, setSundayToggle] = useState(false);

  const checkToggle = (day) => {
    switch (day) {
      case "monday":
        return mondayToggle;

      case "tuesday":
        return tuesdayToggle;

      case "wednesday":
        return wednesdayToggle;

      case "thursday":
        return thursdayToggle;

      case "friday":
        return fridayToggle;

      case "saturday":
        return saturdayToggle;

      case "sunday":
        return sundayToggle;

      default:
        break;
    }
  };

  const onToggle = (day, value) => {
    const operatingHours = serviceDetailsState.input.operatingHours
      ? [...serviceDetailsState.input.operatingHours]
      : [...initialHours];
    operatingHours.find((item) => item.day === day).working = !value;

    changeInputDispatch("operatingHours", operatingHours);
  };

  const onSelectTime = (day, timeField, value) => {
    const operatingHours = serviceDetailsState.input.operatingHours
      ? [...serviceDetailsState.input.operatingHours]
      : [...initialHours];
    operatingHours.find((item) => item.day === day)[timeField] = value;

    changeInputDispatch("operatingHours", operatingHours);
  };

  const onSave = async () => {
    setFieldChecked(true);
    await onSaveStep();
    onNextStep();
    history.push(
      `${match.path
        .split("/")
        .slice(0, match.path.split("/").length - 1)
        .join("/")}/8`
    );
  };

  useEffect(() => {
    if (!serviceDetailsState.input.operatingHours)
      changeInputDispatch("operatingHours", [...initialHours]);
  }, [changeInputDispatch, serviceDetailsState.input.operatingHours]);

  return (
    <div className={styles.mainContainer}>
      {isLoggedIn && (
        <>
          <div className={styles.headerContainer}>
            <h1>Complete your service details</h1>
            <p>
              Please fill out all missing fields marked with an ‘
              <span className={styles.required}>*</span>’
            </p>
          </div>
        </>
      )}
      <div className={styles.mainCard}>
        <div className={styles.cardContent}>
          <h1 className={styles.headerText}>Operating Hours</h1>

          <text className={styles.descriptionText}>
            Please complete the below fields by providing the daily operating
            hours for your service.
          </text>

          <div className={styles.tableContainer}>
            <div className={styles.tableHeader}>
              <text>Day</text>

              <text>Opening</text>

              <text>Closing</text>

              <text>Not working</text>
            </div>

            {days.map((day) => (
              <div key={day} className={styles.tableRow}>
                <text className={styles.day}>
                  {day} <span className={styles.required}>*</span>
                </text>

                {/* <Dropdown
                  className={styles.openingTime}
                  options={timeOptions}
                  value={
                    serviceDetailsState.input.operatingHours
                      ? serviceDetailsState.input.operatingHours.find(
                          (item) => item.day === day
                        ).openTime
                      : initialHours.find((item) => item.day === day).openTime
                  }
                  onChange={(e, data) =>
                    onSelectTime(day, "openTime", data.value)
                  }
                /> */}
                <TimePicker
                  className={styles.openingTime}
                  allowClear={false}
                  use12Hours
                  disabled={
                    serviceDetailsState.input.operatingHours
                      ? !serviceDetailsState.input.operatingHours.find(
                          (item) => item.day === day
                        ).working
                      : !initialHours.find((item) => item.day === day).working
                  }
                  showNow={false}
                  defaultValue={
                    serviceDetailsState.input.operatingHours
                      ? moment(
                          serviceDetailsState.input.operatingHours.find(
                            (item) => item.day === day
                          ).openTime,
                          "HH:mm A"
                        )
                      : moment(
                          initialHours.find((item) => item.day === day)
                            .openTime,
                          "HH:mm A"
                        )
                  }
                  format="h:mm A"
                  onChange={(time, timeString) => {
                    onSelectTime(day, "openTime", timeString);
                  }}
                />

                {/* <Dropdown
                  className={styles.closingTime}
                  options={timeOptions}
                  value={
                    serviceDetailsState.input.operatingHours
                      ? serviceDetailsState.input.operatingHours.find(
                          (item) => item.day === day
                        ).closeTime
                      : initialHours.find((item) => item.day === day).closeTime
                  }
                  onChange={(e, data) => {
                    console.log("change date ", data);
                    onSelectTime(day, "closeTime", data.value);
                  }}
                /> */}

                <TimePicker
                  className={styles.closingTime}
                  allowClear={false}
                  use12Hours
                  disabled={
                    serviceDetailsState.input.operatingHours
                      ? !serviceDetailsState.input.operatingHours.find(
                          (item) => item.day === day
                        ).working
                      : !initialHours.find((item) => item.day === day).working
                  }
                  showNow={false}
                  defaultValue={
                    serviceDetailsState.input.operatingHours
                      ? moment(
                          serviceDetailsState.input.operatingHours.find(
                            (item) => item.day === day
                          ).closeTime,
                          "HH:mm A"
                        )
                      : moment(
                          initialHours.find((item) => item.day === day)
                            .closeTime,
                          "HH:mm A"
                        )
                  }
                  format="h:mm A"
                  onChange={(time, timeString) => {
                    onSelectTime(day, "closeTime", timeString);
                  }}
                />

                <div className={styles.switchContainer}>
                  <Switch
                    onColor="#F56666"
                    offColor="#e4e4e4"
                    checked={
                      serviceDetailsState.input.operatingHours
                        ? !serviceDetailsState.input.operatingHours.find(
                            (item) => item.day === day
                          ).working
                        : !initialHours.find((item) => item.day === day).working
                    }
                    onChange={(checked) => onToggle(day, checked)}
                  />
                </div>
              </div>
            ))}
          </div>

          <div className={styles.buttonGroup}>
            <BlueButton // buttonText="Save step"
              buttonText={
                saveLoading ? (
                  <div className={`flex justify-center items-center`}>
                    <FaSpinner className={`animate-spin`} />
                    <span className={`block pl-2`}>Save step</span>
                  </div>
                ) : (
                  <> Save step</>
                )
              }
              onClick={onSave}
            />

            <button
              className={styles.buttonNextStep}
              onClick={() =>
                history.push(
                  `${match.path
                    .split("/")
                    .slice(0, match.path.split("/").length - 1)
                    .join("/")}/8`
                )
              }
            >
              Next step
            </button>
          </div>

          <div className={styles.buttonStep}>
            <button
              className={styles.buttonStepBack}
              onClick={() =>
                history.push(
                  `${match.path
                    .split("/")
                    .slice(0, match.path.split("/").length - 1)
                    .join("/")}/6`
                )
              }
            >
              <Icon name="angle left" />
              Back a step
            </button>

            <button
              className={styles.buttonFinishLater}
              onClick={onSkipServiceDetails}
            >
              Finish Later
              <Icon name="angle double right" />
            </button>
          </div>
        </div>

        <div className={styles.stepsColumn}>
          {/* {!isLoggedIn && (
            <div
              className={`${styles.stepItemContainer} ${styles.stepItemTickContainer}`}
              onClick={() => history.push(`/qip/auth/legal-notice`)}
            >
              <div className={styles.stepContainerText}>
                <div className={styles.stepItemIndicatorContainer}>
                  <div className={styles.stepItemFillDone} />
                </div>
                Welcome
              </div>
            </div>
          )} */}
          <ServiceSteps route={match.path} />
          {/* {stepsDone.map((steps) => (
            <div
              key={steps.id}
              className={
                stepsIndicator.find((step) => step.id === steps.id).completed
                  ? `${styles.stepItemContainer} ${styles.stepItemTickContainer}`
                  : `${styles.stepItemContainer} ${styles.stepItemCrossContainer}`
              }
              onClick={() =>
                history.push(
                  `${match.path
                    .split("/")
                    .slice(0, match.path.split("/").length - 1)
                    .join("/")}/${steps.id}`
                )
              }
            >
              <div className={styles.stepContainerText}>
                <div className={styles.stepItemIndicatorContainer}>
                  {stepsIndicator.find((step) => step.id === steps.id)
                    .completed ? (
                    <div className={styles.stepItemFillDone} />
                  ) : (
                    <Icon className={styles.stepItemUnfinished} name="close" />
                  )}
                </div>
                {steps.stepsName}
              </div>
            </div>
          ))}

          <div className={styles.CurrentItemContainer}>
            <div className={styles.stepItemCurrent}>
              <div className={styles.stepItemFillDone} />
            </div>

            <text className={styles.stepItemCurrentText}>Operating hours</text>
          </div>

          {stepsNotDone.map((steps) => (
            <div key={steps.id} className={styles.stepItemContainer}>
              <Link
                className={styles.stepItemCurrentText}
                to={`${match.path
                  .split("/")
                  .slice(0, match.path.split("/").length - 1)
                  .join("/")}/${steps.id}`}
              >
                <div className={styles.stepItemIndicatorContainer}>
                  <div className={styles.stepItemFill} />
                </div>
                {steps.stepsName}
              </Link>
            </div>
          ))} */}
        </div>
      </div>
    </div>
  );
}

OperationalHours.propTypes = {
  onNextStep: PropTypes.func,
  onSkipStep: PropTypes.func,
  onSaveStep: PropTypes.func,
  onSkipServiceDetails: PropTypes.func,
};

export default OperationalHours;
