/* eslint-disable @typescript-eslint/no-explicit-any */
// we allow any in case of any changes to the backend
import axios from "../services/axios";
import {
  AuthObject,
  LoginCreds,
  NQA,
  NQALevel,
  NQAQuestion,
  ProgressResponse,
  RawNQAData,
  ServiceDetails,
  SignUpData,
  SignUpResponse,
  Task,
  UploadResponse,
  UploadData,
  NQASubLevel,
  EvidenceData,
  UserResponse,
  CreateUserData,
  Report,
  Feedback,
  AdminAttr,
  CustomQuestionPayload,
  InitialServiceDetails,
  Resource,
  ResourceShop,
  ResourceTutorial,
  ReplyFeedback,
  Message,
  Question,
  Admin,
} from "../types";

import { app_cache } from "../utils";
import { SERVICE_TYPES } from "../pages/Admin/Questions/Questions";

const accessId = (process.env.ACCESS_ID = "3");
const accessKey = (process.env.ACCESS_KEY =
  "87OF7fxU9k8Oqg1CPMxVUeRB0zHpvn0nx2dbALuD");

export async function registerUser(data: SignUpData): Promise<SignUpResponse> {
  const payload = {
    price: data.price,
    name: `${data.firstName} ${data.lastName}`,
    email: data.email,
    phone: data.phoneNumber,
    password: data.password,
    user_type: "ADMIN",
    service_name: data.serviceName,
    service_app_num: data.serviceApprovalNumber,
    service_cat: data.serviceCategory,
    center_user_type: "admin",
    client_id: accessId,
    client_secret: accessKey,
  };

  const response = await axios.post("/api/register", payload);

  let cachtoken = {
    userId: response.data.id,
    accessToken: response.data.accessToken,
  };

  localStorage.setItem("qip_client_token", JSON.stringify(cachtoken));
  return {
    status: response.status,
    userId: response.data.id,
    centerId: response.data.center_id,
  };
}
export async function createNewUser(data: CreateUserData) {
  const payload = {
    name: data.name,
    email: data.email,
    user_type: data.userType,
    center_user_type: data.role,
    password: data.password,
    street_address: data.streetAddress,
    suburb: data.suburb,
    state: data.state,
    postal_code: data.postalCode,
    telnum: data.telNum,
    mobilenum: data.mobileNum,
    add_email: data.additionalEmail,
    center_ids: data.centerIds,
    center_id: data.centerId,
    client_id: accessId,
    client_secret: accessKey,
  };

  const response = await axios.post("/api/register", payload);

  if (response.status === 200 || response.status === 201) return true;

  return false;
}

export async function updateUserDetails(userDetails: UserResponse) {
  const payload = {
    name: userDetails.name,
    username: userDetails.username,
    email: userDetails.email,
    user_type: userDetails.userType,
    center_user_type: userDetails.role,
    street_address: userDetails.streetAddress,
    suburb: userDetails.suburb,
    state: userDetails.state,
    postal_code: userDetails.postalCode,
    telnum: userDetails.telNum,
    mobilenum: userDetails.mobileNum,
    add_email: userDetails.additionalEmail,
    center_ids: userDetails.centerIds,
    // center_id: userDetails.centerId,
    client_id: accessId,
    client_secret: accessKey,
  };
  let token: any = JSON.parse(localStorage.getItem("qip_client_token")!);
  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };

  const response = await axios.put(
    `/api/user/${userDetails.id}`,
    payload,
    config
  );

  if (response.status === 200 || response.status === 201) return true;

  return false;
}

export async function deleteUser(userId: number) {
  const response = await axios.delete(`/api/user/${userId}`);

  if (response.status === 200 || response.status === 201) return true;

  return false;
}

export async function fetchAuthObject(data: LoginCreds): Promise<AuthObject> {
  const loginPayload = {
    password: data.password,
    grant_type: "password",
    username: data.email,
    client_id: accessId,
    client_secret: accessKey,
    remember_me: 0,
  };

  const response = await axios.post("/oauth/token", loginPayload);

  app_cache.token = response.data.access_token;
  app_cache.loggedIn = true;

  let config = {
    headers: {
      Authorization: "Bearer " + app_cache.token,
    },
  };

  const response2 = await axios.get("/api/user", config);
  return {
    userId: response2.data.id,
    stripeId: response2.data.subscription_id,
    createdAt: response2.data.created_at,
    expiresAt: response.data.expires_at,
    accessToken: response.data.access_token,
  };
}

export async function fetchAllUsers(): Promise<UserResponse[]> {
  let token: any = JSON.parse(localStorage.getItem("qip_client_token")!);

  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };

  const response = await axios.get("/api/service", config);

  return response.data.map((user: any) => ({
    id: user.id,
    name: user.name,
    email: user.email,
    phone: user.phone,
    userType: user.user_type,
  }));
}

export async function getAllUsers() {
  let token: any = JSON.parse(localStorage.getItem("qip_client_token")!);

  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };

  const response = await axios.get("/api/message-getallusers", config);

  return response.data.map((user: any) => {
    return {
      key: user.id,
      text: user.name,
      value: user.id,
    };
  });
}

export async function getAllUsersByRole() {
  let token: any = JSON.parse(localStorage.getItem("qip_client_token")!);

  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };

  const response = await axios.get("/api/message-getallusers", config);

  let admins: any = response.data.filter((user: any) => {
    return user.user_type === "ADMIN";
  });

  return {
    admins: admins.map((user: any) => {
      return user.id;
    }),
    users: response.data.map((user: any) => {
      return user.id;
    }),
  };
}

export async function fetchAllCounts() {
  let token: any = JSON.parse(localStorage.getItem("qip_client_token")!);

  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };

  const response = await axios.get("/api/service", config);

  console.log("response counts", response.data);

  return {
    admin: response.data["Total Admin Users: "],
    member: response.data["Total Member Users: "],
    total:
      response.data["Total Admin Users: "] +
      response.data["Total Member Users: "],
  };
}

export async function fetchCenterUsers(
  centerId: number
): Promise<UserResponse[]> {
  let token: any = JSON.parse(localStorage.getItem("qip_client_token")!);

  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };

  const response = await axios.get(`/api/center/${centerId}/users`, config);

  return response.data.map((user: any) => ({
    id: Number(user.user_id),
    name: user.name ? user.name : "Center User",
    role: user.type,
    email: user.email,
    phone: user.phone,
    username: user.username,
    userType: user.type.toLowerCase() === "admin" ? "ADMIN" : "MEMBER",
    centerId: user.center_id,
    streetAddress: user.street_address ? user.street_address : "",
    suburb: user.suburb ? user.suburb : "",
    state: user.state ? user.state : "",
    postalCode: user.postal_code ? user.postal_code : "",
    telnum: user.telnum ? user.telnum : "",
    mobilenum: user.mobilenum ? user.mobilenum : "",
    additionalEmail: user.add_email ? user.add_email : "",
    rememberToken: user.remember_token ? user.remember_token : "",
  }));
}

export async function fetchUserCenters(
  userId: number
): Promise<ServiceDetails[]> {
  let token: any = JSON.parse(localStorage.getItem("qip_client_token")!);
  if (!token) window.location.reload();
  // console.log(token.accessToken+"_token");
  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };

  const response = await axios.get(`/api/user/${userId}/centers`, config);

  return response.data.map((data: any) => ({
    id: data.id,
    name: data.name,
    approvalNumber: data.approval_number,
    deleted_at: data.deleted_at,
    serviceCatId: data.service_cat_id,
    locationStreet: data.location_street,
    locationSuburb: data.location_suburb,
    locationState: data.location_state,
    locationPostalCode: data.location_postal_code,
    postalStreet: data.postal_street,
    postalSuburb: data.postal_suburb,
    postalState: data.postal_state,
    postalCode: data.postal_postal_code,
    contactPhoneNum: data.contact_phonenum,
    contactMobileNum: data.contact_mobilenum,
    contactFaxNum: data.contact_faxnum,
    contactEmail: data.contact_email,
    contactAdditionalEmail: data.contact_add_email,
    providerFirstName: data.provider_fname,
    providerLastName: data.provider_lname,
    providerPhoneNum: data.provider_phonenum,
    providerMobileNum: data.provider_mobilenum,
    providerFaxNum: data.provider_faxnum,
    providerEmail: data.provider_email,
    supervisorFirstName: data.supervisor_fname,
    supervisorLastName: data.supervisor_lname,
    supervisorPhoneNum: data.supervisor_phonenum,
    supervisorMobileNum: data.supervisor_mobilenum,
    supervisorFaxNum: data.supervisor_faxnum,
    supervisorEmail: data.supervisor_email,
    leaderFirstName: data.leader_fname,
    leaderLastName: data.leader_lname,
    leaderPhoneNum: data.leader_phonenum,
    leaderMobileNum: data.leader_mobilenum,
    leaderFaxNum: data.leader_faxnum,
    leaderEmail: data.leader_email,
    operatingHours: JSON.parse(data.operating_hours),
    additionalInfo: data.additional_info,
    rooms: JSON.parse(data.rooms),
    educators: JSON.parse(data.educators),
    employees: JSON.parse(data.employees),
    philosophy: data.philosophy,
    logo: {},
  }));
}

export async function fetchServiceDetails(
  userId: number,
  app_token?: string,
  email?: string
): Promise<ServiceDetails> {
  let token: any = JSON.parse(localStorage.getItem("qip_client_token")!);

  let config = {
    headers: {
      Authorization: "Bearer " + (app_token ? app_token : token.accessToken),
    },
  };

  let responseData: any[] = [];

  const response = await axios.get(`/api/user/${userId}/centers`, config);

  if (response.data.length === 0 && app_token && email) {
    const payload1 = {
      user_id: userId,
      user_type: "ADMIN",
      name: email,
      approval_number: "#",
      service_cat_id: 1,
    };

    const response1 = await axios.post(`/api/centers`, payload1, config);

    const response2 = await axios.get(`/api/user/${userId}/centers`, config);

    responseData = response2.data;
  } else {
    responseData = response.data;
  }

  const selectedServiceId = localStorage.getItem("selectedService");

  const data = selectedServiceId
    ? responseData.find((s: any) => s.id === Number(selectedServiceId))
    : responseData[0];

  const logoResource = app_token ? {} : await getLogo(data.id, "logo");

  return {
    id: data.id,
    name: data.name,
    approvalNumber: data.approval_number,
    serviceCatId: data.service_cat_id,
    locationStreet: data.location_street,
    locationSuburb: data.location_suburb,
    locationState: data.location_state,
    locationPostalCode: data.location_postal_code,
    postalStreet: data.postal_street,
    postalSuburb: data.postal_suburb,
    postalState: data.postal_state,
    postalCode: data.postal_postal_code,
    contactPhoneNum: data.contact_phonenum,
    contactMobileNum: data.contact_mobilenum,
    contactFaxNum: data.contact_faxnum,
    contactEmail: data.contact_email,
    contactAdditionalEmail: data.contact_add_email,
    providerFirstName: data.provider_fname,
    providerLastName: data.provider_lname,
    providerPhoneNum: data.provider_phonenum,
    providerMobileNum: data.provider_mobilenum,
    providerFaxNum: data.provider_faxnum,
    providerEmail: data.provider_email,
    supervisorFirstName: data.supervisor_fname,
    supervisorLastName: data.supervisor_lname,
    supervisorPhoneNum: data.supervisor_phonenum,
    supervisorMobileNum: data.supervisor_mobilenum,
    supervisorFaxNum: data.supervisor_faxnum,
    supervisorEmail: data.supervisor_email,
    leaderFirstName: data.leader_fname,
    leaderLastName: data.leader_lname,
    leaderPhoneNum: data.leader_phonenum,
    leaderMobileNum: data.leader_mobilenum,
    leaderFaxNum: data.leader_faxnum,
    leaderEmail: data.leader_email,
    operatingHours: JSON.parse(data.operating_hours),
    additionalInfo: data.additional_info,
    rooms: JSON.parse(data.rooms),
    educators: JSON.parse(data.educators),
    employees: JSON.parse(data.employees),
    philosophy: data.philosophy,
    logo: logoResource,
  };
}

export async function updateServiceDetails(
  data: ServiceDetails,
  centerId?: number,
  app_token?: string
): Promise<ServiceDetails> {
  const payload = {
    name: data.name,
    approval_number: data.approvalNumber,
    service_cat_id: data.serviceCatId,
    location_street: data.locationStreet,
    location_suburb: data.locationSuburb,
    location_state: data.locationState,
    location_postal_code: data.locationPostalCode,
    postal_street: data.postalStreet,
    postal_suburb: data.postalSuburb,
    postal_state: data.postalState,
    postal_postal_code: data.postalCode,
    contact_phonenum: data.contactPhoneNum,
    contact_mobilenum: data.contactMobileNum,
    contact_faxnum: data.contactFaxNum,
    contact_email: data.contactEmail,
    contact_add_email: data.contactAdditionalEmail,
    provider_fname: data.providerFirstName,
    provider_lname: data.providerLastName,
    provider_phonenum: data.providerPhoneNum,
    provider_mobilenum: data.providerMobileNum,
    provider_faxnum: data.providerFaxNum,
    provider_email: data.providerEmail,
    supervisor_fname: data.supervisorFirstName,
    supervisor_lname: data.supervisorLastName,
    supervisor_phonenum: data.supervisorPhoneNum,
    supervisor_mobilenum: data.supervisorMobileNum,
    supervisor_faxnum: data.supervisorFaxNum,
    supervisor_email: data.supervisorEmail,
    leader_fname: data.leaderFirstName,
    leader_lname: data.leaderLastName,
    leader_phonenum: data.leaderPhoneNum,
    leader_mobilenum: data.leaderMobileNum,
    leader_faxnum: data.leaderFaxNum,
    leader_email: data.leaderEmail,
    operating_hours: data.operatingHours
      ? JSON.stringify(data.operatingHours)
      : null,
    additional_info: data.additionalInfo,
    rooms: JSON.stringify(data.rooms),
    employees: JSON.stringify(data.employees),
    educators: JSON.stringify(data.educators),
    philosophy: data.philosophy,
    logo: data.logo,
    user_id: data.userId,
    client_id: accessId,
    client_secret: accessKey,
  };

  const id = centerId ? centerId : data.id;

  let token: any = JSON.parse(localStorage.getItem("qip_client_token")!);
  let config = {
    headers: {
      Authorization: "Bearer " + (app_token ? app_token : token.accessToken),
    },
  };
  const response = await axios.put(`/api/center/${id}`, payload, config);

  const logoResource = app_token ? {} : await getLogo(response.data.id, "logo");

  const serviceDetails: ServiceDetails = {
    id: response.data.id,
    name: response.data.name,
    approvalNumber: response.data.approval_number,
    serviceCatId: response.data.service_cat_id,
    locationStreet: response.data.location_street,
    locationSuburb: response.data.location_suburb,
    locationState: response.data.location_state,
    locationPostalCode: response.data.location_postal_code,
    postalStreet: response.data.postal_street,
    postalSuburb: response.data.postal_suburb,
    postalState: response.data.postal_state,
    postalCode: response.data.postal_postal_code,
    contactPhoneNum: response.data.contact_phonenum,
    contactMobileNum: response.data.contact_mobilenum,
    contactFaxNum: response.data.contact_faxnum,
    contactEmail: response.data.contact_email,
    contactAdditionalEmail: response.data.contact_add_email,
    providerFirstName: response.data.provider_fname,
    providerLastName: response.data.provider_lname,
    providerPhoneNum: response.data.provider_phonenum,
    providerMobileNum: response.data.provider_mobilenum,
    providerFaxNum: response.data.provider_faxnum,
    providerEmail: response.data.provider_email,
    supervisorFirstName: response.data.supervisor_fname,
    supervisorLastName: response.data.supervisor_lname,
    supervisorPhoneNum: response.data.supervisor_phonenum,
    supervisorMobileNum: response.data.supervisor_mobilenum,
    supervisorFaxNum: response.data.supervisor_faxnum,
    supervisorEmail: response.data.supervisor_email,
    leaderFirstName: response.data.leader_fname,
    leaderLastName: response.data.leader_lname,
    leaderPhoneNum: response.data.leader_phonenum,
    leaderMobileNum: response.data.leader_mobilenum,
    leaderFaxNum: response.data.leader_faxnum,
    leaderEmail: response.data.leader_email,
    operatingHours: JSON.parse(response.data.operating_hours),
    additionalInfo: response.data.additional_info,
    rooms: JSON.parse(response.data.rooms),
    educators: JSON.parse(response.data.educators),
    employees: JSON.parse(response.data.employees),
    philosophy: response.data.philosophy,
    logo: logoResource,
  };

  return serviceDetails;
}

export async function fetchNQAProgress(centerId: number) {
  let token: any = JSON.parse(localStorage.getItem("qip_client_token")!);
  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };

  const response = await axios.get(`/api/category/${centerId}`, config);

  const progressObject: ProgressResponse = {
    totalProgress: response.data.progress,
    nqaItems: [],
  };

  response.data.children.map((nqa: any) => {
    progressObject.nqaItems.push({
      completed: nqa.progress,
      levels: nqa.children.map((level: any) => ({
        completed: level.progress,
        subLevels: level.children.map((subLevel: any) => ({
          completed: subLevel.progress,
        })),
      })),
    });
  });

  return progressObject;
}

export async function fetchNQAData(): Promise<NQA[]> {
  let token: any = JSON.parse(localStorage.getItem("qip_client_token")!);
  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };

  const response = await axios.get("/api/categories", config);

  const rawNQAData: RawNQAData[] = response.data;

  const nqaParents = rawNQAData.slice(0, 7);

  const nqaItems: NQA[] = [];
  nqaParents.map((nqa) => {
    nqaItems.push({
      id: nqa.id,
      name: nqa.label,
      title: nqa.title ? nqa.title : "",
      completed: undefined,
      levels: [],
    });
  });

  const nqaChildren = rawNQAData.slice(7);

  const nqaLevels: RawNQAData[] = [];
  const nqaSubLevels: RawNQAData[] = [];

  nqaChildren.forEach((child) => {
    if (child.parent < 8 && child.parent > 0) nqaLevels.push(child);
    else nqaSubLevels.push(child);
  });

  nqaLevels.forEach((level) => {
    const levelObject: NQALevel = {
      sourceId: level.id,
      id: parseInt(level.label.split(".")[1]),
      completed: undefined,
      info: level.description ? level.description : "",
      name: level.label,
      title: level.title ? level.title : "",
      subLevels: [],
    };

    nqaSubLevels.forEach((subLevel) => {
      if (subLevel.parent === level.id) {
        levelObject.subLevels.push({
          sourceId: subLevel.id,
          id: parseInt(subLevel.label.split(".")[2]),
          label: subLevel.label,
          name: subLevel.title ? subLevel.title : "",
          completed: undefined,
          description: subLevel.description ? subLevel.description : "",
        });
      }
    });

    const nqaParentIndex = nqaItems.findIndex(
      (item) => item.id === level.parent
    );

    nqaItems[nqaParentIndex].levels.push(levelObject);
  });

  return nqaItems;
}

export async function fetchNQAQuestionsAggregate(
  sourceId: number,
  subNqa?: string
) {
  let token: any = JSON.parse(localStorage.getItem("qip_client_token")!);
  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };

  // value: "embedded",
  // value: "critical_reflections",
  // value: "partnerships_with_family",

  const params1 = new URLSearchParams();
  const params2 = new URLSearchParams();
  const params3 = new URLSearchParams();

  params1.set("type", "embedded");
  // params1.set("center_id", centerId.toString());
  params1.set("category_id", sourceId.toString());

  params2.set("type", "critical_reflections");
  // params2.set("center_id", centerId.toString());
  params2.set("category_id", sourceId.toString());

  params3.set("type", "partnerships_with_family");
  // params3.set("center_id", centerId.toString());
  params3.set("category_id", sourceId.toString());

  const response1 = await axios.get(
    `/api/retrieve-questions?${params1.toString()}`,
    config
  );

  const response2 = await axios.get(
    `/api/retrieve-questions?${params2.toString()}`,
    config
  );

  const response3 = await axios.get(
    `/api/retrieve-questions?${params3.toString()}`,
    config
  );

  return [...response1.data, ...response2.data, ...response3.data]
    .sort((a: any, b: any) => {
      return a.id - b.id;
    })
    .map((question) => {
      return {
        id: question.id,
        center_id: question.center_id,
        subNqa,
        type: question.type,
        description: question.description,
        yesDescription: question.yes_description,
        noDescription: question.no_description,
        statement: question.statement,
        archive: question.archive,
        title: question.title,
        serviceType: question.service_type?.length
          ? SERVICE_TYPES.filter(({ value }) =>
              question.service_type.includes(value)
            )
          : [],
      };
    })
    .filter((question) => {
      return question.center_id ? false : true;
    });
}

export async function fetchNQAQuestions(
  centerId: number,
  type: string,
  selectedNQASublevel: NQASubLevel
): Promise<NQAQuestion[]> {
  const params = new URLSearchParams();

  params.set("type", type);
  params.set("center_id", centerId.toString());
  params.set("category_id", selectedNQASublevel.sourceId.toString());

  let token: any = JSON.parse(localStorage.getItem("qip_client_token")!);
  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };

  const response = await axios.get(
    `/api/questions?${params.toString()}`,
    config
  );

  return response.data
    .filter((question: any) => {
      return question.center_id ? false : true;
    })
    .map((question: any) => ({
      id: question.id,
      type: question.type,
      centerId: question.center_id,
      userId: question.user_id,
      qcId: question.qc_id,
      notes: question.notes,
      title: question.title,
      categoryId: question.category_id,
      description: question.description,
      yesDescription: question.yes_description
        ? question.yes_description
        : question.description,
      noDescription: question.no_description
        ? question.no_description
        : question.description,
      noteDescription: question.na_description ? question.na_description : "",
      cqrId: question.cqr_id ? question.cqr_id : null,
      taskCount: question.taskCount ? question.taskCount : 0,
      status: question.status ? Number(question.status) : 0,
      evidence: question.evidence
        ? {
            ...question.evidence,
            tasks: question.evidence.tasks.map((task: any) => ({
              id: task.id,
              name: task.name,
              taskDue: task.due,
              summary: task.summary,
              assignee: task.assignee,
              centerId: task.center_id,
              status: Number(task.status),
              questionId: task.question_id,
            })),
            evidenceDocs: question.evidence.evidenceDocs.map(
              (evidence: any) => ({
                ...evidence,
                id: evidence.attachment_id,
              })
            ),
          }
        : null,
      strategy: question.strategy
        ? {
            ...question.strategy,
            tasks: question.strategy.tasks.map((task: any) => ({
              id: task.id,
              name: task.name,
              taskDue: task.due,
              summary: task.summary,
              assignee: task.assignee,
              centerId: task.center_id,
              status: Number(task.status),
              questionId: task.question_id,
            })),
            downloadResources: question.strategy.downloadResources.map(
              (evidence: any) => ({
                ...evidence,
                id: evidence.attachment_id,
              })
            ),
          }
        : null,
    }));
}

export async function postComply(
  centerId: number,
  questionId: number,
  result: number,
  notes = "",
  goalStrategy = ""
): Promise<boolean> {
  const payload = {
    center_id: centerId,
    question_id: questionId,
    result,
    notes,
    goalStrategy,
    client_id: accessId,
    client_secret: accessKey,
  };

  let token: any = JSON.parse(localStorage.getItem("qip_client_token")!);
  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };

  const response = await axios.post("/api/question/comply", payload, config);

  if (response.status === 200 || response.status === 201) return true;

  return false;
}

export async function uploadEvidence(
  imageData: EvidenceData
): Promise<boolean> {
  const payload = {
    title: imageData.title,
    label: imageData.label,
    cqr_id: imageData.cqrId,
    base64_image: imageData.image,
    client_id: accessId,
    client_secret: accessKey,
  };

  let token: any = JSON.parse(localStorage.getItem("qip_client_token")!);
  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };

  const response = await axios.post("/api/question/evidence", payload, config);

  if (response.status === 201) return true;

  return false;
}

export async function uploadEvidenceLink(
  linkData: EvidenceData
): Promise<boolean> {
  const payload = {
    name: linkData.name,
    label: linkData.label,
    cqr_id: linkData.cqrId,
    title: linkData.title,
    client_id: accessId,
    client_secret: accessKey,
  };

  let token: any = JSON.parse(localStorage.getItem("qip_client_token")!);
  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };

  const response = await axios.post("/api/question/evidence", payload, config);

  if (response.status === 201) return true;

  return false;
}

export async function uploadEvidenceComment(
  commentData: EvidenceData
): Promise<boolean> {
  const payload = {
    title: commentData.title,
    cqr_id: commentData.cqrId,
    label: commentData.label,
    name: commentData.name,
    client_id: accessId,
    client_secret: accessKey,
  };

  let token: any = JSON.parse(localStorage.getItem("qip_client_token")!);
  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };

  const response = await axios.post("/api/question/evidence", payload, config);

  if (response.status === 201) return true;

  return false;
}

export async function deleteAttachment(attachmentId: number): Promise<boolean> {
  let token: any = JSON.parse(localStorage.getItem("qip_client_token")!);
  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };

  const response = await axios.delete(
    `/api/attachments/${attachmentId}`,
    config
  );

  if (response.status === 200 || response.status === 201) return true;

  return false;
}

export async function uploadImage(
  imageData: UploadData
): Promise<UploadResponse> {
  const payload = {
    entity_id: imageData.centerId,
    entity: imageData.entity,
    name: imageData.name,
    title: imageData.title,
    label: imageData.label,
    base64_image: imageData.data,
    client_id: accessId,
    client_secret: accessKey,
  };

  let token: any = JSON.parse(localStorage.getItem("qip_client_token")!);
  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };

  const response = await axios.post("/api/attachments", payload, config);

  return {
    id: response.data.id,
    entity: response.data.entity,
    entityId: response.data.entity_id,
    name: response.data.name,
    label: response.data.label,
    title: response.data.title,
  };
}

export async function fetchImage(imageId: number) {
  let token: any = JSON.parse(localStorage.getItem("qip_client_token")!);
  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };

  const response = await axios.get(`/api/uploads/${imageId}`, {
    responseType: "arraybuffer",
  });

  return Buffer.from(response.data, "binary").toString("base64");
}

export async function fetchTasks(
  centerId: number,
  overdue?: boolean,
  assigneeName?: string,
  fromDate?: string,
  toDate?: string,
  nqaAreas?: number[]
): Promise<Task[]> {
  const params = new URLSearchParams();

  params.set("center_id", String(centerId));

  if (overdue) {
    params.set("overdue", String(overdue));
  }

  if (nqaAreas && nqaAreas.length > 0) {
    params.set("nqa_areas", JSON.stringify(nqaAreas));
  }

  if (assigneeName) {
    params.set("assignee_name", assigneeName);
  }

  if (fromDate && toDate) {
    const dateRange = {
      from: fromDate,
      to: toDate,
    };

    params.set("date_range", JSON.stringify(dateRange));
  }
  let token: any = JSON.parse(localStorage.getItem("qip_client_token")!);
  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };

  const response = await axios.get(`/api/tasks?${params.toString()}`, config);

  return response.data.map((task: any) => ({
    id: task.id,
    asignee: String(task.assignee),
    name: task.name,
    status: Number(task.status),
    summary: task.summary,
    taskDue: task.due,
    centerId: task.center_id,
    nqaArea: task.nqa_id,
    nqaId: task.nqa_id,
    questionId: task.question_id,
    type: task.task_type,
  }));
}

export async function postTask(task: Task): Promise<boolean> {
  const taskPayload = {
    nqa_id: task.nqaId,
    center_id: task.centerId,
    question_id: task.questionId,
    task_type: task.type,
    name: task.name,
    due: task.taskDue,
    summary: task.summary,
    assignee: task.asignee,
    status: String(task.status),
    client_id: accessId,
    client_secret: accessKey,
  };
  let token: any = JSON.parse(localStorage.getItem("qip_client_token")!);
  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };

  const response = await axios.post("/api/tasks", taskPayload, config);

  if (response.status === 200 || response.status === 201) return true;

  return false;
}

export async function updateTask(task: Task) {
  const taskPayload = {
    nqa_id: task.nqaId,
    center_id: task.centerId,
    question_id: task.questionId,
    task_type: task.type,
    name: task.name,
    due: task.taskDue,
    summary: task.summary,
    assignee: task.asignee,
    status: String(task.status),
    client_id: accessId,
    client_secret: accessKey,
  };
  let token: any = JSON.parse(localStorage.getItem("qip_client_token")!);
  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };

  const response = await axios.put(
    `/api/tasks/${task.id}`,
    taskPayload,
    config
  );

  if (response.status === 200 || response.status === 201) return true;

  return false;
}

export async function fetchCenterReports(
  centerId: number,
  userId: number
): Promise<Report[]> {
  const params = new URLSearchParams();

  params.set("center_id", String(centerId));
  params.set("user_id", String(userId));

  let token: any = JSON.parse(localStorage.getItem("qip_client_token")!);
  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };
  const response = await axios.get(`/api/reports?${params.toString()}`, config);

  return response.data.map((report: any) => ({
    id: report.id,
    url: report.url,
    reportlabel: report.report_label,
    type: report.type,
    filters: report.filters,
    createdBy: report.user_name,
    createdAt: report.created_at,
    centerName: report.center_name,
  }));
}

export async function generateReport(
  userId: number,
  centerId: number,
  reportLabel: string,
  type: number,
  filters: number[]
) {
  const nqaFilters = filters.map((filter) => ({ filter }));
  const payload = {
    user_id: userId,
    center_id: centerId,
    report_label: reportLabel,
    type,
    filters: nqaFilters,
    client_id: accessId,
    client_secret: accessKey,
  };

  let token: any = JSON.parse(localStorage.getItem("qip_client_token")!);
  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };

  const response = await axios.post("/api/report", payload, config);

  return response.data;
}

export async function deleteReport(reportId: number) {
  const payload = {
    report_id: reportId,
    client_id: accessId,
    client_secret: accessKey,
  };

  let token: any = JSON.parse(localStorage.getItem("qip_client_token")!);

  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };

  const response = await axios.delete(`/api/reports/${reportId}`, {
    data: { ...payload },
    headers: { ...config.headers },
  });

  // const response = await axios.delete(`/api/reports/${payload}`);
  if (response.status === 200 || response.status === 201) return true;

  return false;
}

export async function requestFeedback(feedback: Feedback): Promise<boolean> {
  const payload = {
    user_id: feedback.userId,
    center_id: feedback.centerId,
    comments: feedback.comments,
    request_nqa: feedback.requestNQA,
    client_id: accessId,
    client_secret: accessKey,
  };

  let token: any = JSON.parse(localStorage.getItem("qip_client_token")!);
  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };
  const response = await axios.post(
    "/api/notifications/feedback/request",
    payload,
    config
  );

  if (response.status === 200 || response.status === 201) return true;

  return false;
}

export async function getUserProduct(userId: number, centerId: number) {
  let token: any = JSON.parse(localStorage.getItem("qip_client_token")!);
  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };
  // api/stripe/product/{user_id}/{center_id}
  const response = await axios.get(
    `api/stripe/product/${userId}/${centerId}`,
    config
  );

  return response.data.product;
}

export async function getUserSubscriptions(userId: number, centers?: any) {
  let token: any = JSON.parse(localStorage.getItem("qip_client_token")!);
  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };

  const response = await axios.get(`api/stripe/products/${userId}`, config);

  const response1 = await axios.get(
    `/api/getall-sd-subscription/${userId}`,
    config
  );

  const response2 = await axios.get(`/api/user/${userId}/centers`, config);

  let active: any = [];
  let softDeleted: any = [];

  console.log("response ", response, "response1 ", response1);

  console.log("centers rr", centers);

  if (response1.data.products) {
    if (response.data.products) {
      active = response.data.products.filter((prod: any) => {
        return !response1.data.products.some(
          (p: any) => Number(p.center_id) === Number(prod.center_id)
        );
      });
      softDeleted = response1.data.products;
    }
  } else {
    if (response.data.products) {
      active = response.data.products;
    }

    softDeleted = [];
  }

  let trials = response2.data
    .filter((product: any) => {
      return !active.some(
        (p: any) => Number(p.center_id) === Number(product.id)
      );
    })
    .filter((product: any) => {
      return !softDeleted.some(
        (p: any) => Number(p.center_id) === Number(product.id)
      );
    })
    .map((product: any) => {
      let p = centers.filter(
        (center: any) => Number(center.center_id) === Number(product.id)
      )[0];

      return {
        center_id: product.id,
        center_name: product.name,
        deleted_at: product.deleted_at,
        product: "trial",
        subscription: p.subscription,
      };
    });

  console.log("centers  ", response2.data);

  if (centers) {
    active = [
      ...active,
      ...trials
        .filter((center: any) => {
          return center.deleted_at === null;
        })
        .map((center: any) => {
          return {
            center_id: center.center_id,
            center_name: center.center_name,
            product: center.subscription.product_name,
            subscription: center.subscription,
          };
        }),
    ];

    softDeleted = [
      ...softDeleted,
      ...trials
        .filter((center: any) => {
          return center.deleted_at !== null;
        })
        .map((center: any) => {
          return {
            center_id: center.center_id,
            center_name: center.center_name,
            product: center.subscription.product_name,
            subscription: center.subscription,
          };
        }),
    ];
  }

  return {
    active,
    softDeleted,
  };
}

export async function getUserProducts(userId: number) {
  let token: any = JSON.parse(localStorage.getItem("qip_client_token")!);
  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };
  // api/stripe/products/{user_id}
  const response = await axios.get(`api/stripe/products/${userId}`, config);

  return response.data;
}

export async function changePlan(userId: number, price: string) {
  const changePlanPayload = {
    user_id: userId,
    price,
  };

  let token: any = JSON.parse(localStorage.getItem("qip_client_token")!);
  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };
  // POST /changeplan
  // { "user_id":455,
  //   "price":"pr_asdjashdgas123"
  // }
  const response = await axios.post(
    `api/changeplan`,
    changePlanPayload,
    config
  );

  return response.data;
}

export async function getPaymentMethods(userId: number) {
  let token: any = JSON.parse(localStorage.getItem("qip_client_token")!);
  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };
  // GET stripe/paymentmethod/{user_id}
  const response = await axios.get(
    `api/stripe/paymentmethod/${userId}`,
    config
  );

  return response.data;
}

export async function addPaymentMethod(userId: number, pmId: string) {
  const payload = {
    user_id: userId,
    payment_method_id: pmId,
  };

  let token: any = JSON.parse(localStorage.getItem("qip_client_token")!);
  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };
  //   PUT stripe/paymentmethod
  // {
  //   "user_id":554,
  //   "payment_method_id":"pm_1IYNmHDeO3XG77wN947MwTl0"
  // }
  const response = await axios.put(`api/stripe/paymentmethod`, payload, config);
}

export async function removePaymentMethod(pmId: string) {
  const payload = {
    payment_method_id: pmId,
  };

  let token: any = JSON.parse(localStorage.getItem("qip_client_token")!);
  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };
  //   POST stripe/paymentmethod/detach
  // {
  //   "payment_method_id":"pm_1IYNmHDeO3XG77wN947MwTl0"
  // }
  const response = await axios.post(
    `api/stripe/paymentmethod/detach`,
    payload,
    config
  );
}

export async function getInvoices(userId: number) {
  let token: any = JSON.parse(localStorage.getItem("qip_client_token")!);
  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };
  // GET stripe/invoices/{user_id}
  const response = await axios.get(`api/stripe/invoices/${userId}`, config);

  let centers: any = [];

  centers = response.data.map((service: any) => {
    return service.center;
  });

  return {
    centers: Array.from(new Set(centers)),
    invoices: response.data,
  };
}

export async function getAdminAttr(memberId: number): Promise<AdminAttr> {
  let token: any = JSON.parse(localStorage.getItem("qip_client_token")!);
  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };

  // GET  /user/{id}
  const response = await axios.get(`api/user/${memberId}`, config);

  return {
    admin_id: response.data.admin_id,
    datetime_end: response.data.datetime_end,
  };
}

export async function getQuestion(qId: number) {
  let token: any = JSON.parse(localStorage.getItem("qip_client_token")!);
  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };
  const params = new URLSearchParams();

  params.set("id", String(qId));

  const response = await axios.get(
    `/api/question/0?${params.toString()}`,
    config
  );

  return response.data[0];
}

export async function downloadReport(reportId: number) {
  const payload = {
    report_id: reportId,
    client_id: accessId,
    client_secret: accessKey,
  };

  let token: any = JSON.parse(localStorage.getItem("qip_client_token")!);
  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };
  // GET api/report/download/{id}
  const response = await axios.get(`/api/report/download/${reportId}`, {
    data: { ...payload },
    headers: { ...config.headers },
  });
  return response.data;
}

export async function deleteTask(taskId: number) {
  const payload = {
    task_id: taskId,
    client_id: accessId,
    client_secret: accessKey,
  };

  let token: any = JSON.parse(localStorage.getItem("qip_client_token")!);
  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };
  // DELETE : tasks/{id}

  const response = await axios.delete(`/api/tasks/${taskId}`, {
    data: { ...payload },
    headers: { ...config.headers },
  });

  if (response.status === 200 || response.status === 201) return true;

  return false;
}

export async function getLogo(centerId: number, title: string) {
  let token: any = JSON.parse(localStorage.getItem("qip_client_token")!);
  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };

  const params = new URLSearchParams();

  params.set("entity_id", String(centerId));
  params.set("title", title);

  const response = await axios.get(
    `/api/attachments?${params.toString()}`,
    config
  );

  if (response.data.length > 0) {
    return {
      id: response.data[0].id,
      name: response.data[0].name,
      // url: `${process.env.REACT_APP_PUBLIC_URL}qip_backend/storage/uploads/${response.data[0].name}`,
      url: response.data[0].name,
      created_at: response.data[0].created_at,
      updated_at: response.data[0].updated_at,
    };
  }

  return {};
}

export async function addCustomQuestion(customPayload: CustomQuestionPayload) {
  let token: any = JSON.parse(localStorage.getItem("qip_client_token")!);
  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
      ContentType: "application/json",
    },
  };

  const payload = {
    title: customPayload.title,
    type: customPayload.type,
    center_id: customPayload.center_id,
    user_id: customPayload.user_id,
    description: customPayload.description,
    category_id: customPayload.category_id,
  };

  // {
  //   "title": "",
  //   "type": "",
  //   "description": "",
  //   "center_id": "",
  //   "user_id": "",
  //   "category_id": "",
  // }
  // POST : questions

  const response = await axios.post(`/api/questions`, payload, config);

  if (response.status === 200 || response.status === 201) return true;

  return false;
}

export async function updateCustomQuestion(
  qId: number,
  customPayload: CustomQuestionPayload
) {
  let token: any = JSON.parse(localStorage.getItem("qip_client_token")!);
  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
      ContentType: "application/json",
    },
  };

  const payload = {
    title: customPayload.title,
    type: customPayload.type,
    center_id: customPayload.center_id,
    user_id: customPayload.user_id,
    description: customPayload.description,
    category_id: customPayload.category_id,
  };
  // put('question/{id}'
  const response = await axios.put(`/api/question/${qId}`, payload, config);

  if (response.status === 200 || response.status === 201) return true;

  return false;
}

export async function deleteCustomQuestion(qId: number, qcId: number) {
  let token: any = JSON.parse(localStorage.getItem("qip_client_token")!);
  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
      ContentType: "application/json",
    },
  };
  // delete('questions/{id}'
  const response = await axios.delete(`/api/questions/${qId}/${qcId}`, config);

  if (response.status === 200 || response.status === 201) return true;

  return false;
}

export async function fetchAuthor(userId: number) {
  let token: any = JSON.parse(localStorage.getItem("qip_client_token")!);
  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };

  const response = await axios.get(`/api/question/author/${userId}`, config);

  return response.data[0];
}

export async function createServiceCenter(
  initialDetails: InitialServiceDetails
): Promise<boolean> {
  let token: any = JSON.parse(localStorage.getItem("qip_client_token")!);
  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };
  const payload = {
    user_id: initialDetails.userId,
    user_type: "ADMIN",
    name: initialDetails.serviceName,
    approval_number: initialDetails.approvalNumber,
    service_cat_id: initialDetails.serviceCatId,
  };

  const response = await axios.post(`/api/centers`, payload, config);

  if (response.status === 200 || response.status === 201) return true;

  return false;
}

export async function fetchAllUsersCenters(centerIds: number[]) {
  let token: any = JSON.parse(localStorage.getItem("qip_client_token")!);

  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };

  const payload = {
    center_id: centerIds,
  };

  // POST https://dev.holisticqip.com.au/qip_backend/public/api/centers/users

  const response = await axios.post("/api/centers/users", payload, config);

  return Array.from(new Set(response.data.map((user: any) => user.id))).map(
    (id) => {
      return response.data.find((user: any) => user.id === id);
    }
  );
}

export async function fetchAllCenterAdmins() {
  let token: any = JSON.parse(localStorage.getItem("qip_client_token")!);

  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };

  // GET:: /api/centers/admin/users

  const response = await axios.get("/api/centers/admin/users", config);

  let admins: any = [];
  let prev_id = 0;
  let tempCenters: any = [];
  let tempAdmin: any = {};

  let adminsSoft: any = [];
  let prev_idSoft = 0;
  let tempCentersSoft: any = [];
  let tempAdminSoft: any = {};

  let paying = 0;
  let trial = 0;

  let payingSoft = 0;
  let trialSoft = 0;

  let softCount = 0;

  let sortedAdmins: any = response.data.sort((a: any, b: any) => {
    return Number(a.user_id) - Number(b.user_id);
  });

  let rawActive: any = [];
  let rawSoft: any = [];

  sortedAdmins.forEach((sub: any) => {
    if (sub.subscription.product_name === "trial") {
      if (sub.center_deleted_at === null) {
        rawActive = [...rawActive, sub];
      } else {
        rawSoft = [...rawSoft, sub];
      }
    } else {
      if (sub.subscription.subscription_deleted_at === null) {
        rawActive = [...rawActive, sub];
      } else {
        rawSoft = [...rawSoft, sub];
      }
    }
  });

  rawActive.forEach((admin: any, key: number) => {
    if (Number(prev_id) === Number(admin.user_id) || Number(prev_id) === 0) {
      tempCenters.push({
        center_id: admin.center_id,
        center_name: admin.center_name,
        subscription: admin.subscription,
        user_deleted_at: admin.user_deleted_at,
        center_deleted_at: admin.center_deleted_at,
      });
      tempAdmin = {
        user_id: admin.user_id,
        email: admin.email,
        type: admin.type,
        full_name: admin.full_name,
        centers: tempCenters,
      };

      if (Number(prev_id) === 0) {
        if (admin.subscription.product_name !== "trial") {
          if (
            admin.subscription.status !== "trial" &&
            admin.subscription.status !== "trialing"
          ) {
            paying = paying + 1;
          } else {
            trial = trial + 1;
          }
        } else {
          trial = trial + 1;
        }
      }

      if (key === rawActive.length - 1) {
        admins.push(tempAdmin);
      }
    } else {
      admins.push(tempAdmin);
      tempCenters = [];
      tempAdmin = {};

      if (admin.subscription.product_name !== "trial") {
        if (
          admin.subscription.status !== "trial" &&
          admin.subscription.status !== "trialing"
        ) {
          paying = paying + 1;
        } else {
          trial = trial + 1;
        }
      } else {
        trial = trial + 1;
      }

      tempCenters.push({
        center_id: admin.center_id,
        center_name: admin.center_name,
        subscription: admin.subscription,
        user_deleted_at: admin.user_deleted_at,
        center_deleted_at: admin.center_deleted_at,
      });
      tempAdmin = {
        user_id: admin.user_id,
        email: admin.email,
        type: admin.type,
        full_name: admin.full_name,
        centers: tempCenters,
      };

      if (key === rawActive.length - 1) {
        admins.push(tempAdmin);
      }
    }
    prev_id = admin.user_id;
  });

  rawSoft.forEach((admin: any, key: number) => {
    if (
      Number(prev_idSoft) === Number(admin.user_id) ||
      Number(prev_idSoft) === 0
    ) {
      tempCentersSoft.push({
        center_id: admin.center_id,
        center_name: admin.center_name,
        subscription: admin.subscription,
        user_deleted_at: admin.user_deleted_at,
        center_deleted_at: admin.center_deleted_at,
      });
      tempAdminSoft = {
        user_id: admin.user_id,
        email: admin.email,
        type: admin.type,
        full_name: admin.full_name,
        centers: tempCentersSoft,
      };

      if (Number(prev_idSoft) === 0) {
        if (admin.subscription.product_name !== "trial") {
          if (
            admin.subscription.status !== "trial" &&
            admin.subscription.status !== "trialing"
          ) {
            payingSoft = payingSoft + 1;
          } else {
            trialSoft = trialSoft + 1;
          }
        } else {
          trialSoft = trialSoft + 1;
        }
      }

      if (key === rawSoft.length - 1) {
        adminsSoft.push(tempAdminSoft);
        softCount = softCount + tempAdminSoft.centers.length;
      }
    } else {
      adminsSoft.push(tempAdminSoft);
      softCount = softCount + tempAdminSoft.centers.length;
      tempCentersSoft = [];
      tempAdminSoft = {};

      if (admin.subscription.product_name !== "trial") {
        if (
          admin.subscription.status !== "trial" &&
          admin.subscription.status !== "trialing"
        ) {
          payingSoft = payingSoft + 1;
        } else {
          trialSoft = trialSoft + 1;
        }
      } else {
        trialSoft = trialSoft + 1;
      }

      tempCentersSoft.push({
        center_id: admin.center_id,
        center_name: admin.center_name,
        subscription: admin.subscription,
        user_deleted_at: admin.user_deleted_at,
        center_deleted_at: admin.center_deleted_at,
      });
      tempAdminSoft = {
        user_id: admin.user_id,
        email: admin.email,
        type: admin.type,
        full_name: admin.full_name,
        centers: tempCentersSoft,
      };

      if (key === rawSoft.length - 1) {
        adminsSoft.push(tempAdminSoft);
        softCount = softCount + tempAdminSoft.centers.length;
      }
    }
    prev_idSoft = admin.user_id;
  });

  return {
    admins: admins.reverse(),
    adminsSoft: adminsSoft.reverse(),
    softDeletedCount: softCount,
    totalSoft: adminsSoft.length,
    payingSoft,
    trialSoft,
    total: admins.length,
    paying: paying,
    trial: trial,
  };
}

export async function fetchAuthUser(app_token?: string) {
  let token: any = JSON.parse(localStorage.getItem("qip_client_token")!);

  let config = {
    headers: {
      Authorization: "Bearer " + (app_token ? app_token : token.accessToken),
    },
  };

  const response = await axios.get("/api/user", config);

  return response.data;
}

export async function fetchRevenue() {
  let token: any = JSON.parse(localStorage.getItem("qip_client_token")!);

  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };
  const response = await axios.get("/api/stripe/revenue", config);

  return response.data[0];
}

export async function uploadResource(resource: Resource) {
  let token: any = JSON.parse(localStorage.getItem("qip_client_token")!);

  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };

  const payload = {
    base64_image: resource.base64Image,
    name: resource.name,
    label: resource.label,
    user_id: resource.userId,
    nqa_id: resource.nqaId,
    child_nqa_id: resource.childNqaId,
  };

  const response = await axios.post("/api/resourceupload", payload, config);

  if (response.status === 200 || response.status === 201) return true;

  return false;
}

export async function getAllResources() {
  let token: any = JSON.parse(localStorage.getItem("qip_client_token")!);

  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };

  const response = await axios.get("/api/getallresources", config);

  return response.data;
}

export async function deleteResource(id: number) {
  let token: any = JSON.parse(localStorage.getItem("qip_client_token")!);

  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };

  const response = await axios.delete(`/api/resources/${id}`, config);

  if (response.status === 200 || response.status === 201) return true;

  return false;
}

export async function updateResource(resource: Resource) {
  let token: any = JSON.parse(localStorage.getItem("qip_client_token")!);

  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };

  const payload = {
    user_id: resource.userId,
    nqa_id: resource.nqaId,
    child_nqa_id: resource.childNqaId,
    name: resource.name,
    label: resource.label,
  };

  const response = await axios.put(
    `/api/resources/update/${resource.id}`,
    payload,
    config
  );

  if (response.status === 200 || response.status === 201) return true;

  return false;
}

export async function uploadResourceShop(resource: ResourceShop) {
  let token: any = JSON.parse(localStorage.getItem("qip_client_token")!);

  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };

  const payload = {
    base64_image: resource.base64Image,
    product_name: resource.productName,
    product_price: resource.productPrice,
    product_description: resource.productDescription,
    product_link: resource.productLink,
  };

  const response = await axios.post(
    `/api/resourceshop/upload`,
    payload,
    config
  );

  if (response.status === 200 || response.status === 201) return true;

  return false;
}

export async function updateResourceShop(resource: ResourceShop) {
  let token: any = JSON.parse(localStorage.getItem("qip_client_token")!);

  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };

  const payload = {
    base64_image: resource.thumbnail,
    product_name: resource.productName,
    product_description: resource.productDescription,
    product_price: resource.productPrice,
    product_link: resource.productLink,
  };

  const response = await axios.put(
    `/api/resourcesshop/update/${resource.id}`,
    payload,
    config
  );

  if (response.status === 200 || response.status === 201) return true;

  return false;
}

export async function deleteResourceShop(id: number) {
  let token: any = JSON.parse(localStorage.getItem("qip_client_token")!);

  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };

  const response = await axios.delete(
    `/api/resourcesshop/delete/${id}`,
    config
  );

  if (response.status === 200 || response.status === 201) return true;

  return false;
}

export async function getAllResourcesShop() {
  let token: any = JSON.parse(localStorage.getItem("qip_client_token")!);

  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };

  const response = await axios.get(`/api/resourcesshop`, config);

  return response.data;
}

export async function uploadResourceTutorial(resource: ResourceTutorial) {
  let token: any = JSON.parse(localStorage.getItem("qip_client_token")!);

  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };

  const payload = {
    base64_image: resource.base64Image,
    tutorial_name: resource.tutorialName,
    tutorial_description: resource.tutorialDescription,
    tutorial_link: resource.tutorialLink,
    tutorial_author: resource.tutorialAuthor,
    tutorial_date: resource.tutorialDate,
  };

  const response = await axios.post(
    `/api/resourcestutorial/upload`,
    payload,
    config
  );

  if (response.status === 200 || response.status === 201) return true;

  return false;
}

export async function deleteResourceTutorial(id: number) {
  let token: any = JSON.parse(localStorage.getItem("qip_client_token")!);

  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };

  const response = await axios.delete(
    `/api/resourcestutorial/delete/${id}`,
    config
  );

  if (response.status === 200 || response.status === 201) return true;

  return false;
}

export async function updateResourceTutorial(resource: ResourceTutorial) {
  let token: any = JSON.parse(localStorage.getItem("qip_client_token")!);

  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };

  const payload = {
    base64_image: resource.base64Image,
    tutorial_name: resource.tutorialName,
    tutorial_description: resource.tutorialDescription,
    tutorial_link: resource.tutorialLink,
    tutorial_author: resource.tutorialAuthor,
    tutorial_date: resource.tutorialDate,
  };

  const response = await axios.put(
    `/api/resourcestutorial/update/${resource.id}`,
    payload,
    config
  );

  if (response.status === 200 || response.status === 201) return true;

  return false;
}

export async function getAllResourcesTutorial() {
  let token: any = JSON.parse(localStorage.getItem("qip_client_token")!);

  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };

  const response = await axios.get(`/api/resourcestutorial`, config);

  return response.data;
}

export async function listRequestFeedback() {
  let token: any = JSON.parse(localStorage.getItem("qip_client_token")!);

  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };

  const response = await axios.get(`/api/requestfeedback/all/list`);

  const response1 = await axios.get(`/api/replyfeedback/all/list`, config);

  return response.data.map((feedback: any) => {
    let rf = response1.data.filter((reply: any) => {
      return Number(reply.request_id) === Number(feedback.id);
    })[0];

    return {
      ...feedback,
      rf: { ...rf },
    };
  });
}

export async function replyFeedback(feedback: ReplyFeedback) {
  let token: any = JSON.parse(localStorage.getItem("qip_client_token")!);

  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };

  const payload = {
    requestfeedback_id: feedback.id,
    provide_feedback: feedback.feedback,
    provided_by: feedback.providedBy,
    status: feedback.status,
    save_or_publish: feedback.saveOrPublish,
    date_reviewed: feedback.dateReviewed,
  };

  const response = await axios.post(`/api/replyfeedback`, payload, config);

  return response.data;
}

export async function updateReplyFeedback(id: number, feedback: ReplyFeedback) {
  let token: any = JSON.parse(localStorage.getItem("qip_client_token")!);

  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };

  const payload = {
    requestfeedback_id: feedback.id,
    provide_feedback: feedback.feedback,
    provided_by: feedback.providedBy,
    status: feedback.status,
    save_or_publish: feedback.saveOrPublish,
    date_reviewed: feedback.dateReviewed,
  };

  const response = await axios.put(
    `/api/replyfeedback/update/${id}`,
    payload,
    config
  );

  return response.data;
}

export async function getReplyFeedback(id: number) {
  let token: any = JSON.parse(localStorage.getItem("qip_client_token")!);

  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };

  const response = await axios.get(`/api/replyfeedback/${id}`);

  return response.data;
}

export async function getReplyFeedbackAll() {
  let token: any = JSON.parse(localStorage.getItem("qip_client_token")!);

  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };

  const response = await axios.get(`/api/replyfeedback/all/list`, config);

  return response.data;
}

export async function deleteReplyFeedback(requestFeedbackId: number) {
  let token: any = JSON.parse(localStorage.getItem("qip_client_token")!);

  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };

  const response = await axios.delete(
    `/api/replyfeedback/remove/${requestFeedbackId}`
  );

  if (response.status === 200 || response.status === 201) return true;

  return false;
}

export async function removeShopImage(id: number) {
  let token: any = JSON.parse(localStorage.getItem("qip_client_token")!);

  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };

  const response = await axios.get(
    `/api/resourcesshop/shop-removeimage/${id}`,
    config
  );

  if (response.status === 200 || response.status === 201) return true;

  return false;
}

export async function imageShopLink(id: number) {
  let token: any = JSON.parse(localStorage.getItem("qip_client_token")!);

  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };

  const response = await axios.get(
    `/api/resourcesshop/shop-downloadimage/${id}`,
    config
  );

  return response.data;
}

export async function imageAppTutorialLink(id: number) {
  let token: any = JSON.parse(localStorage.getItem("qip_client_token")!);

  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };

  const response = await axios.get(
    `api/resourcestutorial/tutorial-downloadimage/${id}`,
    config
  );

  return response.data;
}

export async function removeAppTutorialImage(id: number) {
  let token: any = JSON.parse(localStorage.getItem("qip_client_token")!);

  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };

  const response = await axios.get(
    `api/resourcestutorial/tutorial-removeimage/${id}`,
    config
  );

  if (response.status === 200 || response.status === 201) return true;

  return false;
}

export async function getAppUsageLog(userId: number) {
  let token: any = JSON.parse(localStorage.getItem("qip_client_token")!);

  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };

  const response = await axios.get(`/api/usagelog/${userId}`, config);

  let appLogs: any = [];

  if (response.data) {
    response.data.forEach((data: any) => {
      let centerInfo = {
        centerName: data.center_name,
        centerId: data.center_id,
      };
      data.center_users.forEach((user: any) => {
        centerInfo = {
          ...centerInfo,
          ...{
            id: user.id,
            name: user.name,
            userId: user.user_id,
            type: user.type,
            lastLogin: user.last_login,
          },
        };

        appLogs = [...appLogs, centerInfo];
      });
    });

    return appLogs;
  }

  return response.data;
}

export async function cancelSubscription(userId: number, centerId: number) {
  let token: any = JSON.parse(localStorage.getItem("qip_client_token")!);

  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };

  const payload = {
    user_id: userId,
    center_id: centerId,
  };

  const response = await axios.post(`/api/cancelsubscription`, payload, config);

  return response.data;
}

export async function softDeleteSubscription(userId: number, centerId: number) {
  let token: any = JSON.parse(localStorage.getItem("qip_client_token")!);

  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };

  const payload = {
    user_id: userId,
    center_id: centerId,
  };

  const response = await axios.post(
    `/api/subscription-remove`,
    payload,
    config
  );

  return response.data;
}

export async function restoreSoftDeleteSubscription(
  userId: number,
  centerId: number
) {
  let token: any = JSON.parse(localStorage.getItem("qip_client_token")!);

  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };

  const payload = {
    user_id: userId,
    center_id: centerId,
  };

  const response = await axios.post(
    `/api/subscription-restore`,
    payload,
    config
  );

  return response.data;
}

export async function getAllSdSubscription(userId: number) {
  let token: any = JSON.parse(localStorage.getItem("qip_client_token")!);

  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };

  const response = await axios.get(
    `/api/getall-sd-subscription/${userId}`,
    config
  );

  return response.data;
}

export async function deleteSdSubscription(userId: number, centerId: number) {
  let token: any = JSON.parse(localStorage.getItem("qip_client_token")!);

  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };

  const payload = {
    user_id: userId,
    center_id: centerId,
  };

  const response = await axios.post(
    `/api/delete-sd-subscription`,
    payload,
    config
  );

  return response.data;
}

export async function fetchUsers() {
  let token: any = JSON.parse(localStorage.getItem("qip_client_token")!);

  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };

  const response = await axios.get("/api/service", config);

  return response.data
    .filter((user: any) => {
      return user.user_type === "USER" || user.user_type === "ADMIN";
    })
    .map((user: any) => {
      return {
        key: user.id,
        text: user.name,
        value: user.id,
      };
    });
}

export async function fetchCenters() {
  let token: any = JSON.parse(localStorage.getItem("qip_client_token")!);

  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };

  const response = await axios.get("/api/centers", config);

  return response.data.map((center: any) => {
    return {
      key: center.id,
      text: center.name,
      value: center.id,
    };
  });
}

export async function messageSelectedUser(message: Message) {
  let token: any = JSON.parse(localStorage.getItem("qip_client_token")!);

  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };

  const payload = {
    user_to: message.userIds,
    subject: message.subject,
    content: message.content,
    is_draft: message.isDraft,
  };

  const response = await axios.post(
    `/api/message-selected-user`,
    payload,
    config
  );

  return response.data;
}

export async function messageAdmins(message: Message) {
  let token: any = JSON.parse(localStorage.getItem("qip_client_token")!);

  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };

  const payload = {
    subject: message.subject,
    content: message.content,
    is_draft: message.isDraft,
  };

  const response = await axios.post(`/api/message-admins`, payload, config);

  return response.data;
}

export async function messageAllUsers(message: Message) {
  let token: any = JSON.parse(localStorage.getItem("qip_client_token")!);

  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };

  const payload = {
    subject: message.subject,
    content: message.content,
    is_draft: message.isDraft,
  };

  const response = await axios.post(`/api/message-all-users`, payload, config);

  return response.data;
}

export async function messageUsersToSelectedCenter(message: Message) {
  let token: any = JSON.parse(localStorage.getItem("qip_client_token")!);

  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };

  const payload = {
    center_id: message.centerIds,
    content: message.content,
    subject: message.subject,
    is_draft: message.isDraft,
  };

  const response = await axios.post(
    `/api/message-users-to-selected-center`,
    payload,
    config
  );

  return response.data;
}

export async function softDeleteTrialCenter(centerId: number) {
  let token: any = JSON.parse(localStorage.getItem("qip_client_token")!);

  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };

  const response = await axios.get(
    `/api/center-sd-trialcenter/${centerId}`,
    config
  );

  return response.data;
}

export async function restoreSoftDeleteTrialCenter(centerId: number) {
  let token: any = JSON.parse(localStorage.getItem("qip_client_token")!);

  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };

  const response = await axios.get(
    `/api/center-sd-restore/${centerId}`,
    config
  );

  return response.data;
}

export async function hardDeleteTrialCenter(centerId: number) {
  let token: any = JSON.parse(localStorage.getItem("qip_client_token")!);

  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };

  const response = await axios.get(
    `/api/center-hd-trialcenter/${centerId}`,
    config
  );

  return response.data;
}

export async function getAllMessages() {
  let token: any = JSON.parse(localStorage.getItem("qip_client_token")!);

  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };

  const response = await axios.get(
    `/api/message-getallusers-superadmin`,
    config
  );

  return response.data.sort((a: any, b: any) => {
    return b.id - a.id;
  });
}

export async function deleteMessage(messageId: number) {
  let token: any = JSON.parse(localStorage.getItem("qip_client_token")!);

  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };

  const response = await axios.delete(
    `/api/message-delete-superadmin/${messageId}`,
    config
  );

  return response.data;
}

export async function updateMessage(messageId: number, message: Message) {
  let token: any = JSON.parse(localStorage.getItem("qip_client_token")!);

  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };

  const payload = {
    subject: message.subject,
    content: message.content,
    user_to: message.ids,
    is_draft: message.isDraft,
    options: message.options,
  };

  const response = await axios.put(
    `/api/message-update/${messageId}`,
    payload,
    config
  );

  return response.data;
}

export async function createQuestion(question: Question) {
  let token: any = JSON.parse(localStorage.getItem("qip_client_token")!);

  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };

  const payload = {
    title: question.title,
    type: question.type,
    description: question.description,
    center_id: question.center_id,
    user_id: question.user_id,
    statement: question.statement,
    yes_description: question.yes_description,
    no_description: question.no_description,
    archive: question.archive,
    category_id: question.categoryId,
    service_type: question.service_type || [],
  };

  const response = await axios.post(`/api/questions`, payload, config);

  return response.data;
}

export async function editQuestion(questionId: number, question: any) {
  let token: any = JSON.parse(localStorage.getItem("qip_client_token")!);

  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };

  const payload = {
    title: question.title,
    type: question.type,
    description: question.description,
    center_id: null,
    user_id: null,
    statement: question.statement,
    yes_description: question.yesDescription,
    no_description: question.noDescription,
    archive: question.archive,
    service_type: question.serviceType || [],
  };

  console.log("payload q ", payload);

  const response = await axios.put(
    `/api/question/${questionId}`,
    payload,
    config
  );

  return response.data;
}

export async function archiveQuestion(questionId: number, archive: number) {
  let token: any = JSON.parse(localStorage.getItem("qip_client_token")!);

  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };

  const payload = {
    question_id: questionId,
    archive,
  };

  const response = await axios.post(`/api/question/archive`, payload, config);

  return response.data;
}

export async function createAdmin(admin: Admin) {
  let token: any = JSON.parse(localStorage.getItem("qip_client_token")!);

  let config = {
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  };

  const payload = {
    name: admin.name,
    email: admin.email,
    user_type: admin.role,
    phone: "test",
    password: "test",
    street_address: "test",
    suburb: "test",
    state: "test",
    postal_code: "test",
    telnum: "test",
    mobilenum: "test",
    add_email: "test",
  };

  const response = await axios.post("/api/register", payload);

  if (response.status === 200 || response.status === 201) return true;

  return false;
}
