import * as React from 'react';

import { Route, Switch, useRouteMatch } from 'react-router-dom';

import ResourceCenter from '../../pages/Admin/ResourceCenter';

export default function AdminResources() {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route
        exact
        path={`${match.path}`}
		render={() => <ResourceCenter /> }
      />
    </Switch>
  );
}
