import * as React from "react";
import { useHistory } from "react-router-dom";
import { Button, Dropdown } from "semantic-ui-react";
import InputComponent from "../../../../components/InputComponent";
import ChooseServiceModal from "../../../../components/Modals/ChooseServiceModal";
import ChooseUserModal from "../../../../components/Modals/ChooseUserModal";
import SendingMessageModal from "../../../../components/Modals/SendingMessageModal";
import BlueButton from "../../../../components/BlueButton";
import {
  FaEnvelope,
  FaUser,
  FaUsers,
  FaUserShield,
  FaUserFriends,
  FaTimesCircle,
  FaSpinner,
  FaArrowLeft,
} from "react-icons/fa";
import useSWR from "swr";
import { isEmpty } from "lodash";

import {
  messageUsersToSelectedCenter,
  messageAllUsers,
  messageAdmins,
  messageSelectedUser,
  fetchCenters,
  fetchUsers,
  getAllUsers,
  updateMessage,
  getAllUsersByRole,
} from "../../../../api/requests";

import Swal from "sweetalert2";

import styles from "./MessageDraftView.module.css";
import ReactQuill from "react-quill";

const { useState, useEffect, useCallback } = React;

const tasksPerPage = 10;

function MessageDraftView({ location }) {
  const { message } = location.state;
  const history = useHistory();
  const [selectedReceiver, setSeletectedReceiver] = useState(message.option);
  const [chooseServiceModal, setChooseServiceModal] = useState(false);
  const [chooseUserModal, setChooseUserModal] = useState(false);

  const [selectedServices, setSelectedServices] = useState(
    message.option === 3 ? message.center_id : []
  );
  const [selectedUsers, setSelectedUsers] = useState(
    message.option === 4 ? message.user_id : []
  );

  const [subject, setSubject] = useState(message.subject);
  const [content, setContent] = useState(message.content);

  const [loading, setLoading] = useState(false);

  const [savingDraft, setSavingDraft] = useState(false);

  const [fieldsChecked, setFieldsChecked] = useState(false);

  const [fetchLimitError, setFetchLimitError] = useState(false);

  const [sendingMessage, setSendingMessage] = useState(false);

  const [allUsers, setAllUsers] = useState();

  const modules = {
    toolbar: [
      ["bold", "italic", "underline", "string"],
      ["blockquote", "code-block"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ script: "sub" }, { script: "super" }],
      [{ indent: "-1" }, { indent: "+1" }],
      [{ direction: "rtl" }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ color: [] }, { background: [] }],
      [{ font: [] }],
      [{ align: [] }],
      ["link", "image"],
    ],
  };

  const onMessageAdmins = async ({ isDraft = 0 }) => {
    try {
      const response = await messageAdmins({
        subject,
        content,
        isDraft,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const onMessageAllUsers = async ({ isDraft = 0 }) => {
    try {
      const response = await messageAllUsers({
        subject,
        content,
        isDraft,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const onMessageSelectedUser = async ({ isDraft = 0 }) => {
    try {
      const response = await messageSelectedUser({
        subject,
        content,
        userIds: selectedUsers,
        isDraft,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const onMessageUsersToSelectedCenter = async ({ isDraft = 0 }) => {
    try {
      const response = await messageUsersToSelectedCenter({
        subject,
        content,
        centerIds: selectedServices,
        isDraft,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const fieldsValidation = () => {
    setFieldsChecked(true);
    if (!(content && subject && selectedReceiver !== 0)) {
      return;
    } else {
      if (selectedReceiver === 3 && isEmpty(selectedServices)) {
        return;
      }

      if (selectedReceiver === 4 && isEmpty(selectedUsers)) {
        return;
      }

      setSendingMessage(true);
    }
  };

  const draftFieldsValidation = () => {
    setFieldsChecked(true);
    if (!(content && subject && selectedReceiver !== 0)) {
      return false;
    } else {
      if (selectedReceiver === 3 && isEmpty(selectedServices)) {
        return false;
      }

      if (selectedReceiver === 4 && isEmpty(selectedUsers)) {
        return false;
      }
    }
    return true;
  };

  const onSendMessageDraft = async () => {
    setSendingMessage(false);
    setLoading(true);

    let sendToIds = [];

    switch (selectedReceiver) {
      case 1:
        sendToIds = allUsers.users;
        break;
      case 2:
        sendToIds = allUsers.admins;
        break;
      case 3:
        sendToIds = selectedServices;
        break;
      case 4:
        sendToIds = selectedUsers;
        break;
    }

    try {
      const response = await updateMessage(message.id, {
        subject: subject,
        content: content,
        isDraft: 0,
        options: selectedReceiver,
        ids: sendToIds,
      });
    } catch (error) {
      console.log(error);
    }

    Swal.fire({
      icon: `success`,
      title: `Message Sent!`,
      showConfirmButton: false,
      timer: 1700,
    }).then(() => {
      setFieldsChecked(false);
      setLoading(false);
      history.push(`/qip/messages`);
    });
  };

  const onUpdateMessage = async (isDraft = 0) => {
    if (!draftFieldsValidation()) {
      return;
    }

    setSavingDraft(true);

    let sendToIds = [];

    switch (selectedReceiver) {
      case 1:
        sendToIds = allUsers.users;
        break;
      case 2:
        sendToIds = allUsers.admins;
        break;
      case 3:
        sendToIds = selectedServices;
        break;
      case 4:
        sendToIds = selectedUsers;
        break;
    }

    try {
      const response = await updateMessage(message.id, {
        subject: subject,
        content: content,
        isDraft: isDraft,
        options: selectedReceiver,
        ids: sendToIds,
      });
      setSavingDraft(false);
    } catch (error) {
      setSavingDraft(false);
      console.log(error);
    }
  };

  const onSaveDraft = async ({ isDraft = 0 }) => {
    draftFieldsValidation();

    setSavingDraft(true);
    switch (selectedReceiver) {
      case 1:
        await onMessageAllUsers(isDraft);
        break;
      case 2:
        await onMessageAdmins(isDraft);
        break;
      case 3:
        await onMessageUsersToSelectedCenter(isDraft);
        break;
      case 4:
        await onMessageSelectedUser(isDraft);
        break;
    }
    setSavingDraft(false);
  };

  const onSendMessage = async ({ isDraft = 0 }) => {
    setSendingMessage(false);
    setLoading(true);
    switch (selectedReceiver) {
      case 1:
        await onMessageAllUsers(isDraft);
        break;
      case 2:
        await onMessageAdmins(isDraft);
        break;
      case 3:
        await onMessageUsersToSelectedCenter(isDraft);
        break;
      case 4:
        await onMessageSelectedUser(isDraft);
        break;
    }

    Swal.fire({
      icon: `success`,
      title: `Message Sent!`,
      showConfirmButton: false,
      timer: 1700,
    }).then(() => {
      setFieldsChecked(false);
      setLoading(false);
      history.push(`/qip/messages`);
    });
  };

  const { data: serviceCenters, isValidating: isValidatingCenters } = useSWR(
    ["/centers"],
    () => fetchCenters(),
    {
      onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
        // Only retry up to 3 times.
        if (retryCount && retryCount >= 3) {
          if (!serviceCenters) setFetchLimitError(true);

          return;
        }
        // Retry after 3 seconds.
        setTimeout(() => revalidate({ retryCount: retryCount + 1 }), 3000);
      },
    }
  );

  const { data: users, isValidating: isValidatingUsers } = useSWR(
    ["/users"],
    () => getAllUsers(),
    {
      onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
        // Only retry up to 3 times.
        if (retryCount && retryCount >= 3) {
          if (!users) setFetchLimitError(true);

          return;
        }
        // Retry after 3 seconds.
        setTimeout(() => revalidate({ retryCount: retryCount + 1 }), 3000);
      },
    }
  );

  // const { data: allUsers, isValidating: isValidatingAllUsers } = useSWR(
  //   ["/allUsers"],
  //   () => getAllUsersByRole(),
  //   {
  //     onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
  //       // Only retry up to 3 times.
  //       if (retryCount && retryCount >= 3) {
  //         if (!allUsers) setFetchLimitError(true);

  //         return;
  //       }
  //       // Retry after 3 seconds.
  //       setTimeout(() => revalidate({ retryCount: retryCount + 1 }), 3000);
  //     },
  //   }
  // );

  const getAllMessageUsers = useCallback(async () => {
    try {
      const response = await getAllUsersByRole();
      setAllUsers(response);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const onModifyCenters = (key) => {
    if (selectedServices.includes(key)) {
      setSelectedServices([
        ...selectedServices.filter((service) => service !== key),
      ]);
    }
  };

  const onModifyUsers = (key) => {
    if (selectedUsers.includes(key)) {
      setSelectedUsers([...selectedUsers.filter((user) => user !== key)]);
    }
  };

  useEffect(() => {
    getAllMessageUsers();
  }, []);

  return (
    <div className={`${styles.mainContainer} mt-5 md:mb-28 lg:mb-10`}>
      {sendingMessage && (
        <SendingMessageModal
          visibility={sendingMessage}
          onCloseModal={() => {
            setSendingMessage(false);
          }}
          onConfirm={onSendMessageDraft}
        />
      )}

      {chooseServiceModal && !isEmpty(serviceCenters) && (
        <ChooseServiceModal
          visibility={chooseServiceModal}
          onOpenModal={() => setChooseServiceModal(true)}
          onCloseModal={() => setChooseServiceModal(false)}
          currentCenters={selectedServices}
          setCurrentCenters={setSelectedServices}
          centers={serviceCenters}
        />
      )}
      {chooseUserModal && !isEmpty(users) && (
        <ChooseUserModal
          visibility={chooseUserModal}
          onOpenModal={() => setChooseUserModal(true)}
          onCloseModal={() => setChooseUserModal(false)}
          currentUsers={selectedUsers}
          setCurrentUsers={setSelectedUsers}
          users={users}
        />
      )}
      <div className={`${styles.headerTextContainer} flex`}>
        <div className={styles.headerTextIcon}>
          <FaEnvelope size={55} />
        </div>
        <div className={`w-full flex justify-between justify-baseline`}>
          <div className={`${styles.headerText} ml-4`}>
            <h1>Messages</h1>
            <text>Create and send important updates to Holistic QIP users</text>
          </div>
          <div
            onClick={() => history.push(`/qip/messages`)}
            className={`${styles.backMessageButton} flex justify-center items-center`}
          >
            <FaArrowLeft className={styles.backMessageIcon} />{" "}
            <span className={`${styles.backMessageText} ml-2`}>
              Back to messages
            </span>
          </div>
        </div>
      </div>

      <div className={`${styles.Content} md:px-7 lg:px-14 pt-16 pb-28 mt-9`}>
        <h2>Choose who will see this message</h2>

        <div
          className={`${styles.sendToContainer} flex flex-wrap content-between mt-11 mb-14 pb-10`}
        >
          <div className={`flex`}>
            <button
              className={
                selectedReceiver === 1
                  ? styles.activeIconContainer
                  : styles.iconContainer
              }
              onClick={() => setSeletectedReceiver(1)}
            >
              <FaUsers size={30} />
            </button>

            <div className={`${styles.groupText} ml-4`}>
              <text className={`block mb-1.5`}>Everybody</text>
              <text className={`block`}>To all users & admins</text>
            </div>
          </div>

          <div className={`flex`}>
            <button
              className={
                selectedReceiver === 2
                  ? styles.activeIconContainer
                  : styles.iconContainer
              }
              onClick={() => setSeletectedReceiver(2)}
            >
              <FaUserShield size={30} />
            </button>

            <div className={`${styles.groupText} ml-4`}>
              <text className={`block mb-1.5`}>Admins only</text>
              <text className={`block`}>Only for other admins</text>
            </div>
          </div>

          <div className={`flex`}>
            <button
              className={
                selectedReceiver === 3
                  ? styles.activeIconContainer
                  : styles.iconContainer
              }
              onClick={() => {
                setSeletectedReceiver(3);
                setChooseServiceModal(true);
              }}
            >
              <FaUserFriends size={30} />
            </button>

            <div className={`${styles.groupText} ml-4`}>
              <text className={`block mb-1.5`}>Service teammates</text>
              <text className={`block`}>Seen by a service’s users</text>
            </div>
          </div>

          <div className={`flex`}>
            <button
              className={
                selectedReceiver === 4
                  ? styles.activeIconContainer
                  : styles.iconContainer
              }
              onClick={() => {
                setSeletectedReceiver(4);
                setChooseUserModal(true);
              }}
            >
              <FaUser size={30} />
            </button>

            <div className={`${styles.groupText} ml-4`}>
              <text className={`block mb-1.5`}>Selected user/s</text>
              <text className={`block`}>Seen only by one user</text>
            </div>
          </div>
        </div>
        {selectedReceiver === 3 && !isEmpty(selectedServices) && (
          <div className={styles.receiverLabel}>
            Services that will receive this message
          </div>
        )}
        {!isEmpty(selectedServices) &&
          !isEmpty(serviceCenters) &&
          selectedReceiver === 3 && (
            <div
              className={`${styles.selectedReceiverRow} flex flex-wrap mb-6`}
            >
              {serviceCenters
                .filter((sc) => selectedServices.includes(sc.key))
                .map((sc, k) => {
                  return (
                    <div
                      key={k}
                      className={`${styles.selectedReciever} flex items-center	p-4 mr-3.5 mb-4`}
                    >
                      {sc.text}
                      <FaTimesCircle
                        onClick={() => onModifyCenters(sc.key)}
                        className={`ml-3.5`}
                        size={20}
                      />
                    </div>
                  );
                })}

              <button
                onClick={() => setSelectedServices([])}
                className={`${styles.clearButton} py-3.5 px-6 mb-4`}
              >
                Clear
              </button>
            </div>
          )}
        {selectedReceiver === 4 && !isEmpty(selectedUsers) && (
          <div className={styles.receiverLabel}>
            Users who will receive this message
          </div>
        )}
        {!isEmpty(selectedUsers) && !isEmpty(users) && selectedReceiver === 4 && (
          <div className={`${styles.selectedReceiverRow} flex flex-wrap mb-6`}>
            {users
              .filter((user) => selectedUsers.includes(user.key))
              .map((user, k) => {
                return (
                  <div
                    key={k}
                    className={`${styles.selectedReciever} flex items-center	p-4 mr-3.5 mb-4`}
                  >
                    {user.text}
                    <FaTimesCircle
                      onClick={() => onModifyUsers(user.key)}
                      className={`ml-3.5`}
                      size={20}
                    />
                  </div>
                );
              })}

            <button
              onClick={() => setSelectedUsers([])}
              className={`${styles.clearButton} py-3.5 px-6 mb-4`}
            >
              Clear
            </button>
          </div>
        )}

        <div className={`${styles.formContainer}`}>
          <InputComponent
            fieldName="subject"
            type="text"
            label="Message subject"
            placeholder="Subject"
            required={true}
            showNotFilled={fieldsChecked && !subject}
            inputValue={subject}
            setInputValue={(_, value) => setSubject(value)}
          />

          <div>
            <h4
              className={`pb-2`}
              style={{ fontSize: "20px", lineHeight: "28px" }}
            >
              Message<span className={`text-red-400`}>*</span>
            </h4>
            <div className={`h-96 pb-14 `}>
              <ReactQuill
                className={`h-full ${styles.editor}`}
                modules={modules}
                value={content}
                onChange={(value) => {
                  if (value.trim() === "<p><br></p>") {
                    setContent("");
                  } else {
                    setContent(value);
                  }
                }}
              />
              <textarea
                className={`hidden`}
                value={content}
                onChange={() => null}
              />
            </div>
            {fieldsChecked && !content && (
              <div className={`text-red-400 p-2 font-thin italic `}>
                This field is required.
              </div>
            )}
          </div>

          <div className={`flex justify-center items-center mt-6`}>
            <div
              onClick={() => {
                if (!isEmpty(allUsers)) {
                  if (!savingDraft) {
                    onUpdateMessage(1);
                  }
                }
              }}
              className={`${styles.buttonGreen} flex justify-center items-center`}
            >
              {savingDraft ? (
                <div className={`flex justify-center items-center`}>
                  <FaSpinner className={`animate-spin`} />{" "}
                  <span className={`${styles.buttonText} ml-2`}>
                    Saving draft...
                  </span>{" "}
                </div>
              ) : (
                <span className={`${styles.buttonText} ml-2`}>
                  Save as draft
                </span>
              )}
            </div>

            <BlueButton
              disabled={loading || savingDraft}
              onClick={() => {
                if (!isEmpty(allUsers)) {
                  fieldsValidation();
                }
              }}
              className={`${styles.sendMessageButton} flex justify-center ml-4`}
              buttonText={
                loading ? (
                  <div className={`flex justify-center items-center`}>
                    <FaSpinner className={`animate-spin`} />{" "}
                    <span className={`ml-2`}>Sending...</span>{" "}
                  </div>
                ) : (
                  <span>Send message</span>
                )
              }
            />
          </div>
          {(selectedReceiver === 3 && isEmpty(selectedServices)) ||
            (selectedReceiver === 4 &&
              isEmpty(selectedUsers) &&
              fieldsChecked && (
                <div className={`flex justify-center items-center`}>
                  <text className={`py-1 text-red-400 `}>
                    {" "}
                    Please select at least one{" "}
                    {selectedReceiver === 3 ? "service" : "user"}
                  </text>
                </div>
              ))}
        </div>
      </div>
    </div>
  );
}

export default MessageDraftView;
