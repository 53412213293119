import * as React from "react";

import PropTypes from "prop-types";
import { Modal, Button, Icon } from "semantic-ui-react";
import InputComponent from "../../InputComponent";
import UnlinkQuestionModal from "../UnlinkQuestionModal";
import RemoveResourceModal from "../RemoveResourceModal";
import SuccessUnlinkQuestionModal from "../SuccessUnlinkQuestionModal";
import SuccessRemoveResourceModal from "../SuccessRemoveResourceModal";
import NQASelector from "../../NQASelector/NQASelector";
import styles from "./EditResourceModal.module.css";
import {
  FaPlusCircle,
  FaCommentDots,
  FaEdit,
  FaLink,
  FaTimes,
  FaRegArrowAltCircleLeft,
  FaRegFileWord,
  FaRegFilePdf,
  FaRegFileExcel,
  FaRegFilePowerpoint,
  FaRegImage,
  FaPlay,
  FaVolumeUp,
  FaRegFileArchive,
  FaFile,
  FaShoppingBag,
  FaFileUpload,
  FaSpinner,
} from "react-icons/fa";
import { updateResource, deleteResource } from "../../../api/requests";
import useSWR, { mutate } from "swr";
import { isEmpty } from "lodash";

const { useState } = React;

function EditResourceModal({
  visibility,
  resource,
  nqaData,
  onOpenModal,
  onCloseModal,
}) {
  const [currentTab, setCurrentTab] = useState(resource.user_id);
  const [chooseQuestionsUpload, setChooseQuestionsUpload] = useState(false);
  const [chooseQuestionsLink, setChooseQuestionsLink] = useState(false);
  const [unlinkQuestionModal, setUnlinkQuestionModal] = useState(false);
  const [successUnlinkQuestionModal, setSuccessUnlinkQuestionModal] = useState(
    false
  );

  const [resourceLabel, setResourceLabel] = useState(
    resource.label ? resource.label : "Resource File"
  );

  const [resourceLevel, setResourceLevel] = useState(resource.nqa_id);
  const [resourceSublevel, setResourceSublevel] = useState(
    resource.child_nqa_id
  );

  const [resourceUrl, setResourceUrl] = useState(
    resource.user_id === 2 ? resource.name : ""
  );

  const [showNqaSelector, setShowNqaSelector] = useState(false);

  const [selectorOnFocus, setSelectorOnFocus] = useState(false);

  const [removeResourceModal, setRemoveResourceModal] = useState(false);
  const [successRemoveResourceModal, setSuccessRemoveResourceModal] = useState(
    false
  );

  const [saving, setSaving] = useState(false);

  const onConfirmUnlinkQuestion = async () => {
    setSuccessUnlinkQuestionModal(true);
    setUnlinkQuestionModal(false);
  };

  const onUpdateResource = async () => {
    try {
      setSaving(true);
      const response = await updateResource({
        id: resource.id,
        userId: resource.user_id,
        nqaId: resourceLevel,
        childNqaId: resourceSublevel,
        name: resource.user_id === 2 ? resourceUrl : resource.name,
        label: resourceLabel,
      });
      setSaving(false);
      onCloseModal();
    } catch (error) {
      setSaving(false);
      onCloseModal();
      console.log(error);
    }
  };

  const onConfirmRemoveResourceFile = async () => {
    try {
      setRemoveResourceModal(false);
      const response = await deleteResource(Number(resource.id));
      setSuccessRemoveResourceModal(true);
    } catch (error) {
      setRemoveResourceModal(false);
      onCloseModal();
      console.log(error);
    }
  };

  const styleGrabber = (file) => {
    const ext = file.split(".")[1];
    switch (ext) {
      case "pdf":
        return "pdfFile";
      case "jpg":
      case "jpeg":
      case "png":
        return "imageFile";
      case "zip":
        return "archiveFile";
      case "webm":
      case "wmv":
      case "ogg":
      case "mp4":
      case "avi":
      case "mov":
        return "videoFile";

      case "mp3":
      case "wave":
        return "audioFile";
      case "xlsx":
        return "excelFile";
      case "pptx":
        return "powerPointfile";
      case "docx":
        return "wordFile";
      default:
        return "randomFile";
    }
  };

  const fileTypeGrabber = (file) => {
    const ext = file.split(".")[1];
    switch (ext) {
      case "pdf":
        return "PDF document";
      case "jpg":
      case "jpeg":
      case "png":
        return "Image file";
      case "zip":
        return "ZIP file";
      case "webm":
      case "wmv":
      case "ogg":
      case "mp4":
      case "avi":
      case "mov":
        return "Video file";

      case "mp3":
      case "wave":
        return "Audio file";
      case "xlsx":
        return "Excel file";
      case "pptx":
        return "Powerpoint file";
      case "docx":
        return "Word document";
      default:
        return "File";
    }
  };

  const IconGrabber = ({ file }) => {
    const ext = file.split(".")[1];
    switch (ext) {
      case "pdf":
        return <FaRegFilePdf />;
      case "jpg":
      case "jpeg":
      case "png":
        return <FaRegImage />;
      case "zip":
        return <FaRegFileArchive />;
      case "webm":
      case "wmv":
      case "ogg":
      case "mp4":
      case "avi":
      case "mov":
        return <FaPlay />;
      case "mp3":
      case "wave":
        return <FaVolumeUp />;
      case "xlsx":
        return <FaRegFileExcel />;
      case "pptx":
        return <FaRegFilePowerpoint />;
      case "docx":
        return <FaRegFileWord />;
      default:
        return <FaFile />;
    }
  };

  return (
    <Modal
      size="large"
      centered
      open={visibility}
      onOpen={onOpenModal}
      onClose={onCloseModal}
    >
      <UnlinkQuestionModal
        visibility={unlinkQuestionModal}
        onOpenModal={() => setUnlinkQuestionModal(true)}
        onCloseModal={() => setUnlinkQuestionModal(false)}
        onConfirmUnlinkQuestion={onConfirmUnlinkQuestion}
      />

      <SuccessUnlinkQuestionModal
        visibility={successUnlinkQuestionModal}
        onOpenModal={() => setSuccessUnlinkQuestionModal(true)}
        onCloseModal={() => setSuccessUnlinkQuestionModal(false)}
      />

      {removeResourceModal && (
        <RemoveResourceModal
          visibility={removeResourceModal}
          resourceId={resource.id}
          onOpenModal={() => setRemoveResourceModal(true)}
          onCloseModal={() => setRemoveResourceModal(false)}
          onConfirmRemoveResource={onConfirmRemoveResourceFile}
        />
      )}

      <SuccessRemoveResourceModal
        visibility={successRemoveResourceModal}
        onOpenModal={() => setSuccessRemoveResourceModal(true)}
        onCloseModal={() => {
          setSuccessRemoveResourceModal(false);
          onCloseModal();
        }}
      />

      <div className={`${styles.modalContainer} flex flex-col`}>
        <Button
          className={styles.closeButton}
          onClick={onCloseModal}
          size="big"
          icon="close"
        />
        <text className={`${styles.headerText} block mb-2.5`}>
          Edit resource
        </text>
        <text className={`${styles.subText} block mb-11`}>
          {/* Edit an existing resource document or link for inclusion into the App */}
          Edit an existing{" "}
          {`${resource.user_id === 2 ? "link" : "resource document"}`} for
          inclusion into the App
        </text>

        {/* <div className={`${styles.tabContainer} flex mb-9`}>
          <div
            className={`${
              currentTab === 1 ? styles.selectedTab : styles.tab
            } flex items-center justify-center`}
            // onClick={() => setCurrentTab(1)}
          >
            <FaFileUpload className={`mr-3`} />
            Upload
          </div>

          <div
            className={`${
              currentTab === 2 ? styles.selectedTab : styles.tab
            } flex items-center justify-center`}
            // onClick={() => setCurrentTab(2)}
          >
            <FaLink className={`mr-3`} />
            Link
          </div>
        </div> */}

        <div className={`${styles.tabBodyContainer}`}>
          {currentTab === 1 ? (
            <>
              <div
                className={`${styles.selectedResourceFile} flex items-center justify-between py-5 mb-8`}
              >
                <div className={`flex items-center`}>
                  <div
                    className={`${styles.filelogo} ${
                      styles[`${styleGrabber(resource.name)}`]
                    } flex items-center justify-center mr-3.5`}
                  >
                    <IconGrabber file={resource.name} />
                  </div>
                  <div className={styles.fileContent}>
                    <text className={`${styles.filename} block`}>
                      {" "}
                      {resource.label ? resource.label : `Resource File`}
                    </text>
                    <text className={`${styles.fileType} block`}>
                      {" "}
                      {fileTypeGrabber(resource.name)} / {` `}
                      NQA{` `}
                      {resource.child_nqa_id}
                    </text>
                  </div>
                </div>

                <div className={`${styles.actionButtonGroup}`}>
                  <button
                    onClick={() => setRemoveResourceModal(true)}
                    className={`${styles.removeUserButton} flex items-center p-4 rounded text-base`}
                  >
                    <FaTimes className={`mr-3`} size={15} />
                    Remove
                  </button>
                </div>
              </div>

              {/* <div className={`mb-7`}>
                <input type="file" style={{ display: "none" }} />

                <span className={`${styles.uploadResourceTitle} block mb-2.5`}>
                  Upload resource (PDF, MP4, Word, Excel, PPT)
                </span>

                <div
                  className={`${styles.uploadResourcePlaceholder} text-center py-5`}
                >
                  <div>
                    <Icon
                      className={styles.uploadResourceIcon}
                      name="arrow up"
                    />

                    <text
                      className={`${styles.uploadResourceIconText} block mt-5`}
                    >
                      Upload document
                    </text>
                  </div>
                </div>
              </div> */}

              {/*
              <InputComponent
                fieldName="findService"
                type="text"
                label="Choose NQA areas (separate by comma)"
                placeholder="Start typing (e.g. 1.1.2, 2.2.1)"
              />
              */}

              <InputComponent
                fieldName="documentName"
                type="text"
                label="Document name"
                inputValue={resource.label ? resource.label : "Resource File"}
                setInputValue={(_, data) => {
                  setResourceLabel(data);
                }}
                placeholder="Document Name"
              />
              {showNqaSelector && (
                <NQASelector
                  currentNqaLevel={resource.child_nqa_id}
                  nqaData={nqaData}
                  setNqalevel={(value) => {
                    setResourceLevel(value);
                  }}
                  setSubNqalevel={(value) => {
                    setResourceSublevel(value);
                  }}
                  onClose={() => setShowNqaSelector(false)}
                  setOnFocus={(value) => {
                    setSelectorOnFocus(value);
                  }}
                />
              )}

              <InputComponent
                fieldName="nqaLevel"
                type="text"
                label="NQA level"
                stateValue={resourceSublevel}
                showToggle={true}
                toggle={showNqaSelector}
                onToggle={(val) => {
                  if (!val && !selectorOnFocus) {
                    setShowNqaSelector(false);
                  }
                  if (val && selectorOnFocus) {
                    setShowNqaSelector(true);
                  }
                  if (!val && selectorOnFocus) {
                    setShowNqaSelector(true);
                  }
                  if (val && !selectorOnFocus) {
                    setShowNqaSelector(true);
                  }
                }}
                // setInputValue={(_, data) => {
                //   setResourceSublevel(data);
                // }}
                placeholder="NQA level"
              />

              <div className={`text-center mt-7`}>
                <button
                  onClick={onUpdateResource}
                  disabled={saving}
                  className={`${styles.saveButton}`}
                >
                  {saving ? (
                    <div className={`flex justify-center items-center `}>
                      {" "}
                      <FaSpinner className={`animate-spin`} />{" "}
                      <span className={`ml-2`}>Saving...</span>{" "}
                    </div>
                  ) : (
                    <> Update resource </>
                  )}
                </button>
              </div>

              {/* <div className={`${styles.chooseQuestions} text-center`}>
                <text className={`block`}>
                  Link this resource to a question
                </text>

                <button
                  className={`${styles.chooseQuestionsButton}`}
                  onClick={() => setChooseQuestionsUpload(true)}
                >
                  Choose questions
                </button>
              </div>

              {chooseQuestionsUpload === true ? (
                <div className={`${styles.questionsContainer} mt-16`}>
                  <text className={`mb-6 block`}>
                    3 currently linked questions
                  </text>

                  <div className={`flex`}>
                    <div
                      className={`${styles.questionText} ${styles.selected} flex`}
                    >
                      <div
                        className={`${styles.checkbox} ${styles.selected} mr-6`}
                      >
                        <label>
                          <input type="checkbox" />
                        </label>
                      </div>

                      <text>
                        Is an individual learning plan evident for each child
                        and includes references to all 5 Learning Outcomes? Over
                        time, do individual childrens records show distance
                        travelled in relation to the 5 learning outcomes of
                        EYLF? Is an individual learning plan evident for each
                        child and includes references to all 5 Learning
                        Outcomes? Over time, do individual childrens records
                        show ‘distance travelled in relation to the 5 learning
                        outcomes of EYLF?
                      </text>
                    </div>

                    <div className={`${styles.questionButton}`}>
                      <button
                        className={`flex items-center p-4 rounded text-base`}
                        onClick={() => setUnlinkQuestionModal(true)}
                      >
                        <FaTimes className={`mr-3`} size={15} />
                        Unlink
                      </button>
                    </div>
                  </div>

                  <div className={`flex`}>
                    <div className={`${styles.questionText} flex`}>
                      <div className={`${styles.checkbox} mr-6`}>
                        <label>
                          <input type="checkbox" />
                        </label>
                      </div>

                      <text>
                        Co-programming occurs at the service through morning/
                        afternoon minutes e.g. planning and reflective sessions
                        with the children.
                      </text>
                    </div>

                    <div className={`${styles.questionButton}`}>
                      <button
                        className={`flex items-center p-4 rounded text-base`}
                        onClick={() => setUnlinkQuestionModal(true)}
                      >
                        <FaTimes className={`mr-3`} size={15} />
                        Unlink
                      </button>
                    </div>
                  </div>

                  <div className={`flex`}>
                    <div className={`${styles.questionText} flex`}>
                      <div className={`${styles.checkbox} mr-6`}>
                        <label>
                          <input type="checkbox" />
                        </label>
                      </div>

                      <text>
                        The service documents through a variety of formats, e.g.
                        mind maps, video/audio recordings, learning stories, and
                        a series of jottings and anecdotes.
                      </text>
                    </div>

                    <div className={`${styles.questionButton}`}>
                      <button
                        className={`flex items-center p-4 rounded text-base`}
                        onClick={() => setUnlinkQuestionModal(true)}
                      >
                        <FaTimes className={`mr-3`} size={15} />
                        Unlink
                      </button>
                    </div>
                  </div>

                  <div className={`text-center mt-6`}>
                    <button
                      className={`${styles.saveButton}`}
                      onClick={() => setChooseQuestionsUpload(false)}
                    >
                      Save changes
                    </button>
                  </div>
                </div>
              ) : (
                <></>
              )} */}
            </>
          ) : (
            <>
              <InputComponent
                fieldName="resourceUrl"
                type="text"
                label="Provide a URL link to the resource (e.g. webinar / YouTube video)"
                inputValue={resourceUrl}
                setInputValue={(_, value) => {
                  setResourceUrl(value);
                }}
              />

              {/*
              <InputComponent
                fieldName="findService"
                type="text"
                label="Choose NQA areas (separate by comma)"
				placeholder="Start typing (e.g. 1.1.2, 2.2.1)"
              />
              */}

              <InputComponent
                fieldName="resourceName"
                type="text"
                label="Resource name"
                inputValue={resourceLabel}
                setInputValue={(_, value) => {
                  setResourceLabel(value);
                }}
              />

              {showNqaSelector && (
                <NQASelector
                  currentNqaLevel={resource.child_nqa_id}
                  nqaData={nqaData}
                  setNqalevel={(value) => {
                    setResourceLevel(value);
                  }}
                  setSubNqalevel={(value) => {
                    setResourceSublevel(value);
                  }}
                  onClose={() => setShowNqaSelector(false)}
                  setOnFocus={(value) => {
                    setSelectorOnFocus(value);
                  }}
                />
              )}

              <InputComponent
                fieldName="nqaLevel"
                type="text"
                label="NQA level"
                stateValue={resourceSublevel}
                showToggle={true}
                toggle={showNqaSelector}
                onToggle={(val) => {
                  if (!val && !selectorOnFocus) {
                    setShowNqaSelector(false);
                  }
                  if (val && selectorOnFocus) {
                    setShowNqaSelector(true);
                  }
                  if (!val && selectorOnFocus) {
                    setShowNqaSelector(true);
                  }
                  if (val && !selectorOnFocus) {
                    setShowNqaSelector(true);
                  }
                }}
                // setInputValue={(_, data) => {
                //   setResourceSublevel(data);
                // }}
                placeholder="NQA level"
              />

              <div className={`text-center mt-7`}>
                <button
                  onClick={onUpdateResource}
                  disabled={saving}
                  className={`${styles.saveButton}`}
                >
                  {saving ? (
                    <div className={`flex justify-center items-center `}>
                      {" "}
                      <FaSpinner className={`animate-spin`} />{" "}
                      <span className={`ml-2`}>Saving...</span>{" "}
                    </div>
                  ) : (
                    <> Update resource </>
                  )}
                </button>
              </div>

              {/* <div className={`${styles.chooseQuestions} text-center`}>
                <text className={`block`}>
                  Link this resource to a question
                </text>

                <button
                  className={`${styles.chooseQuestionsButton}`}
                  onClick={() => setChooseQuestionsLink(true)}
                >
                  Choose questions
                </button>
              </div> */}

              {/* {chooseQuestionsLink === true ? (
                <div className={`${styles.questionsContainer} mt-16`}>
                  <text className={`mb-6 block`}>
                    3 currently linked questions
                  </text>

                  <div className={`flex`}>
                    <div
                      className={`${styles.questionText} ${styles.selected} flex`}
                    >
                      <div
                        className={`${styles.checkbox} ${styles.selected} mr-6`}
                      >
                        <label>
                          <input type="checkbox" />
                        </label>
                      </div>

                      <text>
                        Is an individual learning plan evident for each child
                        and includes references to all 5 Learning Outcomes? Over
                        time, do individual childrens records show distance
                        travelled in relation to the 5 learning outcomes of
                        EYLF? Is an individual learning plan evident for each
                        child and includes references to all 5 Learning
                        Outcomes? Over time, do individual childrens records
                        show ‘distance travelled in relation to the 5 learning
                        outcomes of EYLF?
                      </text>
                    </div>

                    <div className={`${styles.questionButton}`}>
                      <button
                        className={`flex items-center p-4 rounded text-base`}
                        onClick={() => setUnlinkQuestionModal(true)}
                      >
                        <FaTimes className={`mr-3`} size={15} />
                        Unlink
                      </button>
                    </div>
                  </div>

                  <div className={`flex`}>
                    <div className={`${styles.questionText} flex`}>
                      <div className={`${styles.checkbox} mr-6`}>
                        <label>
                          <input type="checkbox" />
                        </label>
                      </div>

                      <text>
                        Co-programming occurs at the service through morning/
                        afternoon minutes e.g. planning and reflective sessions
                        with the children.
                      </text>
                    </div>

                    <div className={`${styles.questionButton}`}>
                      <button
                        className={`flex items-center p-4 rounded text-base`}
                        onClick={() => setUnlinkQuestionModal(true)}
                      >
                        <FaTimes className={`mr-3`} size={15} />
                        Unlink
                      </button>
                    </div>
                  </div>

                  <div className={`flex`}>
                    <div className={`${styles.questionText} flex`}>
                      <div className={`${styles.checkbox} mr-6`}>
                        <label>
                          <input type="checkbox" />
                        </label>
                      </div>

                      <text>
                        The service documents through a variety of formats, e.g.
                        mind maps, video/audio recordings, learning stories, and
                        a series of jottings and anecdotes.
                      </text>
                    </div>

                    <div className={`${styles.questionButton}`}>
                      <button
                        className={`flex items-center p-4 rounded text-base`}
                        onClick={() => setUnlinkQuestionModal(true)}
                      >
                        <FaTimes className={`mr-3`} size={15} />
                        Unlink
                      </button>
                    </div>
                  </div>

                  <div className={`text-center mt-6`}>
                    <button
                      className={`${styles.saveButton}`}
                      onClick={() => setChooseQuestionsLink(false)}
                    >
                      Save changes
                    </button>
                  </div>
                </div>
              ) : (
                <></>
              )} */}
            </>
          )}
        </div>
      </div>
    </Modal>
  );
}

EditResourceModal.propTypes = {
  visibility: PropTypes.bool.isRequired,
  onOpenModal: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
};

export default EditResourceModal;
