import * as React from "react";

import PropTypes from "prop-types";
import { Button, Modal } from "semantic-ui-react";

import styles from "./SendingMessageModal.module.css";
import SkipButton from "../../SkipButton";

const { useState, useEffect, useCallback } = React;

function SendingMessageModal({
  visibility,
  onOpenModal,
  onCloseModal,
  message = "Your message will send in 60 seconds.",
  onConfirm,
}) {
  const [startTime, _] = useState(new Date());
  const [timer, setTimer] = useState(60);

  useEffect(() => {
    const timeout = setTimeout(() => {
      const date = new Date();
      const timeDiff = Math.floor(
        (date.getTime() - startTime.getTime()) / 1000
      );
      if (60 - timeDiff < 1) {
        onConfirm();
      }
      setTimer(60 - timeDiff);
    }, 1000);
  }, [timer]);

  return (
    <Modal
      size="small"
      centered={true}
      open={visibility}
      onOpen={onOpenModal}
      onClose={onCloseModal}
      closeOnDimmerClick={false}
    >
      <div className={styles.modalContainer}>
        <text className={styles.headerText}>{message}</text>
        <text className={styles.subText}>Click to cancel, or send now </text>
        <text className={styles.timerText}>{timer} </text>
        <div className={styles.buttonGroup}>
          <button className={styles.nextTipButton} onClick={onConfirm}>
            Send
          </button>

          <SkipButton onClick={onCloseModal} buttonText="Cancel" />
        </div>
      </div>
    </Modal>
  );
}

SendingMessageModal.propTypes = {
  visibility: PropTypes.bool.isRequired,
  onOpenModal: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  onConfirmRemoveUser: PropTypes.func.isRequired,
};

export default SendingMessageModal;
