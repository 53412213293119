import * as React from "react";

import PropTypes from "prop-types";
import { Modal, Button, Icon } from "semantic-ui-react";
import InputComponent from "../../InputComponent";
import styles from "./DeletedPermanentlySubscriptionModal.module.css";
import { FaFileUpload } from "react-icons/fa";
import BlueButton from "../../BlueButton";

const { useState } = React;

function DeletedPermanentlySubscriptionModal({
  visibility,
  onOpenModal,
  onCloseModal,
  onConfirm,
}) {
  return (
    <Modal
      size="small"
      centered
      open={visibility}
      onOpen={onOpenModal}
      onClose={onCloseModal}
    >
      <div className={`${styles.modalContainer} flex flex-col`}>
        <Button
          className={styles.closeButton}
          onClick={onCloseModal}
          size="big"
          icon="close"
        />
        <text className={`${styles.headerText} block mb-4`}>
          This subscription is now permanently deleted
        </text>
        <text className={`${styles.subText} block mb-8`}>
          You will not be able to access this service&apos;s information on the
          QIP Portal anymore.
        </text>

        <div className={`${styles.btnRow} flex items-center justify-center`}>
          <BlueButton
            className={styles.blueButton}
            buttonText="Ok"
            onClick={onConfirm}
          />
        </div>
      </div>
    </Modal>
  );
}

DeletedPermanentlySubscriptionModal.propTypes = {
  visibility: PropTypes.bool.isRequired,
  onOpenModal: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
};

export default DeletedPermanentlySubscriptionModal;
