import * as React from "react";
import { useHistory } from "react-router-dom";
import { FaCommentDots, FaArrowAltCircleLeft } from "react-icons/fa";
import InputComponent from "../../../../components/InputComponent";
import BlueButton from "../../../../components/BlueButton";
import ExistingEmailModal from "../../../../components/Modals/ExistingEmailModal";
import UserCreateNewPasswordModal from "../../../../components/Modals/UserCreateNewPasswordModal";
import SuccessChangedPasswordModal from "../../../../components/Modals/SuccessChangedPasswordModal";

import styles from "./UpdateUser.module.css";

const { useState } = React;

function UpdateUser() {
  const history = useHistory();
  const [existingEmailModal, setExistingEmailModal] = useState(false);
  const [userCreateNewPasswordModal, setUserCreateNewPasswordModal] =
    useState(false);
  const [successChangedPasswordModal, setSuccessChangedPasswordModal] =
    useState(false);

  const onChangedPassword = async () => {
    setSuccessChangedPasswordModal(true);
    setUserCreateNewPasswordModal(false);
  };

  return (
    <div className={`${styles.mainContainer} mt-5 mb-10	`}>
      {userCreateNewPasswordModal && (
        <UserCreateNewPasswordModal
          visibility={userCreateNewPasswordModal}
          onOpenModal={() => setUserCreateNewPasswordModal(true)}
          onCloseModal={() => setUserCreateNewPasswordModal(false)}
          onChangedPassword={onChangedPassword}
        />
      )}
      {successChangedPasswordModal && (
        <SuccessChangedPasswordModal
          visibility={successChangedPasswordModal}
          onOpenModal={() => setSuccessChangedPasswordModal(true)}
          onCloseModal={() => setSuccessChangedPasswordModal(false)}
        />
      )}
      {existingEmailModal && (
        <ExistingEmailModal
          visibility={existingEmailModal}
          onOpenModal={() => setExistingEmailModal(true)}
          onCloseModal={() => setExistingEmailModal(false)}
        />
      )}
      <div className={`flex items-center justify-between`}>
        <div className={`flex`}>
          <div className={styles.headerTextIcon}>
            <FaCommentDots size={55} />
          </div>

          <div className={`${styles.headerText} ml-4`}>
            <h1>Admin users / Update a user</h1>
            <text>Add a user to your team and assign them to a Centre</text>
          </div>
        </div>

        <button
          className={`${styles.backButtonText} flex items-center text-base font-medium py-3 px-5`}
          onClick={() => history.goBack()}
        >
          <FaArrowAltCircleLeft className={`mr-1`} />
          Back
        </button>
      </div>

      <div className={`${styles.formContainer} px-10 py-16 mt-9`}>
        <div className={styles.formContent}>
          <h2 className={`text-center`}>Update user details</h2>

          <div className={`${styles.inputRow} flex flex-wrap mt-9`}>
            <InputComponent
              fieldName="userEmail"
              label="User’s email address"
              type="email"
              required
            />

            <InputComponent
              fieldName="firstName"
              label="First name"
              type="text"
              required
            />

            <InputComponent
              fieldName="lastName"
              label="Last name"
              type="text"
              required
            />
          </div>

          <div className={`flex flex-wrap justify-center items-center mt-6`}>
            <BlueButton
              className={styles.blueButton}
              onClick={() => setExistingEmailModal(true)}
              buttonText="Save changes"
            />

            <button
              className={`${styles.greenButton} ml-6`}
              onClick={() => setUserCreateNewPasswordModal(true)}
            >
              New password
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UpdateUser;
