import * as React from "react";

import PropTypes from "prop-types";
import { Modal, Button, Icon } from "semantic-ui-react";
import InputComponent from "../../InputComponent";
import styles from "./InvoiceNumberFilterModal.module.css";
import { FaFileUpload } from "react-icons/fa";

const { useState } = React;

function InvoiceNumberFilterModal({
  visibility,
  onOpenModal,
  onCloseModal,
  currentKey,
  setSearch,
}) {
  const [key, setKey] = useState(currentKey);

  const onKeyFilter = () => {
    setSearch(key);
    onCloseModal();
  };

  const onClearKey = () => {
    setKey("");
    setSearch("");
    onCloseModal();
  };

  return (
    <Modal
      size="small"
      centered
      open={visibility}
      onOpen={onOpenModal}
      onClose={onCloseModal}
    >
      <div className={`${styles.modalContainer} flex flex-col`}>
        <Button
          className={styles.closeButton}
          onClick={onCloseModal}
          size="big"
          icon="close"
        />
        <text className={`${styles.headerText} block mb-7`}>
          Find an invoice by number
        </text>

        <div className={`${styles.inputContainer}`}>
          <InputComponent
            fieldName="searchKey"
            type="text"
            placeholder="Type the invoice number here"
            inputValue={key}
            setInputValue={(_, value) => {
              setKey(value);
            }}
          />

          <button onClick={onKeyFilter} className={`${styles.btnGreen}`}>
            Find invoice
          </button>

          <div className={styles.clearSection}>
            <button onClick={onClearKey} className={styles.clearSectionButton}>
              Clear selection
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

InvoiceNumberFilterModal.propTypes = {
  visibility: PropTypes.bool.isRequired,
  onOpenModal: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
};

export default InvoiceNumberFilterModal;
