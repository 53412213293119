import * as React from "react";

import PropTypes from "prop-types";
import { Button, Modal } from "semantic-ui-react";

import styles from "./QuestionModal.module.css";
import SkipButton from "../../SkipButton";
import useSWR, { mutate } from "swr";
import { fetchNQAQuestions } from "../../../api/requests";
import { useStoreActions, useStoreState } from "../../../hooks";
import { isEmpty } from "lodash";
import spinner from "../../../static/images/preloader.png";

const { useState, useEffect, useCallback } = React;

function QuestionModal({ visibility, nqaData, onOpenModal, onCloseModal }) {
  const [currentFilter, setCurrentFilter] = useState(1);
  const [subCurrentFilter, setSubCurrentFilter] = useState("1.1.1");
  const [fetchLimitError, setFetchLimitError] = useState(false);

  const selectedNQA = useStoreState((state) => state.nqaStore.selectedNQA);
  const setSelectedNQA = useStoreActions(
    (actions) => actions.nqaStore.setSelectedNQA
  );

  const [centerId, setCenterId] = useState(1);

  const getSublevels = (filter) => {
    let sublevels = [];
    let id = 0;
    if (nqaData) {
      nqaData[filter - 1].levels.forEach((level) => {
        id = id + 1;
        // sublevels.push({ label: level.name, id: id });
        level.subLevels.forEach((sub) => {
          id = id + 1;
          sublevels.push({ label: sub.label, id: id });
        });
      });
    }
    return sublevels;
  };

  const { data: questionItemsEmbedded, isValidating: isValidating1 } = useSWR(
    ["/questions", "embedded", subCurrentFilter],
    (_, selectedQuestionType, subCurrentFilter) =>
      fetchNQAQuestions(
        centerId,
        selectedQuestionType,
        selectedNQA.levels[parseInt(subCurrentFilter.split(".")[1]) - 1]
          .subLevels[parseInt(subCurrentFilter.split(".")[2]) - 1]
      ),

    {
      onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
        // Only retry up to 3 times.
        if (retryCount && retryCount >= 3) {
          if (!questionItemsEmbedded) setFetchLimitError(true);

          return;
        }
        // Retry after 3 seconds.
        setTimeout(() => revalidate({ retryCount: retryCount + 1 }), 3000);
      },
    }
  );

  const { data: questionItemsCritical, isValidating: isValidating2 } = useSWR(
    ["/questions", "critical_reflections", subCurrentFilter],
    (_, selectedQuestionType, subCurrentFilter) =>
      fetchNQAQuestions(
        centerId,
        selectedQuestionType,
        selectedNQA.levels[parseInt(subCurrentFilter.split(".")[1]) - 1]
          .subLevels[parseInt(subCurrentFilter.split(".")[2]) - 1]
      ),

    {
      onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
        // Only retry up to 3 times.
        if (retryCount && retryCount >= 3) {
          if (!questionItemsCritical) setFetchLimitError(true);

          return;
        }
        // Retry after 3 seconds.
        setTimeout(() => revalidate({ retryCount: retryCount + 1 }), 3000);
      },
    }
  );

  const {
    data: questionItemsPartnerships,
    isValidating: isValidating3,
  } = useSWR(
    ["/questions", "partnerships_with_family", subCurrentFilter],
    (_, selectedQuestionType, subCurrentFilter) =>
      fetchNQAQuestions(
        centerId,
        selectedQuestionType,
        selectedNQA.levels[parseInt(subCurrentFilter.split(".")[1]) - 1]
          .subLevels[parseInt(subCurrentFilter.split(".")[2]) - 1]
      ),

    {
      onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
        // Only retry up to 3 times.
        if (retryCount && retryCount >= 3) {
          if (!questionItemsPartnerships) setFetchLimitError(true);

          return;
        }
        // Retry after 3 seconds.
        setTimeout(() => revalidate({ retryCount: retryCount + 1 }), 3000);
      },
    }
  );

  return (
    <Modal
      size="large"
      centered={true}
      open={visibility}
      onOpen={onOpenModal}
      onClose={onCloseModal}
      closeOnDimmerClick={false}
    >
      <div className={`${styles.modalContainer} flex flex-col`}>
        <Button
          className={styles.closeButton}
          onClick={onCloseModal}
          size="big"
          icon="close"
        />

        <text className={styles.headerText}>
          Link this resource to NQA questions
        </text>
        <text className={styles.subheaderText}>
          This resource will be shown next to the questions you select here.
        </text>
        <div className={styles.resourcesContent}>
          <div className={`${styles.resourceFilterRow} flex flex-wrap pb-2.5`}>
            <div
              className={`${
                currentFilter === 1
                  ? styles.resourceFilterSelected
                  : styles.resourceFilter
              }  flex items-center justify-center`}
              onClick={() => {
                if (!(isValidating1 || isValidating2 || isValidating3)) {
                  setSubCurrentFilter("1.1.1");
                  setSelectedNQA(1);
                  setCurrentFilter(1);

                  mutate(["/questions", "embedded", subCurrentFilter]);
                  mutate([
                    "/questions",
                    "critical_reflections",
                    subCurrentFilter,
                  ]);
                  mutate([
                    "/questions",
                    "partnerships_with_family",
                    subCurrentFilter,
                  ]);
                }
              }}
            >
              NQA 1
            </div>
            <div
              className={`${
                currentFilter === 2
                  ? styles.resourceFilterSelected
                  : styles.resourceFilter
              }  flex items-center justify-center`}
              onClick={() => {
                if (!(isValidating1 || isValidating2 || isValidating3)) {
                  setSubCurrentFilter("2.1.1");
                  setSelectedNQA(2);
                  setCurrentFilter(2);

                  mutate(["/questions", "embedded", subCurrentFilter]);
                  mutate([
                    "/questions",
                    "critical_reflections",
                    subCurrentFilter,
                  ]);
                  mutate([
                    "/questions",
                    "partnerships_with_family",
                    subCurrentFilter,
                  ]);
                }
              }}
            >
              NQA 2
            </div>
            <div
              className={`${
                currentFilter === 3
                  ? styles.resourceFilterSelected
                  : styles.resourceFilter
              }  flex items-center justify-center`}
              onClick={() => {
                if (!(isValidating1 || isValidating2 || isValidating3)) {
                  setSubCurrentFilter("3.1.1");
                  setSelectedNQA(3);
                  setCurrentFilter(3);

                  mutate(["/questions", "embedded", subCurrentFilter]);
                  mutate([
                    "/questions",
                    "critical_reflections",
                    subCurrentFilter,
                  ]);
                  mutate([
                    "/questions",
                    "partnerships_with_family",
                    subCurrentFilter,
                  ]);
                }
              }}
            >
              NQA 3
            </div>
            <div
              className={`${
                currentFilter === 4
                  ? styles.resourceFilterSelected
                  : styles.resourceFilter
              }  flex items-center justify-center`}
              onClick={() => {
                if (!(isValidating1 || isValidating2 || isValidating3)) {
                  setSubCurrentFilter("4.1.1");
                  setSelectedNQA(4);
                  setCurrentFilter(4);

                  mutate(["/questions", "embedded", subCurrentFilter]);
                  mutate([
                    "/questions",
                    "critical_reflections",
                    subCurrentFilter,
                  ]);
                  mutate([
                    "/questions",
                    "partnerships_with_family",
                    subCurrentFilter,
                  ]);
                }
              }}
            >
              NQA 4
            </div>
            <div
              className={`${
                currentFilter === 5
                  ? styles.resourceFilterSelected
                  : styles.resourceFilter
              }  flex items-center justify-center`}
              onClick={() => {
                if (!(isValidating1 || isValidating2 || isValidating3)) {
                  setSubCurrentFilter("5.1.1");
                  setSelectedNQA(5);
                  setCurrentFilter(5);

                  mutate(["/questions", "embedded", subCurrentFilter]);
                  mutate([
                    "/questions",
                    "critical_reflections",
                    subCurrentFilter,
                  ]);
                  mutate([
                    "/questions",
                    "partnerships_with_family",
                    subCurrentFilter,
                  ]);
                }
              }}
            >
              NQA 5
            </div>
            <div
              className={`${
                currentFilter === 6
                  ? styles.resourceFilterSelected
                  : styles.resourceFilter
              }  flex items-center justify-center`}
              onClick={() => {
                if (!(isValidating1 || isValidating2 || isValidating3)) {
                  setSubCurrentFilter("6.1.1");
                  setSelectedNQA(6);
                  setCurrentFilter(6);

                  mutate(["/questions", "embedded", subCurrentFilter]);
                  mutate([
                    "/questions",
                    "critical_reflections",
                    subCurrentFilter,
                  ]);
                  mutate([
                    "/questions",
                    "partnerships_with_family",
                    subCurrentFilter,
                  ]);
                }
              }}
            >
              NQA 6
            </div>
            <div
              className={`${
                currentFilter === 7
                  ? styles.resourceFilterSelected
                  : styles.resourceFilter
              }  flex items-center justify-center`}
              onClick={() => {
                if (!(isValidating1 || isValidating2 || isValidating3)) {
                  setSubCurrentFilter("7.1.1");
                  setSelectedNQA(7);
                  setCurrentFilter(7);

                  mutate(["/questions", "embedded", subCurrentFilter]);
                  mutate([
                    "/questions",
                    "critical_reflections",
                    subCurrentFilter,
                  ]);
                  mutate([
                    "/questions",
                    "partnerships_with_family",
                    subCurrentFilter,
                  ]);
                }
              }}
            >
              NQA 7
            </div>
          </div>
          {console.log("qitems emb ", questionItemsEmbedded)}
          <div
            className={`${styles.subResourcesFilterRow} ${
              styles[`nqa_${currentFilter}`]
            } flex flex-wrap`}
          >
            {!isEmpty(getSublevels(currentFilter)) &&
              getSublevels(currentFilter).map((sub, k) => {
                return (
                  <div
                    className={`mb-2 ${
                      subCurrentFilter === sub.label
                        ? styles.subResourcesFilterSelected
                        : styles.subResourcesFilter
                    } flex items-center justify-center`}
                    onClick={() => {
                      if (!(isValidating1 || isValidating2 || isValidating3)) {
                        setSubCurrentFilter(sub.label);

                        mutate(["/questions", "embedded", subCurrentFilter]);
                        mutate([
                          "/questions",
                          "critical_reflections",
                          subCurrentFilter,
                        ]);
                        mutate([
                          "/questions",
                          "partnerships_with_family",
                          subCurrentFilter,
                        ]);
                      }
                    }}
                  >
                    NQA{sub.label}
                  </div>
                );
              })}
          </div>

          {!(
            questionItemsEmbedded &&
            questionItemsCritical &&
            questionItemsPartnerships
          ) ? (
            <div
              className={`flex justify-center items-center w-full h-40 py-12 mt-20`}
            >
              <img src={spinner} />
            </div>
          ) : (
            <div className={`${styles.questionsContainer} mt-14`}>
              <div className={`${styles.questionsCategories}`}>
                <text className={`${styles.questionsCategoryText}`}>
                  {questionItemsEmbedded &&
                    `${questionItemsEmbedded.length} Embedded questions`}
                </text>
                {questionItemsEmbedded &&
                  questionItemsEmbedded.map((q, k) => {
                    return (
                      <div className={`${styles.question}`}>
                        <div className={`${styles.checkbox} mr-6`}>
                          <label>
                            <input type="checkbox" />
                          </label>
                        </div>

                        <text>{q.description}</text>
                      </div>
                    );
                  })}
              </div>

              <div className={`${styles.questionsCategories}`}>
                <text className={`${styles.questionsCategoryText}`}>
                  {questionItemsCritical &&
                    `${questionItemsCritical.length} Critical Reflection questions`}
                </text>
                {questionItemsCritical &&
                  questionItemsCritical.map((q, k) => {
                    return (
                      <div className={`${styles.question}`}>
                        <div className={`${styles.checkbox} mr-6`}>
                          <label>
                            <input type="checkbox" />
                          </label>
                        </div>

                        <text>{q.description}</text>
                      </div>
                    );
                  })}
              </div>

              <div className={`${styles.questionsCategories}`}>
                <text className={`${styles.questionsCategoryText}`}>
                  {questionItemsPartnerships &&
                    `${questionItemsPartnerships.length} Partnerships with Families questions`}
                </text>
                {questionItemsPartnerships &&
                  questionItemsPartnerships.map((q, k) => {
                    return (
                      <div className={`${styles.question}`}>
                        <div className={`${styles.checkbox} mr-6`}>
                          <label>
                            <input type="checkbox" />
                          </label>
                        </div>

                        <text>{q.description}</text>
                      </div>
                    );
                  })}
              </div>
            </div>
          )}

          {questionItemsEmbedded &&
            questionItemsCritical &&
            questionItemsPartnerships && (
              <div
                className={`${styles.questionsButton} flex items-center justify-center mt-9`}
              >
                <button
                  className={`${styles.saveButton} flex items-center justify-center mr-4`}
                >
                  Save selection
                </button>

                <button
                  className={`${styles.clearButton} flex items-center justify-center`}
                >
                  Clear selection
                </button>
              </div>
            )}
        </div>
      </div>
    </Modal>
  );
}

QuestionModal.propTypes = {
  visibility: PropTypes.bool.isRequired,
  onOpenModal: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
};

export default QuestionModal;
