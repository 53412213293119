import * as React from "react";

import PropTypes from "prop-types";
import { Icon } from "semantic-ui-react";
import { Link, useHistory, useRouteMatch } from "react-router-dom";

import styles from "./AdditionalInformation.module.css";
import BlueButton from "../../../components/BlueButton";
import InputComponent from "../../../components/InputComponent";
import { ServiceDetailsContext } from "../../../context/ServiceDetailsContext";
import { useStoreState } from "../../../hooks";

import { ServiceSteps } from "../../../components/Steps";

import { FaSpinner } from "react-icons/fa";

const { useContext, useEffect, useState } = React;

const stepsNotDone = [
  {
    id: 9,
    stepsName: "Educators",
  },
  {
    id: 10,
    stepsName: "QIP submission",
  },
  {
    id: 11,
    stepsName: "Philosophy",
  },
  {
    id: 12,
    stepsName: "Upload logo",
  },
];

const stepsDone = [
  {
    id: 1,
    stepsName: "Location",
  },
  {
    id: 2,
    stepsName: "Postal Address",
  },
  {
    id: 3,
    stepsName: "Contact Details",
  },
  {
    id: 4,
    stepsName: "Providers",
  },
  {
    id: 5,
    stepsName: "Supervisor",
  },
  {
    id: 6,
    stepsName: "Leader",
  },
  {
    id: 7,
    stepsName: "Operating hours",
  },
];

function AdditionalInformation({
  onNextStep,
  onSaveStep,
  onSkipServiceDetails,
}) {
  const history = useHistory();
  const match = useRouteMatch();

  const {
    saveLoading,
    serviceDetailsState,
    changeInputDispatch,
    stepsIndicator,
  } = useContext(ServiceDetailsContext);

  const isLoggedIn = useStoreState((state) => state.authStore.isLoggedIn);

  const [fieldChecked, setFieldChecked] = useState(false);

  const [errors, setErrors] = useState([]);

  const [initialRooms, setInitialRooms] = useState(
    serviceDetailsState.input.rooms != undefined
      ? serviceDetailsState.input.rooms.length > 0
        ? serviceDetailsState.input.rooms
        : []
      : []
  );

  const [rooms, setRooms] = useState(
    serviceDetailsState.input.rooms != undefined
      ? serviceDetailsState.input.rooms.length > 0
        ? serviceDetailsState.input.rooms.length
        : 0
      : 0
  );

  const setChildrenAgeGroup = (index, ageInput, value) => {
    setErrors([]);
    const currentRooms = serviceDetailsState.input.rooms
      ? [...serviceDetailsState.input.rooms]
      : [
          {
            roomNumber: index,
            toAge: 0,
            fromAge: 0,
          },
        ];

    if (currentRooms.find((room) => room.roomNumber === index)) {
      currentRooms[currentRooms.findIndex((room) => room.roomNumber === index)][
        ageInput
      ] = value;
    } else {
      const roomObject = {
        roomNumber: index,
        toAge: 0,
        fromAge: 0,
      };

      roomObject[ageInput] = value;
      currentRooms.push(roomObject);
    }

    changeInputDispatch("rooms", currentRooms);
  };

  const onSave = async () => {
    setFieldChecked(true);

    if (!(!isNaN(rooms) && rooms > 0 && errors.length <= 0)) {
      return;
    }
    await onSaveStep();

    onNextStep();
    history.push(
      `${match.path
        .split("/")
        .slice(0, match.path.split("/").length - 1)
        .join("/")}/9`
    );
    // } else alert("Please fill all required fields.");
  };

  const AgeGroupValidation = (index, newAddition = false) => {
    let message = "";

    if (rooms != undefined && !isNaN(rooms) && rooms > 0) {
      if (newAddition) {
        message = "These fields are required.";
      } else {
        if (
          serviceDetailsState.input.rooms[index].toAge > 0 ||
          serviceDetailsState.input.rooms[index].fromAge > 0
        ) {
          if (
            serviceDetailsState.input.rooms[index].toAge <
            serviceDetailsState.input.rooms[index].fromAge
          ) {
            message = "Invalid Age Range";
          }
        } else {
          message = "These fields are required";
        }
      }
    }
    if (message.length > 0) {
      if (!errors.includes(index)) {
        setErrors([...errors, index]);
      }
    }
    return message;
  };

  useEffect(() => {
    if (rooms < 0) setRooms(0);
  }, [rooms]);

  return (
    <div className={styles.mainContainer}>
      {isLoggedIn && (
        <>
          <div className={styles.headerContainer}>
            <h1>Complete your service details</h1>
            <p>
              Please fill out all missing fields marked with an ‘
              <span className={styles.required}>*</span>’
            </p>
          </div>
        </>
      )}
      <div className={styles.mainCard}>
        <div className={styles.cardContent}>
          <h1 className={styles.headerText}>Additional information</h1>

          <text className={styles.pleaseComplete}>
            Please complete the below fields about your service.
          </text>

          <InputComponent
            className={styles.additionalInfo}
            textArea
            fieldName="additionalInfo"
            label="Additional information about your service"
            inputValue={serviceDetailsState.input.additionalInfo}
            setInputValue={changeInputDispatch}
          />

          <text className={styles.headerText}>How are children grouped?</text>

          <text className={styles.descriptionText}>
            How many rooms are available at your center?
          </text>

          <input
            className={styles.roomsInput}
            type="number"
            min={0}
            value={rooms}
            onChange={(e) => {
              setErrors([]);
              if (!isNaN(e.target.value)) {
                changeInputDispatch(
                  "rooms",
                  initialRooms.slice(0, e.target.value)
                );
              }
              setRooms(e.target.value);
            }}
          />
          {fieldChecked && !(!isNaN(rooms) && rooms > 0) > 0 && (
            <div className={`p-2 text-red-400 font-thin`}>
              Please enter number of rooms available at your center.
            </div>
          )}

          <div className={styles.roomEntryGroup}>
            {rooms != undefined && !isNaN(rooms) && rooms > 0
              ? Array.from({ length: rooms }, (v, index) => (
                  <>
                    <div className={styles.roomEntry} key={index}>
                      <text className={styles.groupChildrenTitle}>
                        Room {index + 1} children group is from ageGroup
                        {index === 0 && (
                          <span className={styles.required}>*</span>
                        )}
                      </text>
                      <div className={`flex flex-col items-center`}>
                        <div className={styles.ageGroup}>
                          <input
                            className={styles.ageInput}
                            type="number"
                            min={0}
                            value={
                              serviceDetailsState.input.rooms &&
                              serviceDetailsState.input.rooms[index]
                                ? serviceDetailsState.input.rooms[index].fromAge
                                : 0
                            }
                            onChange={(e) =>
                              setChildrenAgeGroup(
                                index + 1,
                                "fromAge",
                                parseInt(e.target.value)
                              )
                            }
                          />

                          <text>to</text>

                          <input
                            className={styles.ageInput}
                            type="number"
                            min={0}
                            value={
                              serviceDetailsState.input.rooms &&
                              serviceDetailsState.input.rooms[index]
                                ? serviceDetailsState.input.rooms[index].toAge
                                : 0
                            }
                            onChange={(e) =>
                              setChildrenAgeGroup(
                                index + 1,
                                "toAge",
                                parseInt(e.target.value)
                              )
                            }
                          />
                        </div>
                        <div>
                          <div className={`p-2 text-red-400 font-thin`}>
                            {AgeGroupValidation(
                              index,
                              serviceDetailsState.input.rooms &&
                                !serviceDetailsState.input.rooms[index]
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ))
              : null}
          </div>

          <div className={styles.buttonHolders}>
            <BlueButton // buttonText="Save step"
              buttonText={
                saveLoading ? (
                  <div className={`flex justify-center items-center`}>
                    <FaSpinner className={`animate-spin`} />
                    <span className={`block pl-2`}>Save step</span>
                  </div>
                ) : (
                  <> Save step</>
                )
              }
              onClick={onSave}
            />

            <button
              className={styles.buttonNextStep}
              onClick={() =>
                history.push(
                  `${match.path
                    .split("/")
                    .slice(0, match.path.split("/").length - 1)
                    .join("/")}/9`
                )
              }
            >
              Next step
            </button>
          </div>

          <div className={styles.buttonStep}>
            <button
              className={styles.buttonStepBack}
              onClick={() =>
                history.push(
                  `${match.path
                    .split("/")
                    .slice(0, match.path.split("/").length - 1)
                    .join("/")}/7`
                )
              }
            >
              <Icon name="angle left" />
              Back a step
            </button>

            <button
              className={styles.buttonFinishLater}
              onClick={onSkipServiceDetails}
            >
              Finish Later
              <Icon name="angle double right" />
            </button>
          </div>
        </div>

        <div className={styles.stepsColumn}>
          {/* {!isLoggedIn && (
            <div
              className={`${styles.stepItemContainer} ${styles.stepItemTickContainer}`}
              onClick={() => history.push(`/qip/auth/legal-notice`)}
            >
              <div className={styles.stepContainerText}>
                <div className={styles.stepItemIndicatorContainer}>
                  <div className={styles.stepItemFillDone} />
                </div>
                Welcome
              </div>
            </div>
          )} */}

          <ServiceSteps route={match.path} />
          {/* {stepsDone.map(steps => (
            <div
              key={steps.id}
              className={stepsIndicator.find(step => step.id === steps.id).completed ? `${styles.stepItemContainer} ${styles.stepItemTickContainer}` : `${styles.stepItemContainer} ${styles.stepItemCrossContainer}`}
              onClick={() => history.push(`${match.path.split('/').slice(0, match.path.split('/').length - 1).join('/')}/${steps.id}`)}
            >

              <div
                className={styles.stepContainerText}
              >
                <div className={styles.stepItemIndicatorContainer}>
                  {stepsIndicator.find(step => step.id === steps.id).completed
                    ? <div className={styles.stepItemFillDone} />
                    : (
                      <Icon
                        className={styles.stepItemUnfinished}
                        name="close"
                      />
                    )}
                </div>
                {steps.stepsName}
              </div>
            </div>
          ))}

          <div className={styles.CurrentItemContainer}>

            <div className={styles.stepItemCurrent}>
              <div className={styles.stepItemFillDone} />
            </div>

            <text className={styles.stepItemCurrentText}>
              Additional info
            </text>

          </div>

          {stepsNotDone.map(steps => (
            <div
              key={steps.id}
              className={styles.stepItemContainer}
            >
              <Link
                className={styles.stepItemCurrentText}
                to={`${match.path.split('/').slice(0, match.path.split('/').length - 1).join('/')}/${steps.id}`}
              >
                <div className={styles.stepItemIndicatorContainer}>
                  <div className={styles.stepItemFill} />
                </div>
                {steps.stepsName}
              </Link>
            </div>
          ))} */}
        </div>
      </div>
    </div>
  );
}

AdditionalInformation.propTypes = {
  onNextStep: PropTypes.func,
  onSkipStep: PropTypes.func,
  onSaveStep: PropTypes.func,
  onSkipServiceDetails: PropTypes.func,
};

export default AdditionalInformation;
