import * as React from "react";

import PropTypes from "prop-types";
import { Modal, Button, Icon } from "semantic-ui-react";
import InputComponent from "../../InputComponent";
import styles from "./RestoredSubscriptionModal.module.css";
import { FaFileUpload } from "react-icons/fa";
import BlueButton from "../../BlueButton";
import spinner from "../../../static/images/preloader.png";

const { useState } = React;

function RestoredSubscriptionModal({
  visibility,
  onOpenModal,
  onCloseModal,
  onConfirm,
  loading = false,
}) {
  return (
    <Modal
      size="small"
      centered
      open={visibility}
      onOpen={onOpenModal}
      onClose={onCloseModal}
    >
      <div className={`${styles.modalContainer} flex flex-col`}>
        {loading ? (
          <div className={`flex flex-col justify-center items-center`}>
            <img src={spinner} className={`block`} />

            <div>Restoring subscription...</div>
          </div>
        ) : (
          <>
            <Button
              className={styles.closeButton}
              onClick={onCloseModal}
              size="big"
              icon="close"
            />
            <text className={`${styles.headerText} block mb-4`}>
              This subscription will now be restored
            </text>
            <text className={`${styles.subText} block mb-8`}>
              This subscription will be visible again in the Active tab and all
              the information restored.
            </text>

            <div
              className={`${styles.btnRow} flex items-center justify-center`}
            >
              <BlueButton
                className={styles.blueButton}
                buttonText="Ok"
                onClick={onConfirm}
              />
            </div>
          </>
        )}
      </div>
    </Modal>
  );
}

RestoredSubscriptionModal.propTypes = {
  visibility: PropTypes.bool.isRequired,
  onOpenModal: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
};

export default RestoredSubscriptionModal;
