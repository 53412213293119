import * as React from "react";

import PropTypes from "prop-types";
import { Modal, Button, Icon } from "semantic-ui-react";
import InputComponent from "../../InputComponent";
import styles from "./ChooseServiceFilterModal.module.css";
import { FaFileUpload } from "react-icons/fa";
import { isEmpty } from "lodash";

const { useState } = React;

function ChooseServiceFilterModal({
  visibility,
  onOpenModal,
  onCloseModal,
  centers,
  selectedCenters,
  setCenters,
}) {
  const [checkedCenters, setCheckedCenters] = useState([...selectedCenters]);

  const [searchKey, setSearchKey] = useState("");

  const onFilterCenters = (center) => {
    if (!checkedCenters.includes(center)) {
      setCheckedCenters([...checkedCenters, center]);
    } else {
      if (!isEmpty(checkedCenters)) {
        setCheckedCenters([...checkedCenters.filter((c) => c !== center)]);
      }
    }
  };

  return (
    <Modal
      size="small"
      centered
      open={visibility}
      onOpen={onOpenModal}
      onClose={onCloseModal}
    >
      <div className={`${styles.modalContainer} flex flex-col`}>
        <Button
          className={styles.closeButton}
          onClick={onCloseModal}
          size="big"
          icon="close"
        />
        {console.log("selectd c ", selectedCenters)}
        <text className={`${styles.headerText} block mb-7`}>
          Choose a service to filter by
        </text>

        <div className={`${styles.modalContent}`}>
          <div className={`${styles.servicesList}`}>
            <div className={styles.inputGroup}>
              <input
                className={styles.inputBox}
                placeholder={`Type the service name here`}
                value={searchKey}
                onChange={(e) => setSearchKey(e.target.value)}
              />
              {/* <InputComponent
              fieldName="searchKey"
              type="text"
              placeholder="Type the service name here"
              inputValue={searchKey}
              setInputValue={(_, value) => {
                setSearchKey(value);
              }}
            /> */}
            </div>
            <ul>
              {!isEmpty(centers) &&
                centers
                  .filter((center) => {
                    if (searchKey) {
                      return center.includes(searchKey);
                    } else {
                      return true;
                    }
                  })
                  .map((center) => {
                    return (
                      <li
                        onClick={() => {
                          onFilterCenters(center);
                        }}
                        className={`${
                          checkedCenters.includes(center) ? styles.selected : ""
                        }`}
                      >
                        {center}
                        <label className={`${styles.checkbox}`}>
                          <span className={`${styles.checkmark}`}></span>
                        </label>
                      </li>
                    );
                  })}
            </ul>
          </div>

          <div className={`${styles.actionBtn}`}>
            <button
              onClick={() => {
                setCenters(checkedCenters);
                onCloseModal();
              }}
            >
              Filter invoices
            </button>
          </div>

          <div className={styles.clearSection}>
            <button
              onClick={() => {
                setCenters([]);
                onCloseModal();
              }}
              className={styles.clearSectionButton}
            >
              Clear selection
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

ChooseServiceFilterModal.propTypes = {
  visibility: PropTypes.bool.isRequired,
  onOpenModal: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
};

export default ChooseServiceFilterModal;
