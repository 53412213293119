import * as React from "react";
import { useHistory } from "react-router-dom";
import { FaCommentDots, FaArrowAltCircleLeft } from "react-icons/fa";
import InputComponent from "../../../../components/InputComponent";
import BlueButton from "../../../../components/BlueButton";
import CreateUserModal from "../../../../components/Modals/CreateUserModal";
import { createAdmin } from "../../../../api/requests";
import { FaSpinner } from "react-icons/fa";

import styles from "./CreateUser.module.css";

const { useState } = React;

function CreateUser() {
  const history = useHistory();
  const [createUserModal, setCreateUserModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fieldsChecked, setFieldsChecked] = useState(false);
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const onCreateAdmin = async (role = "CLIENT_ADMIN") => {
    setFieldsChecked(true);
    if (!(email && firstName && lastName)) {
      return;
    }

    try {
      setLoading(true);
      const response = await createAdmin({
        email,
        name: firstName + process.env("SEP") + lastName,
        role,
      });
      setLoading(false);
      setCreateUserModal(true);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  return (
    <div className={`${styles.mainContainer} mt-5 mb-10	`}>
      {createUserModal && (
        <CreateUserModal
          visibility={createUserModal}
          onOpenModal={() => setCreateUserModal(true)}
          onCloseModal={() => setCreateUserModal(false)}
        />
      )}
      <div className={`flex items-center justify-between`}>
        <div className={`flex`}>
          <div className={styles.headerTextIcon}>
            <FaCommentDots size={55} />
          </div>

          <div className={`${styles.headerText} ml-4`}>
            <h1>Admin users / Create a user</h1>
            <text>Add a user to your team and assign them to a Centre</text>
          </div>
        </div>

        <button
          className={`${styles.backButtonText} flex items-center text-base font-medium py-3 px-5`}
          onClick={() => history.goBack()}
        >
          <FaArrowAltCircleLeft className={`mr-1`} />
          Back
        </button>
      </div>

      <div className={`${styles.formContainer} px-10 py-16 mt-9`}>
        <div className={styles.formContent}>
          <h2 className={`text-center`}>Fill out the user details</h2>

          <div className={`${styles.inputRow} flex flex-wrap mt-9`}>
            <InputComponent
              fieldName="userEmail"
              label="User’s email address"
              type="email"
              inputValue={email}
              setInputValue={(_, value) => {
                setEmail(value);
              }}
              required
              showNotFilled={fieldsChecked && !email}
            />

            <InputComponent
              fieldName="firstName"
              label="First name"
              type="text"
              inputValue={firstName}
              setInputValue={(_, value) => {
                setFirstName(value);
              }}
              required
              showNotFilled={fieldsChecked && !firstName}
            />

            <InputComponent
              fieldName="lastName"
              label="Last name"
              type="text"
              inputValue={lastName}
              setInputValue={(_, value) => {
                setLastName(value);
              }}
              required
              showNotFilled={fieldsChecked && !lastName}
            />
          </div>

          <BlueButton
            disabled={loading}
            className={`${styles.blueButton} text-center mt-6`}
            onClick={() => onCreateAdmin("CLIENT_ADMIN")}
            buttonText={
              loading ? (
                <div className={`flex justify-center items-center`}>
                  {" "}
                  <FaSpinner className={`animate-spin`} />{" "}
                  <span className={`ml-2`}>Creating...</span>
                </div>
              ) : (
                <> Create user</>
              )
            }
          />
        </div>
      </div>
    </div>
  );
}

export default CreateUser;
