import * as React from "react";

import PropTypes from "prop-types";
import { FaRegArrowAltCircleLeft } from "react-icons/fa";
import { useHistory, useRouteMatch } from "react-router-dom";
import { Dropdown } from "semantic-ui-react";

import styles from "./PaymentSignUpForm.module.css";
import spinner from "../../../static/images/preloader.png";
import { useStoreActions } from "../../../hooks";
import BlueButton from "../../../components/BlueButton";
import InputComponent from "../../../components/InputComponent";

const { useCallback, useReducer, useState } = React;

const serviceTypeOption = [
  {
    key: "1",
    value: 1,
    text: "Long Daycare",
  },
  {
    key: "2",
    value: 2,
    text: "Family Daycare",
  },
  {
    key: "3",
    value: 3,
    text: "Pre-school",
  },
  {
    key: "4",
    value: 4,
    text: "OOSH",
  },
];

const signUpReducer = (state, action) => {
  switch (action.type) {
    case "SIGN_UP": {
      const updatedInput = {
        ...state.input,
        [action.field]: action.value,
      };

      const updatedValidation = {
        ...state.validation,
        [action.field]: action.valid,
      };

      const formValidity = Object.values(updatedValidation).every(Boolean);

      return {
        input: updatedInput,
        validation: updatedValidation,
        formValidation: formValidity,
      };
    }
    default:
      return state;
  }
};

function PaymentSignUpForm({ planChoice, planSubscription }) {
  const history = useHistory();
  const match = useRouteMatch();
  const signup = useStoreActions((actions) => actions.authStore.signUp);
  const [fieldsChecked, setFieldsChecked] = useState(false);

  const [signUpLoading, setLoading] = useState(false);

  const getPrice = () => {
    if (planChoice === "portal") {
      if (planSubscription === "monthly") {
        return 97;
      } else {
        return 997;
      }
    } else {
      if (planSubscription === "monthly") {
        return 197;
      } else {
        return 1997;
      }
    }
  };

  const initialState = {
    input: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      phoneNumber: "",
      serviceName: "",
      serviceApprovalNumber: "",
      serviceCategory: "",
      price: getPrice(),
    },
    validation: {
      firstName: false,
      lastName: false,
      email: false,
      password: false,
      phoneNumber: false,
      serviceName: false,
      serviceApprovalNumber: false,
      serviceCategory: false,
    },
    formValidation: false,
  };

  const [signUpState, loginDispatch] = useReducer(signUpReducer, initialState);

  const changeInputDispatch = useCallback((field, value) => {
    let valid = false;

    if (value) valid = true;
    const action = {
      type: "SIGN_UP",
      field,
      value,
      valid,
    };
    loginDispatch(action);
  }, []);
  const PlanHeader = () => {
    let planCost = "";

    if (planChoice === "portal") {
      if (planSubscription === "monthly") {
        planCost = "97";
      } else {
        planCost = "997";
      }
    } else {
      if (planSubscription === "monthly") {
        planCost = "197";
      } else {
        planCost = "1,997";
      }
    }
    return (
      <>
        <text className={styles.cardHeader}>{planCost}</text>
        <text
          style={{
            fontSize: "20px",
            lineHeight: "62px",
            alignSelf: "flex-end",
          }}
        >
          {planSubscription === "monthly" ? "/month" : "/year"}
        </text>
      </>
    );
  };

  const onSubmit = async () => {
    setFieldsChecked(true);
    if (!signUpState.formValidation) {
      // alert("Please fill up all fields.");

      return;
    }
    console.log("signup data ", signUpState.input);
    setLoading(true);

    try {
      const signUpData = { ...signUpState.input };

      const response = await signup(signUpData);

      if (response) {
        setLoading(false);
        history.push(`/qip/auth/payment`);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <div className={styles.mainContainer}>
      <div className={styles.backButtonContainer}>
        <button
          className={styles.backButtonText}
          onClick={() => history.goBack()}
        >
          <FaRegArrowAltCircleLeft
            className={styles.backButton}
            color="#C4C4C4"
            size={24}
          />
        </button>
      </div>

      {signUpLoading ? (
        <div
          className={`w-full h-screen flex flex-col justify-center items-center`}
        >
          <img src={spinner} className={`block`} />
          <span> Registering user...</span>
        </div>
      ) : (
        <div className={styles.mainCard}>
          <div className={styles.cardContent}>
            <div className={styles.cardHeaderGroup}>
              <text
                style={{
                  fontSize: "20px",
                  position: "absolute",
                  top: "20px",
                }}
              >
                $
              </text>
              <PlanHeader />
            </div>

            <h4 className={styles.cardSubheader}>
              {planChoice === "portal" ? "QIP Portal" : "QIP + Mentoring"}
            </h4>

            <div className={styles.cardDescription}>
              <text>
                Complete your sign up to Holistic QIP and start making your QIP
                preparation and submission process easier.
              </text>
            </div>

            <div className={styles.inputRow}>
              <div className={styles.inputColumn}>
                <InputComponent
                  fieldName="firstName"
                  label="First name"
                  type="text"
                  required
                  showNotFilled={fieldsChecked}
                  inputValue={signUpState.input.firstName}
                  setInputValue={changeInputDispatch}
                />
                <InputComponent
                  fieldName="lastName"
                  label="Last name"
                  type="text"
                  required
                  showNotFilled={fieldsChecked}
                  inputValue={signUpState.input.lastName}
                  setInputValue={changeInputDispatch}
                />
              </div>
              <div className={styles.inputColumn}>
                <InputComponent
                  fieldName="email"
                  label="Email address"
                  type="email"
                  required
                  showNotFilled={fieldsChecked}
                  inputValue={signUpState.input.emailAddress}
                  setInputValue={changeInputDispatch}
                />
                <InputComponent
                  fieldName="phoneNumber"
                  label="Phone number"
                  type="text"
                  required
                  showNotFilled={fieldsChecked}
                  inputValue={signUpState.input.phoneNumber}
                  setInputValue={changeInputDispatch}
                />
              </div>
              <div className={styles.inputColumn}>
                <InputComponent
                  fieldName="serviceName"
                  label="Service name"
                  type="text"
                  required
                  showNotFilled={fieldsChecked}
                  inputValue={signUpState.input.lastName}
                  setInputValue={changeInputDispatch}
                />
                <InputComponent
                  className={styles.serviceNumberInput}
                  fieldName="serviceApprovalNumber"
                  label="Service approval number"
                  type="text"
                  required
                  showNotFilled={fieldsChecked}
                  inputValue={signUpState.input.emailAddress}
                  setInputValue={changeInputDispatch}
                />
              </div>
              <div className={styles.inputColumn}>
                <div className={styles.dropdownGroup}>
                  <text className={styles.dropdownText}>
                    Service type <span className={styles.required}>*</span>
                  </text>

                  <Dropdown
                    className={styles.dropdownBox}
                    fluid
                    selection
                    placeholder="Choose"
                    options={serviceTypeOption}
                    onChange={(e, data) =>
                      changeInputDispatch("serviceCategory", data.value)
                    }
                  />
                  {fieldsChecked && !signUpState.input.serviceCategory && (
                    <div className={`p-2 text-red-400 font-thin`}>
                      This field is required.
                    </div>
                  )}
                </div>
                <InputComponent
                  fieldName="password"
                  label="Create a password"
                  type="password"
                  required
                  showNotFilled={fieldsChecked}
                  inputValue={signUpState.input.password}
                  setInputValue={changeInputDispatch}
                />
              </div>
            </div>

            <BlueButton
              className={styles.blueButton}
              buttonText="Continue"
              onClick={onSubmit}
            />
          </div>
        </div>
      )}
    </div>
  );
}

PaymentSignUpForm.propTypes = {
  planChoice: PropTypes.string,
  planSubscription: PropTypes.string,
};

export default PaymentSignUpForm;
