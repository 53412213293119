import * as React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { useStoreState, useStoreActions } from "../../hooks";
import {
  fetchServiceDetails,
  updateServiceDetails,
  fetchUserCenters,
} from "../../api/requests";
import { FaSpinner } from "react-icons/fa";

const { useEffect, useState } = React;

export default function HubAdminRedirect({ iAmLoading }) {
  const match = useRouteMatch();

  const url = new URL(window.location.href);

  const userId = url.searchParams.get("userId");

  const [loading, setLoading] = useState(false);

  // const centerId = url.searchParams.get("centerId");
  const stripeId = url.searchParams.get("stripeId");
  const accessToken = url.searchParams.get("accessToken");
  const counter = url.searchParams.get("counter");

  const setAuthObject = useStoreActions(
    (actions) => actions.authStore.setAuthObject
  );

  const initDetails = async () => {
    iAmLoading(true);
    localStorage.clear();
    // await resetSession();
    // localStorage.setItem("selectedService", centerId);
    if (stripeId === "empty" || stripeId === "null") {
      setAuthObject({
        userId: Number(userId),
        stripeId: null,
        accessToken: accessToken,
        viewer: "viewer",
      });
    } else {
      setAuthObject({
        userId: Number(userId),
        stripeId: stripeId,
        accessToken: accessToken,
        viewer: "viewer",
      });
    }

    iAmLoading(false);
  };

  useEffect(() => {
    initDetails().then(() => {
      window.location = "/";
    });
  }, [counter]);

  return (
    <div
      className={`w-full h-screen flex flex-col justify-center items-center`}
    >
      <FaSpinner className={`animate-spin text-qipGreen font-bold text-6xl`} />

      <div>Logging in...</div>
    </div>
  );
}
