import * as React from "react";

import { useHistory, useParams } from "react-router-dom";
import { FaCommentDots, FaCaretDown } from "react-icons/fa";
import { Button } from "semantic-ui-react";
import styles from "./FeedbackRequestList.module.css";
import { listRequestFeedback } from "../../../../api/requests";
import useSWR, { mutate } from "swr";
import { isEmpty } from "lodash";
import Pagination from "../../../../components/Pagination/Pagination";
import moment from "moment";

const { useState } = React;

const feedbacksPerPage = 10;

function FeedbackRequestList() {
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState(1);

  const [fetchLimitError, setFetchLimitError] = useState(false);

  const { data: feedbacks, isValidating } = useSWR(
    ["/feedbacks"],
    () => listRequestFeedback(),
    {
      onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
        // Only retry up to 3 times.
        if (retryCount && retryCount >= 3) {
          if (!feedbacks) setFetchLimitError(true);

          return;
        }
        // Retry after 3 seconds.
        setTimeout(() => revalidate({ retryCount: retryCount + 1 }), 3000);
      },
    }
  );

  return (
    <div className={`${styles.mainContainer} mt-5 md:mb-28 lg:mb-10`}>
      <div className={`${styles.headerTextContainer} flex`}>
        <div className={styles.headerTextIcon}>
          <FaCommentDots size={55} />
        </div>

        <div className={`${styles.headerText} ml-4`}>
          <h1>Feedback Requests</h1>
          <text>View the current list, or order by column</text>
        </div>
      </div>
      {console.log("feedbacks ", feedbacks)}
      <div
        className={`${styles.feedBackRequestContainer} md:px-7 lg:px-14 pt-10 pb-16 mt-9`}
      >
        <h2>Feedback requests</h2>

        <div className={`${styles.tableContainer}`}>
          <div className={`${styles.tableHeader} flex`}>
            {/* <div className={`${styles.tableHeaderName} flex p-4`}>
              <text>First Name</text>
              <FaCaretDown className={`ml-1 mt-1`} size={12} />
            </div> */}
            <div className={`${styles.tableHeaderName} flex p-4`}>
              <text>Name</text>
              <FaCaretDown className={`ml-1 mt-1`} size={12} />
            </div>
            <div className={`${styles.tableHeaderName} flex p-4`}>
              <text>Service</text>
              <FaCaretDown className={`ml-1 mt-1`} size={12} />
            </div>
            <div className={`${styles.tableHeaderName} flex p-4`}>
              <text>NQAs</text>
              <FaCaretDown className={`ml-1 mt-1`} size={12} />
            </div>
            <div className={`${styles.tableHeaderName} flex p-4`}>
              <text>Recieved</text>
              <FaCaretDown className={`ml-1 mt-1`} size={12} />
            </div>
            <div className={`${styles.tableHeaderName} flex p-4`}>
              <text>Status</text>
              <FaCaretDown className={`ml-1 mt-1`} size={12} />
            </div>
            <div className={`${styles.tableHeaderName} flex p-4`}></div>
          </div>
          <div className={`${styles.tableBody}`}>
            {!isEmpty(feedbacks) &&
              feedbacks
                .slice(
                  currentPage * feedbacksPerPage - feedbacksPerPage,
                  currentPage * feedbacksPerPage
                )
                .map((feedback, k) => {
                  return (
                    <div key={k} className={`${styles.tableRow} flex`}>
                      {/* <div
                        className={`${styles.tableData} flex items-center p-4`}
                      >
                        <text>Jane</text>
                      </div> */}
                      <div
                        className={`${styles.tableData} flex items-center p-4`}
                      >
                        <text>{feedback.name}</text>
                      </div>
                      <div
                        className={`${styles.tableData} flex items-center p-4`}
                      >
                        <text>{feedback.center_name}</text>
                      </div>
                      <div
                        className={`${styles.tableData} flex items-center p-4`}
                      >
                        <text>{feedback.nqa.join(", ")}</text>
                      </div>
                      <div
                        className={`${styles.tableData} flex items-center p-4`}
                      >
                        <text>{feedback.date.split("-").join("/")}</text>
                      </div>
                      <div
                        className={`${styles.tableData} flex items-center p-4`}
                      >
                        <text
                          className={`${
                            !isEmpty(feedback.rf)
                              ? feedback.rf.save_or_publish === 2
                                ? styles.published
                                : styles.unpublished
                              : styles.unpublished
                          }`}
                        >
                          {`${
                            !isEmpty(feedback.rf)
                              ? feedback.rf.save_or_publish !== 2
                                ? "Unpublished"
                                : "Published"
                              : "Unpublished"
                          }`}
                        </text>
                      </div>
                      <div
                        className={`${styles.tableData} flex items-center p-1.5`}
                      >
                        <button
                          className={`${styles.detailsButton}`}
                          onClick={() =>
                            history.push("/qip/request-feedback/single", {
                              feedback: feedback,
                            })
                          }
                        >
                          Details
                        </button>
                      </div>
                    </div>
                  );
                })}
          </div>
        </div>

        <div className={`flex justify-start`}>
          {!isEmpty(feedbacks) && feedbacksPerPage < feedbacks.length && (
            <Pagination
              currentPage={currentPage}
              lastPage={Math.ceil(feedbacks.length / feedbacksPerPage)}
              onPageChange={setCurrentPage}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default FeedbackRequestList;
