import * as React from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { Button, Button as IconButton, Input } from "semantic-ui-react";

import {
  FaPlusCircle,
  FaCommentDots,
  FaEdit,
  FaLink,
  FaTimes,
  FaRegArrowAltCircleLeft,
  FaRegFileWord,
  FaRegFilePdf,
  FaRegFileExcel,
  FaRegFilePowerpoint,
  FaRegImage,
  FaPlay,
  FaVolumeUp,
  FaRegFileArchive,
  FaFile,
  FaShoppingBag,
  FaSpinner,
} from "react-icons/fa";

import BlueButton from "../../../components/BlueButton";
import EditResourceModal from "../../../components/Modals/EditResourceModal";
import AddResourceModal from "../../../components/Modals/AddResourceModal";
import AddShopResourceModal from "../../../components/Modals/AddShopResourceModal";
import AddAppResourceModal from "../../../components/Modals/AddAppResourceModal";
import EditShopResourceModal from "../../../components/Modals/EditShopResourceModal";
import EditAppResourceModal from "../../../components/Modals/EditAppResourceModal";
import RemoveResourceModal from "../../../components/Modals/RemoveResourceModal";
import SuccessRemoveResourceModal from "../../../components/Modals/SuccessRemoveResourceModal";
import QuestionModal from "../../../components/Modals/QuestionModal";
import styles from "./ResourceCenter.module.css";
import useSWR, { mutate } from "swr";
import {
  fetchNQAData,
  getAllResources,
  deleteResource,
  getAllResourcesShop,
  getAllResourcesTutorial,
  deleteResourceShop,
  imageShopLink,
  imageAppTutorialLink,
  deleteResourceTutorial,
} from "../../../api/requests";
import Pagination from "../../../components/Pagination/Pagination";
import { isEmpty } from "lodash";
import { useStoreActions, useStoreState } from "../../../hooks";

const { useState, useEffect, useCallback } = React;

const resourcesPerPage = 10;

export default function ResourceCenter() {
  const history = useHistory();
  const match = useRouteMatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [shopCurrentPage, setShopCurrentPage] = useState(1);
  const [appCurrentPage, setAppCurrentPage] = useState(1);
  const [currentFilter, setCurrentFilter] = useState(1);
  const [subCurrentFilter, setSubCurrentFilter] = useState("");
  const [selectedOption, setOption] = useState(1);
  const [nqaVisibility, setNQAVisibility] = useState(false);
  const [editResourceModal, setEditResourceModal] = useState(false);
  const [addResourceModal, setAddResourceModal] = useState(false);
  const [addShopResourceModal, setAddShopResourceModal] = useState(false);
  const [addAppResourceModal, setAddAppResourceModal] = useState(false);
  const [editShopResourceModal, setEditShopResourceModal] = useState(false);
  const [editAppResourceModal, setEditAppResourceModal] = useState(false);
  const [removeResourceModal, setRemoveResourceModal] = useState(false);

  const [shopLink, setShopLink] = useState("");
  const [tutorialAppLink, setTutorialAppLink] = useState("");

  const [removeResourceShopModal, setRemoveResourceShopModal] = useState(false);

  const [removeType, setRemoveType] = useState("resource");

  const [successRemoveResourceModal, setSuccessRemoveResourceModal] =
    useState(false);

  const [successRemoveResourceShopModal, setSuccessRemoveResourceShopModal] =
    useState(false);

  const [fetchLimitError, setFetchLimitError] = useState(false);
  const [questionModal, setQuestionModal] = useState(false);

  const [currentResource, setCurrentResource] = useState({});

  const [shopCurrentResource, setShopCurrentResource] = useState({});
  const [appTutorialCurrentResource, setAppTutorialCurrentResource] = useState(
    {}
  );

  const [loadingEditShop, setLoadingEditShop] = useState(false);
  const [loadingEditApp, setLoadingEditApp] = useState(false);

  const setSelectedNQA = useStoreActions(
    (actions) => actions.nqaStore.setSelectedNQA
  );

  const onConfirmRemoveResourceFile = async () => {
    try {
      setRemoveResourceModal(false);
      if (removeType === "resource") {
        const response = await deleteResource(resourceId);
        await mutate(["/allResources"]);
        setSuccessRemoveResourceModal(true);
      }
      if (removeType === "shop") {
        const response = await deleteResourceShop(resourceId);
        await mutate(["/resourcesshop"]);
        setSuccessRemoveResourceModal(true);
      }
      if (removeType === "tutorial") {
        const response = await deleteResourceTutorial(resourceId);
        await mutate(["/resourcestutorial"]);
        setSuccessRemoveResourceModal(true);
      }
    } catch (error) {
      setRemoveResourceModal(false);
      console.log(error);
    }
  };

  const getNQAData = useStoreActions(
    (actions) => actions.nqaStore.fetchNQAData
  );

  const [resourceId, setResourceId] = useState(-1);
  const [appTutorialResourceId, setAppTutorialResourceId] = useState(-1);

  const pullNQAData = useCallback(async () => {
    try {
      await getNQAData();
      setSelectedNQA(1);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const fileTypeGrabber = (file) => {
    if (file.user_id === 2) {
      return "URL";
    } else {
      const ext = file.name.split(".")[1];
      switch (ext) {
        case "pdf":
          return "PDF document";
        case "jpg":
        case "jpeg":
        case "png":
          return "Image file";
        case "zip":
          return "ZIP file";
        case "webm":
        case "wmv":
        case "ogg":
        case "mp4":
        case "avi":
        case "mov":
          return "Video file";

        case "mp3":
        case "wave":
          return "Audio file";
        case "xlsx":
          return "Excel file";
        case "pptx":
          return "Powerpoint file";
        case "docx":
          return "Word document";
        default:
          return "File";
      }
    }
  };

  const iconGrabber = (file) => {
    if (file.user_id === 2) {
      return <FaLink />;
    } else {
      const ext = file.name.split(".")[1];
      switch (ext) {
        case "pdf":
          return <FaRegFilePdf />;
        case "jpg":
        case "jpeg":
        case "png":
          return <FaRegImage />;
        case "zip":
          return <FaRegFileArchive />;
        case "webm":
        case "wmv":
        case "ogg":
        case "mp4":
        case "avi":
        case "mov":
          return <FaPlay />;
        case "mp3":
        case "wave":
          return <FaVolumeUp />;
        case "xlsx":
          return <FaRegFileExcel />;
        case "pptx":
          return <FaRegFilePowerpoint />;
        case "docx":
          return <FaRegFileWord />;
        default:
          return <FaFile />;
      }
    }
  };

  const getSublevels = (filter) => {
    let sublevels = [];
    let id = 0;
    if (nqaData) {
      nqaData[filter - 1].levels.forEach((level) => {
        id = id + 1;
        sublevels.push({ label: level.name, id: id });
        // level.subLevels.forEach((sub) => {
        //   id = id + 1;
        //   sublevels.push({ label: sub.label, id: id });
        // });
      });
    }
    return sublevels;
  };

  const { data: nqaData, isValidating } = useSWR(
    ["/nqaData"],
    () => fetchNQAData(),
    {
      onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
        // Only retry up to 3 times.
        if (retryCount && retryCount >= 3) {
          if (!nqaData) setFetchLimitError(true);

          return;
        }
        // Retry after 3 seconds.
        setTimeout(() => revalidate({ retryCount: retryCount + 1 }), 3000);
      },
    }
  );

  const { data: allResources, isValidating: isValidatingResources } = useSWR(
    ["/allResources"],
    () => getAllResources(),
    {
      onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
        // Only retry up to 3 times.
        if (retryCount && retryCount >= 3) {
          if (!allResources) setFetchLimitError(true);

          return;
        }
        // Retry after 3 seconds.
        setTimeout(() => revalidate({ retryCount: retryCount + 1 }), 3000);
      },
    }
  );

  const { data: allResourcesShop, isValidating: isValidatingShop } = useSWR(
    ["/resourcesshop"],
    () => getAllResourcesShop(),
    {
      onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
        // Only retry up to 3 times.
        if (retryCount && retryCount >= 3) {
          if (!allResourcesShop) setFetchLimitError(true);

          return;
        }
        // Retry after 3 seconds.
        setTimeout(() => revalidate({ retryCount: retryCount + 1 }), 3000);
      },
    }
  );

  const { data: allResourcesTutorial, isValidating: isValidatingTutorial } =
    useSWR(["/resourcestutorial"], () => getAllResourcesTutorial(), {
      onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
        // Only retry up to 3 times.
        if (retryCount && retryCount >= 3) {
          if (!allResourcesTutorial) setFetchLimitError(true);

          return;
        }
        // Retry after 3 seconds.
        setTimeout(() => revalidate({ retryCount: retryCount + 1 }), 3000);
      },
    });

  const getDownloadImage = async (id) => {
    try {
      setLoadingEditShop(true);
      const response = await imageShopLink(id);
      // setShopLink(response);
      await getBase64FromUrl(response, (base64) => {
        setShopLink(base64);
      });
      setLoadingEditShop(false);
    } catch (error) {
      setLoadingEditShop(false);
      console.log(error);
    }
  };

  const getDownloadTutorialImage = async (id) => {
    try {
      setLoadingEditApp(true);
      const response = await imageAppTutorialLink(id);
      // setShopLink(response);
      await getBase64FromUrl(response, (base64) => {
        setTutorialAppLink(base64);
      });
      setLoadingEditApp(false);
    } catch (error) {
      setLoadingEditApp(false);
      console.log(error);
      return "";
    }
  };

  const getBase64FromUrl = async (url, callback) => {
    var img = new Image();

    img.crossOrigin = "Anonymous";

    img.onload = function () {
      var canvas = document.createElement("CANVAS");
      var ctx = canvas.getContext("2d");
      var dataURL;
      canvas.height = this.height;
      canvas.width = this.width;
      ctx.drawImage(this, 0, 0);
      dataURL = canvas.toDataURL();
      callback(dataURL);
      canvas = null;
    };

    img.src = url;
  };

  useEffect(() => {
    pullNQAData();
  }, []);

  return (
    <div className={`${styles.mainContainer} mt-5 md:mb-28 lg:mb-10`}>
      {questionModal && (
        <QuestionModal
          visibility={questionModal}
          nqaData={nqaData ? nqaData : []}
          onOpenModal={() => setQuestionModal(true)}
          onCloseModal={() => setQuestionModal(false)}
        />
      )}

      {editResourceModal && nqaData && (
        <EditResourceModal
          visibility={editResourceModal}
          resource={currentResource}
          nqaData={nqaData}
          onOpenModal={() => setEditResourceModal(true)}
          onCloseModal={async () => {
            await mutate(["/allResources"]);
            setEditResourceModal(false);
          }}
        />
      )}

      {addResourceModal && subCurrentFilter && (
        <AddResourceModal
          visibility={true}
          nqaId={currentFilter}
          childNqaId={subCurrentFilter}
          onOpenModal={() => setAddResourceModal(true)}
          onCloseModal={async () => {
            await mutate(["/allResources"]);
            setAddResourceModal(false);
          }}
        />
      )}

      {addShopResourceModal && (
        <AddShopResourceModal
          visibility={addShopResourceModal}
          onOpenModal={() => setAddShopResourceModal(true)}
          onCloseModal={async () => {
            await mutate(["/resourcesshop"]);
            setAddShopResourceModal(false);
          }}
        />
      )}

      <AddAppResourceModal
        visibility={addAppResourceModal}
        onOpenModal={() => setAddAppResourceModal(true)}
        onCloseModal={() => setAddAppResourceModal(false)}
      />

      {editShopResourceModal && !isEmpty(shopCurrentResource) && shopLink && (
        <EditShopResourceModal
          visibility={editShopResourceModal}
          shopLink={shopLink}
          resource={shopCurrentResource}
          onOpenModal={async () => {
            setEditShopResourceModal(true);
          }}
          onCloseModal={async () => {
            setShopCurrentResource({});
            setShopLink("");
            await mutate(["/resourcesshop"]);
            setEditShopResourceModal(false);
          }}
        />
      )}

      {editAppResourceModal &&
        !isEmpty(appTutorialCurrentResource) &&
        tutorialAppLink && (
          <EditAppResourceModal
            visibility={editAppResourceModal}
            tutorialAppLink={tutorialAppLink}
            resource={appTutorialCurrentResource}
            onOpenModal={async () => setEditAppResourceModal(true)}
            onCloseModal={async () => {
              setAppTutorialCurrentResource({});
              setTutorialAppLink("");
              await mutate(["/resourcestutorial"]);
              setEditAppResourceModal(false);
            }}
          />
        )}

      <SuccessRemoveResourceModal
        visibility={successRemoveResourceModal}
        onOpenModal={() => setSuccessRemoveResourceModal(true)}
        onCloseModal={() => setSuccessRemoveResourceModal(false)}
      />

      {removeResourceModal && resourceId !== -1 && (
        <RemoveResourceModal
          visibility={removeResourceModal}
          resourceId={resourceId}
          onOpenModal={() => setRemoveResourceModal(true)}
          onCloseModal={() => setRemoveResourceModal(false)}
          onConfirmRemoveResource={onConfirmRemoveResourceFile}
        />
      )}

      <div className={`${styles.headerTextContainer} flex`}>
        <div className={styles.headerTextIcon}>
          <FaCommentDots size={55} />
        </div>

        <div className={`${styles.headerText} ml-4`}>
          <h1>Resources dashboard</h1>
          <text>Add, update and remove resources for specific NQA areas</text>
        </div>
      </div>

      <div className={`${styles.resourcesContainer} mt-9`}>
        <div className={`${styles.navigationOptionsRow} flex`}>
          <div
            className={`${
              selectedOption === 1
                ? styles.navigationOptionSelected
                : styles.navigationOption
            } flex items-center justify-center w-1/3 h-32`}
            onClick={() => setOption(1)}
          >
            QIP/SAT resources
          </div>

          <div
            className={`${
              selectedOption === 2
                ? styles.navigationOptionSelected
                : styles.navigationOption
            } flex items-center justify-center w-1/3 h-32`}
            onClick={() => setOption(2)}
          >
            Shop resources
          </div>

          <div
            className={`${
              selectedOption === 3
                ? styles.navigationOptionSelected
                : styles.navigationOption
            } flex items-center justify-center w-1/3 h-32`}
            onClick={() => setOption(3)}
          >
            App tutorials
          </div>
        </div>

        <div className={`${styles.contentHolder} md:px-7 lg:px-14 pt-16 pb-20`}>
          {(() => {
            if (selectedOption === 1) {
              return (
                <div className={styles.resourcesContent}>
                  <div
                    className={`${styles.resourceFilterRow} flex flex-wrap pb-2.5`}
                  >
                    <div
                      className={`${
                        currentFilter === 1
                          ? styles.resourceFilterSelected
                          : styles.resourceFilter
                      }  flex items-center justify-center`}
                      onClick={() => {
                        setSubCurrentFilter("");
                        setCurrentPage(1);
                        setCurrentFilter(1);
                      }}
                    >
                      NQA 1
                    </div>
                    <div
                      className={`${
                        currentFilter === 2
                          ? styles.resourceFilterSelected
                          : styles.resourceFilter
                      }  flex items-center justify-center`}
                      onClick={() => {
                        setSubCurrentFilter("");
                        setCurrentPage(1);
                        setCurrentFilter(2);
                      }}
                    >
                      NQA 2
                    </div>
                    <div
                      className={`${
                        currentFilter === 3
                          ? styles.resourceFilterSelected
                          : styles.resourceFilter
                      }  flex items-center justify-center`}
                      onClick={() => {
                        setSubCurrentFilter("");
                        setCurrentPage(1);
                        setCurrentFilter(3);
                      }}
                    >
                      NQA 3
                    </div>
                    <div
                      className={`${
                        currentFilter === 4
                          ? styles.resourceFilterSelected
                          : styles.resourceFilter
                      }  flex items-center justify-center`}
                      onClick={() => {
                        setSubCurrentFilter("");
                        setCurrentPage(1);
                        setCurrentFilter(4);
                      }}
                    >
                      NQA 4
                    </div>
                    <div
                      className={`${
                        currentFilter === 5
                          ? styles.resourceFilterSelected
                          : styles.resourceFilter
                      }  flex items-center justify-center`}
                      onClick={() => {
                        setSubCurrentFilter("");
                        setCurrentPage(1);
                        setCurrentFilter(5);
                      }}
                    >
                      NQA 5
                    </div>
                    <div
                      className={`${
                        currentFilter === 6
                          ? styles.resourceFilterSelected
                          : styles.resourceFilter
                      }  flex items-center justify-center`}
                      onClick={() => {
                        setSubCurrentFilter("");
                        setCurrentPage(1);
                        setCurrentFilter(6);
                      }}
                    >
                      NQA 6
                    </div>
                    <div
                      className={`${
                        currentFilter === 7
                          ? styles.resourceFilterSelected
                          : styles.resourceFilter
                      }  flex items-center justify-center`}
                      onClick={() => {
                        setSubCurrentFilter("");
                        setCurrentPage(1);
                        setCurrentFilter(7);
                      }}
                    >
                      NQA 7
                    </div>
                  </div>
                  <div
                    className={`relative ${styles.subResourcesFilterRow} ${
                      styles[`nqa_${currentFilter}`]
                    } flex justify-start flex-wrap pb-6`}
                  >
                    {!isEmpty(getSublevels(currentFilter)) &&
                      getSublevels(currentFilter).map((sub, key) => {
                        return (
                          <div
                            key={sub.label}
                            className={`${
                              subCurrentFilter === sub.label
                                ? styles.subResourcesFilterSelected
                                : styles.subResourcesFilter
                            } flex items-center justify-center`}
                            onClick={() => {
                              setSubCurrentFilter(sub.label);
                            }}
                          >
                            NQA{sub.label}
                          </div>
                        );
                      })}
                    {!subCurrentFilter && addResourceModal && (
                      <div
                        className={`absolute bottom-0 left-0 ml-5 text-red-400 -mb-4`}
                      >
                        <span className={`mt-3`}>
                          {" "}
                          Please select sub NQA level.
                        </span>
                      </div>
                    )}
                  </div>

                  <div className={`${styles.addNewResource} my-7`}>
                    <button
                      className={`flex justify-center items-center`}
                      onClick={() => setAddResourceModal(true)}
                    >
                      <FaPlusCircle className={`mr-3`} size={15} />
                      Create a new resource under NQA {currentFilter}
                    </button>
                  </div>

                  <div className={styles.documentRow}>
                    {!isEmpty(allResources) &&
                      allResources
                        .filter(
                          (resource) =>
                            Number(resource.nqa_id) === Number(currentFilter)
                        )
                        .slice(
                          currentPage * resourcesPerPage - resourcesPerPage,
                          currentPage * resourcesPerPage
                        )
                        .map((resource, k) => {
                          return (
                            <div
                              key={k}
                              className={`${styles.documentLists} flex items-center justify-between py-5`}
                            >
                              <div className={styles.fileContainer}>
                                <div
                                  className={`${styles.filelogo} ${styles.wordFile}`}
                                >
                                  {iconGrabber(resource)}
                                </div>
                                <div className={styles.fileContent}>
                                  <text className={styles.filename}>
                                    {resource.label
                                      ? resource.label
                                      : "Resource File"}
                                  </text>

                                  <text className={styles.fileType}>
                                    {fileTypeGrabber(resource)} /{` `}
                                    NQA{` `}
                                    {resource.child_nqa_id}
                                  </text>
                                </div>
                              </div>

                              <div
                                className={`${styles.actionButtonGroup} flex`}
                              >
                                {/* <button
                                  className={`${styles.questionButton} flex items-center p-4 mr-2.5 rounded text-base font-medium`}
                                  onClick={() => setQuestionModal(true)}
                                >
                                  <FaLink className={`mr-1.5`} size={15} />
                                  Question
                                </button> */}
                                <button
                                  className={`${styles.editUserButton} flex items-center p-4 mr-2.5 rounded text-base font-medium`}
                                  onClick={() => {
                                    setCurrentResource(resource);
                                    setEditResourceModal(true);
                                  }}
                                >
                                  <FaEdit className={`mr-3`} size={15} />
                                  Edit
                                </button>
                                <button
                                  className={`${styles.removeUserButton} flex items-center p-4 rounded text-base font-medium`}
                                  onClick={() => {
                                    setRemoveType("resource");
                                    setResourceId(resource.id);
                                    setRemoveResourceModal(true);
                                  }}
                                >
                                  <FaTimes className={`mr-3`} size={15} />
                                  Remove
                                </button>
                              </div>
                            </div>
                          );
                        })}
                  </div>

                  <div
                    className={`${styles.paginationContainer} flex justify-start py-7`}
                  >
                    {!isEmpty(allResources) &&
                      resourcesPerPage <
                        allResources.filter(
                          (resource) =>
                            Number(resource.nqa_id) === Number(currentFilter)
                        ).length && (
                        <Pagination
                          currentPage={currentPage}
                          lastPage={Math.ceil(
                            allResources.filter(
                              (resource) =>
                                Number(resource.nqa_id) ===
                                Number(currentFilter)
                            ).length / resourcesPerPage
                          )}
                          onPageChange={setCurrentPage}
                        />
                      )}
                  </div>
                </div>
              );
            } else if (selectedOption === 2) {
              return (
                <div className={styles.resourcesShopContent}>
                  <div className={`${styles.addNewResource} mb-7`}>
                    <button
                      className={`flex justify-center items-center`}
                      onClick={() => setAddShopResourceModal(true)}
                    >
                      <FaPlusCircle className={`mr-3`} size={15} />
                      Create a new shop resource
                    </button>
                  </div>

                  <div className={styles.documentRow}>
                    {allResourcesShop &&
                      allResourcesShop
                        .slice(
                          shopCurrentPage * resourcesPerPage - resourcesPerPage,
                          shopCurrentPage * resourcesPerPage
                        )
                        .map((resource, k) => {
                          return (
                            <div
                              key={k}
                              className={`${styles.documentLists} flex items-center justify-between py-5`}
                            >
                              <div className={styles.fileContainer}>
                                <div className={`${styles.filelogo}`}>
                                  <FaShoppingBag />
                                </div>
                                <div className={styles.fileContent}>
                                  <text className={styles.filename}>
                                    {" "}
                                    {resource.product_name}
                                  </text>
                                </div>
                              </div>

                              <div
                                className={`${styles.actionButtonGroup} flex`}
                              >
                                <button
                                  disabled={loadingEditShop}
                                  className={`${styles.editUserButton} flex items-center p-4 mr-2.5 rounded text-base font-medium`}
                                  onClick={async () => {
                                    setShopCurrentResource(resource);
                                    await getDownloadImage(resource.id);

                                    setEditShopResourceModal(true);
                                  }}
                                >
                                  {loadingEditShop &&
                                  shopCurrentResource.id === resource.id ? (
                                    <div
                                      className={`flex justify-center items-center px-5`}
                                    >
                                      <FaSpinner
                                        className={`animate-spin `}
                                        size={15}
                                      />
                                    </div>
                                  ) : (
                                    <>
                                      {" "}
                                      <FaEdit className={`mr-3`} size={15} />
                                      Edit{" "}
                                    </>
                                  )}
                                </button>
                                <button
                                  className={`${styles.removeUserButton} flex items-center p-4 rounded text-base font-medium`}
                                  onClick={() => {
                                    setRemoveType("shop");
                                    setResourceId(resource.id);
                                    setRemoveResourceModal(true);
                                  }}
                                >
                                  <FaTimes className={`mr-3`} size={15} />
                                  Remove
                                </button>
                              </div>
                            </div>
                          );
                        })}
                  </div>
                  <div
                    className={`${styles.paginationContainer} flex justify-start py-7`}
                  >
                    {!isEmpty(allResourcesShop) &&
                      resourcesPerPage < allResourcesShop.length && (
                        <Pagination
                          currentPage={shopCurrentPage}
                          lastPage={Math.ceil(
                            allResourcesShop.length / resourcesPerPage
                          )}
                          onPageChange={setShopCurrentPage}
                        />
                      )}
                  </div>
                </div>
              );
            } else {
              return (
                <div className={styles.resourcesAppTutorialsContent}>
                  <div className={`${styles.addNewResource} mb-7`}>
                    <button
                      className={`flex justify-center items-center`}
                      onClick={() => setAddAppResourceModal(true)}
                    >
                      <FaPlusCircle className={`mr-3`} size={15} />
                      Create a new app tutorial
                    </button>
                  </div>

                  <div className={styles.documentRow}>
                    {allResourcesTutorial &&
                      allResourcesTutorial
                        .slice(
                          appCurrentPage * resourcesPerPage - resourcesPerPage,
                          appCurrentPage * resourcesPerPage
                        )
                        .map((resource, key) => {
                          return (
                            <div
                              key={key}
                              className={`${styles.documentLists} flex items-center justify-between py-5`}
                            >
                              <div className={styles.fileContainer}>
                                <div className={`${styles.filelogo}`}>
                                  <FaPlay />
                                </div>
                                <div className={styles.fileContent}>
                                  <text className={styles.filename}>
                                    {resource.tutorial_name}
                                  </text>
                                </div>
                              </div>

                              <div
                                className={`${styles.actionButtonGroup} flex`}
                              >
                                <button
                                  className={`${styles.editUserButton} flex items-center p-4 mr-2.5 rounded text-base font-medium`}
                                  onClick={async () => {
                                    setAppTutorialCurrentResource(resource);
                                    await getDownloadTutorialImage(resource.id);

                                    setEditAppResourceModal(true);
                                  }}
                                >
                                  {loadingEditApp &&
                                  appTutorialCurrentResource.id ===
                                    resource.id ? (
                                    <div
                                      className={`flex justify-center items-center px-5`}
                                    >
                                      <FaSpinner
                                        className={`animate-spin `}
                                        size={15}
                                      />
                                    </div>
                                  ) : (
                                    <>
                                      {" "}
                                      <FaEdit className={`mr-3`} size={15} />
                                      Edit{" "}
                                    </>
                                  )}

                                  {/* <FaEdit className={`mr-3`} size={15} />
                                  Edit */}
                                </button>
                                <button
                                  className={`${styles.removeUserButton} flex items-center p-4 rounded text-base font-medium`}
                                  onClick={() => {
                                    // setRemoveType("shop");
                                    // setResourceId(resource.id);
                                    // setRemoveResourceModal(true);
                                    setRemoveType("tutorial");
                                    setResourceId(resource.id);
                                    setRemoveResourceModal(true);
                                  }}
                                >
                                  <FaTimes className={`mr-3`} size={15} />
                                  Remove
                                </button>
                              </div>
                            </div>
                          );
                        })}
                  </div>

                  <div
                    className={`${styles.paginationContainer} flex justify-start py-7`}
                  >
                    {!isEmpty(allResourcesTutorial) &&
                      resourcesPerPage < allResourcesTutorial.length && (
                        <Pagination
                          currentPage={appCurrentPage}
                          lastPage={Math.ceil(
                            allResourcesTutorial.length / resourcesPerPage
                          )}
                          onPageChange={setAppCurrentPage}
                        />
                      )}
                  </div>
                </div>
              );
            }
          })()}
        </div>
      </div>
    </div>
  );
}
