/* eslint-disable react/jsx-max-props-per-line */
import React, { ChangeEventHandler } from "react";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";
import { MultiSelect as MultiSelectInput } from "react-multi-select-component";
import styles from "./MultiSelect.module.css";

type MultiSelectProps = React.ComponentProps<typeof MultiSelectInput> & {
  placeholder?: string;
};

interface CustomItemRendererProps {
  checked: boolean;
  option: MultiSelectProps["options"][0];
  onClick: ChangeEventHandler<HTMLInputElement>;
  disabled?: boolean;
}

const CustomItemRenderer = ({
  checked,
  option,
  onClick,
  disabled,
}: CustomItemRendererProps) => {
  return (
    <div className={`${styles.item} ${disabled && styles.disabled}`}>
      <div className={styles.label}>
        <span>{option.label}</span>
        <input
          type="checkbox"
          onChange={onClick}
          checked={checked}
          tabIndex={-1}
          disabled={disabled}
          hidden
        />
        {checked ? <FaCheckCircle size={24} /> : <FaTimesCircle size={24} />}
      </div>
    </div>
  );
};

const MultiSelect = (props: MultiSelectProps) => {
  return (
    <MultiSelectInput
      className={styles.select}
      disableSearch
      ClearSelectedIcon={<></>}
      ArrowRenderer={({ expanded }) =>
        expanded ? <IoMdArrowDropup /> : <IoMdArrowDropdown />
      }
      ItemRenderer={CustomItemRenderer}
      valueRenderer={(selected, _options) => {
        return selected.length
          ? selected.map(({ label }) => label).join(", ")
          : props.placeholder || "Select";
      }}
      {...props}
    />
  );
};

export default MultiSelect;
