import * as React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import MessageCompose from "../../pages/Admin/Messages/MessageCompose";
import MessageView from "../../pages/Admin/Messages/MessageView";
import MessageDraftView from "../../pages/Admin/Messages/MessageDraftView";
import AllMessages from "../../pages/Admin/Messages/AllMessages";

export default function AdminMessages() {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${match.path}`} render={() => <AllMessages />} />

      <Route
        exact
        path={`${match.path}/compose`}
        render={() => <MessageCompose />}
      />

      <Route
        exact
        path={`${match.path}/draft`}
        render={(props) => <MessageDraftView {...props} />}
      />

      <Route
        exact
        path={`${match.path}/view`}
        render={(props) => <MessageView {...props} />}
      />
    </Switch>
  );
}
