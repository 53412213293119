import * as React from "react";
import { useHistory } from "react-router-dom";
import { Button, Dropdown } from "semantic-ui-react";
import {
  FaFlagCheckered,
  FaCommentDots,
  FaEdit,
  FaTimes,
} from "react-icons/fa";

import RemoveUserModal from "../../../../components/Modals/RemoveUserModal";
import SuccessRemoveUserModal from "../../../../components/Modals/SuccessRemoveUserModal";

import styles from "./UserList.module.css";

const { useCallback, useReducer, useState } = React;

const tasksPerPage = 10;

function UserList() {
  const history = useHistory();
  const [removedUserModal, setRemovedUserModal] = useState(false);
  const [removeUserModal, setRemoveUserModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const onConfirmRemoveReport = async () => {
    setRemovedUserModal(true);
    setRemoveUserModal(false);
  };

  return (
    <div className={`${styles.mainContainer} mt-5 md:mb-28 lg:mb-10`}>
      {removeUserModal && (
        <RemoveUserModal
          visibility={removeUserModal}
          onOpenModal={() => setRemoveUserModal(true)}
          onCloseModal={() => setRemoveUserModal(false)}
          onConfirmRemoveUser={onConfirmRemoveReport}
        />
      )}
      {removedUserModal && (
        <SuccessRemoveUserModal
          visibility={removedUserModal}
          onOpenModal={() => setRemovedUserModal(true)}
          onCloseModal={() => setRemovedUserModal(false)}
        />
      )}

      <div className={`${styles.headerTextContainer} flex`}>
        <div className={styles.headerTextIcon}>
          <FaCommentDots size={55} />
        </div>

        <div className={`${styles.headerText} ml-4`}>
          <h1>User Accounts</h1>
          <text>Update and manage your HM&C Admin Users</text>
        </div>
      </div>

      <div
        className={`${styles.addNewUserContainer} flex justify-end py-4 px-3 mt-9`}
      >
        <button
          className={`${styles.addNewUserButton} flex items-center justify-center`}
          onClick={() => {
            history.push("/qip/user-management/create-user");
          }}
        >
          Add new user
        </button>
      </div>

      <div className={styles.userContainer}>
        <div
          className={`${styles.userList} flex justify-between items-center mt-1 py-3 pl-8 pr-3`}
        >
          <div className={`${styles.userName} flex flex-wrap`}>
            <text className={`mr-3.5`}>Alina Dan</text>
            <span
              className={`${styles.userType} flex items-center px-1.5 py-1`}
            >
              Super admin
            </span>
          </div>
          <div className={`${styles.actionButtonGroup} flex`}>
            <button
              className={`${styles.taskUserButton} flex items-center p-4 mr-2.5 hidden`}
            >
              <FaFlagCheckered className={`mr-3`} size={15} />
              Tasks
            </button>
            <button
              className={`${styles.editUserButton} flex items-center p-4 mr-2.5`}
              onClick={() => {
                history.push("/qip/user-management/update-user");
              }}
            >
              <FaEdit className={`mr-3`} size={15} />
              Edit
            </button>
            <button
              className={`${styles.removeUserButton} flex items-center p-4 `}
              onClick={() => setRemoveUserModal(true)}
            >
              <FaTimes className={`mr-3`} size={15} />
              Remove
            </button>
          </div>
        </div>

        <div
          className={`${styles.userList} flex justify-between items-center mt-1 py-3 pl-8 pr-3`}
        >
          <div className={`${styles.userName} flex flex-wrap`}>
            <text>Karen Mahoney</text>
          </div>
          <div className={`${styles.actionButtonGroup} flex`}>
            <button
              className={`${styles.taskUserButton} flex items-center p-4 mr-2.5 hidden`}
            >
              <FaFlagCheckered className={`mr-3`} size={15} />
              Tasks
            </button>
            <button
              className={`${styles.editUserButton} flex items-center p-4 mr-2.5`}
            >
              <FaEdit className={`mr-3`} size={15} />
              Edit
            </button>
            <button
              className={`${styles.removeUserButton} flex items-center p-4`}
            >
              <FaTimes className={`mr-3`} size={15} />
              Remove
            </button>
          </div>
        </div>

        <div
          className={`${styles.userList} flex justify-between items-center mt-1 py-3 pl-8 pr-3`}
        >
          <div className={`${styles.userName} flex flex-wrap`}>
            <text className={`mr-3.5`}>Amanda Blackshaw</text>
            <span
              className={`${styles.userType} flex items-center px-1.5 py-1`}
            >
              Super admin
            </span>
          </div>
          <div className={`${styles.actionButtonGroup} flex`}>
            <button
              className={`${styles.taskUserButton} flex items-center p-4 mr-2.5 hidden`}
            >
              <FaFlagCheckered className={`mr-3`} size={15} />
              Tasks
            </button>
            <button
              className={`${styles.editUserButton} flex items-center p-4 mr-2.5`}
            >
              <FaEdit className={`mr-3`} size={15} />
              Edit
            </button>
            <button
              className={`${styles.removeUserButton} flex items-center p-4`}
            >
              <FaTimes className={`mr-3`} size={15} />
              Remove
            </button>
          </div>
        </div>
      </div>

      <div
        className={`${styles.addNewUserContainer} flex justify-between flex-wrap items-center py-4 px-3 mt-1`}
      >
        <div className={`flex justify-center`}>
          <Button
            className={`${styles.pageButtonStartEnd} rounded`}
            icon="angle double left"
            onClick={() => setCurrentPage(1)}
          />

          {[...Array(Math.ceil(50 / tasksPerPage)).keys()].map((page) => (
            <button
              key={page}
              className={
                currentPage === page + 1
                  ? styles.pageButtonSelected
                  : styles.pageButton
              }
              onClick={() => setCurrentPage(page + 1)}
            >
              {page + 1}
            </button>
          ))}

          <Button
            className={`${styles.pageButtonStartEnd} rounded`}
            icon="angle double right"
            onClick={() => setCurrentPage(Math.ceil(50 / tasksPerPage))}
          />
        </div>

        <button
          className={`${styles.addNewUserButton} flex items-center justify-center`}
          onClick={() => {
            history.push("/qip/user-management/create-user");
          }}
        >
          Add new user
        </button>
      </div>
    </div>
  );
}

export default UserList;
