import * as React from "react";

import { useHistory, useParams } from "react-router-dom";
import { FaCommentDots, FaSpinner, FaArrowAltCircleLeft } from "react-icons/fa";
import { Button, Icon, Dropdown } from "semantic-ui-react";
import InputComponent from "../../../../components/InputComponent";
import BlueButton from "../../../../components/BlueButton";
import SkipButton from "../../../../components/SkipButton";
import RemoveFeedbackModal from "../../../../components/Modals/RemoveFeedbackModal/RemoveFeedbackModal";
import DeleteFeedbackModal from "../../../../components/Modals/DeleteFeedbackModal/DeleteFeedbackModal";
import PublishFeedbackModal from "../../../../components/Modals/PublishFeedbackModal/PublishFeedbackModal";

import styles from "./SingleFeedbackRequest.module.css";
import { isEmpty } from "lodash";
import moment from "moment";
import ReactQuill from "react-quill";

import {
  replyFeedback,
  getReplyFeedback,
  getReplyFeedbackAll,
  updateReplyFeedback,
  deleteReplyFeedback,
} from "../../../../api/requests";
import Swal from "sweetalert2";

const { useState, useEffect, useCallback } = React;

const nqaStatus = [
  {
    text: "Working towards",
    value: 1,
  },
  {
    text: "Meeting",
    value: 2,
  },
  {
    text: "Exceeding",
    value: 3,
  },
];

function SingleFeedbackRequest({ location }) {
  const history = useHistory();

  const [rfeedback, setrFeedback] = useState(
    !isEmpty(location.state.feedback.rf) ? location.state.feedback.rf : {}
  );

  const [loading, setLoading] = useState(false);

  const [loadingDelete, setLoadingDelete] = useState(false);

  const [action, setAction] = useState("save");

  const [showRemoveModal, setShowRemoveModal] = useState(false);

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [showPublishModal, setShowPublishModal] = useState(false);

  const [fieldsChecked, setFieldsChecked] = useState(false);

  const [feedback, setFeedback] = useState(
    !isEmpty(location.state.feedback.rf)
      ? location.state.feedback.rf.provide_feedback
      : ""
  );
  const [providedBy, setProvidedBy] = useState(
    !isEmpty(location.state.feedback.rf)
      ? location.state.feedback.rf.provided_by
      : ""
  );
  const [status, setStatus] = useState(
    !isEmpty(location.state.feedback.rf) ? location.state.feedback.rf.status : 1
  );
  const [dateReviewed, setDateReviewed] = useState(
    !isEmpty(location.state.feedback.rf)
      ? location.state.feedback.rf.date_reviewed
      : ""
  );

  const modules = {
    toolbar: [
      ["bold", "italic", "underline", "string"],
      ["blockquote", "code-block"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ script: "sub" }, { script: "super" }],
      [{ indent: "-1" }, { indent: "+1" }],
      [{ direction: "rtl" }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ color: [] }, { background: [] }],
      [{ font: [] }],
      [{ align: [] }],
      ["link", "image"],
    ],
  };

  const onUpdateReplyFeedback = async (saveOrPublish) => {
    if (!(feedback && providedBy && status && dateReviewed)) {
      return;
    }

    try {
      setLoading(true);
      const response = await updateReplyFeedback(rfeedback.id, {
        feedback: feedback,
        providedBy: providedBy,
        dateReviewed: dateReviewed,
        status: status,
        saveOrPublish: saveOrPublish,
      });
      await fetchReplyFeedback();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const onReplyFeedback = async (saveOrPublish) => {
    const initialSave = isEmpty(rfeedback);

    setFieldsChecked(true);

    if (!(feedback && providedBy && status && dateReviewed)) {
      return;
    }

    try {
      setLoading(true);

      if (!isEmpty(location.state)) {
        const response = await replyFeedback({
          id: location.state.feedback.id,
          dateReviewed: dateReviewed,
          feedback: feedback,
          providedBy: providedBy,
          status: status,
          saveOrPublish: saveOrPublish,
        });
      }

      if (initialSave) {
        await fetchReplyFeedback();
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const fetchReplyFeedback = useCallback(async () => {
    try {
      const response = await getReplyFeedbackAll();
      if (!isEmpty(response)) {
        const rf = response.filter(
          (r) => r.request_id === location.state.feedback.id
        )[0];

        setrFeedback(rf);

        setFeedback(rf.provide_feedback);
        setProvidedBy(rf.provided_by);
        setStatus(rf.status);
        setDateReviewed(rf.date_reviewed);
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  const onDeleteFeedback = async () => {

    try {
      setLoadingDelete(true);      
      const response = await deleteReplyFeedback(
        Number(location.state.feedback.id)
      );

      Swal.fire({
        icon: `info`,
        title: `Feedback Deleted!`,
        showConfirmButton: false,
        timer: 1700,
      }).then(() => {
        window.location = `/qip/request-feedback/list`;
      });

      setLoadingDelete(false);
    } catch (error) {
      setLoadingDelete(false);
      console.log(error);
    }
  };

  // useEffect(() => {
  //   fetchReplyFeedback();
  // }, []);

  return (
    <div className={`${styles.mainContainer} mt-5 md:mb-28 lg:mb-10`}>
      {showDeleteModal && (
        <DeleteFeedbackModal
          visibility={true}
          onConfirm={() => {
            setShowDeleteModal(false);
            onDeleteFeedback();
          }}
          onCloseModal={() => setShowDeleteModal(false)}
        />
      )}

      {showRemoveModal && (
        <RemoveFeedbackModal
          visibility={true}
          onConfirm={() => {
            setShowRemoveModal(false);
            onUpdateReplyFeedback(1);
          }}
          onCloseModal={() => setShowRemoveModal(false)}
        />
      )}

      {showPublishModal && (
        <PublishFeedbackModal
          visibility={true}
          onConfirm={() => {
            setShowPublishModal(false);
            onUpdateReplyFeedback(2);
          }}
          onCloseModal={() => setShowPublishModal(false)}
        />
      )}

      {/* <div className={styles.headerTextIcon}>
          <FaCommentDots size={55} />
        </div>
        <div className={`${styles.headerText} ml-4`}>
          <h1>Feedback Request</h1>
          <text>Lorem ipsum dolor amet</text>
        </div> */}

      <div
        className={`${styles.headerTextContainer} flex items-center justify-between`}
      >
        <div className={`flex`}>
          <div className={styles.headerTextIcon}>
            <FaCommentDots size={55} />
          </div>

          <div className={`${styles.headerText} ml-4`}>
            <h1>Feedback Request</h1>
            <text>Add, update, publish and remove feedback</text>
          </div>
        </div>

        <div className={`${styles.headerTextButton}`}>
          <button
            className={`flex items-center justify-center`}
            onClick={() => history.goBack()}
          >
            <FaArrowAltCircleLeft size={16} />
            Back
          </button>
        </div>
      </div>

      <div
        className={`${styles.formDataContainer} md:px-7 lg:px-14 pt-14 pb-16 mt-9`}
      >
        <div
          className={`${styles.formDataRow} flex flex-wrap justify-between pb-14`}
        >
          <div className={`${styles.formLeft}`}>
            <div className={`flex mb-2.5`}>
              <text className={`${styles.infoTitle}`}>
                <strong>User Name:</strong>
              </text>
              <text className={`${styles.infoData}`}>
                {!isEmpty(location.state) && location.state.feedback.name}
              </text>
            </div>
            <div className={`flex mb-2.5`}>
              <text className={`${styles.infoTitle}`}>
                <strong>Service Name:</strong>
              </text>
              <text className={`${styles.infoData}`}>
                {!isEmpty(location.state) &&
                  location.state.feedback.center_name}{" "}
              </text>
            </div>
            <div className={`flex mb-2.5`}>
              <text className={`${styles.infoTitle}`}>
                <strong>Date Received:</strong>
              </text>
              <text className={`${styles.infoData}`}>
                {!isEmpty(location.state) &&
                  location.state.feedback.date.split("-").join("/")}
              </text>
            </div>
            <div className={`flex items-center mb-2.5`}>
              <text className={`${styles.infoTitle}`}>
                <strong>Status:</strong>
              </text>

              <div>
                <div>
                  {!isEmpty(rfeedback) && rfeedback.save_or_publish === 2 && (
                    <text style={{ color: "#8bc334" }}> Published</text>
                  )}

                  {!isEmpty(rfeedback) && rfeedback.save_or_publish === 1 && (
                    <text className={`text-red-400`}> Unpublished </text>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className={`${styles.formRight}`}>
            <div className={`mb-5`}>
              <text className={`${styles.infoTitle}`}>
                <strong>NQAs: </strong>
                <text className={`${styles.infoData}`}>
                  Requiring feedback:{" "}
                  {!isEmpty(location.state) &&
                    location.state.feedback.nqa.join(", ")}
                </text>
              </text>
            </div>
            <div className={`mb-6`}>
              <text className={`${styles.infoTitle}`}>
                <strong>Service Comments: </strong>
                <text className={`${styles.infoData}`}>
                  {!isEmpty(location.state) && location.state.feedback.comment}
                </text>
              </text>
            </div>

            <button
              className={`${styles.greenButton}`}
              onClick={() => {
                const hostname = new URL(window.location.href).hostname.split(
                  "."
                );

                let token = JSON.parse(
                  localStorage.getItem("qip_client_token")
                );

                const sub = new URL(
                  process.env.REACT_APP_QIP_URL
                ).hostname.split(".")[0];

                if (hostname[0] === "localhost") {
                  window.open(
                    "http://localhost:3001/view-service-dashboard?userId=" +
                      location.state.feedback.user_id +
                      "&centerId=" +
                      location.state.feedback.center_id +
                      "&stripeId=" +
                      (!isEmpty(location.state.feedback.stripe_id)
                        ? location.state.feedback.stripe_id
                        : "empty") +
                      "&accessToken=" +
                      token.accessToken
                  );
                } else {
                  const host = hostname.slice(1, hostname.length).join(".");
                  window.open(
                    "https://" +
                      sub +
                      "." +
                      host +
                      "/view-service-dashboard?userId=" +
                      location.state.feedback.user_id +
                      "&centerId=" +
                      location.state.feedback.center_id +
                      "&stripeId=" +
                      (!isEmpty(location.state.feedback.stripe_id)
                        ? location.state.feedback.stripe_id
                        : "empty") +
                      "&accessToken=" +
                      token.accessToken
                  );
                }
              }}
              // onClick={() =>
              //   history.push("/qip/request-feedback/services-dashboard")
              // }
            >
              View service’s dashboard
            </button>
          </div>
        </div>

        <div
          className={`${styles.formDataRow} flex flex-wrap justify-between py-12`}
        >
          <div className={`${styles.formLeft}`}>
            <div className={styles.inputGroup}>
              <text className={styles.inputName}>
                Provide Feedback
                <span className={styles.inputWarning}>*</span>
              </text>
              <div className={`h-64 pb-14 `}>
                <ReactQuill
                  className={`h-full ${styles.editor}`}
                  modules={modules}
                  value={feedback}
                  onChange={(value) => setFeedback(value)}
                />
                <textarea
                  className={`hidden`}
                  value={feedback}
                  onChange={() => null}
                />
              </div>
              {fieldsChecked && !feedback && (
                <div className={`text-red-400 p-2 font-thin italic `}>
                  This field is required.
                </div>
              )}
            </div>
            {/* <div className={styles.inputGroup}>
              <text className={styles.inputName}>
                Provide Feedback
                <span className={styles.inputWarning}>*</span>
              </text>

              <textarea
                value={feedback}
                className={`${styles.inputTextArea} `}
                onChange={(e) => setFeedback(e.target.value)}
              />
              {fieldsChecked && !feedback && (
                <div className={`text-red-400 p-2 font-thin italic`}>
                  This field is required.
                </div>
              )}
            </div> */}
          </div>

          <div className={`${styles.formRight}`}>
            <div className={styles.inputGroup}>
              <text className={styles.inputName}>
                Reviewed by
                <span className={styles.inputWarning}>*</span>
              </text>

              <input
                value={providedBy}
                className={`${styles.inputBox}`}
                onChange={(e) => setProvidedBy(e.target.value)}
              />
              {fieldsChecked && !providedBy && (
                <div className={`text-red-400 p-2 font-thin italic`}>
                  This field is required.
                </div>
              )}
            </div>

            <div className={styles.inputGroup}>
              <text className={styles.inputName}>
                Date Reviewed
                <span className={styles.inputWarning}>*</span>
              </text>

              <input
                type={`date`}
                value={moment(dateReviewed).format("YYYY-MM-DD")}
                className={`${styles.inputBox}`}
                onChange={(e) => setDateReviewed(e.target.value)}
              />

              {fieldsChecked && !dateReviewed && (
                <div className={`text-red-400 p-2 font-thin italic`}>
                  This field is required.
                </div>
              )}
            </div>

            <div className={`lg:flex items-center my-9`}>
              <text className={`${styles.infoTitle} lg:mr-5`}>
                <strong>NQA Status:</strong>
              </text>

              <div className={` md:mt-2 lg:mt-0`}>
                <div>
                  <div className={`flex items-center justify-between`}>
                    {/* <text> Working towards </text>
                    <Icon name="caret down" /> */}
                    <Dropdown
                      placeholder={`Select...`}
                      selection
                      value={status}
                      options={nqaStatus}
                      onChange={(e, data) => {
                        setStatus(data.value);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className={`lg:flex`}>
              <button
                disabled={loading}
                onClick={() => {
                  setAction("save");
                  if (isEmpty(rfeedback)) {
                    onReplyFeedback(1);
                  } else {
                    onUpdateReplyFeedback(rfeedback.save_or_publish);
                  }
                }}
                className={`${styles.greenButton} md:mb-3 lg:mb-0 lg:mr-3.5`}
              >
                {loading && action === "save" ? (
                  <div className={`flex justify-center items-center`}>
                    {" "}
                    <FaSpinner className={`animate-spin`} />{" "}
                    <span className={`ml-2`}>Saving...</span>{" "}
                  </div>
                ) : (
                  <> Save feedback </>
                )}
              </button>

              {!isEmpty(rfeedback) && rfeedback.save_or_publish === 1 && (
                <button
                  disabled={
                    loading ||
                    isEmpty(rfeedback) ||
                    rfeedback.save_or_publish === 2
                  }
                  onClick={() => {
                    setAction("publish");
                    setShowPublishModal(true);
                  }}
                  className={`${styles.greenButton}`}
                >
                  {loading && action === "publish" ? (
                    <div className={`flex justify-center items-center`}>
                      {" "}
                      <FaSpinner className={`animate-spin`} />{" "}
                      <span className={`ml-2`}>Publishing...</span>{" "}
                    </div>
                  ) : (
                    <> Publish </>
                  )}
                </button>
              )}

              {!isEmpty(rfeedback) && rfeedback.save_or_publish === 2 && (
                <SkipButton
                  disabled={
                    loading ||
                    isEmpty(rfeedback) ||
                    rfeedback.save_or_publish === 1
                  }
                  onClick={() => {
                    setAction("unpublish");
                    setShowRemoveModal(true);
                  }}
                  className={` ml-3`}
                  buttonText={
                    loading && action === "unpublish" ? (
                      <div className={`flex justify-center items-center`}>
                        <FaSpinner className={`animate-spin`} />{" "}
                        <span
                          style={{
                            fontSize: "16px",
                            lineHeight: "19px",
                            fontWeight: "500",
                          }}
                          className={`ml-2`}
                        >
                          Removing...
                        </span>{" "}
                      </div>
                    ) : (
                      <span
                        style={{
                          fontSize: "16px",
                          lineHeight: "19px",
                          fontWeight: "500",
                        }}
                      >
                        {" "}
                        Unpublish{" "}
                      </span>
                    )
                  }
                />
              )}
              {!isEmpty(rfeedback) && (
                <SkipButton
                  disabled={loadingDelete}
                  onClick={() => {
                    setShowDeleteModal(true);
                  }}
                  className={` ml-3`}
                  buttonText={
                    loadingDelete ? (
                      <div className={`flex justify-center items-center`}>
                        <FaSpinner className={`animate-spin`} />{" "}
                        <span
                          style={{
                            fontSize: "16px",
                            lineHeight: "19px",
                            fontWeight: "500",
                          }}
                          className={`ml-2`}
                        >
                          Deleting...
                        </span>{" "}
                      </div>
                    ) : (
                      <span
                        style={{
                          fontSize: "16px",
                          lineHeight: "19px",
                          fontWeight: "500",
                        }}
                      >
                        Delete
                      </span>
                    )
                  }
                />
              )}
            </div>
          </div>
        </div>

        <div
          className={`${styles.formDataRow} flex flex-wrap justify-between py-14 hidden`}
        >
          <h2 className={`mb-6`}>Create a task</h2>

          <div className={`${styles.formLeft}`}>
            <InputComponent
              fieldName="taskName"
              type="text"
              label="Task name"
            />

            <div className={`${styles.formDataInputRow} lg:flex`}>
              <InputComponent
                fieldName="taskFor"
                type="text"
                label="Task for"
              />

              <InputComponent
                fieldName="completedBy"
                type="date"
                label="To be completed by"
              />
            </div>

            <div
              className={`${styles.dropdownGroup} ${styles.dropdownGroupChangeStatus} mt-4`}
            >
              <text className={`${styles.dropdownLabel} mb-3`}>
                Change task status
              </text>
              <div>
                <div
                  className={`${styles.dropdownTextContainer} flex items-center justify-between`}
                >
                  <text> Not started </text>
                  <Icon name="caret down" />
                </div>
              </div>
            </div>
          </div>

          <div className={`${styles.formRight}`}>
            <InputComponent
              textArea
              fieldName="taskSummary"
              label="Task summary"
              className={`${styles.taskSummary}`}
            />
          </div>

          <BlueButton
            className={`${styles.setTaskButton} flex justify-center mt-6`}
            buttonText="Set task"
          />
        </div>

        <div className={`flex justify-center mt-14 hidden`}>
          <button className={`${styles.addTask}`}>Add another task</button>
        </div>
      </div>
    </div>
  );
}

export default SingleFeedbackRequest;
