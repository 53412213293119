import * as React from "react";

import { useHistory, useParams } from "react-router-dom";
import {
  FaUsers,
  FaCaretDown,
  FaCaretUp,
  FaSearch,
  FaCheckCircle,
  FaTrashAlt,
  FaArrowAltCircleLeft,
  FaTimesCircle,
  FaTrashRestore,
} from "react-icons/fa";
import { Button, Dropdown } from "semantic-ui-react";
import BlueButton from "../../../../components/BlueButton";
import DeleteSubscriptionModal from "../../../../components/Modals/DeleteSubscriptionModal";
import CancelSubscriptionSubscribersModal from "../../../../components/Modals/CancelSubscriptionSubscribersModal";
import InvoiceNumberFilterModal from "../../../../components/Modals/InvoiceNumberFilterModal";
import ChooseDateRangePastInvoicesModal from "../../../../components/Modals/ChooseDateRangePastInvoicesModal";
import ChooseServiceFilterModal from "../../../../components/Modals/ChooseServiceFilterModal";
import DeletePermanentSubscriptionModal from "../../../../components/Modals/DeletePermanentSubscriptionModal";
import DeletedPermanentlySubscriptionModal from "../../../../components/Modals/DeletedPermanentlySubscriptionModal";
import RestoredSubscriptionModal from "../../../../components/Modals/RestoredSubscriptionModal";
import {
  getUserSubscriptions,
  getPaymentMethods,
  getInvoices,
  cancelSubscription,
  softDeleteSubscription,
  getAllSdSubscription,
  restoreSoftDeleteSubscription,
  deleteSdSubscription,
  softDeleteTrialCenter,
  restoreSoftDeleteTrialCenter,
  hardDeleteTrialCenter,
} from "../../../../api/requests";
import useSWR, { mutate } from "swr";
import styles from "./SubscriptionAndBilling.module.css";
import { isEmpty } from "lodash";
import moment from "moment";
import { FaSpinner } from "react-icons/fa";
import Pagination from "../../../../components/Pagination/Pagination";
import Swal from "sweetalert2";

const { useState, useCallback, useRef } = React;

const invoicesPerPage = 10;

function SubscriptionAndBilling({ location }) {
  const history = useHistory();

  const { centers } = location.state.sumLocState;

  const invoiceRef = useRef();

  const [downloading, setDownloading] = useState(false);

  const [invoiceName, setInvoiceName] = useState("");
  const [invoiceFile, setInvoiceFile] = useState("");
  const [invoiceNum, setInvoiceNum] = useState("");

  const [activeView, setActiveView] = useState(true);

  const [activeInvoiceTab, setActiveInvoiceTab] = useState("all");

  const [selectedCenters, setSelectedCenters] = useState([]);

  const [searchKey, setSearchKey] = useState("");

  const [currentServiceId, setCurrentServiceId] = useState(0);

  const [currentSdServiceId, setCurrentSdServiceId] = useState(0);

  const [currentDateFilter, setCurrentDateFilter] = useState({
    from: "",
    to: "",
  });

  const [sdType, setSdType] = useState(0);

  const [restoreType, setRestoreType] = useState(0);

  const [hdType, setHdType] = useState(0);

  const [currentPage, setCurrentPage] = useState(1);

  const [loadingsd, setLoadingsd] = useState(false);

  const [loadingcs, setLoadingcs] = useState(false);

  const [loadingpd, setLoadingpd] = useState(false);

  const [loadingrs, setLoadingrs] = useState(false);

  const [deleteSubscriptionModal, setDeleteSubscriptionModal] = useState(false);
  const [
    cancelSubscriptionSubscribersModal,
    setCancelSubscriptionSubscribersModal,
  ] = useState(false);
  const [invoiceNumberFilterModal, setInvoiceNumberFilterModal] =
    useState(false);
  const [
    chooseDateRangePastInvoicesModal,
    setChooseDateRangePastInvoicesModal,
  ] = useState(false);
  const [chooseServiceFilterModal, setChooseServiceFilterModal] =
    useState(false);

  const [permanentlyDeleteSubModal, setPermanentlyDeleteSubModal] =
    useState(false);

  const [permanentlyDeletedSubModal, setPermanentlyDeletedSubModal] =
    useState(false);

  const [restoreDeletedSubModal, setRestoreDeletedSubModal] = useState(false);

  const [fetchLimitError, setFetchLimitError] = useState(false);

  const { data: subscriptions, isValidating } = useSWR(
    ["/subscriptions", location.state.userId, centers],
    (_, userId, centers) => getUserSubscriptions(userId, centers),
    {
      onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
        // Only retry up to 3 times.
        if (retryCount && retryCount >= 3) {
          if (!subscriptions) setFetchLimitError(true);

          return;
        }
        // Retry after 3 seconds.
        setTimeout(() => revalidate({ retryCount: retryCount + 1 }), 3000);
      },
      onSuccess: (data) => {
        if (isEmpty(data.active) && isEmpty(data.softDeleted)) {
          window.location = `/qip/subscribers/summary`;
        }
      },
    }
  );

  const { data: pms, isValidating: isValidatingPms } = useSWR(
    ["/paymentMethods", location.state.userId],
    (_, userId) => getPaymentMethods(userId),
    {
      onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
        // Only retry up to 3 times.
        if (retryCount && retryCount >= 3) {
          if (!pms) setFetchLimitError(true);

          return;
        }
        // Retry after 3 seconds.
        setTimeout(() => revalidate({ retryCount: retryCount + 1 }), 3000);
      },
    }
  );

  const { data: invoices, isValidating: isValidatingInvoices } = useSWR(
    ["/invoices", location.state.userId],
    (_, userId) => getInvoices(userId),
    {
      onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
        // Only retry up to 3 times.
        if (retryCount && retryCount >= 3) {
          if (!invoices) setFetchLimitError(true);

          return;
        }
        // Retry after 3 seconds.
        setTimeout(() => revalidate({ retryCount: retryCount + 1 }), 3000);
      },
    }
  );

  const onDownloadInvoice = async (invoiceURL, invoiceName) => {
    await setDownloading(true);

    await setInvoiceName(invoiceName);
    await setInvoiceFile(invoiceURL);
    await invoiceRef.current.click();

    setDownloading(false);
  };

  const ServiceOptions = ({ currentService }) => {
    if (currentService.product === "trial") {
      return (
        <ul className={`${styles.tableMenuAction}`}>
          <li
            className={`flex items-center justify-start`}
            onClick={() => {
              setSdType(0);
              setDeleteSubscriptionModal(true);
            }}
          >
            <FaTrashAlt className={`mr-1.5`} size={18} />
            Delete subscription
          </li>
        </ul>
      );
    } else {
      if (currentService.subscription.cancel_at_period_end) {
        return (
          <ul className={`${styles.tableMenuAction}`}>
            <li
              className={`flex items-center justify-start`}
              onClick={() => {
                setSdType(1);
                setDeleteSubscriptionModal(true);
              }}
            >
              <FaTrashAlt className={`mr-1.5`} size={18} />
              Delete subscription
            </li>
          </ul>
        );
      } else {
        return (
          <ul className={`${styles.tableMenuAction}`}>
            <li
              className={`flex items-center justify-start`}
              onClick={() => setCancelSubscriptionSubscribersModal(true)}
            >
              <FaTimesCircle className={`mr-1.5`} size={18} />
              Cancel subscription
            </li>
            <li
              className={`opacity-50 flex items-center justify-start cursor-default `}
            >
              <FaTrashAlt className={`mr-1.5`} size={18} />
              Delete subscription
            </li>
          </ul>
        );
      }
    }
  };

  const SDServiceOptions = ({ currentService }) => {
    return (
      <ul className={`${styles.tableMenuAction}`}>
        <li
          className={`flex items-center justify-start`}
          onClick={() => {
            if (currentService.product === "trial") {
              setRestoreType(0);
            } else {
              setRestoreType(1);
            }
            setRestoreDeletedSubModal(true);
          }}
        >
          <FaTrashRestore className={`mr-1.5`} size={18} />
          Restore subscription
        </li>
        <li
          className={`flex items-center justify-start`}
          onClick={() => {
            if (currentService.product === "trial") {
              setHdType(0);
            } else {
              setHdType(1);
            }
            setPermanentlyDeleteSubModal(true);
          }}
        >
          <FaTimesCircle className={`mr-1.5`} size={18} />
          Permanently delete
        </li>
      </ul>
    );
  };

  const compareDate = (ref, from, to) => {
    if (moment(ref).isBetween(from, to, undefined, `[]`)) {
      return true;
    }
    return false;
  };

  const alertMessage = (type, message) => {
    Swal.fire({
      icon: `${type}`,
      title: `${message}`,
      showConfirmButton: false,
      timer: 1500,
    });
  };

  const onConFirmCancelSub = async () => {
    try {
      setLoadingcs(true);
      const response = await cancelSubscription(
        Number(location.state.userId),
        Number(currentServiceId)
      );
      await mutate(["/subscriptions", location.state.userId, centers]);

      setCancelSubscriptionSubscribersModal(false);
      setLoadingcs(false);
      alertMessage(`info`, `Subscription canceled.`);
    } catch (error) {
      setCancelSubscriptionSubscribersModal(false);
      setLoadingcs(false);
      console.log(error);
    }
  };

  const onConfirmRestoreSub = async () => {
    if (currentSdServiceId !== 0) {
      try {
        setLoadingrs(true);
        if (restoreType === 1) {
          const response = await restoreSoftDeleteSubscription(
            Number(location.state.userId),
            Number(currentSdServiceId)
          );
        }

        if (restoreType === 0) {
          const response = await restoreSoftDeleteTrialCenter(
            Number(currentSdServiceId)
          );
        }

        await mutate(["/subscriptions", location.state.userId, centers]);

        setRestoreDeletedSubModal(false);
        setLoadingrs(false);
        alertMessage(`info`, `Subscription restored.`);
      } catch (error) {
        setRestoreDeletedSubModal(false);
        setLoadingrs(false);
        console.log(error);
      }
    }
  };

  const onConfirmPermanentDeleteSub = async () => {
    if (currentSdServiceId !== 0) {
      try {
        setLoadingpd(true);
        if (hdType === 1) {
          const response = await deleteSdSubscription(
            Number(location.state.userId),
            Number(currentSdServiceId)
          );
        }

        if (hdType === 0) {
          const response = await hardDeleteTrialCenter(
            Number(currentSdServiceId)
          );
        }

        await mutate(["/subscriptions", location.state.userId, centers]);

        setPermanentlyDeleteSubModal(false);
        setPermanentlyDeletedSubModal(true);
        setLoadingpd(false);
      } catch (error) {
        setPermanentlyDeleteSubModal(false);
        setPermanentlyDeletedSubModal(true);
        setLoadingpd(false);
        console.log(error);
      }
    }
  };

  const onConfirmDeleteSub = async () => {
    try {
      setLoadingsd(true);
      if (sdType === 1) {
        const response = await softDeleteSubscription(
          Number(location.state.userId),
          Number(currentServiceId)
        );
      }
      if (sdType === 0) {
        console.log("current sd ", currentServiceId);
        const response = await softDeleteTrialCenter(Number(currentServiceId));
        await mutate(["/centers/admin/users"]);
      }

      await mutate(["/subscriptions", location.state.userId, centers]);
      setDeleteSubscriptionModal(false);
      alertMessage(`info`, `Subscription deleted.`);
      setLoadingsd(false);
    } catch (error) {
      setDeleteSubscriptionModal(false);
      setLoadingsd(false);
      console.log(error);
    }
  };

  return (
    <div className={`${styles.mainContainer} mt-8 mb-28`}>
      {chooseServiceFilterModal && !isEmpty(invoices) && (
        <ChooseServiceFilterModal
          visibility={chooseServiceFilterModal}
          onOpenModal={() => setChooseServiceFilterModal(true)}
          onCloseModal={() => setChooseServiceFilterModal(false)}
          centers={invoices.centers}
          selectedCenters={selectedCenters}
          setCenters={(centers) => {
            setSelectedCenters([...centers]);
          }}
        />
      )}

      {chooseDateRangePastInvoicesModal && (
        <ChooseDateRangePastInvoicesModal
          visibility={chooseDateRangePastInvoicesModal}
          onOpenModal={() => setChooseDateRangePastInvoicesModal(true)}
          onCloseModal={() => setChooseDateRangePastInvoicesModal(false)}
          onSetDateFilter={(from, to) => {
            setCurrentDateFilter({ from, to });
          }}
          currentDateFilter={currentDateFilter}
        />
      )}

      {cancelSubscriptionSubscribersModal && (
        <CancelSubscriptionSubscribersModal
          visibility={cancelSubscriptionSubscribersModal}
          onOpenModal={() => setCancelSubscriptionSubscribersModal(true)}
          onCloseModal={() => setCancelSubscriptionSubscribersModal(false)}
          onConfirm={onConFirmCancelSub}
          loading={loadingcs}
        />
      )}

      {deleteSubscriptionModal && (
        <DeleteSubscriptionModal
          visibility={deleteSubscriptionModal}
          onOpenModal={() => setDeleteSubscriptionModal(true)}
          onCloseModal={() => setDeleteSubscriptionModal(false)}
          onConfirm={onConfirmDeleteSub}
          loading={loadingsd}
        />
      )}

      {invoiceNumberFilterModal && (
        <InvoiceNumberFilterModal
          visibility={invoiceNumberFilterModal}
          onOpenModal={() => setInvoiceNumberFilterModal(true)}
          onCloseModal={() => setInvoiceNumberFilterModal(false)}
          currentKey={searchKey}
          setSearch={setSearchKey}
        />
      )}

      {permanentlyDeleteSubModal && (
        <DeletePermanentSubscriptionModal
          visibility={permanentlyDeleteSubModal}
          onOpenModal={() => setPermanentlyDeleteSubModal(true)}
          onCloseModal={() => setPermanentlyDeleteSubModal(false)}
          onConfirm={onConfirmPermanentDeleteSub}
          loading={loadingpd}
        />
      )}

      {permanentlyDeletedSubModal && (
        <DeletedPermanentlySubscriptionModal
          visibility={permanentlyDeletedSubModal}
          onOpenModal={() => setPermanentlyDeletedSubModal(true)}
          onCloseModal={() => setPermanentlyDeletedSubModal(false)}
          onConfirm={() => {
            setPermanentlyDeletedSubModal(false);
          }}
        />
      )}

      {restoreDeletedSubModal && (
        <RestoredSubscriptionModal
          visibility={restoreDeletedSubModal}
          onOpenModal={() => setRestoreDeletedSubModal(true)}
          onCloseModal={() => setRestoreDeletedSubModal(false)}
          onConfirm={onConfirmRestoreSub}
          loading={loadingrs}
        />
      )}

      <div
        className={`${styles.headerTextContainer} flex items-center justify-between`}
      >
        <div className={`flex`}>
          <div className={styles.headerTextIcon}>
            <FaUsers size={55} />
          </div>

          <div className={`${styles.headerText} ml-4`}>
            <h1>{!isEmpty(location.state) && location.state.userName}</h1>
            <text>Subscription & Billing</text>
          </div>
        </div>

        <div className={`${styles.headerTextButton}`}>
          <button
            className={`flex items-center justify-center`}
            onClick={() => history.goBack()}
          >
            <FaArrowAltCircleLeft size={16} />
            Back
          </button>
        </div>
      </div>

      <div className={`${styles.container} md:px-7 lg:px-14 py-10 mt-7`}>
        <div className={`${styles.subsDetails}`}>
          <div
            className={`${styles.sectionHeader} flex items-center justify-between`}
          >
            <h2>Subscription details</h2>
            <text className={`flex items-center`}>
              <span className={`${styles.servicesNo} mr-1.5`}>
                {!isEmpty(subscriptions) && subscriptions.active.length}
              </span>{" "}
              {!isEmpty(subscriptions) ? (
                subscriptions.active.length > 1 ? (
                  `Services
              on this subscription`
                ) : (
                  `Service
              on this subscription`
                )
              ) : (
                <> </>
              )}
            </text>
          </div>

          <div className={`flex items-center`}>
            <label
              onClick={() => {
                setCurrentServiceId(0);
                setCurrentSdServiceId(0);
                setActiveView(true);
              }}
              className={`${
                activeView ? styles.topHeaderStatus : styles.deletedBtn
              } inline-flex items-center cursor-pointer `}
            >
              <FaCheckCircle className={`mr-1.5`} size={12} /> Active
            </label>
            <label
              onClick={() => {
                setCurrentServiceId(0);
                setCurrentSdServiceId(0);
                setActiveView(false);
              }}
              className={`${
                activeView ? styles.deletedBtn : styles.topHeaderStatus
              } inline-flex items-center cursor-pointer ml-1.5`}
            >
              <FaTrashAlt className={`mr-1.5`} size={12} /> Deleted (
              {!isEmpty(subscriptions) ? subscriptions.softDeleted.length : 0})
            </label>
          </div>
          {activeView && (
            <div className={`${styles.tableContainer}`}>
              <div className={`${styles.tableHeader} flex flex-wrap`}>
                <div className={`${styles.tableHeaderName} w-1/3 flex p-4`}>
                  <text>Service name</text>
                </div>
                <div className={`${styles.tableHeaderName} flex p-4`}>
                  <text>Subscription</text>
                </div>
                <div className={`${styles.tableHeaderName} flex p-4`}>
                  <text>Status</text>
                </div>
                <div className={`${styles.tableHeaderName} flex p-4`}>
                  <text>Status</text>
                </div>
                <div className={`${styles.tableHeaderName} flex p-4`}>
                  <text>Next payment</text>
                </div>
              </div>
              {console.log("subscription active ", subscriptions)}
              <div className={`${styles.tableBody}`}>
                {!isEmpty(subscriptions) &&
                  subscriptions.active.map((sub) => {
                    return (
                      <div
                        className={`${styles.tableRow} w-full flex flex-wrap`}
                      >
                        <div
                          className={`${styles.tableData} flex flex-col p-4`}
                        >
                          <text>{sub.center_name}</text>
                        </div>
                        <div
                          className={`${styles.tableData} flex flex-col p-4`}
                        >
                          <text className={`capitalize`}>{sub.product}</text>
                        </div>
                        <div
                          className={`${styles.tableData} flex flex-col p-4`}
                        >
                          <text className={`capitalize`}>
                            {sub.subscription.product_name === "trial"
                              ? sub.subscription.trial_remaining_days.includes(
                                  "-"
                                )
                                ? "Trial (Ended) "
                                : "Trial"
                              : sub.subscription.cancel_at_period_end
                              ? "Canceled"
                              : sub.subscription.status === "trialing"
                              ? "Trial"
                              : sub.subscription.status ===
                                  "incomplete_expired" ||
                                sub.subscription.status === "incomplete" ||
                                sub.subscription.status === "past_due"
                              ? "Payment Failed"
                              : sub.subscription.status}
                          </text>
                        </div>
                        <div
                          className={`${styles.tableData} flex flex-col p-4`}
                        >
                          <text>QIP</text>
                        </div>
                        <div
                          className={`${styles.tableData} flex flex-col p-4`}
                        >
                          <text>
                            {sub.product !== "trial"
                              ? sub.subscription.cancel_at_period_end
                                ? `Cancel at ${moment
                                    .unix(sub.subscription.current_period_end)
                                    .format("DD/MM/YYYY")}`
                                : moment
                                    .unix(sub.subscription.current_period_end)
                                    .format("DD/MM/YYYY")
                              : `Trial end: ${moment(
                                  sub.subscription.created_at,
                                  "DD-MM-YYYY"
                                )
                                  .add(30, "days")
                                  .format("DD/MM/YYYY")}`}
                          </text>
                        </div>
                        <div
                          className={`${styles.tableData} text-center p-1 relative`}
                        >
                          <Button
                            onClick={() => {
                              if (currentServiceId !== 0) {
                                if (currentServiceId !== sub.center_id) {
                                  setCurrentServiceId(sub.center_id);
                                } else {
                                  setCurrentServiceId(0);
                                }
                              } else {
                                setCurrentServiceId(sub.center_id);
                              }
                            }}
                            className={`${styles.cog} ${
                              currentServiceId === sub.center_id
                                ? styles.active
                                : ""
                            }`}
                            icon="cog"
                          />
                          {currentServiceId === sub.center_id && (
                            <ServiceOptions currentService={sub} />
                          )}
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          )}

          {!activeView && (
            <div className={`${styles.tableContainer}`}>
              <div className={`${styles.tableHeader} flex flex-wrap`}>
                <div className={`${styles.tableHeaderName} w-1/3 flex p-4`}>
                  <text>Service name</text>
                </div>
                <div className={`${styles.tableHeaderName} flex p-4`}>
                  <text>Subscription</text>
                </div>
                <div className={`${styles.tableHeaderName} flex p-4`}>
                  <text>Status</text>
                </div>
                <div className={`${styles.tableHeaderName} flex p-4`}>
                  <text>Status</text>
                </div>
                <div className={`${styles.tableHeaderName} flex p-4`}>
                  <text>Next payment</text>
                </div>
              </div>

              <div className={`${styles.tableBody}`}>
                {!isEmpty(subscriptions) &&
                  subscriptions.softDeleted.map((sub) => {
                    return (
                      <div
                        className={`${styles.tableRow} w-full flex flex-wrap`}
                      >
                        <div
                          className={`${styles.tableData} flex flex-col p-4`}
                        >
                          <text>{sub.center_name}</text>
                        </div>
                        <div
                          className={`${styles.tableData} flex flex-col p-4`}
                        >
                          <text className={`capitalize`}>{sub.product}</text>
                        </div>
                        <div
                          className={`${styles.tableData} flex flex-col p-4`}
                        >
                          <text className={`capitalize`}>
                            {sub.subscription.product_name === "trial"
                              ? sub.subscription.trial_remaining_days.includes(
                                  "-"
                                )
                                ? "Trial (Ended) "
                                : "Trial"
                              : sub.subscription.cancel_at_period_end
                              ? "Canceled"
                              : sub.subscription.status === "trialing"
                              ? "Trial"
                              : sub.subscription.status ===
                                  "incomplete_expired" ||
                                sub.subscription.status === "incomplete" ||
                                sub.subscription.status === "past_due"
                              ? "Payment Failed"
                              : sub.subscription.status}
                          </text>
                        </div>
                        <div
                          className={`${styles.tableData} flex flex-col p-4`}
                        >
                          <text>QIP</text>
                        </div>
                        <div
                          className={`${styles.tableData} flex flex-col p-4`}
                        >
                          <text>
                            {sub.product !== "trial"
                              ? sub.subscription.cancel_at_period_end
                                ? `Cancel at ${moment
                                    .unix(sub.subscription.current_period_end)
                                    .format("DD/MM/YYYY")}`
                                : moment
                                    .unix(sub.subscription.current_period_end)
                                    .format("DD/MM/YYYY")
                              : `Trial end: ${
                                  sub.subscription.trial_ends.split("-")[0]
                                }/${
                                  sub.subscription.trial_ends.split("-")[1]
                                }/${sub.subscription.trial_ends.split("-")[2]}`}
                          </text>
                        </div>
                        <div
                          className={`${styles.tableData} text-center p-1 relative`}
                        >
                          <Button
                            onClick={() => {
                              if (currentSdServiceId !== 0) {
                                if (currentSdServiceId !== sub.center_id) {
                                  setCurrentSdServiceId(sub.center_id);
                                } else {
                                  setCurrentSdServiceId(0);
                                }
                              } else {
                                console.log(sub.center_id + " center_id");
                                setCurrentSdServiceId(sub.center_id);
                              }
                            }}
                            className={`${styles.cog} ${
                              currentSdServiceId === sub.center_id
                                ? styles.active
                                : ""
                            }`}
                            icon="cog"
                          />
                          {currentSdServiceId === sub.center_id && (
                            <SDServiceOptions currentService={sub} />
                          )}
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          )}
        </div>

        <div className={`${styles.paymentDetails} mt-14`}>
          <div className={`${styles.sectionHeader}`}>
            <h2>Payment details</h2>
          </div>
          {!isEmpty(pms) &&
            pms.data.map((pm) => {
              return (
                <ul className={`grid`}>
                  <li>Subscription method:</li>
                  <li>
                    {`${
                      pm.card.brand.charAt(0).toUpperCase() +
                      pm.card.brand.slice(1)
                    }
                    ending ${pm.card.last4}`}
                  </li>
                  <li>{`Expires ${pm.card.exp_month}/${pm.card.exp_year}`}</li>
                </ul>
              );
            })}
        </div>

        <div className={`${styles.pastInvoices} mt-14`}>
          <div
            className={`${styles.sectionHeader} flex items-center justify-between`}
          >
            <h2>Past invoices</h2>
            <text>Summary of past invoices</text>
          </div>

          <div className={`${styles.invoicesFilter}`}>
            <ul className={`grid`}>
              <li
                onClick={() => {
                  setSelectedCenters([]);
                  setCurrentDateFilter({ from: "", to: "" });
                  setSearchKey("");
                  setActiveInvoiceTab("all");
                }}
                className={`${activeInvoiceTab === "all" ? styles.active : ""}`}
              >
                All invoices
              </li>
              <li
                onClick={() => {
                  setActiveInvoiceTab("service");
                  setChooseServiceFilterModal(true);
                }}
                className={`${
                  activeInvoiceTab === "service" ? styles.active : ""
                }`}
              >
                Choose a service
              </li>
              <li
                onClick={() => {
                  setActiveInvoiceTab("date");
                  setChooseDateRangePastInvoicesModal(true);
                }}
                className={`${
                  activeInvoiceTab === "date" ? styles.active : ""
                }`}
              >
                Date added
              </li>
              <li
                onClick={() => {
                  setActiveInvoiceTab("number");
                  setInvoiceNumberFilterModal(true);
                }}
                className={`${
                  activeInvoiceTab === "number" ? styles.active : ""
                }`}
              >
                Invoice number
              </li>
            </ul>
          </div>

          <div className={`${styles.tableContainer}`}>
            <div className={`${styles.tableHeader} flex flex-wrap`}>
              <div className={`${styles.tableHeaderName} w-1/3 flex p-4`}>
                <text>Service</text>
                <FaCaretDown className={`ml-1 mt-1`} size={12} />
              </div>
              <div className={`${styles.tableHeaderName} flex p-4`}>
                <text>Date</text>
                <FaCaretDown className={`ml-1 mt-1`} size={12} />
              </div>
              <div className={`${styles.tableHeaderName} flex p-4`}>
                <text>Invoice number</text>
                <FaCaretDown className={`ml-1 mt-1`} size={12} />
              </div>
              <div className={`${styles.tableHeaderName} flex p-4`}>
                <text>Download</text>
              </div>
            </div>

            <div className={`${styles.tableBody}`}>
              {!isEmpty(invoices) &&
                invoices.invoices
                  .filter((invoice) => {
                    if (!isEmpty(selectedCenters)) {
                      return selectedCenters.includes(invoice.center);
                    } else {
                      return true;
                    }
                  })
                  .filter((invoice) => {
                    if (
                      !isEmpty(currentDateFilter.from) &&
                      !isEmpty(currentDateFilter.to)
                    ) {
                      return compareDate(
                        invoice.date,
                        moment(currentDateFilter.from).format("DD/MM/YYYY"),
                        moment(currentDateFilter.to).format("DD/MM/YYYY")
                      );
                    } else {
                      return true;
                    }
                  })
                  .filter((invoice) => {
                    if (!isEmpty(searchKey.trim())) {
                      return invoice.invoice.number.includes(searchKey.trim());
                    } else {
                      return true;
                    }
                  })
                  .map((invoice) => {
                    return (
                      <div
                        className={`${styles.tableRow} w-full flex flex-wrap`}
                      >
                        <div
                          className={`${styles.tableData} flex flex-col p-4`}
                        >
                          <text>{invoice.center}</text>
                        </div>
                        <div
                          className={`${styles.tableData} flex flex-col p-4`}
                        >
                          <text>{invoice.date}</text>
                        </div>
                        <div
                          className={`${styles.tableData} flex flex-col p-4`}
                        >
                          <text>{invoice.invoice.number}</text>
                        </div>

                        <div className={`${styles.tableData} p-1.5`}>
                          <button
                            onClick={() => {
                              setInvoiceNum(invoice.invoice.number);
                              setDownloading(true);

                              onDownloadInvoice(
                                invoice.invoice.invoice_pdf,
                                `Invoice-${invoice.invoice.number}`
                              );
                            }}
                            className={`${styles.downloadButton}`}
                          >
                            <span>Download</span>
                          </button>
                          <a
                            ref={invoiceRef}
                            href={invoiceFile}
                            download={invoiceName}
                            className={`hidden`}
                          ></a>
                        </div>
                      </div>
                    );
                  })}
            </div>
          </div>
        </div>

        <div className={`${styles.paginationContainer} flex justify-start`}>
          {!isEmpty(invoices) && invoicesPerPage < invoices.invoices.length && (
            <Pagination
              currentPage={currentPage}
              onPageChange={setCurrentPage}
              lastPage={Math.ceil(invoices.invoices.length / invoicesPerPage)}
            />
          )}
        </div>

        <div className={`${styles.viewSubsAccount}`}>
          <div className={`${styles.sectionHeader}`}>
            <h2>View subscriber’s account</h2>
          </div>

          <div className={`${styles.viewSubsAccountBtn} grid`}>
            <BlueButton
              onClick={() => {
                const hostname = new URL(window.location.href).hostname.split(
                  "."
                );

                let token = JSON.parse(
                  localStorage.getItem("qip_client_token")
                );

                const sub = new URL(
                  process.env.REACT_APP_QIP_URL
                ).hostname.split(".")[0];

                if (hostname[0] === "localhost") {
                  window.open(
                    "http://localhost:3001/view-service-dashboard?userId=" +
                      location.state.userId +
                      "&centerId=" +
                      location.state.sumLocState.centerId +
                      "&stripeId=" +
                      (!isEmpty(
                        location.state.sumLocState.centers[0].subscription
                      )
                        ? location.state.sumLocState.centers[0].subscription
                            .customer_id
                        : "empty") +
                      "&accessToken=" +
                      token.accessToken
                  );
                } else {
                  const host = hostname.slice(1, hostname.length).join(".");
                  window.open(
                    "https://" +
                      sub +
                      "." +
                      host +
                      "/view-service-dashboard?userId=" +
                      location.state.userId +
                      "&centerId=" +
                      location.state.sumLocState.centerId +
                      "&stripeId=" +
                      (!isEmpty(
                        location.state.sumLocState.centers[0].subscription
                      )
                        ? location.state.sumLocState.centers[0].subscription
                            .customer_id
                        : "empty") +
                      "&accessToken=" +
                      token.accessToken
                  );
                }
              }}
              buttonText="Visit service’s dashboard"
            />
            <BlueButton buttonText="Subscription & Billing" />
            <BlueButton
              onClick={() => {
                history.push(`/qip/subscribers/app-usage-logs`, {
                  sumLocState: location.state.sumLocState,
                  userId: location.state.userId,
                  userName: location.state.userName,
                });
              }}
              buttonText="App usage logs"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default SubscriptionAndBilling;
