import * as React from "react";

import PropTypes from "prop-types";
import { Button, Modal } from "semantic-ui-react";

import styles from "./TasksChooseDateRangeModal.module.css";
import BlueButton from "../../BlueButton";

const { useState, useRef, useEffect } = React;

function TasksChooseDateRangeModal({
  visibility,
  onOpenModal,
  onCloseModal,
  onSetDateFilter,
}) {
  const [fromDate, setFromDate] = useState("");
  const [dateError, setDateError] = useState(false);
  const [toDate, setToDate] = useState("");

  const fromRef = useRef();
  const toRef = useRef();

  const onSelectDate = (dateOption, date) => {
    if (dateOption === "from") {
      if (toDate) {
        const dateCompare = new Date(toDate);
        const dateSelected = new Date(date);

        if (dateSelected.getTime() > dateCompare.getTime()) setDateError(true);
        else setDateError(false);
      }

      setFromDate(date);
    } else {
      if (fromDate) {
        const dateCompare = new Date(fromDate);
        const dateSelected = new Date(date);

        if (dateSelected.getTime() < dateCompare.getTime()) setDateError(true);
        else setDateError(false);
      }

      setToDate(date);
    }
  };

  const onClearDate = () => {
    setFromDate("");
    setToDate("");
    onSetDateFilter("", "");
  };

  const onFilterTask = () => {
    onSetDateFilter(fromDate, toDate);
    onCloseModal();
  };

  return (
    <Modal
      size="small"
      centered={false}
      open={visibility}
      onOpen={onOpenModal}
      onClose={onCloseModal}
    >
      <div className={styles.modalContainer}>
        <Button
          className={styles.closeButton}
          onClick={onCloseModal}
          size="big"
          icon="close"
        />

        <text className={styles.headerText}>Choose date range</text>

        <div className={styles.dateRow}>
          <div>
            <text className={styles.dateHeader}>Choose from date</text>

            <div className={styles.datePickerGroup}>
              <input
                ref={fromRef}
                onFocus={() => (fromRef.current.type = `date`)}
                onBlur={() => (fromRef.current.type = `text`)}
                className={
                  dateError
                    ? `${styles.datePickerBox} ${styles.errorBorder}`
                    : styles.datePickerBox
                }
                value={fromDate}
                placeholder={`Start date`}
                onChange={(e) => onSelectDate("from", e.target.value)}
              />

              {/* <Icon
                className={styles.datePickerIcon}
                name="caret down"
              /> */}
            </div>
          </div>

          <div>
            <text className={styles.dateHeader}>Choose to date</text>

            <div className={styles.datePickerGroup}>
              <input
                ref={toRef}
                onFocus={() => (toRef.current.type = `date`)}
                onBlur={() => (toRef.current.type = `text`)}
                className={
                  dateError
                    ? `${styles.datePickerBox} ${styles.errorBorder}`
                    : styles.datePickerBox
                }
                value={toDate}
                placeholder={`End date`}
                onChange={(e) => onSelectDate("to", e.target.value)}
              />
              {/* <Icon
                className={styles.datePickerIcon}
                name="caret down"
              /> */}
            </div>
          </div>
        </div>

        <BlueButton
          className={styles.blueButton}
          disabled={dateError}
          buttonText="Filter tasks"
          onClick={onFilterTask}
        />

        <div className={styles.clearSection}>
          <button className={styles.clearSectionButton} onClick={onClearDate}>
            Clear selection
          </button>
        </div>
      </div>
    </Modal>
  );
}

TasksChooseDateRangeModal.propTypes = {
  visibility: PropTypes.bool.isRequired,
  onOpenModal: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  onSetDateFilter: PropTypes.func.isRequired,
};

export default TasksChooseDateRangeModal;
