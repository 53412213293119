import * as React from "react";

import { useHistory, useParams } from "react-router-dom";
import { FaUsers, FaCaretDown, FaArrowAltCircleLeft } from "react-icons/fa";
import { Button, Dropdown } from "semantic-ui-react";
import styles from "./SubscriberDetails.module.css";
import BlueButton from "../../../../components/BlueButton";
import {
  fetchUserCenters,
  fetchNQAProgress,
  listRequestFeedback,
} from "../../../../api/requests";
import Details from "../../../../components/Subscribers/Details/Details";

import { FaSpinner } from "react-icons/fa";
import { isEmpty } from "lodash";

const { useState, useEffect, useCallback } = React;

function SubscriberDetails({ location }) {
  const history = useHistory();

  const [selectedOption, setOption] = useState(0);

  const [feedbacks, setFeedbacks] = useState([]);
  const [userId, setUserId] = useState(
    !isEmpty(location.state) ? location.state.userId : 0
  );
  const [centerId, setCenterId] = useState(
    !isEmpty(location.state) ? location.state.centerId : 0
  );

  const [centers, setCenters] = useState(
    !isEmpty(location.state) ? location.state.centers : []
  );

  const [selectedCenterId, setSelectedCenterId] = useState(
    !isEmpty(location.state) ? location.state.centerId : 0
  );
  const [userName, setUserName] = useState(
    !isEmpty(location.state) ? location.state.userName : ""
  );

  const [services, setServices] = useState(
    !isEmpty(location.state) ? location.state.centers : []
  );

  // const fetchServices = useCallback(async () => {
  //   try {
  //     const userServices = await fetchUserCenters(userId);
  //     if (!isEmpty(userServices)) {
  //       setSelectedCenterId(userServices[0].id);
  //       setServices(userServices);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }, []);

  // useEffect(() => {
  //   fetchServices();
  // }, [location.state]);

  const fetchFeedbacks = useCallback(async () => {
    try {
      const response = await listRequestFeedback();
      setFeedbacks(response);
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    fetchFeedbacks();
  }, []);

  if (isEmpty(location.state)) {
    history.push(`/qip/subscribers/summary`);
    return;
  }

  return (
    <div className={`${styles.mainContainer} mt-5 md:mb-28 lg:mb-10`}>
      <div
        className={`${styles.headerTextContainer} flex items-center justify-between`}
      >
        <div className={`flex`}>
          <div className={styles.headerTextIcon}>
            <FaUsers size={55} />
          </div>

          <div className={`${styles.headerText} ml-4`}>
            <h1>{userName} subscription</h1>
            <text>Subscription details</text>
          </div>
        </div>

        <div className={`${styles.headerTextButton}`}>
          <button
            className={`flex items-center justify-center`}
            onClick={() => history.goBack()}
          >
            <FaArrowAltCircleLeft size={16} />
            Back
          </button>
        </div>
      </div>

      {/* <div className={`${styles.headerTextContainer} flex`}>
        <div className={styles.headerTextIcon}>
          <FaUsers size={55} />
        </div>

        <div className={`${styles.headerText} ml-4`}>
          <h1>{userName} subscription</h1>
          <text>Subscription details</text>
        </div>
      </div>

      <div className={`${styles.headerTextButton}`}>
        <button
          className={`flex items-center justify-center`}
          onClick={() => history.goBack()}
        >
          <FaArrowAltCircleLeft size={16} />
            Back
          </button>
      </div> */}

      <div className={`${styles.detailsContainer} pb-16 mt-9`}>
        <div
          className={`${styles.tabContainer} flex flex-wrap justify-center mb-14`}
        >
          {services.map((service, key) => {
            return (
              <div
                key={key}
                // style={{ minWidth: `${(1 / services.length) * 100}%` }}
                className={`flex-grow ${
                  selectedOption === key || services.length === 1
                    ? styles.tabSelected
                    : styles.tab
                } flex items-center ml-0 p-6`}
                onClick={() => {
                  setSelectedCenterId(service.center_id);
                  setOption(key);
                }}
              >
                {service.center_name}
              </div>
            );
          })}
        </div>
        {services.length === 0 && selectedCenterId === 0 ? (
          <div
            className={`w-full h-full flex flex-col justify-center items-center py-20`}
          >
            <FaSpinner
              className={`animate-spin text-qipGreen font-bold text-6xl`}
            />
            <span className={`block`}> Loading data...</span>
          </div>
        ) : (
          <Details
            sumLocState={location.state}
            userId={userId}
            userName={userName}
            center={
              centers.filter(
                (center) =>
                  Number(center.center_id) === Number(selectedCenterId)
              )[0]
            }
            centerId={selectedCenterId}
            nqaFeedbacks={feedbacks}
          />
        )}
      </div>
    </div>
  );
}

export default SubscriberDetails;
