import * as React from "react";

import PropTypes from "prop-types";
import { Button, Modal } from "semantic-ui-react";

import styles from "./FilterUpcomingTasksModal.module.css";

const { useState } = React;

function FilterUpcomingTasksModal({
  visibility,
  onOpenModal,
  onCloseModal,
}) {

  const [selectedFilter, setSelectedFilter] = useState(1);

  return (
    <Modal
      size="small"
      centered={true}
      open={visibility}
      onOpen={onOpenModal}
      onClose={onCloseModal}
    >
      <div className={`flex flex-col px-20 pt-12 pb-14`}>
        <Button
          className={styles.closeButton}
          onClick={onCloseModal}
          size="big"
          icon="close"
        />

        <text className={`${styles.headerText} block mb-7`}>Filter upcoming tasks</text>

        <div className={`${styles.filterContainer} flex`}>

          <button
            className={ selectedFilter === 1 ? styles.selectedFilter : styles.filter }
            onClick={() => setSelectedFilter(1)}
          >
            This week
          </button>

          <button
            className={ selectedFilter === 2 ? styles.selectedFilter : styles.filter }
            onClick={() => setSelectedFilter(2)}
          >
            This week
          </button>

          <button
            className={ selectedFilter === 3 ? styles.selectedFilter : styles.filter }
            onClick={() => setSelectedFilter(3)}
          >
            This week
          </button>

        </div>

        <div className={`text-center mt-7`}>
          <button
            className={styles.clearSectionButton}
          >
            Clear selection
          </button>
        </div>

      </div>
    </Modal>
  );
}

FilterUpcomingTasksModal.propTypes = {
  visibility: PropTypes.bool.isRequired,
  onOpenModal: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
};

export default FilterUpcomingTasksModal;
