import * as React from "react";
import { useHistory } from "react-router-dom";
import { Button, Dropdown, Modal } from "semantic-ui-react";
import InputComponent from "../../../../components/InputComponent";
import ChooseServiceModal from "../../../../components/Modals/ChooseServiceModal";
import ChooseUserModal from "../../../../components/Modals/ChooseUserModal";
import ShowListModal from "../../../../components/Modals/ShowListModal";
import DeleteMessageModal from "../../../../components/Modals/DeleteMessageModal";
import BlueButton from "../../../../components/BlueButton";

import {
  FaEnvelope,
  FaUsers,
  FaCaretDown,
  FaCaretUp,
  FaSearch,
  FaCheckCircle,
  FaTrashAlt,
  FaCog,
  FaTrashRestore,
  FaTimesCircle,
  FaEye,
  FaSpinner,
} from "react-icons/fa";
import useSWR, { mutate } from "swr";
import { isEmpty } from "lodash";

import { getAllMessages, deleteMessage } from "../../../../api/requests";

import Swal from "sweetalert2";

import styles from "./AllMessages.module.css";
import ReactQuill from "react-quill";
import moment from "moment";
import Pagination from "../../../../components/Pagination/Pagination";
import { message } from "antd";

const { useState } = React;

const messagesPerPage = 10;

function AllMessages() {
  const history = useHistory();

  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [fieldsChecked, setFieldsChecked] = useState(false);

  const [fetchLimitError, setFetchLimitError] = useState(false);

  const [currentMessage, setCurrentMessage] = useState({});
  const [deletingMessage, setDeletingMessage] = useState(false);

  const [searchKey, setSearchKey] = useState("");

  const [columnFilter, setColumnFilter] = useState("none");
  const [filterDir, setFilterDir] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [messageDeletedModal, setMessageDeletedModal] = useState(false);

  const [showListModal, setShowListModal] = useState(false);
  const [items, setItems] = useState([]);
  const [option, setOption] = useState(0);

  const onDeleteMessage = async () => {
    try {
      setDeletingMessage(true);
      const response = await deleteMessage(currentMessage.id);

      await mutate(["/allMessages"]);
      setDeletingMessage(false);
      setShowDeleteModal(false);
      setMessageDeletedModal(true);
    } catch (error) {
      setDeletingMessage(false);
      setShowDeleteModal(false);
      console.log(error);
    }
  };

  const MessageOptions = () => {
    return (
      <ul className={`${styles.tableMenuAction}`}>
        <li
          onClick={() => {
            if (currentMessage.is_draft === 0) {
              history.push(`/qip/messages/view`, {
                message: currentMessage,
              });
            }
            if (currentMessage.is_draft === 1) {
              history.push(`/qip/messages/draft`, {
                message: currentMessage,
              });
            }
          }}
          className={`flex items-center justify-start`}
        >
          View
        </li>

        <li
          onClick={() => {
            setShowDeleteModal(true);
          }}
          className={`flex items-center justify-start`}
        >
          Delete
        </li>
      </ul>
    );
  };

  const { data: messages, isValidating: fetchingMessages } = useSWR(
    ["/allMessages"],
    () => getAllMessages(),
    {
      onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
        // Only retry up to 3 times.
        if (retryCount && retryCount >= 3) {
          if (!messages) setFetchLimitError(true);

          return;
        }
        // Retry after 3 seconds.
        setTimeout(() => revalidate({ retryCount: retryCount + 1 }), 3000);
      },
    }
  );

  const filteredMessages = (array, searchFilter, filterColumn, filterDir) => {
    return array
      .filter((message) => {
        if (searchFilter.trim() === "") {
          return true;
        } else {
          return message.subject
            .toLowerCase()
            .includes(searchFilter.toLowerCase());
        }
      })
      .map((message) => {
        if (filterColumn === "sentto") {
          if (!isEmpty(message.user_to)) {
            return message;
          }
          if (!isEmpty(message.sent_to)) {
            return {
              id: message.id,
              sender: message.sender,
              subject: message.subject,
              content: message.content,
              sent_to: message.sent_to.sort(),
              date: message.date,
              is_draft: message.is_draft,
            };
          }

          if (!isEmpty(message.send_to)) {
            return {
              id: message.id,
              sender: message.sender,
              subject: message.subject,
              content: message.content,
              send_to: message.send_to.sort(),
              date: message.date,
              is_draft: message.is_draft,
            };
          }
        } else {
          return message;
        }
      })
      .sort((a, b) => {
        switch (filterColumn) {
          case "sender":
            if (filterDir) {
              if (a.sender.toLowerCase() < b.sender.toLowerCase()) {
                return -1;
              }
              if (a.sender.toLowerCase() > b.sender.toLowerCase()) {
                return 1;
              }
              return 0;
            } else {
              if (a.sender.toLowerCase() < b.sender.toLowerCase()) {
                return 1;
              }
              if (a.sender.toLowerCase() > b.sender.toLowerCase()) {
                return -1;
              }
              return 0;
            }
          case "subject":
            if (filterDir) {
              if (a.subject.toLowerCase() < b.subject.toLowerCase()) {
                return -1;
              }
              if (a.subject.toLowerCase() > b.subject.toLowerCase()) {
                return 1;
              }
              return 0;
            } else {
              if (a.subject.toLowerCase() < b.subject.toLowerCase()) {
                return 1;
              }
              if (a.subject.toLowerCase() > b.subject.toLowerCase()) {
                return -1;
              }
              return 0;
            }
          case "status":
            if (filterDir) {
              return a.is_draft - b.is_draft;
            } else {
              return b.is_draft - a.is_draft;
            }
          case "date":
            if (filterDir) {
              return moment(a.date, "DD-MM-YYYY").diff(
                moment(b.date, "DD-MM-YYYY")
              );
            } else {
              return moment(b.date, "DD-MM-YYYY").diff(
                moment(a.date, "DD-MM-YYYY")
              );
            }
          // case "sentto":
          //   if (!isEmpty(a.user_to) && !isEmpty(b.user_to)) {
          //     if (a.user_to.toLowerCase() < b.user_to.toLowerCase()) {
          //       return filterDir ? -1 : 1;
          //     }
          //     if (a.user_to.toLowerCase() > b.user_to.toLowerCase()) {
          //       return filterDir ? 1 : -1;
          //     }
          //     return 0;
          //   }
          //   if (isEmpty(a.user_to) && isEmpty(b.user_to)) {
          //     if (a.user_to.toLowerCase() < b.user_to.toLowerCase()) {
          //       return filterDir ? -1 : 1;
          //     }
          //     if (a.user_to.toLowerCase() > b.user_to.toLowerCase()) {
          //       return filterDir ? 1 : -1;
          //     }
          //     return 0;
          //   }
          //   if (!isEmpty(a.user_to) && isEmpty(b.user_to)) {
          //   }
          //   if (isEmpty(a.user_to) && !isEmpty(b.user_to)) {
          //   }
        }
      });
  };

  const onMessageDeletedConfirmation = async () => {
    setMessageDeletedModal(false);
  };

  const ContainLists = (entry) => {
    const limit = 20;
    const threshold = 8;

    if (!isEmpty(entry.user_to)) {
      return <span className={styles.hideOverflow}> {entry.user_to} </span>;
    }

    if (!isEmpty(entry.sent_to)) {
      const arrLength = entry.sent_to.length;

      let testLength = 0;
      let n = 0;

      let strTemp = "";

      entry.sent_to.every((str, index) => {
        testLength = testLength + str.length;
        n = n + 1;
        if (testLength < limit) {
          if (strTemp !== "") {
            strTemp = strTemp + ", " + str;
          } else {
            strTemp = strTemp + str;
          }
        }
        if (testLength >= limit) {
          if (arrLength === n) {
            if (testLength - limit > threshold) {
              if (strTemp !== "") {
                strTemp =
                  strTemp +
                  ", " +
                  str.substr(0, str.length - (testLength - limit)) +
                  "...";
              } else {
                strTemp =
                  strTemp +
                  str.substr(0, str.length - (testLength - limit)) +
                  "...";
              }
            } else {
              if (strTemp !== "") {
                strTemp = strTemp + ", " + str;
              } else {
                strTemp = strTemp + str;
              }
            }
          } else {
            if (strTemp !== "") {
              strTemp =
                strTemp +
                ", " +
                str.substr(0, str.length - (testLength - limit)) +
                "...";
            } else {
              strTemp =
                strTemp +
                str.substr(0, str.length - (testLength - limit)) +
                "...";
            }
          }

          return false;
        }
        return true;
      });

      return arrLength - n > 0 ? (
        <span>
          {`${strTemp} `}
          <span
            className={`py-1 px-2 border-2 bg-white cursor-pointer`}
            onClick={() => {
              setOption(entry.option);
              setItems(entry.sent_to);
              setShowListModal(true);
            }}
          >
            {`+${arrLength - n} more`}
          </span>
        </span>
      ) : (
        <span>{strTemp}</span>
      );
    }

    if (!isEmpty(entry.send_to)) {
      const arrLength = entry.send_to.length;

      let testLength = 0;
      let n = 0;

      let strTemp = "";

      entry.send_to.every((str, index) => {
        testLength = testLength + str.length;
        n = n + 1;
        if (testLength < limit) {
          if (strTemp !== "") {
            strTemp = strTemp + ", " + str;
          } else {
            strTemp = strTemp + str;
          }
        }
        if (testLength >= limit) {
          if (arrLength === n) {
            if (testLength - limit > threshold) {
              if (strTemp !== "") {
                strTemp =
                  strTemp +
                  ", " +
                  str.substr(0, str.length - (testLength - limit)) +
                  "...";
              } else {
                strTemp =
                  strTemp +
                  str.substr(0, str.length - (testLength - limit)) +
                  "...";
              }
            } else {
              if (strTemp !== "") {
                strTemp = strTemp + ", " + str;
              } else {
                strTemp = strTemp + str;
              }
            }
          } else {
            if (strTemp !== "") {
              strTemp =
                strTemp +
                ", " +
                str.substr(0, str.length - (testLength - limit)) +
                "...";
            } else {
              strTemp =
                strTemp +
                str.substr(0, str.length - (testLength - limit)) +
                "...";
            }
          }

          return false;
        }
        return true;
      });

      return arrLength - n > 0 ? (
        <span>
          {`${strTemp} `}
          <span
            className={`py-1 px-2 border-2 bg-white cursor-pointer`}
            onClick={() => {
              setOption(entry.option);
              setItems(entry.send_to);
              setShowListModal(true);
            }}
          >
            {`+${arrLength - n} more`}
          </span>
        </span>
      ) : (
        <span>{strTemp}</span>
      );
    }
  };

  return (
    <div className={`${styles.mainContainer} mt-5 md:mb-28 lg:mb-10`}>
      {messageDeletedModal && (
        <Modal
          size="small"
          centered={true}
          open={messageDeletedModal}
          onOpen={() => setMessageDeletedModal(true)}
          onClose={() => setMessageDeletedModal(false)}
        >
          <div className={styles.modalContainer}>
            <text className={styles.modalHeaderText}>
              Message is now deleted
            </text>
            <BlueButton
              className={styles.blueButton}
              buttonText="Ok"
              onClick={onMessageDeletedConfirmation}
            />
          </div>
        </Modal>
      )}

      {showDeleteModal && (
        <DeleteMessageModal
          visibility={showDeleteModal}
          onCloseModal={() => setShowDeleteModal(false)}
          onRemoveMessage={onDeleteMessage}
          loading={deletingMessage}
        />
      )}

      {showListModal && (
        <ShowListModal
          visibility={showListModal}
          onCloseModal={() => setShowListModal(false)}
          option={option}
          items={items}
        />
      )}
      <div className={`${styles.headerTextContainer} flex`}>
        <div className={styles.headerTextIcon}>
          <FaEnvelope size={55} />
        </div>
        <div className={`w-full flex justify-between justify-baseline`}>
          <div className={`${styles.headerText} ml-4`}>
            <h1>Sent messages</h1>
            <text>View and delete messages sent to users of Holistic QIP</text>
          </div>
          <div
            onClick={() => history.push(`/qip/messages/compose`)}
            className={`${styles.createMessageButton} flex justify-center items-center`}
          >
            <FaEnvelope className={styles.createMessageIcon} />{" "}
            <span className={`${styles.createMessageText} ml-2`}>
              Create message
            </span>
          </div>
        </div>
      </div>

      <div className={`${styles.Content} md:px-7 lg:px-14 pt-16 pb-28 mt-9`}>
        <div className={`w-full flex justify-between`}>
          <h2 className={styles.serviceHeader}>Sent messages</h2>
          <div className={`${styles.searchHolder} flex items-center py-4 px-2`}>
            <input
              className={`${styles.searchText} flex-1`}
              type={`text`}
              placeholder={`Search...`}
              onChange={(e) => {
                if (currentPage !== 1) {
                  setCurrentPage(1);
                }
                setSearchKey(e.target.value);
              }}
            />

            <div
              className={`${styles.searchIconHolder} flex justify-center items-center`}
            >
              <FaSearch className={styles.searchIcon} />
            </div>
          </div>
        </div>
        <div className={`${styles.tableContainer}`}>
          <div className={`${styles.tableHeader} flex flex-wrap`}>
            {/* <div className={`${styles.tableHeaderName} flex p-4`}>
              <text>First Name</text>
              <FaCaretDown className={`ml-1 mt-1`} size={12} />
            </div> */}
            <div
              onClick={() => {
                setColumnFilter("sender");
                setFilterDir(!filterDir);
              }}
              className={`${styles.tableHeaderName} flex p-4`}
            >
              <text>Sender</text>
              {columnFilter === "sender"
                ? (filterDir && (
                    <FaCaretUp className={`ml-1 mt-1`} size={12} />
                  )) ||
                  (!filterDir && (
                    <FaCaretDown className={`ml-1 mt-1`} size={12} />
                  ))
                : (filterDir && (
                    <FaCaretUp className={`ml-1 mt-1`} size={12} />
                  )) ||
                  (!filterDir && (
                    <FaCaretDown className={`ml-1 mt-1`} size={12} />
                  ))}
            </div>

            <div
              onClick={() => {
                setColumnFilter("subject");
                setFilterDir(!filterDir);
              }}
              className={`${styles.tableHeaderName} flex p-4`}
            >
              <text>Message subject</text>
              {columnFilter === "subject"
                ? (filterDir && (
                    <FaCaretUp className={`ml-1 mt-1`} size={12} />
                  )) ||
                  (!filterDir && (
                    <FaCaretDown className={`ml-1 mt-1`} size={12} />
                  ))
                : (filterDir && (
                    <FaCaretUp className={`ml-1 mt-1`} size={12} />
                  )) ||
                  (!filterDir && (
                    <FaCaretDown className={`ml-1 mt-1`} size={12} />
                  ))}
            </div>

            <div
              onClick={() => {
                setColumnFilter("status");
                setFilterDir(!filterDir);
              }}
              className={`${styles.tableHeaderName} flex p-4`}
            >
              <text>Status</text>
              {columnFilter === "status"
                ? (filterDir && (
                    <FaCaretUp className={`ml-1 mt-1`} size={12} />
                  )) ||
                  (!filterDir && (
                    <FaCaretDown className={`ml-1 mt-1`} size={12} />
                  ))
                : (filterDir && (
                    <FaCaretUp className={`ml-1 mt-1`} size={12} />
                  )) ||
                  (!filterDir && (
                    <FaCaretDown className={`ml-1 mt-1`} size={12} />
                  ))}
            </div>

            <div
              onClick={() => {
                setColumnFilter("sentto");
                setFilterDir(!filterDir);
              }}
              className={`${styles.tableHeaderName} w-1/3 flex p-4`}
            >
              <text>Sent to</text>
              {columnFilter === "sentto"
                ? (filterDir && (
                    <FaCaretUp className={`ml-1 mt-1`} size={12} />
                  )) ||
                  (!filterDir && (
                    <FaCaretDown className={`ml-1 mt-1`} size={12} />
                  ))
                : (filterDir && (
                    <FaCaretUp className={`ml-1 mt-1`} size={12} />
                  )) ||
                  (!filterDir && (
                    <FaCaretDown className={`ml-1 mt-1`} size={12} />
                  ))}
            </div>
            <div
              onClick={() => {
                setColumnFilter("date");
                setFilterDir(!filterDir);
              }}
              className={`${styles.tableHeaderName} flex p-4`}
            >
              <text>Send date</text>
              {columnFilter === "date"
                ? (filterDir && (
                    <FaCaretUp className={`ml-1 mt-1`} size={12} />
                  )) ||
                  (!filterDir && (
                    <FaCaretDown className={`ml-1 mt-1`} size={12} />
                  ))
                : (filterDir && (
                    <FaCaretUp className={`ml-1 mt-1`} size={12} />
                  )) ||
                  (!filterDir && (
                    <FaCaretDown className={`ml-1 mt-1`} size={12} />
                  ))}
            </div>

            <div className={`${styles.tableHeaderName} flex p-4`}></div>
          </div>

          {!messages ? (
            <div
              className={`w-full h-full flex flex-col justify-center items-center py-20`}
            >
              <FaSpinner
                className={`animate-spin text-qipGreen font-bold text-6xl`}
              />
              <span className={`block`}> Loading data...</span>
            </div>
          ) : (
            <div className={`${styles.tableBody}`}>
              {messages &&
                filteredMessages(messages, searchKey, columnFilter, filterDir)
                  .slice(
                    currentPage * messagesPerPage - messagesPerPage,
                    currentPage * messagesPerPage
                  )
                  .map((message, key) => {
                    return (
                      <div
                        key={key}
                        className={`${styles.tableRow} w-full flex flex-wrap`}
                      >
                        <div
                          className={`${styles.tableData} break-words flex p-4`}
                        >
                          <text>{message.sender}</text>
                        </div>
                        <div
                          className={`${styles.tableData} break-words flex p-4`}
                        >
                          <text
                            onClick={() => {
                              if (message.is_draft === 0) {
                                history.push(`/qip/messages/view`, {
                                  message,
                                });
                              }
                              if (message.is_draft === 1) {
                                history.push(`/qip/messages/draft`, {
                                  message,
                                });
                              }
                            }}
                            className={`${styles.messageSubject} ${styles.hideOverflow} `}
                          >
                            {message.subject}
                          </text>
                        </div>

                        <div
                          className={`${styles.tableData} flex flex-col p-4`}
                        >
                          <text>
                            {message.is_draft === 0 ? (
                              <span className={styles.sent}>Sent</span>
                            ) : (
                              <span className={styles.draft}> Draft</span>
                            )}
                          </text>
                        </div>

                        <div
                          className={`${styles.tableData} flex flex-col p-4`}
                        >
                          <text>{ContainLists(message)}</text>
                        </div>

                        <div
                          className={`${styles.tableData} flex flex-col p-4`}
                        >
                          <text>{message.date.split("-").join("/")}</text>
                        </div>
                        <div
                          className={`${styles.tableData} flex justify-center items-center `}
                        >
                          <div className={`relative `}>
                            <Button
                              disabled={deletingMessage}
                              className={`block ${
                                !isEmpty(currentMessage) &&
                                currentMessage.id === message.id
                                  ? styles.cogSelected
                                  : styles.cog
                              }`}
                              icon="cog"
                              onClick={() => {
                                // setCurrentMessage(message);
                                if (
                                  !isEmpty(currentMessage) &&
                                  currentMessage.id === message.id
                                ) {
                                  setCurrentMessage({});
                                } else {
                                  setCurrentMessage(message);
                                }
                              }}
                            />

                            {!isEmpty(currentMessage) &&
                              currentMessage.id === message.id && (
                                <MessageOptions />
                              )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
            </div>
          )}
        </div>
        <div className={`flex justify-start`}>
          {messages &&
            messagesPerPage <
              filteredMessages(messages, searchKey, columnFilter, filterDir)
                .length && (
              <Pagination
                currentPage={currentPage}
                lastPage={Math.ceil(
                  filteredMessages(messages, searchKey, columnFilter, filterDir)
                    .length / messagesPerPage
                )}
                onPageChange={setCurrentPage}
              />
            )}
        </div>
      </div>
    </div>
  );
}

export default AllMessages;
