import * as React from "react";

import { Link } from "react-router-dom";
import { HiCheckCircle } from "react-icons/hi";
import { Icon } from "semantic-ui-react";

import styles from "./LoginCard.module.css";

import { useStoreActions } from "../../hooks";
import BlueButton from "../../components/BlueButton";
import InputComponent from "../../components/InputComponent";
import IncorrectCreds from "../../components/Modals/CredentialErrorModal";
import { FaInfoCircle, FaArrowCircleRight, FaSpinner } from "react-icons/fa";
import { isEmpty } from "lodash";
import LoginRight from "../../static/svg/loginRight.svg";
import QIPBanner from "../../static/svg/qipBanner.svg";

const { useCallback, useReducer, useState } = React;

const loginReducer = (state, action) => {
  switch (action.type) {
    case "LOGIN": {
      const updatedInput = {
        ...state.input,
        [action.field]: action.value,
      };

      const updatedValidation = {
        ...state.validation,
        [action.field]: action.valid,
      };

      const formValidity = Object.values(updatedValidation).every(Boolean);

      return {
        input: updatedInput,
        validation: updatedValidation,
        formValidation: formValidity,
      };
    }
    default:
      return state;
  }
};

export default function LoginCard() {
  const loginAction = useStoreActions((actions) => actions.authStore.login);

  useStoreActions((state) => state.appStore.setFirstSignUp(false));

  const initialLoginState = {
    input: {
      username: "",
      password: "",
    },
    validation: {
      username: true,
      password: true,
    },
    formValidation: true,
  };

  const [loginLoading, setLoading] = useState(false);

  const [loginState, loginDispatch] = useReducer(
    loginReducer,
    initialLoginState
  );

  const changeInputDispatch = useCallback((field, value) => {
    let valid = false;

    if (value) valid = true;
    const action = {
      type: "LOGIN",
      field,
      value,
      valid,
    };
    loginDispatch(action);
  }, []);

  const [incorrectCredsModalVis, setIncorrectCredsModalVis] = useState(false);

  const onLogin = async () => {
    setLoading(true);
    const loginCreds = {
      ...loginState.input,
    };

    const response = await loginAction(loginCreds);

    if (response !== true && response !== false) {
      if (!isEmpty(response)) {
        if (response.redirect) {
          window.location.replace(response.location);
        }
      }
    }

    if (response === false) {
      setLoading(false);
      setIncorrectCredsModalVis(true);
    }
  };

  const onConfirmIncorrect = () => {
    setIncorrectCredsModalVis(false);
  };

  return (
    <div className={styles.mainContainer}>
      {incorrectCredsModalVis && (
        <IncorrectCreds
          visibility={incorrectCredsModalVis}
          onOpenModal={() => setIncorrectCredsModalVis(true)}
          onCloseModal={onConfirmIncorrect}
        />
      )}
      {loginLoading ? (
        // <div className={styles.spinnerDiv}>
        <div
          className={`w-full h-screen flex flex-col justify-center items-center`}
        >
          <FaSpinner
            className={`animate-spin text-qipGreen font-bold text-6xl`}
          />

          <span className={`block`}>Loading data...</span>
        </div>
      ) : (
        <div className={`${styles.cardContainer} block screen992:flex `}>
          <div className={`${styles.cardLeft} p-12`}>
            <h2 className={`${styles.headerText} my-10`}>
              Administrator&nbsp;login
            </h2>

            <InputComponent
              fieldName="email"
              label="Your username"
              type="text"
              className={`${styles.inputField} `}
              placeholder={`Username`}
              inputValue={loginState.input.username}
              setInputValue={changeInputDispatch}
            />

            <InputComponent
              fieldName="password"
              label="Password"
              type="password"
              className={`${styles.inputField} `}
              placeholder={`************`}
              inputValue={loginState.input.password}
              setInputValue={changeInputDispatch}
            />

            <button
              className={`${styles.loginButton} px-8 flex justify-center items-center my-7`}
              onClick={onLogin}
            >
              <FaArrowCircleRight />{" "}
              <span className={`ml-2`}>LOGIN&nbsp;NOW</span>
            </button>

            <div className={styles.forgotPasswordGroup}>
              {/* <Icon className={styles.forgotPasswordIcon} name="info circle" /> */}
              <FaInfoCircle className={styles.forgotPasswordIcon} />
              <Link
                className={styles.forgotPasswordText}
                to="/qip/auth/forgot-password"
              >
                I forgot my password
              </Link>
            </div>
          </div>

          <div
            className={`${styles.cardRight} hidden screen992:block bg-cover bg-center relative`}
            style={{ backgroundImage: `url(${LoginRight})` }}
          >
            <div
              className={`absolute top-0 right-0 mt-8 mr-8 z-50`}
              style={{
                width: "174px",
                height: "33px",
                backgroundImage: `url(${QIPBanner})`,
              }}
            >
              {" "}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
