import React from "react";
import ReactDOM from "react-dom";

import { StoreProvider } from "easy-peasy";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

// import './index.css';
import "./app.scss";
import App from "./App";
import mainStore from "./store/mainStore";
import * as serviceWorker from "./serviceWorker";
// const stripePromise = loadStripe("pk_live_51HjLRMDeO3XG77wNHweMnNsYOuMyvxnYrbED6yi3bkM7qjoTi5A3hiTfI5DHZfJrCz3cnwl2urwgPJZAUtE6zmrE00mME9I8tG");

const stripePromise = loadStripe(
  "pk_test_51HjLRMDeO3XG77wNhwW9qSymKTSUt1JpKn0AkJXx98oX98jZnR2OH8FuPrZ2nuetqtnKedHaVDcXWjKAV9JRo8cR00w3WbJKpG"
);

ReactDOM.render(
  <React.StrictMode>
    <StoreProvider store={mainStore}>
      <Elements stripe={stripePromise}>
        <App />
      </Elements>
    </StoreProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
