import * as React from "react";

import PropTypes from "prop-types";
import { Modal, Button, Icon } from "semantic-ui-react";
import InputComponent from "../../InputComponent";
import styles from "./AddAppResourceModal.module.css";
import { FaFileUpload, FaSpinner } from "react-icons/fa";
import { uploadResourceTutorial } from "../../../api/requests";
import ReactQuill from "react-quill";

const { useState, useRef, useCallback, useReducer } = React;

const reducer = (state, action) => {
  switch (action.type) {
    case "CHANGE": {
      const updatedInput = {
        ...state.input,
        [action.field]: action.value,
      };

      const updatedValidation = {
        ...state.validation,
        [action.field]: action.valid,
      };

      const validation = Object.values(updatedValidation).every(Boolean);

      return {
        input: updatedInput,
        validation: updatedValidation,
        fieldsValidation: validation,
      };
    }
  }
};

function AddAppResourceModal({ visibility, onOpenModal, onCloseModal }) {
  const [loading, setLoading] = useState(false);

  const inputRef = useRef();

  const [fieldsChecked, setFieldsChecked] = useState(false);

  const changeInputDispatch = useCallback((field, value) => {
    let valid = false;
    let rvalue = value;
    if (value.length > 0) {
      if (field === "tutorialDescription") {
        if (value.trim() === "<p><br></p>") {
          valid = false;
          rvalue = "";
        } else {
          valid = true;
        }
      } else {
        valid = true;
      }
    }

    const action = {
      type: "CHANGE",
      field,
      value: rvalue,
      valid,
    };

    fieldsDispatch(action);
  }, []);

  const onSelectResource = (event) => {
    try {
      const files = event.target.files;

      const reader = new FileReader();

      reader.readAsDataURL(files[0]);

      reader.onload = (e) =>
        changeInputDispatch("base64Image", e.target.result);
    } catch (error) {
      console.log(error);
    }
  };

  const modules = {
    toolbar: [
      ["bold", "italic", "underline", "string"],
      ["blockquote", "code-block"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ script: "sub" }, { script: "super" }],
      [{ indent: "-1" }, { indent: "+1" }],
      [{ direction: "rtl" }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ color: [] }, { background: [] }],
      [{ font: [] }],
      [{ align: [] }],
      ["link", "image"],
    ],
  };

  const initialState = {
    input: {
      base64Image: "",
      tutorialName: "",
      tutorialDescription: "",
      tutorialLink: "",
      tutorialAuthor: "",
      tutorialDate: "",
    },
    validation: {
      tutorialName: false,
      tutorialDescription: false,
      tutorialLink: false,
      tutorialAuthor: false,
      tutorialDate: false,
    },
    fieldsValidation: false,
  };

  const [fieldsState, fieldsDispatch] = useReducer(reducer, initialState);

  const onSaveResource = async () => {
    setFieldsChecked(true);

    if (!fieldsState.fieldsValidation) {
      return;
    }

    try {
      setLoading(true);
      const response = await uploadResourceTutorial({ ...fieldsState.input });

      if (response) {
        onCloseModal();
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  return (
    <Modal
      size="large"
      centered
      open={visibility}
      onOpen={onOpenModal}
      onClose={onCloseModal}
    >
      <div className={`${styles.modalContainer} flex flex-col`}>
        <Button
          className={styles.closeButton}
          onClick={onCloseModal}
          size="big"
          icon="close"
        />
        <text className={`${styles.headerText} block mb-2.5`}>
          Add app tutorial
        </text>
        <text className={`${styles.subText} block mb-20`}>
          Add details below to for a new app tutorial for the resources section
        </text>

        <div className={`${styles.Container}`}>
          <div>
            <input
              ref={inputRef}
              onChange={onSelectResource}
              type="file"
              accept="image/jpeg"
              style={{ display: "none" }}
            />

            <span className={`${styles.uploadResourceTitle} block mb-2.5`}>
              Upload feature image (JPG 600x400px)
            </span>

            <div
              onClick={() => inputRef.current.click()}
              className={`${styles.uploadResourcePlaceholder} text-center py-5`}
            >
              {fieldsState.input.base64Image ? (
                <div className={`w-full flex justify-center items-center`}>
                  <img
                    className={`bg-cover bg-center h-72 w-auto`}
                    src={fieldsState.input.base64Image}
                  />
                </div>
              ) : (
                <div>
                  <Icon className={styles.uploadResourceIcon} name="arrow up" />

                  <text
                    className={`${styles.uploadResourceIconText} block mt-5`}
                  >
                    Upload feature image
                  </text>
                </div>
              )}
            </div>
            {fieldsChecked && !fieldsState.validation.base64Image && (
              <div className={`text-red-400 p-2 font-thin italic`}>
                This field is required.
              </div>
            )}
          </div>

          <InputComponent
            fieldName="tutorialName"
            type="text"
            label="Tutorial name"
            placeholder="Tutorial name"
            required={true}
            showNotFilled={
              fieldsChecked && !fieldsState.validation.tutorialName
            }
            setInputValue={changeInputDispatch}
          />

          <div>
            <h4
              className={`pb-2`}
              style={{ fontSize: "20px", lineHeight: "28px" }}
            >
              Tutorial description<span className={`text-red-400`}>*</span>
            </h4>
            <div className={`h-64 pb-14 `}>
              <ReactQuill
                className={`h-full `}
                modules={modules}
                value={fieldsState.input.tutorialDescription}
                onChange={(value) =>
                  changeInputDispatch("tutorialDescription", value)
                }
              />
              <textarea
                className={`hidden`}
                value={fieldsState.input.tutorialDescription}
                onChange={() => null}
              />
            </div>
            {fieldsChecked && !fieldsState.validation.tutorialDescription && (
              <div className={`text-red-400 p-2 font-thin italic `}>
                This field is required.
              </div>
            )}
          </div>

          <InputComponent
            fieldName="tutorialLink"
            type="text"
            label="Tutorial link (YouTube)"
            placeholder="Tutorial link (YouTube)"
            required={true}
            showNotFilled={
              fieldsChecked && !fieldsState.validation.tutorialLink
            }
            setInputValue={changeInputDispatch}
          />

          <div className={`grid grid-cols-2 gap-x-8`}>
            <InputComponent
              fieldName="tutorialAuthor"
              type="text"
              label="Tutorial by (Author)"
              placeholder="Tutorial by (Author)"
              required={true}
              showNotFilled={
                fieldsChecked && !fieldsState.validation.tutorialAuthor
              }
              setInputValue={changeInputDispatch}
            />

            <InputComponent
              fieldName="tutorialDate"
              type="date"
              label="Tutorial date (date)"
              required={true}
              showNotFilled={
                fieldsChecked && !fieldsState.validation.tutorialDate
              }
              setInputValue={changeInputDispatch}
            />
          </div>

          <div className={`text-center mt-11`}>
            <button
              disabled={loading}
              onClick={onSaveResource}
              className={`${styles.saveButton}`}
            >
              {loading ? (
                <div className={`flex justify-center items-center`}>
                  {" "}
                  <FaSpinner className={`animate-spin`} />{" "}
                  <span className={`ml-2`}>Saving...</span>{" "}
                </div>
              ) : (
                `Save resource`
              )}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

AddAppResourceModal.propTypes = {
  visibility: PropTypes.bool.isRequired,
  onOpenModal: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
};

export default AddAppResourceModal;
