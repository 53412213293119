import * as React from "react";

import PropTypes from "prop-types";
import { FaEye } from "react-icons/fa";

import styles from "./InputComponent.module.css";

const { useEffect, useState } = React;

function InputComponent({
  className,
  fieldName,
  label,
  type,
  textArea,
  required,
  disabled,
  readOnly,
  // isInvalid,
  placeholder,
  inputValue,
  setInputValue,
  showNotFilled = false,
  stateValue,
  toggle,
  showToggle = false,
  onToggle,
}) {
  const [value, setValue] = useState(inputValue ? inputValue : "");

  const [getReadOnly, setReadOnly] = useState(readOnly);
  const [inputType, setType] = useState(type);
  const [isPassword, setPassword] = useState(false);

  const onTogglePassword = () => {
    if (inputType === "password") setType("text");
    else setType("password");
  };

  useEffect(() => {
    setType(type);
    setPassword(type === "password" ? true : false);
  }, [label, type]);

  return (
    <div className={styles.inputGroup}>
      <text className={styles.inputName}>
        {label}
        {required && <span className={styles.inputWarning}>*</span>}
      </text>

      {isPassword ? (
        <>
          <div className={styles.passwordContainer}>
            {textArea ? (
              <textarea
                // className={`${styles.inputTextArea} ${className} ${isInvalid && styles.inputInvalid}`}
                className={`${styles.inputTextArea} ${className}`}
                required
                value={value}
                disabled={disabled}
                onChange={(e) => setValue(e.target.value)}
                onBlur={
                  setInputValue
                    ? () =>
                        setInputValue(
                          fieldName,
                          value.trim(),
                          required && value.trim() ? true : false
                        )
                    : null
                } // setInputValue dispatch function set to optional temporarily for testing
              />
            ) : (
              <input
                // className={`${styles.passwordInputBox} ${className} ${isInvalid && styles.inputInvalid}`}
                className={`${styles.passwordInputBox} ${className}`}
                value={value}
                placeholder={placeholder}
                type={inputType}
                disabled={disabled}
                onChange={(e) => setValue(e.target.value)}
                onBlur={
                  setInputValue
                    ? () =>
                        setInputValue(
                          fieldName,
                          value.trim(),
                          required && value.trim() ? true : false
                        )
                    : null
                }
              />
            )}
            <FaEye
              className={styles.passwordIcon}
              onClick={onTogglePassword}
              size={24}
            />
          </div>
          {required && showNotFilled && value.trim().length <= 0 && (
            <div className={`text-red-400 p-2 font-thin italic`}>
              This field is required.
            </div>
          )}
        </>
      ) : textArea ? (
        <>
          <textarea
            // className={`${styles.inputTextArea} ${className} ${isInvalid && styles.inputInvalid}`}
            className={`${styles.inputTextArea} ${className}`}
            value={stateValue ? stateValue : value}
            required={required}
            disabled={disabled}
            placeholder={placeholder}
            onChange={(e) => setValue(e.target.value)}
            onBlur={
              setInputValue
                ? () =>
                    setInputValue(
                      fieldName,
                      value.trim(),
                      required && value.trim() ? true : false
                    )
                : null
            }
          />
          {required && showNotFilled && value.trim().length <= 0 && (
            <div className={`text-red-400 p-2 font-thin italic`}>
              This field is required.
            </div>
          )}
        </>
      ) : (
        <>
          <input
            // className={`${styles.inputBox} ${className} ${isInvalid && styles.inputInvalid}`}
            className={`${styles.inputBox} ${className}`}
            value={stateValue ? stateValue : value}
            type={inputType}
            placeholder={placeholder}
            disabled={disabled}
            required={required}
            readOnly={getReadOnly}
            onClick={(e) => {
              if (showToggle) {
                onToggle(!toggle);
              }

              setReadOnly(false);
            }}
            onChange={(e) => setValue(e.target.value)}
            onBlur={
              setInputValue
                ? () => {
                    setInputValue(
                      fieldName,
                      value.trim(),
                      required && value.trim() ? true : false
                    );
                    setReadOnly(readOnly);
                  }
                : null
            }
          />
          {required && showNotFilled && value.trim().length <= 0 && (
            <div className={`text-red-400 p-2 font-thin italic`}>
              This field is required.
            </div>
          )}
        </>
      )}
    </div>
  );
}

InputComponent.defaultProps = {
  textArea: false,
};

InputComponent.propTypes = {
  fieldName: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  isInvalid: PropTypes.bool,
  type: PropTypes.string,
  textArea: PropTypes.bool,
  inputValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setInputValue: PropTypes.func,
  onToggle: PropTypes.func,
};

export default InputComponent;
