import * as React from "react";
import { Icon } from "semantic-ui-react";
import { useHistory, Link, useLocation } from "react-router-dom";
import {
  FaFlagCheckered,
  FaEnvelope,
  FaCog,
  FaUsers,
  FaRegCommentDots,
  FaQuestion,
  FaUserAlt,
  FaHeart,
  FaList,
} from "react-icons/fa";

import styles from "./Header.module.css";
import logoImg from "../../static/images/logo_flat.png";
import { useStoreState } from "../../hooks";
import LogoutModal from "../Modals/LogoutModal";

const { useState } = React;

export default function Header() {
  const location = useLocation();
  const isLoggedIn = useStoreState((state) => state.authStore.isLoggedIn);

  const [logoutModalVisibility, setLogoutModalVisibility] = useState(false);

  const hub_admin_url = process.env.REACT_APP_HUB_ADMIN_URL;

  return (
    <>
      {logoutModalVisibility && (
        <LogoutModal
          visibility={logoutModalVisibility}
          onOpenModal={() => setLogoutModalVisibility(true)}
          onCloseModal={() => setLogoutModalVisibility(false)}
        />
      )}
      {isLoggedIn && (
        <div className={`bg-qipBlack`}>
          <div className={`text-white text-opacity-60 py-4 ${styles.row}`}>
            <div className="flex flex-1 justify-end">
              <div className="flex">
                <ul className={styles.blackMenu}>
                  <li>
                    <Link
                      to={{ pathname: `${hub_admin_url}messages` }}
                      target="_blank"
                      className={`${
                        location.pathname === "/qip/messages"
                          ? "text-white"
                          : ""
                      } hover:text-white flex`}
                    >
                      <FaEnvelope />
                      Messages
                    </Link>
                  </li>
                  {/* <li>
                    <Link
                      to="#"
                      className={`${
                        location.pathname === "/users" ? "text-white" : ""
                      } hover:text-white flex`}
                    >
                      <FaUsers />
                      Users
                    </Link>
                  </li> */}
                  <li>
                    <Link
                      to={{ pathname: hub_admin_url }}
                      target="_blank"
                      className={`${
                        location.pathname === "/qip/subscribers/summary"
                          ? "text-white"
                          : ""
                      } hover:text-white flex`}
                    >
                      <FaList />
                      Subscriptions
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={{
                        pathname: `https://holisticmanagement.net.au/get-help/`,
                      }}
                      target="_blank"
                      className={`${
                        location.pathname === "/help" ? "text-white" : ""
                      } hover:text-white flex`}
                    >
                      <FaHeart />
                      Help
                    </Link>
                  </li>
                  <li>
                    <button
                      className="rounded bg-white bg-opacity-20 text-white text-opacity-60 hover:text-white hover:bg-opacity-40 px-5 py-2"
                      onClick={() => setLogoutModalVisibility(true)}
                    >
                      Logout
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className={`bg-white text-qipBlack py-4 ${styles.header}`}>
        <div className={styles.row}>
          <div className={styles.logo}>
            <Link to="/">
              <img src={logoImg} alt="QIP Logo" />
            </Link>
          </div>
          {isLoggedIn && (
            <div className={styles.menu}>
              <div>
                <ul className={styles.menuList}>
                  <li>
                    <Link
                      to="/qip/questions"
                      className={`${
                        location.pathname === "/qip/questions"
                          ? "bg-qipGreen text-white"
                          : ""
                      } hover:text-white hover:bg-qipGreen`}
                    >
                      QUESTIONS
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/qip/request-feedback/list"
                      className={`${
                        location.pathname === "/qip/request-feedback/list"
                          ? "bg-qipGreen text-white"
                          : ""
                      } hover:text-white hover:bg-qipGreen`}
                    >
                      FEEDBACK
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/qip/resources"
                      className={`${
                        location.pathname === "/qip/resources"
                          ? "bg-qipGreen text-white"
                          : ""
                      } hover:text-white hover:bg-qipGreen`}
                    >
                      RESOURCES
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
