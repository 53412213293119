import React from "react";
import styles from "./ServiceSignup.module.css";
import {
  fetchServiceDetails,
  updateServiceDetails,
  fetchUserCenters,
} from "../../api/requests";
import { Route, Switch, useRouteMatch, Redirect } from "react-router-dom";
import ServiceTypeForm from "../../pages/ServiceTypeForm/ServiceTypeForm";
import ServiceNameForm from "../../pages/ServiceNameForm/ServiceNameForm";
import { ServiceSignupContext } from "../../context/ServiceSignupContext";
import { useStoreActions } from "../../hooks";
import { isEmpty } from "lodash";

const { useState, useEffect, useCallback } = React;

const ServiceSignup = () => {
  const match = useRouteMatch();
  const [serviceCatId, setServiceCatId] = useState(0);
  const [serviceName, setServiceName] = useState("");
  const [loading, setLoading] = useState(false);
  const [userId, setUserId] = useState();
  const [accessToken, setAccessToken] = useState("");
  const [innerHeight, setInnerHeight] = useState(0);

  const setAuthObject = useStoreActions(
    (actions) => actions.authStore.setAuthObject
  );
  const fetchUserDetails = useStoreActions(
    (actions) => actions.userStore.fetchUserDetails
  );

  const setServices = useStoreActions(
    (actions) => actions.authStore.setServices
  );
  const setService = useStoreActions(
    (actions) => actions.authStore.setSelectedService
  );

  const fetchServices = useStoreActions(
    (actions) => actions.authStore.fetchServices
  );

  const saveServiceDetails = async (currentServiceDetails) => {
    currentServiceDetails.serviceCatId = serviceCatId;
    currentServiceDetails.name = serviceName;

    try {
      const response = await updateServiceDetails(
        {
          ...currentServiceDetails,
          userId,
        },
        currentServiceDetails.id,
        accessToken
      );

      setAuthObject({
        userId: Number(userId),
        accessToken: accessToken,
      });

      await fetchUserDetails({
        centerId: Number(currentServiceDetails.id),
        userId: Number(userId),
      });

      await fetchServices(Number(userId));
    } catch (error) {
      console.log(error);
    }
  };

  const getServiceDetails = async () => {
    try {
      const response = await fetchServiceDetails(userId, accessToken);
      if (!isEmpty(response)) {
        await saveServiceDetails(response);
        localStorage.setItem(`${userId}_completed`, "_completed");
        window.location = "/nqa";
        // setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onFinish = async () => {
    setLoading(true);
    await getServiceDetails();
  };

  return (
    <ServiceSignupContext.Provider
      value={{
        serviceCatId,
        serviceName,
        loading,
        onFinish,
        setUserId,
        setAccessToken,
        innerHeight,
      }}
    >
      <div
        ref={(page) => {
          if (!page) return;
          setInnerHeight(window.innerHeight);
        }}
        className={`overflow-hidden max-h-screen h-screen relative `}
      >
        <div className={styles.circle1}>
          {" "}
          <div className={styles.circle3}> </div>{" "}
        </div>
        <div className={styles.circle2}>
          {" "}
          <div className={styles.circle4}> </div>
        </div>

        <div className={styles.circle5}> </div>
        <div
          className={`min-h-screen h-screen flex flex-col justify-center items-center`}
        >
          <Switch>
            <Route exact path={`${match.path}`}>
              <ServiceTypeForm
                innerHeight={innerHeight}
                setCatId={setServiceCatId}
              />
            </Route>
            <Route exact path={`${match.path}/service-name`}>
              <ServiceNameForm
                innerHeight={innerHeight}
                onFinish={() => {
                  if (serviceName) {
                    onFinish();
                  }
                }}
                setName={setServiceName}
              />
            </Route>
          </Switch>
        </div>
      </div>
    </ServiceSignupContext.Provider>
  );
};

export default ServiceSignup;
