import * as React from "react";

export default function Footer() {
  return (
    <footer className="bg-black mt-auto">
      <p className="text-center text-white text-opacity-60 p-9">
        Copyright © 2021 Holistic Management And Consultancy. All Rights
        Reserved
      </p>
    </footer>
  );
}
