import * as React from "react";

import { Link, useHistory, Redirect } from "react-router-dom";
import { HiCheckCircle } from "react-icons/hi";
import { Icon } from "semantic-ui-react";

import styles from "./LoginOnly.module.css";

import { useStoreActions } from "../../hooks";
import InputComponent from "../../components/InputComponent";
import IncorrectCreds from "../../components/Modals/CredentialErrorModal";
import { FaInfoCircle, FaSpinner } from "react-icons/fa";
import { isEmpty } from "lodash";

const { useCallback, useReducer, useState } = React;

const loginReducer = (state, action) => {
  switch (action.type) {
    case "LOGIN": {
      const updatedInput = {
        ...state.input,
        [action.field]: action.value,
      };

      const updatedValidation = {
        ...state.validation,
        [action.field]: action.valid,
      };

      const formValidity = Object.values(updatedValidation).every(Boolean);

      return {
        input: updatedInput,
        validation: updatedValidation,
        formValidation: formValidity,
      };
    }
    default:
      return state;
  }
};

export default function LoginOnly() {
  const loginAction = useStoreActions((actions) => actions.authStore.login);
  useStoreActions((state) => state.appStore.setFirstSignUp(false));

  const history = useHistory();

  const initialLoginState = {
    input: {
      username: "",
      password: "",
    },
    validation: {
      username: true,
      password: true,
    },
    formValidation: true,
  };

  const [loginLoading, setLoading] = useState(false);

  const [loginState, loginDispatch] = useReducer(
    loginReducer,
    initialLoginState
  );

  const changeInputDispatch = useCallback((field, value) => {
    let valid = false;

    if (value) valid = true;
    const action = {
      type: "LOGIN",
      field,
      value,
      valid,
    };
    loginDispatch(action);
  }, []);

  const [incorrectCredsModalVis, setIncorrectCredsModalVis] = useState(false);

  const onLogin = async () => {
    setLoading(true);
    const loginCreds = {
      ...loginState.input,
    };

    const response = await loginAction(loginCreds).then((res) => {
      if (res !== true && res !== false) {
        if (!isEmpty(res)) {
          if (res.redirect) {
            window.location.replace(res.location);
          }
        }
      }

      if (res === false) {
        setLoading(false);
        setIncorrectCredsModalVis(true);
      }

      window.location.replace("/qip/pricing-plans");
    });
  };

  const onConfirmIncorrect = () => {
    setIncorrectCredsModalVis(false);
  };

  return (
    <div className={styles.mainContainer}>
      {incorrectCredsModalVis && (
        <IncorrectCreds
          visibility={incorrectCredsModalVis}
          onOpenModal={() => setIncorrectCredsModalVis(true)}
          onCloseModal={onConfirmIncorrect}
        />
      )}
      {loginLoading ? (
        <div
          className={`w-full h-screen flex flex-col justify-center items-center`}
        >
          <FaSpinner
            className={`animate-spin text-qipGreen font-bold text-6xl`}
          />

          <span className={`block`}>Loading data...</span>
        </div>
      ) : (
        <div className={styles.cardContainer}>
          <div className={styles.card}>
            <h2 className={styles.headerText}>Login</h2>
            <text className={styles.lightText}>Sign in below.</text>

            <InputComponent
              fieldName="email"
              label="Username"
              type="text"
              inputValue={loginState.input.username}
              setInputValue={changeInputDispatch}
            />

            <InputComponent
              fieldName="password"
              label="Password"
              type="password"
              inputValue={loginState.input.password}
              setInputValue={changeInputDispatch}
            />

            <button className={styles.loginButton} onClick={onLogin}>
              Login
            </button>

            <div className={styles.forgotPasswordGroup}>
              {/* <Icon className={styles.forgotPasswordIcon} name="info circle" /> */}
              <FaInfoCircle className={styles.forgotPasswordIcon} />
              <Link
                className={styles.forgotPasswordText}
                to="/qip/auth/forgot-password"
              >
                I forgot my password
              </Link>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
