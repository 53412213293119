import * as React from 'react';

import PropTypes from 'prop-types';
import { Button, Modal } from 'semantic-ui-react';
  
import BlueButton from '../../BlueButton';
import InputComponent from '../../InputComponent';
import styles from './UserCreateNewPasswordModal.module.css';

const { useState } = React;

function UserCreateNewPasswordModal({ visibility, onOpenModal, onCloseModal, onSavePassword, onChangedPassword }) {

  return (
    <Modal
      size="small" 
      centered={true}
      open={visibility}
      onOpen={onOpenModal}
      onClose={onCloseModal}
    >
      <div className={styles.modalContainer}>

        <Button
          className={styles.closeButton}
          onClick={onCloseModal} 
          size="big"
          icon="close"
        />

        <text className={styles.headerText}>
          Create a new password
        </text>

        <div className={styles.inputRow}>

          <InputComponent
            className={styles.inputBox}
            label="Enter new password"
            type="password"
            fieldName="password"
          />

          <InputComponent
            className={styles.inputBox}
            label="Repeat new password"
            type="password"
            fieldName="passwordConfirm"
          />

        </div>

        <BlueButton
          className={styles.saveButton}
          buttonText="Save password"
          onClick={onChangedPassword}
        />


      </div>

    </Modal>
  );
}

UserCreateNewPasswordModal.propTypes = {
  visibility: PropTypes.bool.isRequired,
  onOpenModal: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  onSavePassword: PropTypes.func.isRequired,
  onChangedPassword: PropTypes.func.isRequired,
};

export default UserCreateNewPasswordModal;
