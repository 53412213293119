import * as React from "react";

import PropTypes from "prop-types";
import { Modal, Button, Icon } from "semantic-ui-react";
import InputComponent from "../../InputComponent";
import styles from "./EditShopResourceModal.module.css";
import imgSelected from "../../../static/images/webinarBox1.png";
import {
  updateResourceShop,
  removeShopImage,
  imageShopLink,
} from "../../../api/requests";
import { FaSpinner } from "react-icons/fa";
import ReactQuill from "react-quill";

const { useState, useCallback, useReducer, useRef, useEffect } = React;

const reducer = (state, action) => {
  switch (action.type) {
    case "UPDATE": {
      const updatedInput = {
        ...state.input,
        [action.field]: action.value,
      };

      const updatedValidation = {
        ...state.validation,
        [action.field]: action.valid,
      };

      const validity = Object.values(updatedValidation).every(Boolean);

      return {
        input: updatedInput,
        validation: updatedValidation,
        fieldsValidation: validity,
      };
    }
  }
};

function EditShopResourceModal({
  visibility,
  shopLink,
  resource,
  onOpenModal,
  onCloseModal,
}) {
  const [saving, setSaving] = useState(false);

  const downloadRef = useRef();
  const inputRef = useRef();

  const [fieldsChecked, setFieldsChecked] = useState(false);

  const [downloadLink, setDownloadLink] = useState("");

  const modules = {
    toolbar: [
      ["bold", "italic", "underline", "string"],
      ["blockquote", "code-block"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ script: "sub" }, { script: "super" }],
      [{ indent: "-1" }, { indent: "+1" }],
      [{ direction: "rtl" }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ color: [] }, { background: [] }],
      [{ font: [] }],
      [{ align: [] }],
      ["link", "image"],
    ],
  };

  const initialState = {
    input: {
      thumbnail: shopLink,
      productName: resource.product_name,
      productDescription: resource.product_description,
      productPrice: resource.product_price,
      productLink: resource.product_link,
    },
    validation: {
      thumbnail: true,
      productName: true,
      productDescription: true,
      productPrice: true,
      productLink: true,
    },
    fieldsValidation: false,
  };

  const [fieldsState, fieldsDispatch] = useReducer(reducer, initialState);

  const changeInputDispatch = useCallback((field, value) => {
    let valid = false;
    let rvalue = value;
    if (value.length > 0) {
      if (field === "productDescription") {
        if (value.trim() === "<p><br></p>") {
          valid = false;
          rvalue = "";
        } else {
          valid = true;
        }
      } else {
        valid = true;
      }
    }

    const action = {
      type: "UPDATE",
      field,
      value: rvalue,
      valid,
    };
    fieldsDispatch(action);
  });

  const onUpdateResource = async () => {
    setFieldsChecked(true);
    if (!fieldsState.fieldsValidation) {
      return;
    }

    try {
      setSaving(true);
      const response = await updateResourceShop({
        ...fieldsState.input,
        id: resource.id,
      });
      if (response) {
        onCloseModal();
      }
      setSaving(false);
    } catch (error) {
      setSaving(false);
      console.log(error);
    }
  };

  const onSelectResource = (event) => {
    try {
      const files = event.target.files;

      const reader = new FileReader();

      reader.readAsDataURL(files[0]);

      reader.onload = (e) => changeInputDispatch("thumbnail", e.target.result);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Modal
      size="large"
      centered
      open={visibility}
      onOpen={onOpenModal}
      onClose={onCloseModal}
    >
      <div className={`${styles.modalContainer} flex flex-col`}>
        <Button
          className={styles.closeButton}
          onClick={onCloseModal}
          size="big"
          icon="close"
        />
        <text className={`${styles.headerText} block mb-2.5`}>
          Edit shop resource
        </text>
        <text className={`${styles.subText} block mb-20`}>
          Edit details below to link to the Holistic Management & Consultancy
          website shop
        </text>

        <div className={`${styles.Container}`}>
          <div>
            <input
              ref={inputRef}
              onChange={onSelectResource}
              type="file"
              accept={`image/jpeg, image/jpg`}
              style={{ display: "none" }}
            />

            <span className={`${styles.uploadResourceTitle} block mb-2.5`}>
              {fieldsState.input.thumbnail ? `Uploaded` : `Upload`} feature
              image (JPG 600x400px)
            </span>

            <div
              className={`${styles.selectedFeatureImage} flex justify-between`}
            >
              {fieldsState.input.thumbnail ? (
                <div className={`${styles.featureImage}`}>
                  {/* <img src={imgSelected} /> */}
                  <img src={fieldsState.input.thumbnail} />
                </div>
              ) : (
                <div
                  onClick={() => inputRef.current.click()}
                  className={`flex flex-col justify-center items-center cursor-pointer`}
                >
                  <Icon className={styles.uploadResourceIcon} name="arrow up" />

                  <text
                    className={`${styles.uploadResourceIconText} block mt-5`}
                  >
                    Upload feature image
                  </text>
                </div>
              )}

              <div
                className={`${styles.actionButtons} grid grid-cols-2 gap-x-10`}
              >
                <div className={`${styles.actionButton}`}>
                  <Button
                    disabled={!fieldsState.validation.thumbnail}
                    onClick={() => changeInputDispatch("thumbnail", "")}
                    className={styles.removeImage}
                    icon="close"
                  />

                  <text>Remove</text>
                </div>

                <div className={`${styles.actionButton}`}>
                  <Button
                    onClick={() => downloadRef.current.click()}
                    className={styles.downloadImage}
                    icon="download"
                  />

                  <text>Download</text>
                </div>
                <a
                  ref={downloadRef}
                  className={`hidden`}
                  href={shopLink}
                  target={`_blank`}
                  download={resource.thumbnail}
                />
              </div>
            </div>

            <div
              className={`${styles.uploadResourcePlaceholder} text-center py-5`}
              style={{ display: "none" }}
            >
              <div>
                <Icon className={styles.uploadResourceIcon} name="arrow up" />

                <text className={`${styles.uploadResourceIconText} block mt-5`}>
                  Upload feature image
                </text>
              </div>
            </div>
          </div>
          {fieldsChecked && !fieldsState.validation.thumbnail && (
            <div className={`text-red-400 p-2 font-thin italic `}>
              This field is required.
            </div>
          )}

          <InputComponent
            fieldName="productName"
            type="text"
            label="Product name"
            placeholder="Product name"
            inputValue={fieldsState.input.productName}
            required={true}
            showNotFilled={fieldsChecked && !fieldsState.validation.productName}
            setInputValue={changeInputDispatch}
          />

          <div>
            <h4
              className={`pb-2`}
              style={{ fontSize: "20px", lineHeight: "28px" }}
            >
              Product description<span className={`text-red-400`}>*</span>
            </h4>
            <div className={`h-64 pb-14 `}>
              <ReactQuill
                className={`h-full `}
                modules={modules}
                value={fieldsState.input.productDescription}
                onChange={(value) =>
                  changeInputDispatch("productDescription", value)
                }
              />
              <textarea
                className={`hidden`}
                value={fieldsState.input.productDescription}
                onChange={() => null}
              />
            </div>
            {fieldsChecked && !fieldsState.validation.productDescription && (
              <div className={`text-red-400 p-2 font-thin italic `}>
                This field is required.
              </div>
            )}
          </div>

          <InputComponent
            fieldName="productPrice"
            type="text"
            label="Product price"
            placeholder="Product price"
            inputValue={fieldsState.input.productPrice}
            required={true}
            showNotFilled={
              fieldsChecked && !fieldsState.validation.productPrice
            }
            setInputValue={changeInputDispatch}
          />

          <InputComponent
            fieldName="productLink"
            type="text"
            label="Product link"
            placeholder="Product link"
            inputValue={fieldsState.input.productLink}
            required={true}
            showNotFilled={fieldsChecked && !fieldsState.validation.productLink}
            setInputValue={changeInputDispatch}
          />

          <div className={`text-center mt-11`}>
            <button
              onClick={onUpdateResource}
              disabled={saving}
              className={`${styles.saveButton}`}
            >
              {saving ? (
                <div className={`flex justify-center items-center `}>
                  {" "}
                  <FaSpinner className={`animate-spin`} />{" "}
                  <span className={`ml-2`}>Saving...</span>{" "}
                </div>
              ) : (
                <> Update resource </>
              )}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

EditShopResourceModal.propTypes = {
  visibility: PropTypes.bool.isRequired,
  onOpenModal: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
};

export default EditShopResourceModal;
