import * as React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import UserList from "../../pages/Admin/UserManagement/UserList";
import CreateUser from "../../pages/Admin/UserManagement/CreateUser";
import UpdateUser from "../../pages/Admin/UserManagement/UpdateUser";

export default function AdminUserManagement () {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route
        exact
        path={`${match.path}/user-list`}
		render={() => <UserList /> }
      />
      <Route
        exact
        path={`${match.path}/create-user`}
		render={() => <CreateUser /> }
      />
      <Route
        exact
        path={`${match.path}/update-user`}
		render={() => <UpdateUser /> }
      />
    </Switch>
  );
}
