import * as React from "react";

import { useHistory, useParams } from "react-router-dom";
import { FaUsers, FaCaretDown, FaArrowAltCircleLeft } from "react-icons/fa";
import { Button, Dropdown } from "semantic-ui-react";
import BlueButton from "../../../../components/BlueButton";
import styles from "./AppUsageLogs.module.css";
import { getAppUsageLog } from "../../../../api/requests";
import Pagination from "../../../../components/Pagination/Pagination";
import useSWR, { mutate } from "swr";
import { isEmpty } from "lodash";
import moment from "moment";

const { useState, useCallback } = React;

const logsPerPage = 10;

function AppUsageLogs({ location }) {
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState(1);
  const [fetchLimitError, setFetchLimitError] = useState(false);

  const { data: AppUsageLogs, isValidating } = useSWR(
    [`/logs`, location.state.userId],
    (_, userId) => getAppUsageLog(Number(userId)),
    {
      onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
        // Only retry up to 3 times.
        if (retryCount && retryCount >= 3) {
          if (!AppUsageLogs) setFetchLimitError(true);

          return;
        }
        // Retry after 3 seconds.
        setTimeout(() => revalidate({ retryCount: retryCount + 1 }), 3000);
      },
    }
  );

  return (
    <div className={`${styles.mainContainer} mt-8 mb-28`}>
      <div
        className={`${styles.headerTextContainer} flex items-center justify-between`}
      >
        <div className={`flex`}>
          <div className={styles.headerTextIcon}>
            <FaUsers size={55} />
          </div>

          <div className={`${styles.headerText} ml-4`}>
            <h1>{!isEmpty(location.state) && location.state.userName}</h1>
            <text>App usage logs</text>
          </div>
        </div>

        <div className={`${styles.headerTextButton}`}>
          <button
            className={`flex items-center justify-center`}
            onClick={() => history.goBack()}
          >
            <FaArrowAltCircleLeft size={16} />
            Back
          </button>
        </div>
      </div>

      <div className={`${styles.container} md:px-7 lg:px-14 py-10 mt-7`}>
        <div className={`${styles.appUsageLogs}`}>
          <h2>App usage logs</h2>

          <div className={`${styles.tableContainer}`}>
            <div className={`${styles.tableHeader} flex flex-wrap`}>
              <div className={`${styles.tableHeaderName} w-1/3 flex p-4`}>
                <text>Name</text>
                <FaCaretDown className={`ml-1 mt-1`} size={12} />
              </div>
              <div className={`${styles.tableHeaderName} flex p-4`}>
                <text>Service</text>
                <FaCaretDown className={`ml-1 mt-1`} size={12} />
              </div>
              <div className={`${styles.tableHeaderName} flex p-4`}>
                <text>User Type</text>
                <FaCaretDown className={`ml-1 mt-1`} size={12} />
              </div>
              <div className={`${styles.tableHeaderName} flex p-4`}>
                <text>Last logged in</text>
                <FaCaretDown className={`ml-1 mt-1`} size={12} />
              </div>
            </div>
            <div className={`${styles.tableBody}`}>
              {!isEmpty(AppUsageLogs) &&
                AppUsageLogs.slice(
                  currentPage * logsPerPage - logsPerPage,
                  currentPage * logsPerPage
                ).map((appLog, k) => {
                  return (
                    <div
                      key={k}
                      className={`${styles.tableRow} w-full flex flex-wrap`}
                    >
                      <div className={`${styles.tableData} flex flex-col p-4`}>
                        <text>{appLog.name}</text>
                      </div>
                      <div className={`${styles.tableData} flex flex-col p-4`}>
                        <text>{appLog.centerName}</text>
                      </div>
                      <div className={`${styles.tableData} flex flex-col p-4`}>
                        <text>{appLog.type}</text>
                      </div>
                      <div className={`${styles.tableData} flex flex-col p-4`}>
                        <text>
                          {!isEmpty(appLog.lastLogin) &&
                            moment(appLog.lastLogin).format(
                              "DD/MM/YYYY [at] HH:mm"
                            )}
                        </text>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>

        <div className={`${styles.paginationContainer} flex justify-start`}>
          {!isEmpty(AppUsageLogs) && logsPerPage < AppUsageLogs.length && (
            <Pagination
              currentPage={currentPage}
              lastPage={Math.ceil(AppUsageLogs.length / logsPerPage)}
              onPageChange={setCurrentPage}
            />
          )}
        </div>

        <div className={`${styles.viewSubsAccount}`}>
          <BlueButton
            onClick={() =>
              history.push("/qip/subscribers/details", {
                ...location.state.sumLocState,
              })
            }
            buttonText="Back to subscription details"
          />
        </div>
      </div>
    </div>
  );
}

export default AppUsageLogs;
