import React from "react";
import { isEmpty } from "lodash";
import { Modal, Button, Icon } from "semantic-ui-react";

const MediaHandler = ({ resourceFile }) => {
  return (
    <div className={`flex justify-center`}>
      {console.log("resource file ", resourceFile)}

      {resourceFile.mediaType === "image" && (
        <embed
          // width="80%"
          // height="80%"
          className={`object-contain w-full h-96 `}
          src={resourceFile.file}
        />
      )}

      {resourceFile.mediaType === "text" && (
        <embed
          // width="80%"
          // height="80%"
          className={`object-contain w-full h-96 `}
          src={resourceFile.file}
        />
      )}

      {resourceFile.mediaType === "audio" && (
        <audio className={`object-contain w-full h-96 `} controls>
          <source src={resourceFile.file} />
        </audio>
      )}
      {resourceFile.mediaType === "video" && (
        <video
          type={`video/*`}
          controls
          controlsList={`nodownload nofullscreen noremoteplayback`}
          className={`object-contain w-full h-96 `}
        >
          <source src={resourceFile.file} />
        </video>
      )}

      {resourceFile.mediaType === "application" ? (
        resourceFile.type === "pdf" ? (
          <embed
            // width="80%"
            // height="80%"
            className={`object-contain w-full h-96 `}
            src={resourceFile.file}
          />
        ) : (
          <div
            className={`object-contain w-full h-96 flex justify-center items-center`}
          >
            <h1>{resourceFile.name}</h1>
          </div>
        )
      ) : (
        <> </>
      )}

      {resourceFile.mediaType !== "application" &&
        resourceFile.mediaType !== "image" &&
        resourceFile.mediaType !== "video" &&
        resourceFile.mediaType !== "audio" &&
        resourceFile.mediaType !== "text" && (
          <div
            className={`object-contain w-full h-96 flex justify-center items-center`}
          >
            <h1>{resourceFile.name}</h1>
          </div>
        )}
    </div>
  );
};

export default MediaHandler;
