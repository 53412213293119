import * as React from 'react';
import { FaRegArrowAltCircleLeft } from 'react-icons/fa';

import { useHistory } from 'react-router-dom';
import { Button, Icon } from 'semantic-ui-react';

import styles from './ServicesDashboard.module.css';
  
const { useState } = React;

const dummyFeedback = [
  {
    id: 1,
    name: 'NQA 1 - Feedback Assessment',
    nqaStatus: 2,
    reviewedBy: 'Jonathan Patterson',
    reviewedOn: '12/11/2020'
  },
  {
    id: 2,
    name: 'NQA 2 - Feedback Assessment',
    nqaStatus: 1,
    reviewedBy: 'Jonathan Patterson',
    reviewedOn: '12/11/2020'
  },
  {
    id: 3,
    name: 'NQA 3 - Feedback Assessment',
    nqaStatus: 3,
    reviewedBy: 'Jonathan Patterson',
    reviewedOn: '12/11/2020'
  },
];

const sampleBodyText = `
Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Parturient montes nascetur ridiculus mus mauris vitae ultricies leo integer. Laoreet id donec ultrices tincidunt arcu. Viverra mauris in aliquam sem. In vitae turpis massa sed.

Eget gravida cum sociis natoque penatibus et magnis dis parturient. In tellus integer feugiat scelerisque varius morbi. Aliquam sem et tortor consequat id porta. Ac turpis egestas maecenas pharetra convallis posuere.
Non odio euismod lacinia at quis risus sed vulputate. Diam sollicitudin tempor id eu nisl nunc mi ipsum faucibus. Pharetra vel turpis nunc eget lorem dolor.

Metus dictum at tempor commodo ullamcorper a lacus vestibulum. Tellus elementum sagittis vitae et leo. Semper auctor neque vitae tempus quam pellentesque nec nam aliquam. Faucibus et molestie ac feugiat sed. Aliquam ultrices sagittis orci a. Gravida arcu ac tortor dignissim. Hac habitasse platea dictumst quisque. Et molestie ac feugiat sed lectus. Volutpat lacus laoreet non curabitur gravida arcu ac tortor dignissim.
Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Parturient montes nascetur ridiculus mus mauris vitae ultricies leo integer. Laoreet id donec ultrices tincidunt arcu. Viverra mauris in aliquam sem. In vitae turpis massa sed.
              
Eget gravida cum sociis natoque penatibus et magnis dis parturient. In tellus integer feugiat scelerisque varius morbi. Aliquam sem et tortor consequat id porta. Ac turpis egestas maecenas pharetra convallis posuere.
`;

function ServicesDashboard() {
  const history = useHistory();

  const [selectedFeedback, setSelectedFeedback] = useState(dummyFeedback[0]);

  const onPreviousFeedback = () => {
    const currentIndex = dummyFeedback.indexOf(selectedFeedback);

    if (currentIndex > 0) {
      setSelectedFeedback(dummyFeedback[currentIndex - 1]);
    }
  };

  const onNextFeedback = () => {
    const currentIndex = dummyFeedback.indexOf(selectedFeedback);

    if (currentIndex < (dummyFeedback.length - 1)) {
      setSelectedFeedback(dummyFeedback[currentIndex + 1]);
    }
  };

  return (
    <div className={`${styles.mainContainer} md:mb-20 xl:mb-7`}>

      <div className={styles.backButtonContainer}>

        <button
          className={styles.backButtonText}
          onClick={() => history.goBack()}
        >
          <FaRegArrowAltCircleLeft
            className={styles.backButton}
            color="#C4C4C4"
            size={24}
          />
        </button>

      </div>

      <div className={`${styles.content} flex flex-col`}>

        <div className={`${styles.headerContainer} flex justify-between mb-16`}>

          <div className={styles.headerTextGroup}>

            <h1 className={styles.headerText}>
              Mentor NQA reviews
            </h1>

            <text className={styles.headerSubText}>
              You can find all previous mentor feedback here.
            </text>

          </div>

          <button
            className={styles.backToDashboardButton}
            onClick={() => history.push('/nqa')}
          >
            Back to dashboard
          </button>

        </div>

        {dummyFeedback.map((feedback, index) =>
          (
            <div
              key={feedback.id}
              className={selectedFeedback.id === feedback.id ? `${styles.feedbackRow} ${styles.activeFeedbackRow}` : styles.feedbackRow}
              onClick={() => setSelectedFeedback(dummyFeedback[index])}
            >
              <div>

                <text>
                  {feedback.reviewedOn}
                </text>

                <text>
                  {feedback.name}
                </text>

              </div>

              <div>

                <text className={selectedFeedback.id === feedback.id ? `${styles.feedbackButton} ${styles.activeFeedbackButton}` : styles.feedbackButton}>
                  {selectedFeedback.id === feedback.id ? 'Active' : 'Open'}
                </text>

                <Button
                  className={selectedFeedback.id === feedback.id ? `${styles.feedbackButtonIcon} ${styles.activeFeedbackButtonIcon}` : styles.feedbackButtonIcon}
                  icon={selectedFeedback.id === feedback.id ? 'arrow down' : 'arrow right'}
                  circular
                />

              </div>

            </div>
          ))}

        <div className={`${styles.cardContainer} flex md:px-8 lg:px-12 py-16 mt-6`}>

          <div className={styles.cardLeftColumn}>

            <text className={styles.cardHeaderText}>
              {selectedFeedback.name}
            </text>

            <text className={styles.cardSubText}>
              Curriculum decision making contributes to each child’s learning and development outcomes in relation to their identity,
              connection with the community, wellbeing, confidence as learners and effectiveness as communicators.
            </text>

            <text className={styles.cardHeaderText}>
              Feedback notes
            </text>

            <text className={styles.cardSubText}>
              {sampleBodyText}
            </text>

          </div>

          <div className={`${styles.cardRightColumn}`}>

            <div className={`flex items-start flex-col pl-7 lg:px-10`}>

              <text className={styles.cardHeaderText}>
                NQA Status
              </text>

              <text className={styles.statusInactive}>
                Working towards
              </text>

              <div className={styles.stepLine} />

              <text className={styles.statusActive}>
                Meeting
              </text>

              <div className={styles.stepLine} />

              <text className={styles.statusInactive}>
                Exceeding
              </text>

              <text className={styles.reviewDetailHeader}>
                Review by
              </text>

              <text className={styles.reviewDetailSubText}>
                {selectedFeedback.reviewedBy}
              </text>

              <text className={styles.reviewDetailHeader}>
                Review written on
              </text>

              <text className={styles.reviewDetailSubText}>
                {selectedFeedback.reviewedOn}
              </text>

            </div>

          </div>

        </div>

        <div className={styles.reportButtonRow}>

          <button
            className={dummyFeedback.indexOf(selectedFeedback) === 0 ? styles.prevNextReportButton : styles.activePrevNextReportButton}
            onClick={onPreviousFeedback}

          >
            <Icon name="angle double left" />
            <text>
              Previous report
            </text>
          </button>

          <button
            className={dummyFeedback.indexOf(selectedFeedback) === dummyFeedback.length - 1 ? styles.prevNextReportButton : styles.activePrevNextReportButton}
            onClick={onNextFeedback}
          >
            <text>
              Next report
            </text>
            <Icon name="angle double right" />
          </button>

        </div>

      </div>

    </div>
  );
}

export default ServicesDashboard;
